export default {
	getSelectedOperationMode: (state) => state.modes.find(mode => mode.id === state.selectedModeID),
	getOperationModePayload: (state) => {
		const fields = state.fields && state.fields.length ? state.fields : state.listOfFields
		const args = {}

		if (!state.isRating) {
			args['custom-filter[operating-mode]'] = state.selectedModeID

			if (state.selectedModeID === -1) {
				args.fields = fields.join(',') || null
			}
		} else {
			args.fields = fields.join(',') || null
		}

		return args
	},
	getCurrentKeyedFields: (state) => Object.keys(state.fieldsLocalization).reduce((fields, field) => {
		if (state.fields.includes(field)) {
			fields[field] = state.fieldsLocalization[field]
		}

		return fields
	}, {})
}
