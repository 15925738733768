const lunh = number => {
	const arr = [],
		card_number = number.toString()
	for (let i = 0; i < card_number.length; i++) {
		if (i % 2 === 0) {
			const m = parseInt(card_number[i]) * 2
			if (m > 9) {
				arr.push(m - 9)
			} else {
				arr.push(m)
			}
		} else {
			const n = parseInt(card_number[i])
			arr.push(n)
		}
	}
	const sum = arr.reduce(function (a, b) {
		return a + b
	})

	return Boolean(!(sum % 10))

}

export default lunh
