import authMiddleware from './middleware/auth'
import canMiddleware from './middleware/can'

const CRM_ROUTES = [
	{
		path: '/crm/deal/:id?',
		name: 'CRM.DEAL',
		component: () => import(/* webpackChunkName: "crm-deal" */ '../views/CRM/Deal/Store.vue'),
		meta: {
			title: 'Сделка',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to edit deal form'])
			]
		}
	},
	{
		path: '/crm/vehicle/:id?',
		name: 'CRM.VEHICLE.UPDATE',
		component: () => import(/* webpackChunkName: "crm-vehicle" */ '../views/CRM/Vehicle/Store.vue'),
		meta: {
			title: 'Транспорт',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/vehicles',
		name: 'CRM.VEHICLE.INDEX',
		component: () => import(/* webpackChunkName: "crm-vehicle" */ '../views/CRM/Vehicle/Index.vue'),
		meta: {
			title: 'Fox - Транспорт',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/contacts',
		name: 'CRM.CONTACT.INDEX',
		component: () => import(/* webpackChunkName: "crm-contact" */ '../views/CRM/Contact/Index.vue'),
		meta: {
			title: 'Fox - Контакты',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/drivers',
		name: 'CRM.DRIVER.INDEX',
		component: () => import(/* webpackChunkName: "crm-contact" */ '../views/CRM/Driver/Index.vue'),
		meta: {
			title: 'Fox - Водители',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/driver/:id?',
		name: 'CRM.DRIVER.CREATE',
		component: () => import(/* webpackChunkName: "crm-contact" */ '../views/CRM/Contact/Store.vue'),
		props: true,
		meta: {
			title: 'Водитель',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/contact/:id?',
		name: 'CRM.CONTACT.UPDATE',
		component: () => import(/* webpackChunkName: "crm-contact" */ '../views/CRM/Contact/Store.vue'),
		props: true,
		meta: {
			title: 'Контакт',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/companies',
		name: 'CRM.COMPANY.INDEX',
		component: () => import(/* webpackChunkName: "crm-company" */ '../views/CRM/Company/Index.vue'),
		meta: {
			title: 'Fox - Компании',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/company/:id',
		name: 'CRM.COMPANY.UPDATE',
		component: () => import(/* webpackChunkName: "crm-company" */ '../views/CRM/Company/Store.vue'),
		props: true,
		meta: {
			title: 'Компания',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/company/:id?',
		name: 'CRM.COMPANY.CREATE',
		component: () => import(/* webpackChunkName: "crm-company" */ '../views/CRM/Company/Store.vue'),
		props: true,
		meta: {
			title: 'Компания',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access-to-crm'])
			]
		}
	},
	{
		path: '/crm/v2/clients-orders',
		name: 'CRM.CLIENT.ORDER.V2',
		component: () => import(/* webpackChunkName: "crm-new-order" */ '../views/CRM/Order/ClientsOrdersV2.vue'),
		meta: {
			title: 'Fox - Грузы компаний',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to client orders'])
			]
		}
	},
	{
		path: '/crm/v2/clients-orders/:companyID',
		name: 'CRM.CLIENT.ORDER.FILTER.V2',
		component: () => import(
		  /* webpackChunkName: "crm-new-order" */ '../views/CRM/Order/ClientsOrderFilterTableV2.vue'
		),
		props: true,
		meta: {
			title: 'Заказы компании',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to client orders'])
			]
		}
	},
	{
		path: '/crm/locations',
		name: 'Locations',
		component: () => import(/* webpackChunkName: "crm-locations" */ '../views/CRM/Locations/BaseLayout.vue'),
		props: true,
		meta: {
			title: 'Fox - Локации',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to locations table'])
			]
		}
	},
	{
		path: '/crm/location/:id?',
		name: 'Location',
		component: () => import(/* webpackChunkName: "crm-locations" */ '../views/CRM/Locations/Store.vue'),
		props: true,
		meta: {
			title: 'Локация',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to locations table', 'access to create and delete locations'])
			]
		}
	},
	{
		path: '/crm/credit-cards',
		name: 'CreditCards',
		component: () => import(/* webpackChunkName: "crm-credit-cards" */ '../views/CRM/CreditCards/Index'),
		props: true,
		meta: {
			title: 'FOX - Банковские карты',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to the credit card table'])
			]
		}
	},
	{
		path: '/crm/credit-card/:id?',
		name: 'CreditCard',
		component: () => import(/* webpackChunkName: "crm-credit-card" */ '../views/CRM/CreditCards/Store.vue'),
		props: true,
		meta: {
			title: 'Кредитная карта',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to the credit card table'])
			]
		}
	}
]

export default CRM_ROUTES
