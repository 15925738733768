<template>
  <label
    class="fox-checkbox-wrapper"
    :class="{
      'fox-checkbox-wrapper__inline': inline,
      'fox-checkbox-wrapper__empty-label': !label && !$slots.label,
      'fox-checkbox-wrapper__as-switch': switcher,
      'fox-checkbox-wrapper__as-button': button,
      'fox-checkbox-wrapper__as-checkbox': !button && !switcher,
    }"
  >
    <template v-if="!button">
      <template v-if="label || $slots.label">
        <span v-if="label && !$slots.label" class="fox-checkbox-label">{{ label }}</span>
        <slot v-else-if="$slots.label" name="label" />
      </template>

      <input
        class="fox-checkbox"
        type="checkbox"
        :name="name"
        :checked="isSelected ? 'checked' : ''"
        :disabled="disabled"
        @change="onChange"
      >

      <template v-if="!switcher">
        <div class="control-indicator" />
      </template>
      <template v-else>
        <div class="switcher-label">
          <div class="switcher-inner" />
          <div class="switcher-switch" />
        </div>
      </template>
    </template>

    <fox-button
      v-else
      :disabled="disabled"
      :size="buttonSize"
      :variant="selectedBtnVariant"
      @click.native="onBtnBoxClick"
    >
      <span v-if="label && !$slots.label">{{ label }}</span>
      <slot v-else-if="$slots.label" name="label" />
    </fox-button>
  </label>
</template>

<script>
import FoxButton from '@/UIKit/components/button/FoxButton'

export default {
  name: 'FoxCheckbox',
  components: { FoxButton },
  props: {
    value: {
      required: false,
      default: false,
      type: [Boolean, String, Number]
    },
    name: {
      required: false,
      default: '',
      type: String
    },
    disabled: Boolean,
    inline: Boolean,
    label: {
      required: false,
      default: '',
      type: String
    },
    selected: {
      required: false,
      default: true,
      type: [Boolean, String, Number]
    },
    notSelected: {
      required: false,
      default: false,
      type: [Boolean, String, Number]
    },
    switcher: Boolean,
    button: Boolean,
    buttonVariant: {
      required: false,
      default: 'primary',
      type: String
    },
    notSelectedButtonVariant: {
      required: false,
      default: 'outline',
      type: String
    },
    buttonSize: {
      required: false,
      default: 'lg',
      type: String
    }
  },
  computed: {
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isSelected () {
      return this.vModel === this.selected
    },
    selectedBtnVariant () {
      return this.isSelected ? this.buttonVariant : this.notSelectedButtonVariant
    }
  },
  methods: {
    onBtnBoxClick () {
      if (!this.button) {
        return false
      }

      this.vModel = !this.isSelected ? this.selected : this.notSelected
    },
    onChange ($event) {
      this.vModel = $event.target.checked ? this.selected : this.notSelected
    }
  }
}
</script>

<style scoped lang="sass">
@import '../../scss/components/checkbox'
</style>
