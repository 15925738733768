import axios from 'axios'
const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	getCreditCards (payload = {}) {
		return axios.get(`${HOST_URL}/credit-card`, {
			params: payload
		})
	},
	getCreditCard (id) {
		return axios.get(`${HOST_URL}/credit-card/${id}`)
	},
	createCreditCard (payload = {}) {
		return axios.post(`${HOST_URL}/credit-card`, payload)
	},
	updateCreditCard (id, payload = {}) {
		return axios.put(`${HOST_URL}/credit-card/${id}`, payload, {
			'_method': 'PUT'
		})
	},
	deleteCreditCard (id) {
		return axios.delete(`${HOST_URL}/credit-card/${id}`)
	},
	removeRelation (payload = {}) {
		return axios.post(`${HOST_URL}/remove-relations-card`, payload)
	},
	removeRelationCompany (payload = {}) {
		return axios.post(`${HOST_URL}/remove-relation-with-company`, payload)
	},
	addResponsible (card_id, contact_id) {
		return axios.post(`${HOST_URL}/add-manager-to-card/${card_id}/${contact_id}`)
	},
	removeRelationContact (payload = {}) {
		return axios.post(`${HOST_URL}/remove-relation-with-contact`, payload)
	}

}