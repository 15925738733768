import fileTypes from '../config/file-types'

export function getAvatarsFromFiles (files = [], type = null) {
	return files.reduce((memo, file) => {
		if (file) {
			let avatar = ''
          
		    switch (type) {
			case 'company':
				avatar = fileTypes.COMPANY_AVATAR
				break;
			case 'vehicle':
				avatar = fileTypes.VEHICLE_AVATAR
				break;
			default:
				avatar = fileTypes.AVATAR
			}

			const typeByID = file.document_type && file.document_type.id === avatar
			const typeByCustomKey = file.document_type_id === avatar


			if (typeByID || typeByCustomKey) {
				memo.push(file)
			}
		}

		return memo
	}, [])
}

export function getLastAvatar (files = [], type = null) {
	const avatars = getAvatarsFromFiles(files, type)

	return avatars.length ? avatars[avatars.length - 1].link : null
}
