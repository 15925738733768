import mailingConfig from '@/config/mailing-type-config'
import mailingAPI from '@/api/mailing'
import Vue from "vue";


const smsState = () => ({
    messageSms: null,
    smsSender: null,
    isSuccess: false,
    isLoading: false
})

const emailState = () => ({
    emailSender: null,
    emailTitle: null,
    messageEmail: null,
    isSuccess: false,
    isLoading: false,
    isCustomEmail: false
})

const mailing = {
    namespaced: true,
    state: {
        mailingType: mailingConfig.EMAIL,
        isEnabledMailing: null,
        isCheckboxVisible: false,

        selectedContactsIds: {},
        emailFirst: false,
        senderOptions: [{
            name: 'EZDKA'
        }],

        smsState: smsState(),
        emailState: emailState()

    },

    getters: {
        getMailingTitle: state => {
         return state.mailingType === mailingConfig.EMAIL ? 'E-mail рассылка' : 'СМС рассылка'
        },
        getTotalSelectedContacts: state => {
            return Object.values(state.selectedContactsIds).reduce((memo, cur) => {
                memo += cur.length
                return memo
            },0)
        },
        prepareIdsPayload: state => {
            return Object.values(state.selectedContactsIds).reduce((memo, cur) => {
                memo.push(...cur)
                return memo
            },[])
        },
        checkboxVisibility: state => {
            return !!(state.mailingType && state.isEnabledMailing)
        },
        isAllCheckboxesChecked: (state, getters, rootState) => (key) => {
            const currentPageContacts = state.selectedContactsIds[rootState.CRM_CONTACT.index.additionalData.page]
            const rootStateContacts = rootState.CRM_CONTACT.index[key]

            if (key === 'drivers') {
                return rootStateContacts.map(el => el.contact_id).every(item => currentPageContacts ? currentPageContacts.includes(item) : false)

            } else if (key === 'contacts') {
                return rootStateContacts.map(el => el.id).every(item => currentPageContacts ? currentPageContacts.includes(item) : false)
            }

        },
        isAllCheckedInContacts: (state,getters) => {
             return getters.isAllCheckboxesChecked('contacts')
        },
        isAllCheckedInDrivers: (state,getters) => {
             return getters.isAllCheckboxesChecked('drivers')
        },
        getUserEmails: (state, getters, rootState) => {
           return rootState.user.contact.emails.map(el => ({
               id: el.id,
               email: el.email
           }))
        },
        getLocalPayload: (state) => {
            const args = {}
            if(state.isEnabledMailing){
                args.type_send = state.mailingType
            }
            return args
        }

    },
    actions: {
        getSelectedContacts({dispatch}, selected) {
            return dispatch('prepareData', {
                key: 'contacts',
                selected
            })
        },
        getSelectedDrivers({dispatch}, selected) {
            return dispatch('prepareData', {
                key: 'drivers',
                selected
            })
        },
        setSmsDefaultSender({commit, state, getters}){
            commit('UPDATE_SMS_STATE', {
                field: 'smsSender',
                value: state.senderOptions[0].name
            })
        },
        checkIfEmailOne({commit, getters}) {
            if (getters.getUserEmails.length === 1) {
                commit('UPDATE_EMAIL_STATE', {
                    field: 'emailSender',
                    value: getters.getUserEmails[0].email
                })
            }
        },
         prepareData({commit, rootState}, payload ) {
             let value = null
             const page = rootState.CRM_CONTACT.index.additionalData.page

             if (payload.key === 'drivers') {
                 value = rootState.CRM_CONTACT.index[payload.key].map(el => el.contact_id)
             } else if (payload.key === 'contacts') {
                 value = rootState.CRM_CONTACT.index[payload.key].map(el => el.id)
             }

             if (!payload.selected) {
                 commit('UPDATE_SELECTED_CONTACTS_ARR', {
                     page,
                     value
                 })
             } else {
                 commit('RESET_SELECTED_IN_PAGE', page)
             }
         },
        async sendSms({commit, state, getters}) {
            try {
                let errorMessage = ''

                commit('UPDATE_SMS_STATE', {
                    field: 'isLoading',
                    value: true
                })
                const {messages} = await mailingAPI.sendSms({
                    ids: getters.prepareIdsPayload,
                    from: state.smsState.smsSender,
                    messages: state.smsState.messageSms,
                })
                commit('UPDATE_SMS_STATE', {
                    field: 'isSuccess',
                    value: true
                })
                commit('RESET_SELECTED')

                if (messages && messages.length) {
                    errorMessage = messages.reduce(function (memo, val) {
                        memo += val;
                        return memo;
                    }, errorMessage)
                }

                if (errorMessage.length) {
                    this._vm.$crmNotify.error(errorMessage)
                }   else {
                    this._vm.$crmNotify.success('Рассылка успешна!')
                }
            } catch (e) {
                this._vm.$crmNotify.error('Не удалось отправить сообщения!', e)
                console.log(e)
            } finally {
                commit('UPDATE_SMS_STATE', {
                    field: 'isLoading',
                    value: false
                })
            }
        },
        async sendEmail({commit, state, getters}) {
            try {
                commit('UPDATE_EMAIL_STATE', {
                    field: 'isLoading',
                    value: true
                })
                await mailingAPI.sendEmail({
                    ids: getters.prepareIdsPayload,
                    from: state.emailState.emailSender,
                    title: state.emailState.emailTitle,
                    messages: state.emailState.messageEmail,
                })
                commit('UPDATE_EMAIL_STATE', {
                    field: 'isSuccess',
                    value: true
                })
                commit('RESET_SELECTED')
            } catch (e) {
                this._vm.$crmNotify.error('Не удалось отправить письма!', e)
                console.log(e)
            } finally {
                commit('UPDATE_EMAIL_STATE', {
                    field: 'isLoading',
                    value: false
                })
            }
        },
        resetEntireMailingState ({commit}) {
            commit('RESET_SELECTED')
            commit('UPDATE_MAILING_STATE', {
                field: 'isEnabledMailing',
                value: null
            })
            commit('UPDATE_MAILING_STATE', {
                field: 'isCheckboxVisible',
                value: false
            })
            commit('UPDATE_MAILING_STATE', {
                field: 'emailFirst',
                value: false
            })
            commit('RESET_SMS_STATE')
            commit('RESET_EMAIL_STATE')
        }
    },
    mutations: {
        UPDATE_MAILING_STATE(state, payload) {
            state[payload.field] = payload.value
        },
        UPDATE_SMS_STATE(state, payload) {
            Vue.set(state.smsState, payload.field, payload.value)
        },
        UPDATE_EMAIL_STATE(state, payload) {
            Vue.set(state.emailState, payload.field, payload.value)
        },
        UPDATE_SELECTED_CONTACTS_ARR(state, payload) {
            Vue.set(state.selectedContactsIds, payload.page, payload.value)
        },
        PUSH_TO_SELECTED(state, payload) {
            if (!state.selectedContactsIds[payload.page]) {
                Vue.set(state.selectedContactsIds, payload.page, []);
                state.selectedContactsIds[payload.page].push(payload.value)
            } else if (state.selectedContactsIds[payload.page] && !state.selectedContactsIds[payload.page].includes(payload.value)) {
                state.selectedContactsIds[payload.page].push(payload.value)
            } else {
                const index = state.selectedContactsIds[payload.page].indexOf(payload.value)
                if (index >= 0) {
                    state.selectedContactsIds[payload.page].splice(index, 1)
                }
            }
        },
        RESET_SELECTED_IN_PAGE(state, page) {
            Vue.set(state.selectedContactsIds, page, []);
        },
        RESET_SELECTED(state) {
            Vue.set(state, 'selectedContactsIds', {})
        },
        RESET_SMS_STATE(state, payload = {}) {
            const s = smsState()
            Object.keys(s).forEach(key => {
                Vue.set(state.smsState, key, s[key])
            })
        },
        RESET_EMAIL_STATE(state, payload = {}) {
            const s = emailState()
            Object.keys(s).forEach(key => {
                Vue.set(state.emailState, key, s[key])
            })
        }
    }
}
export default mailing
