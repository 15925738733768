import userApi from '@/api/user'

export default {
	resetCfoFilter ({ commit }) {
		commit('RESET_FILTER_STATE')
	},
	resetRouterFilter ({ commit }) {
		commit('SET_FILTER_FIELD_DATA', {
			field: 'routerQuery',
			value: {}
		})
	},
	setInitialFilterSettings ({ commit, rootGetters }, payload = {}) {
		const {
			selectCurrentUser,
			selectedSeller,
			loadingRange,
			client,
			routerQuery = {}
		} = payload

		// if user has some roles, then filter need to be only for that user
		if (selectCurrentUser) {
			commit('SET_FILTER_FIELD_DATA', {
				field: 'selectedUsers',
				value: [
					{
						value: rootGetters.getUser.contact.id,
						label: rootGetters.userFullName
					}
				]
			})
		}


		if (selectedSeller) {
			commit('PUSH_SELLER_OPTION', {
				value: rootGetters.getUser.contact.id,
				label: rootGetters.userFullName
			})
			commit('SET_FILTER_FIELD_DATA', {
				field: 'selectedSeller',
				value: rootGetters.getUser.contact.id
			})
		}

		if (loadingRange && !routerQuery['filter[driver_id]'] && !routerQuery['filter[id]']) {
			commit('SET_FILTER_FIELD_DATA', {
				field: 'loadingRange',
				value: loadingRange
			})
		}

		// if user specified client
		if (client) {
			commit('UPDATE_FILTER_BY_FIELDS_DATA', {
				field: 'client_name',
				value: client
			})
		}

		if (routerQuery['filter[id]']) {
			commit('SET_FILTER_FIELD_DATA', {
				field: 'department',
				value: null
			})
		}

		// set additional query params from router
		commit('SET_FILTER_FIELD_DATA', {
			field: 'routerQuery',
			value: routerQuery
		})
	},
	async searchUsers ({ commit }, searchValue) {
		const response = await userApi.getUsers({
			'search[full_name]': searchValue
		})

		const { data } = response

		commit('SET_FILTER_FIELD_DATA', {
			field: 'users',
			value: data.data.map(i => ({
				label: i.full_name,
				value: i.contact_id
			}))
		})

		return data
	},
	async searchSellers ({ commit }, searchValue) {
		const response = await userApi.getUsers({
			'search[full_name]': searchValue,
			'search[role]': 'Продажник'
		})

		const { data } = response

		commit('SET_FILTER_FIELD_DATA', {
			field: 'sellers',
			value: data.data.map(i => ({
				label: i.full_name,
				value: i.contact_id
			}))
		})

		return data
	},
	setAdditionalFilterParams ({ commit }, payload = {}) {
		commit('SET_ADDITIONAL_FILTER_OBJECT', payload)
	}
}
