import roles from '@/config/roles.config'

export default function defaultRedirects ({
	next,
	store,
	nextMiddleware
}) {
	if (store.getters.getRoles.length === 1 && store.getters.getRoles[0].name === roles.ROLE_CASHIER) {
		return next({ name: 'cashier' })
	} else if (store.getters.getRoles.length === 1 && store.getters.getRoles[0].name === roles.ROLE_MC) {
		return next({ name: 'MONITORING_CENTER' })
	} else if (store.getters.canGetter('access to cfo table')) {
		return next({ name: 'CFO' })
	}

	return nextMiddleware()
}
