import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
	getCompanyTypes (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/company-type`, {
			params: payload
		})
	},
	getPaymentCondition (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/payment-condition`, {
			params: payload
		})
	},
	getContactTypes (payload = {}) {
		return axios.get(`${HOST_URL}/contact-type`, {
			params: payload
		})
	},
	getMCPriorities (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/monitoring-priority`, {
			params: payload
		})
	},
	getOurCompanies (query) {
		return axios.get(`${HOST_URL}/company`, {
			params: {
				'filter[is_our]': true,
				'search[name]': query,
				'page-limit': 9999
			}
		})
	},
	getBanks (query) {
		return axios.get(`${HOST_URL}/bank`, {
			params: {
				'search[name]': query,
				'page-limit': 9999
			}
		})
	},
	getBanksByMfoCode (query) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/bank`, {
			params: {
				'search[mfo_code]': query
			}
		})
	},
	getLinkTypes (query) {
		return axios.get(`${HOST_URL}/link-type`, {
			params: {
				'search[name]': query,
				'page-limit': 9999
			}
		})
	},
	getLocationTypes (query) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/location-type`, {
			params: {
				'search[name]': query,
				'page-limit': -1
			}
		})
	},
	getCompanyCargoTypes (params = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/company-cargo-type`, params)
	},
	getIndustry (query) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/industry`, {
			params: {
				'search[name]': query,
				'page-limit': -1
			}
		})
	},
	getIndustries () {
		return axios.get(`${HOST_URL}/v${API_VERSION}/industry`, {
			params: {
				'page-limit': -1
			}
		})
	},
	getLocationCategories () {
		return axios.get(`${HOST_URL}/v${API_VERSION}/location-category`, {
			params: {
				'page-limit': -1
			}
		})
	},
	getLardiData () {
		return axios.get(`${HOST_URL}/order/filter/our/companies`, {
			params: {
				'page-limit': -1
			}
		})
	},
	getCompaniesByOrderData (params) {
		return axios.get(`${HOST_URL}/order/filter/companies`, {
			params: params
		})
	},
	getContactsByRoles (params) {
		return axios.get(`${HOST_URL}/order/filter/roles`, {
			params: params
		})
	},
	getContactsByOrderType (params) {
		return axios.get(`${HOST_URL}/order/filter/contacts`, {
			params: params
		})
	},
	getDriverByID (id, payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/driver/${id}`, {
			params: payload
		})
	},
	updateClientOrderStatus (payload = {}) {
		const obj = {
			id: payload['orderId'],
			status: {id: payload['status']}
		}

		return axios.post(`${HOST_URL}/order/${payload['type']}`, obj)
	},
	getOrderVehicleTypes (params = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/order_vehicle_type`, {
			params: params
		})
	},
	vehicleTypes (params = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/vehicle-type`, {
			params: params
		})
	},
	vehicleOrderTypes (params = {}) {
		return axios.get(`${HOST_URL}/order-vehicle-type`, {
			params: params
		})
	},
	loadingTypes () {
		return axios.get(`${HOST_URL}/v${API_VERSION}/loading-type`)
	},
	vehicleCategories () {
		return axios.get(`${HOST_URL}/v${API_VERSION}/vehicle-type-category`)
	},
	findVehicleBrand (params = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/car-brand`, {
			params: params
		})
	},
	getPaymentForms (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/payment-form`, {
			params: payload
		})
	},
	getDocumentReceivingMethods (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/document-receiving-method`, {
			params: payload
		})
	},
	getPostalServicesList (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/postal-service`, {
			params: payload
		})
	},
	getListDocs (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/list-doc`, {
			params: payload
		})
	},
	getPaymentTerms (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/payment-term`, {
			params: payload
		})
	},
	getRoles () {
		return axios.get(`${HOST_URL}/role`, {
			params: {
				'page-limit': -1
			}
		})
	},
	vehicleTypeCommunication () {
		return axios.get(`${HOST_URL}/v${API_VERSION}/vehicle-type-communication`)
	},
	getTransactionTypes (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/transaction-type`, {
			params: payload
		})
	},
	getDetailedHistoryInfo (id, type) {
		return axios.get(`${HOST_URL}/logger/${type}/${id}`)
	},
	getVehicleAdditionalData () {
		return axios.get(`${HOST_URL}/full-vehicle-info`)
	},
	changeEntityManager (type, payload = {}) {
		return axios.patch(`${HOST_URL}/${type}/change-managers`, payload)
	},
	getCRMDataAsExcel (tableUrl, payload = {}) {
		return axios.get(`${HOST_URL}/${tableUrl}`, {
			params: payload,
			responseType: 'blob'
		})
	},
	getTransporterCards (payload = {}) {
		return axios.get(`${HOST_URL}/get-transporter-cards`, {
			params: payload
		})
	},
	getGoogleApiKey (type) {
		return axios.get(`${HOST_URL}/google-api-keys/get-by-type`, {
			params: {
				type
			}
		})
  },
	getChangelogList () {
		return axios.get(`${HOST_URL}/get-change-log`)
	},
	getChangelogDescription (releaseID) {
		return axios.get(`${HOST_URL}/get-change-log-description/${releaseID}`)
	}
}
