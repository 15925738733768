import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	getCFOData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo`, {
			params: payload
		})
	},
	getCFODataAsExcel (payload = {}) {
		return axios.get(`${HOST_URL}/cfo`, {
			params: payload,
			responseType: 'blob'
		})
	},

	getFinanceCashData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/cash`, {
			params: payload
		})
	},
	getFinanceCashlessData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/cashless`, {
			params: payload
		})
	},
	getFinanceSoftData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/soft`, {
			params: payload
		})
	},
	getFinanceFuelData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/fuel`, {
			params: payload
		})
	},
	payFinanceWithFuel (payload = {}) {
		return axios.post(`${HOST_URL}/cfo/fuel/paid-with-fuel`, payload)
	},

	/**
   * RNN
   */
	postDealDocumentTurnover (payload = {}) {
		return axios.post(`${HOST_URL}/cfo/rnn/ddt`, {
			params: payload
		})
	},
	postRnnData (payload = {}, headers = {}) {
		let formData = new FormData()
		formData.append('file', payload.file)
		formData.append('selectedCompany', payload.selectedCompany)
		formData.append('compareFreight', payload.compareFreight)
		formData.append('selectedDateRnn', payload.selectedDateRnn)

		return axios.post(`${HOST_URL}/cfo/rnn`, formData, headers)
	},

	/**
   * PLANS
   */
	getBonusesData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/bonus`, {
			params: payload
		})
	},
	getPlansData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/plan`, {
			params: payload
		})
	},
	updatePlans (payload = {}) {
		return axios.post(`${HOST_URL}/cfo/plan`, payload)
	},

	/**
   * CASHLESS SEPARATION
   */
	postCashlessSeparationData (payload = {}, headers = {}) {
		let formData = new FormData()
		formData.append('file', payload.file)
		formData.append('selectedBank', payload.selectedBank)

		return axios.post(`${HOST_URL}/cfo/cashless-separation`, formData, headers)
	},
	getDealsByStatementText (params) {
		return axios.post(`${HOST_URL}/cfo/cashless-separation/by_statement_text`, params)
	},
	uploadPostpone (params) {
		return axios.post(`${HOST_URL}/cfo/cashless-separation/upload-postpone`, params)
	},
	markTransactionsForPayment () {
		return axios.get(`${HOST_URL}/cfo/cashless/mark-transactions-for-payment`)
	},
	generateCarrierCheck (payload = {}) {
		return axios.post(`${HOST_URL}/invoice-generate`, payload)
	}
}
