export default {
  getOptionsByKey: (state) => (key, fieldsArrObject = []) => {
    return state[key].reduce((memo, val) => {
      // if we are not specified custom fields for object
      if (!fieldsArrObject.length) {
        memo.push({
          value: val.id,
          label: val.name,
          is_blocked: val.is_blocked,
          is_not_resident: val.is_not_resident,
          status: val.status
        })
      } else {
        let obj = {}

        for (let i = 0, length = fieldsArrObject.length; i < length; i++) {
          if (typeof fieldsArrObject[i].value !== 'function') {
            obj[fieldsArrObject[i].key] = val[fieldsArrObject[i].value]
          } else {
            obj[fieldsArrObject[i].key] = fieldsArrObject[i].value(val)
          }
        }

        memo.push(obj)
      }
      return memo;
    }, []);
  },

  transactionTypesOptions: (state, getters) => {
    return getters.getOptionsByKey('transactionTypes')
  },

  paymentFormsOptions: (state, getters) => {
    return getters.getOptionsByKey('paymentForms')
  },

  paymentConditionOptions: (state, getters) => {
    return getters.getOptionsByKey('paymentCondition')
  },

  currenciesOptions: (state, getters) => {
    return getters.getOptionsByKey('currency')
  },

  contacts: state => state.contacts,
  getContactOptions: (state, getters) => {
    return getters.getOptionsByKey('contacts', [
      {
        key: 'id',
        value: 'id'
      },
      {
        key: 'name',
        value: 'full_name'
      }
    ])
  },
  contactsOptions: (state, getters) => {
    return getters.getOptionsByKey('contacts', [
      {
        key: 'value',
        value: 'id'
      },
      {
        key: 'label',
        value: 'full_name'
      },
    ])
  },

  users: state => state.users,
  usersOptions: (state, getters) => {
    return getters.getOptionsByKey('users', [
      {
        key: 'value',
        value: 'contact_id',
      },
      {
        key: 'label',
        value: 'full_name',
      },
    ])
  },

  insetsOptions: (state, getters) => {
    return getters.getOptionsByKey('insets')
  },

  companyTypeOptions: (state, getters) => {
    return getters.getOptionsByKey('companyTypes')
  },

  cargoTypesOptions: (state, getters) => {
    return getters.getOptionsByKey('cargoTypes')
  },

  mcPrioritiesOptions: (state, getters) => {
    return getters.getOptionsByKey('mcPriorities')
  },

  getOurCompaniesOptions: (state, getters) => {
    return getters.getOptionsByKey('ourCompanies')
  },

  companiesOptions: (state, getters) => {
    return getters.getOptionsByKey('companies')
  },

  getContactsByRole: (state, getters) => {
    return getters.getOptionsByKey('contactsByRole', [
      {
        key: 'id', value: 'id'
      },
      {
        key: 'label',
        value: (value) => {
          return value.full_name || value.name || ''
        }
      },
      {
        key: 'last_name', value: 'last_name'
      },
      {
        key: 'type',
        value: (value) => {
          return value.user.roles.map(r => r.readable_name).join(', ')
        }
      },
      {
        key: 'roleIds',
        value: (value) => {
          return value.user.roles.map(r => r.id)
        }
      },
      {
        key: 'roleFilter',
        value: (value) => {
          return value.user.roles.map(r => r.readable_name).pop()
        }
      }
    ])
  },

  getCompaniesByOrderType: (state, getters) => {
    return getters.getOptionsByKey('companiesByOrderType', [
      {
        key: 'id',
        value: 'id',
      },
      {
        key: 'label',
        value: 'name',
      },
      {
        key: 'type',
        value: (value) => {
          return value.company_types.map(c => c.name).pop()
        },
      },
      {
        key: 'contacts',
        value: 'contacts'
      },
      {
        key: 'directions',
        value: (value) => {
          return value.directions && value.directions.length ? value.directions.map(
            d => d.address.address).join(', ') : null
        }
      }
    ])
  },
  getContactsByOrderType: (state, getters) => {
    return getters.getOptionsByKey('contactsByOrderType', [
      {
        key: 'id',
        value: 'id',
      },
      {
        key: 'label',
        value: (value) => {
          return value.last_name + ' ' + value.first_name
        },
      },
      {
        key: 'type',
        value: (value) => {
          return value.contact_types.map(c => ({
            name: c.name,
            id: c.id,
          }))
        },
      },
      {
        key: 'companies', value: 'companies'
      },
      {
        key: 'isLpr', value: 'is_lpr'
      }
    ])
  },
  getCurrentUserEmails: (state, getters) => {
    return getters.getOptionsByKey('userContact', [
      {
        key: 'value',
        value: 'id',
      },
      {
        key: 'label',
        value: 'email',
      },
      {
        key: 'isDefault',
        value: 'is_default',
      },
    ])
  },
  getCurrentUserPhones: (state, getters) => {
    return getters.getOptionsByKey('userPhones', [
      {
        key: 'value',
        value: 'id',
      },
      {
        key: 'label',
        value: 'phone',
      },
    ])
  },
}
