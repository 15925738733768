export default () => ({
	loadingDateRange: null,
	loadingFrom: null,
	loadingTo: null,
	vehicleType: null,
	capacityFrom: null,
	capacityTo: null,
	volumeFrom: null,
	volumeTo: null
})
