import Vue from 'vue'
import router from '@/router'
import dealApi from '@/api/deal'
import vehicleApi from '@/api/vehicle'
import helperApi from '@/api/helper'
import contactApi from '@/api/contact'
import companyApi from '@/api/company'
import { getLastAvatar } from '@/business-logic/contact'

import jsonToFormData from '@/mixins/jsonToFormData'
import {uuniqBy} from '@/mixins/light_lodash'
import transactionTypes from '@/config/transaction-types'
import fileTypes from '@/config/file-types'

import DEAL_HELPERS_MODULE from '@/store/modules/CRM/deal/helpers.module'
import { convertDate, formats } from '@/helpers/date'

const LOCATION_TYPE_LOADING = 1
const LOCATION_TYPE_UNLOADING = 2


/**
 * Используется для получения списка юр. лицо заказчика/перевозчика из контрактов
 * @param getters
 * @param commit
 * @param type
 * @param data
 * @returns {Promise<void>}
 */
const prepareCompanyOurCompanies = async (getters, commit, type, data) => {
	const relatedDealOurCompany = []
	const EDITING_DEAL = getters.getGlobalState.editingDeal
	/**
   *  если редактирование сделки
   *  и в сделке есть связь с заказчиком/перевозом
   *  и Айди заказчика/перевозчика не отличается от того, что уже в инпуте (если при редактировании полностью изменить)
   *  и у сделки есть связь с выбраной нашей компанией
   */
	const upperCaseFirstLetterForType = type === 'client'
		? 'Client'
		: 'Transporter'
	const isEditingDeal = EDITING_DEAL &&
    EDITING_DEAL[type] &&
    EDITING_DEAL[type].id ===
    getters[`get${upperCaseFirstLetterForType}State`].companyID &&
    EDITING_DEAL[`${type}_our_company`]
	if (isEditingDeal && !getters.getPermissionsState.selectTypeOfTransportation) {
		relatedDealOurCompany.push({
			value: EDITING_DEAL[`${type}_our_company`].id,
			label: EDITING_DEAL[`${type}_our_company`].name
		})
		await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
			field: 'ourCompanyID',
			value: EDITING_DEAL[`${type}_our_company`].id
		})
	}
	let matchedOurCompanies = [...relatedDealOurCompany]

	if (data && data.contracts && data.contracts.length > 0) {
		let validContracts = data.contracts.filter(i => i.our_company_role)

		if (getters.getPermissionsState.selectTypeOfTransportation) {
			validContracts = validContracts
				.filter(contract => contract.transportation_types)
				.filter(contract => contract.transportation_types
					.some(type => type.id === getters.getGlobalState.typeOfTransportation))
		}

		validContracts = validContracts.map(i => ({
			value: i.our_company.id,
			label: i.our_company.name || i.our_company.full_name
		}))

		matchedOurCompanies = [...matchedOurCompanies, ...validContracts]
	}
	await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
		field: 'helperOurCompanies_',
		value: uuniqBy(matchedOurCompanies, 'value')
	})

	// if (!isEditingDeal) {
	// если только 1 наша компания, тогда можно сразу выбрать в инпут
	if (matchedOurCompanies && matchedOurCompanies.length === 1) {
		await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
			field: 'ourCompanyID',
			value: matchedOurCompanies[0].value
		})
	}
	// }
}
/**
 * Используется для получения списка грузов из компании
 * @param getters
 * @param commit
 * @param type
 * @param data
 * @returns {Promise<void>}
 */
const prepareCompanyCargoTypes = async (getters, commit, type, data) => {
	let matchedCargoTypes = []
	if (data && data.cargo_types && data.cargo_types.length > 0) {
		const companyCargoTypes = data.cargo_types.map(cargo => ({
			value: cargo.cargo_type.id,
			label: cargo.cargo_type.name
		}))

		matchedCargoTypes = [...companyCargoTypes]

		const defaultCargoType = data.cargo_types.find(i => i.is_default)
		const EDITING_DEAL = getters.getGlobalState.editingDeal
		const isEditingDeal = EDITING_DEAL &&
      EDITING_DEAL.client &&
      EDITING_DEAL.client.id ===
      getters[`getClientState`].companyID
		/**
     * Если у компании есть дефолтный груз
     * и компания отличается от редактируемой
     * То подставим его сразу в инпуты
     */
		if (
			!isEditingDeal && defaultCargoType
		) {
			await commit('UPDATE_CLIENT_STATE', {
				field: 'cargoTypeID',
				value: defaultCargoType.cargo_type.id
			})
			await commit('UPDATE_CLIENT_STATE', {
				field: 'cargoCost',
				value: defaultCargoType.amount
			})
		}
	}

	await commit('UPDATE_CLIENT_STATE', {
		field: 'helperCargoTypes_',
		value: matchedCargoTypes
	})
}
/**
 * Используется для получения списка водителей
 * @param getters
 * @param commit
 * @param dispatch
 * @param companyType
 * @param data
 * @returns {Promise<void>}
 */
const prepareCompanyDrivers = async (getters, commit, dispatch, companyType, data, isMc = false) => {
	if (!data) {
		return ;
	}

	commit('UPDATE_TRANSPORTER_STATE', {
		field: 'driverProcessing',
		value: true
	})

	let drivers = []

	switch (companyType) {
	case 'companies': {
		// get all driver-contacts from company object
		const companyDriversArray = data.contacts.reduce((contacts, item) => {
			if (item.contact_types && item.contact_types.find(i => i.id === 8) && item.driver) {
				contacts.push(item)
			}

			return contacts
		}, [])


		// after disabled title "Company Drivers" add to select all company drivers
		drivers = [
			{
				value: 'not-selectable-1',
				label: 'Водители компании',
				type: 'not-selectable'
			},
			...companyDriversArray
		]

		// after that fetch all forwarders from selected company
		// (it is business logic)

		if (!isMc && getters.getTransporterState.companyID) {
			const forwardersResponse = await contactApi.getContactsByRelation(
				'dispatcherFor',
				getters.getTransporterState.companyID
			)

			// if forwarders in company exists add them to select
			if (forwardersResponse && forwardersResponse.length) {
				drivers = [
					...drivers,
					{
						value: 'not-selectable-2',
						label: 'Водители, с компанией экспедитором',
						type: 'not-selectable'
					},
					...forwardersResponse
				]
			}
		}

		break
	}
	case 'contacts': {
		// maybe deprecated case, dont touch for back compatibility
		if (!data.is_lpr && getters.getTransporterState.companyID) {
			const response = await contactApi.getContactsByRelation(
				'lprContacts',
				getters.getTransporterState.companyID
			)

			if (response && response.length) {
				drivers = response
			}
		} else {
			if (data.driver) {
				drivers = [
					{
						driver: {
							id: data.driver.id
						},
						...data
					}
				]
			}
			if (data.lpr_drivers && data.lpr_drivers.length > 0) {
				drivers = [
					...drivers,
					{
						value: 'not-selectable-3',
						label: 'Водители контакта',
						type: 'not-selectable'
					},
					...data.lpr_drivers.map(i => ({
						driver: {
							id: i.id
						},
						...i
					}))
				]
			}
		}

		if (getters.getTransporterState.companyID) {
			const dispatchersResponse = await contactApi.getContactsByRelation(
				'relatedContacts',
				getters.getTransporterState.companyID
			)

			if (dispatchersResponse && dispatchersResponse.length) {
				drivers = [
					...drivers,
					{
						value: 'not-selectable-4',
						label: 'Водители выбраного диспетчера',
						type: 'not-selectable'
					},
					...dispatchersResponse
				]
			}
		}

		break
	}
	default: {
      	drivers = []
		  break
	  }
	}

	let driverAutocompletable = true
	const getFromDeal = getters.getGlobalState.editingDeal &&
    getters.getGlobalState.editingDeal.driver &&
    getters.getGlobalState.editingDeal.driver.id ===
    getters.getTransporterState.transporterDriverID
	if (getFromDeal) {
		const driverFromDeal = {
			driver: {
				id: getters.getGlobalState.editingDeal.driver.id
			},
			...getters.getGlobalState.editingDeal.driver.contact
		}
		drivers = [
			driverFromDeal,
			...drivers
		]

		driverAutocompletable = false
	}

	drivers = uuniqBy(drivers, 'id')

	await commit('UPDATE_TRANSPORTER_STATE', {
		field: 'helperDrivers_',
		value: drivers
	})

	if (drivers && drivers.length === 0) {
		await commit('UPDATE_TRANSPORTER_STATE', {
			field: 'transporterDriverID',
			value: null
		})
	}

	const driversWithoutUselessOpts = drivers.filter(driver => !driver.type)
	if (driverAutocompletable && driversWithoutUselessOpts && driversWithoutUselessOpts.length === 1) {
		commit('UPDATE_TRANSPORTER_STATE', {
			field: 'transporterDriverID',
			value: driversWithoutUselessOpts[0].driver.id
		})


		dispatch('fetchDriverByID', driversWithoutUselessOpts[0].driver.id)

	}

	commit('UPDATE_TRANSPORTER_STATE', {
		field: 'driverProcessing',
		value: false
	})
}
/**
 * Используется для получения списка машин и прицепов водителя
 * @param getters
 * @param commit
 * @param data
 * @returns {Promise<void>}
 */
const prepareDriverVehiclesAndTrailers = async (getters, commit, dispatch, data) => {
	const gettingFromDriver = getters.getGlobalState.editingDeal &&
    getters.getGlobalState.editingDeal.driver &&
    getters.getGlobalState.editingDeal.driver.id !==
    getters.getTransporterState.transporterDriverID

	const defaultVehicle = []
	const defaultTrailer = []

	if (getters.getGlobalState.editingDeal && !gettingFromDriver) {
		if (getters.getGlobalState.editingDeal.vehicle) {
			defaultVehicle.push({
				value: getters.getGlobalState.editingDeal.vehicle.id,
				label: getters.getGlobalState.editingDeal.vehicle.license_plate,
				info: getters.getGlobalState.editingDeal.vehicle
			})
		}
		if (getters.getGlobalState.editingDeal.trailer) {
			defaultTrailer.push({
				value: getters.getGlobalState.editingDeal.trailer.id,
				label: getters.getGlobalState.editingDeal.trailer.license_plate,
				info: getters.getGlobalState.editingDeal.trailer
			})
		}
	}

	let separatedVehicles = null

	if (data && data.separated_vehicles) {
		separatedVehicles = data.separated_vehicles
	} else if (getters.getGlobalState.id) {
		const response = await dealApi.getSeparatedVehicleFromDeal(getters.getGlobalState.id)

		if (response && response.data) {
			separatedVehicles = response.data
		}
	}

	if (separatedVehicles) {
		const vehicle = separatedVehicles.vehicle
		const trailer = separatedVehicles.trailer

		let selectedVehicle = null

		if (vehicle) {
			let vehicles = [
				...defaultVehicle, ...vehicle.filter(i => i).map(i => ({
					value: i.id,
					label: i.license_plate,
					info: i
				}))
			]
			vehicles = uuniqBy(vehicles, 'value')
			await commit('UPDATE_TRANSPORTER_STATE', {
				field: 'helperVehicles_',
				value: vehicles
			})
			if (vehicles && vehicles.length === 1) {
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverVehicleID',
					value: vehicles[0].value
				})
				selectedVehicle = vehicles[0]
				dispatch('checkVehicleIsBlocked', 'vehicle')
			}
		}
		if (trailer) {
			let trailers = [
				...defaultTrailer, ...trailer.filter(i => i).map(i => ({
					value: i.id,
					label: i.license_plate,
					info: i
				}))
			]
			trailers = uuniqBy(trailers, 'value')
			await commit('UPDATE_TRANSPORTER_STATE', {
				field: 'helperTrailers_',
				value: trailers
			})

			const needAutocomplete = getters.getGlobalState.editingDeal ?
				(getters.getGlobalState.editingDeal &&
        getters.getGlobalState.editingDeal.trailer &&
        getters.getGlobalState.editingDeal.trailer.id !==
        getters.getTransporterState.transporterDriverID) : true

			const vehicleHasType1 = !!(selectedVehicle &&
        selectedVehicle.info.vehicle_types.filter(cat => cat.id === 1).length)

			if (
				trailers &&
        trailers.length === 1 &&
        needAutocomplete && vehicleHasType1
			) {
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverTrailerID',
					value: trailers[0].value
				})
				dispatch('checkVehicleIsBlocked', 'trailer')
			}
		}
	}
}

const clientState = () => ({
	type: 'companies',
	companyID: null,
	ourCompanyID: null,
	isCrossdoc: false,
	cargoTypeID: null,
	cargoCost: null,
	monitoringPriorityID: 1,
	transactions: [],
	editingTransactionIndex__: -1,
	companyObject: {},
	files: [],
	transportationNumber: null,
	insetID: null,
	delay: null,
	paymentCondition: null,

	// helpers for store data
	helperCompanies_: [],
	helperOurCompanies_: [],
	helperCargoTypes_: [],
	next_driver_call: null,
	own_weight: null
})

const transporterState = () => ({
	type: 'companies',
	companyID: null,
	ourCompanyID: null,
	transporterDriverID: null,
	driverVehicleID: null,
	driverTrailerID: null,
	transactions: [],
	editingTransactionIndex__: -1,
	companyObject: {},
	driverObject: {},
	transporterDrivers: [],
	files: [],
	transportationType: 'land',
	containerNumber: null,
	marineLine: null,
	teoCompanyID: null,
	delay: null,
	paymentCondition: null,

	// helpers for store data
	helperCompanies_: [],
	helperOurCompanies_: [],
	helperDrivers_: [],
	helperVehicles_: [],
	helperTrailers_: [],
	driverProcessing: false,
	permissibleLoad: null,
	permissibleLoadError: null
})

const permissionsState = () => ({
	selectTypeOfTransportation: false
})

const defaultLocationItem = (cat) => ({
	location: {
		id: null
	},
	position: null,
	location_type: cat ? {id: cat} : null,
	expectation_time_in: null,
	expectation_time_out: null,
	_meta: null
})

const pathLocationsState = () => []

const defaultState = () => ({
	client: clientState(),
	transporter: transporterState(),
	responsibleSalesManID: null,
	logistID: null,
	dealStatusID: null,
	department: 1,
	typeOfTransportation: 1,
	description: '',

	id: null,
	otherTransactions: [],
	documentTurnover: [],

	editingDeal: null,

	isCoping: false,
	copingType: null,
	copingId: null,
	validator_: {},

	permissions: permissionsState(),

	pathLocations: pathLocationsState(),

	loadingPointsDescription: null,
	unloadingPointsDescription: null,

	driverBlocked: false,
	clientCompanyIsBlocked: false,
	transporterCompanyIsBlocked: false,
	vehicleIsBlocked: false,
	trailerIsBlocked: false
})

const CRM_DEAL = {
	namespaced: true,
	state: defaultState(),
	modules: {
		DEAL_HELPERS_MODULE: DEAL_HELPERS_MODULE
	},
	getters: {
		getClientState: state => state.client,
		getTransporterState: state => state.transporter,
		getGlobalState: state => state,

		checkIsEntityIsLocked: state => state.driverBlocked ||
          state.clientCompanyIsBlocked ||
          state.transporterCompanyIsBlocked ||
          state.vehicleIsBlocked ||
          state.transporter.driverTrailerID ? state.trailerIsBlocked : false,

		isCoping: state => state.isCoping,
		isInternationalDeal: state => state.typeOfTransportation === 2,

		copingId: state => state.copingId,
		copingType: state => state.copingType,

		isGroundTransportationType:
      state => state.transporter.transportationType === 'land' ||
        state.transporter.transportationType === null,
		isSalesDepartment: state => state.department === 1,

		getPermissionsState: state => state.permissions,
		getPathLocations: state => state.pathLocations,
		getCompaniesObject: (state) => (key) => state[key].helperCompanies_.map(i => {
			const avatar = getLastAvatar(i.files || [], 'company')

			return {
				value: i.id,
				label: i.name || i.full_name,
				code: i.code,
				avatar: avatar
			}
		}),
		isTransportDepartment: (state) => state.department === 2,
		whosIsBlocked: (state, getters) => {
			const data = {
				client: getters.getClientState.companyObject ? getters.getClientState.companyObject.is_blocked : false,
				transporter: getters.getTransporterState.companyObject ?
					getters.getTransporterState.companyObject.is_blocked : false,
				driver: state.driverBlocked,
				vehicle: state.vehicleIsBlocked,
				trailer: state.transporter.driverTrailerID ? state.trailerIsBlocked : false
			}

			return {
				data,
				valid: Object.values(data).every(val => !val)
			}
		},
		companiesNotRegistered: () => (obj) => obj && obj.companyObject &&
        obj.companyObject.id &&
        obj.companyID === obj.companyObject.id &&
        obj.companyObject.status &&
        obj.companyObject.status !== 'зареєстровано',
		clientNotRegistered: (state, getters) => getters.companiesNotRegistered(getters.getClientState),
		transporterNotRegistered: (state, getters) => getters.companiesNotRegistered(getters.getTransporterState),
		disabledByBlockers: (state, getters) => getters.clientNotRegistered ||
        getters.transporterNotRegistered ||
        !getters.whosIsBlocked.valid,
		selectedVehicle: (state) => {
			const vehicle = state.transporter.helperVehicles_.find(v => v.value === state.transporter.driverVehicleID)

			if (!vehicle) {
				return null
			}

			return vehicle.info
		},
		getCapacityOrVolumeAmount: (state, getters) => (key) => {
			const selectedTrailer = state.transporter.helperTrailers_
				.find(v => v.value === state.transporter.driverTrailerID)

			if (getters.selectedVehicle && selectedTrailer && selectedTrailer.info) {
				return getters.selectedVehicle[key] + selectedTrailer.info[key]
			} else if (getters.selectedVehicle && !selectedTrailer) {
				return getters.selectedVehicle[key]
			}
		},
		getCapacityAmount: (state, getters) => getters.getCapacityOrVolumeAmount('max_capacity'),
		getVolumeAmount: (state, getters) => getters.getCapacityOrVolumeAmount('volume'),
		selectedVehicleHasId1: (state, getters) => {
			if (!getters.selectedVehicle) {
				return null
			}

			const type = getters.selectedVehicle.vehicle_types &&
        getters.selectedVehicle.vehicle_types.find(cat => cat.id === 1)

			return !!type
		}
	},
	mutations: {
		UPDATE_CLIENT_STATE (state, payload) {
			state.client[payload.field] = payload.value
		},
		UPDATE_PERMISSIONS_STATE (state, payload) {
			state.permissions[payload.field] = payload.value
		},
		UPDATE_TRANSPORTER_STATE (state, payload) {
			state.transporter[payload.field] = payload.value
		},
		UPDATE_GLOBAL_STATE (state, payload) {
			Vue.set(state, payload.field, payload.value)
		},

		/**
     * CLIENT TRANSACTION MUTATIONS
     */
		PUSH_TRANSACTION_TO_CLIENT_STATE (state, transaction) {
			state.client.transactions.push(transaction)
		},
		EDIT_TRANSACTION_FROM_CLIENT_STATE (state, transaction) {
			Vue.set(state.client.transactions, state.client.editingTransactionIndex__,
				transaction)
			state.client.editingTransactionIndex__ = -1
		},
		DELETE_TRANSACTION_FROM_CLIENT_STATE (state, transaction) {
			const index = state.client.transactions.findIndex(i => i === transaction)

			state.client.transactions.splice(index, 1)
		},

		PUSH_TRANSACTION_TO_GLOBAL_STATE (state, transaction) {
			state.otherTransactions.push(transaction)
		},
		DELETE_TRANSACTION_FROM_GLOBAL_STATE (state, index) {
			state.otherTransactions.splice(index, 1)
		},

		/**
     * TRANSPORTER TRANSACTION MUTATIONS
     */
		PUSH_TRANSACTION_TO_TRANSPORTER_STATE (state, transaction) {
			state.transporter.transactions.push(transaction)
		},
		EDIT_TRANSACTION_FROM_TRANSPORTER_STATE (state, transaction) {
			Vue.set(state.transporter.transactions,
				state.transporter.editingTransactionIndex__, transaction)
			state.transporter.editingTransactionIndex__ = -1
		},
		DELETE_TRANSACTION_FROM_TRANSPORTER_STATE (state, transaction) {
			const index = state.transporter.transactions.findIndex(
				i => i === transaction)

			state.transporter.transactions.splice(index, 1)
		},

		RESET_STATE (state) {
			const s = defaultState()
			Object.keys(s).forEach(key => {
				state[key] = s[key]
			})
		},
		RESET_TRANSPORTER_STATE (state) {
			const s = transporterState()
			Object.keys(s).forEach(key => {
				if (key !== 'transportationType') {
					state.transporter[key] = s[key]
				}
			})
		},
		RESET_CLIENT_STATE (state) {
			const s = clientState()
			Object.keys(s).forEach(key => {
				state.client[key] = s[key]
			})
		},

		DELETE_LOCATION (state, index) {
			state.pathLocations.splice(index, 1)
		},
		ADD_EMPTY_LOCATION (state, cat) {
			state.pathLocations.push({ ...defaultLocationItem(cat) })
		},
		ADD_LOCATION (state, payload) {
			(state.client.companyObject.locations || []).push(payload)
		},
		EDIT_LOCATION (state, payload) {
			const item = state.pathLocations[payload.index]

			item.location = payload.value
			Vue.set(item, 'location', payload.value)
		},
		EDIT_LOCATION_CATEGORY (state, payload) {
			const item = state.pathLocations[payload.index]

			if (item && item.location_type) {
				item.location_type.id = payload.value
			} else {
				item.location_type = {
					id: payload.value
				}
			}
		},
		EDIT_LOCATION_ITEM (state, payload) {
			const item = state.pathLocations[payload.index]

			Vue.set(item, payload.field, payload.value)
		},
		ROLLBACK_PATH_LOCATIONS (state, payload) {
			Vue.set(state, 'pathLocations', payload)
		},
		ADD_POINT_META_DATA (state, payload) {
			//TODO FIXME test & debug. It seems like state.pathLocations
			// should be filled up beforehand - but it is set in an unclear way
			if (!state.pathLocations[payload.index]) {
				Vue.set(state.pathLocations, payload.index, {});
			}
			Vue.set(state.pathLocations[payload.index], '_meta', payload.value)
		},
		REST_POINTS_META_DATA (state) {
			state.pathLocations.forEach((location, index) => {
				Vue.set(state.pathLocations[index], '_meta', null)
			})
		},
		RESET_PATH_LOCATIONS (state, payload) {
			const validLocations = []
			for (let i = 0; i < state.pathLocations.length; i++) {
				const item = state.pathLocations[i]
				const invalidLocationIds = payload.map(i => i.id)

				const validTypes = [LOCATION_TYPE_LOADING, LOCATION_TYPE_UNLOADING]
				// check if location has location type and this type is loading/unloading type
				const inLoadingUnloadingTypes = item.location_type && validTypes.includes(item.location_type.id)
				// check if location not in invalid location array
				const validLocation = !invalidLocationIds.includes(item.location.id)

				if (validLocation && inLoadingUnloadingTypes) {
					item.id = null
					item._meta = null
					validLocations.push(item)
				}
			}
			Vue.set(state, 'pathLocations', validLocations)
		},
		SET_DRIVER_IN_BLOCK_LIST (state, payload) {
			state.driverBlocked = payload
		},
		CLIENT_COMPANY_IS_BLOCKED (state, payload) {
			state.clientCompanyIsBlocked = payload
		},
		TRANSPORTER_COMPANY_IS_BLOCKED (state, payload) {
			state.transporterCompanyIsBlocked = payload
		},
		VEHICLE_IS_BLOCKED (state, payload) {
			state.vehicleIsBlocked = payload
		},
		TRAILER_IS_BLOCKED (state, payload) {
			state.trailerIsBlocked = payload
		},
		SET_PERMISSIBLE_LOAD (state, payload) {
			state.transporter.permissibleLoad = payload
		},
		SET_PERMISSIBLE_LOAD_ERROR_MSG (state, payload) {
			state.transporter.permissibleLoadError = payload
		}
	},
	actions: {
		/**
     * Fetch and search drivers by full name ONLY for transport department or
     * not ground transportation type
     * @param commit
     * @param getters
     * @param payload
     * @returns {Promise<void>}
     */
		async getDriversForNotStandardDeal ({ commit, getters }, payload = null) {
			if (getters.isTransportDepartment || !getters.isGroundTransportationType) {
				try {
					const data = await contactApi.getContactsByName(payload)

					commit('UPDATE_TRANSPORTER_STATE', {
						field: 'helperDrivers_',
						value: data
					})
				} catch (e) {
					window.console.error(e)
					this._vm.$notify({
						type: 'error',
						text: 'Ошибка поиска водителей! ' + e
					})
				}
			}
		},
		async fetchCompanyInfoByCompanyId ({ commit, state, getters, dispatch }, actionPayload) {
			try {
				const {type, autocomplete} = actionPayload
				let response = null

				const requestType = {}
				if (actionPayload['request-type']) {
					requestType['request-type'] = actionPayload['request-type']
				}
				if (state[type].type === 'companies' && state[type].companyID) {
					response = await companyApi.getCompanyDataByID(state[type].companyID, requestType)
				} else if (state[type].type === 'contacts' && state[type].companyID) {
					response = await contactApi.getContactByID(state[type].companyID)
				}

				const { data } = response

				await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
				  field: 'companyObject',
				  value: data
				})

				if (type === 'client') {
					dispatch('clientCompanyIsBlocked', data)
				} else {
					dispatch('transporterCompanyIsBlocked', data)
				}

				if (autocomplete) {
					if (type === 'client') {
						await prepareCompanyOurCompanies(getters, commit, type, data)
						await prepareCompanyCargoTypes(getters, commit, type, data)
					}
					if (type === 'transporter') {
						await prepareCompanyOurCompanies(getters, commit, type, data)
						await prepareCompanyDrivers(getters, commit, dispatch, state[type].type, data)
					}
				}
			} catch (err) {
				window.console.error(err)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения данных по компании!'
				})
			}
		},
		fetchClientCompanyForOrder ({dispatch}, autocomplete) {
			return dispatch('fetchCompanyInfoByCompanyId', {
				type: 'client',
				autocomplete,
				'request-type': 'order'
			})
		},
		fetchClientCompany ({ dispatch }, autocomplete) {
			return dispatch('fetchCompanyInfoByCompanyId', {
				type: 'client',
				autocomplete,
				'request-type': 'deal'
			})
		},
		fetchTransporterCompany ({ dispatch }, autocomplete) {
			return dispatch('fetchCompanyInfoByCompanyId', {
				type: 'transporter',
				autocomplete,
				'request-type': 'deal'
			})
		},
		async fetchDriverByID ({ commit, getters, dispatch }, id) {
			try {
				const { data } = await helperApi.getDriverByID(id)


				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverObject',
					value: data
				})

				await prepareDriverVehiclesAndTrailers(getters, commit, dispatch, data)

				dispatch('driverIsBlocked', data)
			} catch (err) {
			  window.console.error(err)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения данных по водителю!'
				})
			}
		},
		async createDeal ({ commit, state, getters, dispatch }, {
			withRedirect = true,
			withRefreshCfo = true,
			cb
		}) {
			let _transactions = [
				...getters.getClientState.transactions,
				...getters.getGlobalState.otherTransactions
			]
			if (getters.isSalesDepartment) {
				_transactions = [
					..._transactions,
					...getters.getTransporterState.transactions
				]
			}
			let nextCallDateFormat = formats.DEFAULT_DATE_TIME_FORMAT

			if (
				getters.getClientState.next_driver_call &&
				typeof getters.getClientState.next_driver_call === 'string'
			) {
				nextCallDateFormat = getters.getClientState.next_driver_call.includes('-') ?
					'YYYY-MM-DD HH:mm' :
					formats.DEFAULT_DATE_TIME_FORMAT
			}

			let deal = {
				status_id: getters.getGlobalState.dealStatusID || 1,

				// date_loading: getters.getLoadingInfoState.date,
				// date_loading_departure: getters.getLoadingInfoState.dateDeparture,
				// date_unloading: getters.getUnloadingInfoState.date,
				monitoring_priority: {
					id: getters.getClientState.monitoringPriorityID
				},
				next_driver_call: getters.getClientState.next_driver_call ?
					convertDate(getters.getClientState.next_driver_call, 'YYYY-MM-DD HH:mm', nextCallDateFormat) :
					null,
				point_loading_description: getters.getGlobalState.loadingPointsDescription,
				point_unloading_description: getters.getGlobalState.unloadingPointsDescription,
				// point_loadings: getters.getLoadingInfoState.points.map(i => ({
				//   id: i.id
				// })),
				// point_unloading: getters.getUnloadingInfoState.points.map(i => ({
				//   id: i.id
				// })),
				responsible_salesman: {
					id: getters.getGlobalState.responsibleSalesManID
				},
				logistical: {
					id: getters.getGlobalState.logistID || ''
				},
				broker: null,
				description: getters.getGlobalState.description,
				transportation_type: getters.getGlobalState.typeOfTransportation ? {
					id: getters.getGlobalState.typeOfTransportation
				} : null,
				cargo_type: {
					id: getters.getClientState.cargoTypeID
				},
				cargo_cost: getters.getClientState.cargoCost,
				adr_category: null,
				is_crossdoc: getters.getClientState.isCrossdoc,
				container_number: getters.getTransporterState.containerNumber,
				marine_line: !getters.getTransporterState.marineLine
					? null
					: { id: getters.getTransporterState.marineLine },
				department: {
					id: getters.getGlobalState.department
				},
				transaction: _transactions,

				// client part
				client: {
					id: getters.getClientState.companyID
				},
				client_delay: getters.getClientState.delay || null,
				client_our_company: {
					id: getters.getClientState.ourCompanyID
				},
				client_type: getters.getClientState.type,
				own_weight: getters.getClientState.own_weight,

				// transporter part
				transporter: getters.isSalesDepartment &&
        getters.getTransporterState.companyID ? {
						id: getters.getTransporterState.companyID
					} : null,
				transporter_our_company: getters.isSalesDepartment &&
        getters.getTransporterState.ourCompanyID ? {
						id: getters.getTransporterState.ourCompanyID
					} : null,
				transporter_delay: getters.isSalesDepartment
					? (getters.getTransporterState.delay || null)
					: null,
				transporter_type: getters.isSalesDepartment
					? getters.getTransporterState.type
					: null,

				driver: getters.getTransporterState.transporterDriverID ? {
					id: getters.getTransporterState.transporterDriverID
				} : null,
				vehicle: getters.getTransporterState.driverVehicleID ? {
					id: getters.getTransporterState.driverVehicleID
				} : null,
				trailer: getters.getTransporterState.driverTrailerID ? {
					id: getters.getTransporterState.driverTrailerID
				} : null,

				transportation_number: getters.getClientState.transportationNumber || null,
				inset: getters.getClientState.insetID
					? { id: getters.getClientState.insetID }
					: null,
				type_transportation: getters.isSalesDepartment
					? getters.getTransporterState.transportationType
					: null,
				document_turnover: getters.getGlobalState.documentTurnover &&
        getters.getGlobalState.documentTurnover.length > 0
					? getters.getGlobalState.documentTurnover
					: []
			}

			if ( getters.getPathLocations.filter(i => i.location_type).length) {
				deal.deal_locations = getters.getPathLocations.map((i, index) => {
					i.position = index

					return i
				})
			} else {
				deal.deal_locations = null
			}

			if (getters.getGlobalState.id) {
				deal = { ...{ id: getters.getGlobalState.id }, ...deal }
			}

			try {
				const _mapIterator = file => ({
					document_type_id: file.document_type ? file.document_type.id : (file.document_type_id || null),
					weight: 0,
					file: file.fileObject || null,
					name: file.original_name || null,
					tmp_path: file.tmp_path || null
				})
				let _files = [
					...getters.getClientState.files.filter(i => i.fileObject)
						.map(_mapIterator)
				]
				if (getters.isSalesDepartment) {
					_files = [
						..._files,
						...getters.getTransporterState.files.filter(i => i.fileObject)
							.map(_mapIterator)
					]
				}

				const nullableFields = Object.keys(deal)
					.filter(i => i !== 'transaction')

				const dealFormData = jsonToFormData(deal, {
					includeNullValues: true,
					fieldsForNullValue: [...nullableFields]
				})

				deal.deal_locations.forEach((location, index) => {
					['expectation_time_in', 'expectation_time_out'].forEach(key => {
						if (!location[key]) {
							dealFormData.append(`deal_locations[${index}][${key}]`, '')
						}
					})
				})

				if (deal.point_loadings && deal.point_loadings.length === 0) {
					dealFormData.append('point_loadings[]', '')
				}
				if (deal.point_unloading && deal.point_unloading.length === 0) {
					dealFormData.append('point_unloading[]', '')
				}

				_files.forEach((value, index) => {
					dealFormData.append(`files[${index}][document_type_id][]`,
						value.document_type_id)
					dealFormData.append(`files[${index}][weight][]`, value.weight)
					if (value.tmp_path) {
						dealFormData.append(`files[${index}][tmp_path]`, value.tmp_path)
						dealFormData.append(`files[${index}][name]`, value.name)
					} else {
						dealFormData.append(`files[${index}][files][]`, value.file)
					}
				})
				let response = null
				if (!state.id) {
					response = await dealApi.createDeal(dealFormData, {
						'Content-Type': 'multipart/form-data'
					})
				} else {
					response = await dealApi.updateDeal(dealFormData, {
						'Content-Type': 'multipart/form-data',
						'_method': 'PUT'
					})

					const isContainNewItems = (items = []) => items.length
						? items.some(item => !item.id)
						: false

					if (isContainNewItems(getters.getClientState.transactions)) {
						commit('UPDATE_CLIENT_STATE', {
							field: 'transactions',
							value: (response.data.transaction || [])
								.filter(i => i.type.id === transactionTypes.CLIENT_FREIGHT)
						})
					}

					if (isContainNewItems(getters.getTransporterState.transactions)) {
						commit('UPDATE_TRANSPORTER_STATE', {
							field: 'transactions',
							value: (response.data.transaction || [])
								.filter(i => i.type.id === transactionTypes.TRANSPORTER_FREIGHT
									|| i.type.id === transactionTypes.TEO)
						})
					}
					if (isContainNewItems(getters.getGlobalState.otherTransactions)) {
						const types = [
							transactionTypes.CLIENT_DISPATCHERS,
							transactionTypes.SOTKA_SALESMAN,
							transactionTypes.SOTKA_LOGIST,
							transactionTypes.BROKERS_TYPE
						]

						commit('UPDATE_GLOBAL_STATE', {
							field: 'otherTransactions',
							value: (response.data.transaction || [])
								.filter(i => types.includes(i.type.id))
						})
					}

					if (isContainNewItems(getters.getClientState.files)) {
						commit('UPDATE_CLIENT_STATE', {
							field: 'files',
							value: response.data.files
								.filter(file => file.document_type_id === fileTypes.CLIENT_ORDER)
						})
					}

					if (isContainNewItems(getters.getTransporterState.files)) {
						commit('UPDATE_TRANSPORTER_STATE', {
							field: 'files',
							value: response.data.files
								.filter(file => file.document_type_id === fileTypes.TRANSPORTER_ORDER)
						})
					}
				}

				if (withRefreshCfo) {
					dispatch('cfo/refreshEntireCfo', {}, {root:true});
				}
				this._vm.$notify({
					type: 'success',
					text: 'Сделка успешно создана!'
				})

				if (cb) {
					cb()
				}

				if (withRedirect) {
					router.push({ name: 'CFO' })

					commit('RESET_STATE')
				}

				// todo: IF YOU WANT MAKE SOME MAGIC PLACE IT BELOW THIS LINE

				return response ? response.data : null
			} catch (e) {
				window.console.error(e)

				this._vm.$notify({
					type: 'error',
					text: 'Ошибка создания сделки!',
					message: e
				})
			}
		},
		async fetchEditingDeal ({ commit, getters, dispatch }, payload) {
			try {
				const headers = {}

				if (payload.small_object) {
					headers['request-type'] = 'deal'
				} else if (payload.mc) {
					headers['request-type'] = 'mc'
				}

				const isMc = !!payload.mc

				document.title = `Сделка № ${payload.id}`

				const { data } = await dealApi.getBaseDeal(payload.id, headers)

				//todo refactor - replace all commits into one

				await commit('UPDATE_GLOBAL_STATE', {
					field: 'otherTransactions',
					value: (data.transaction || []).filter(i => ![1, 2, 13].includes(i.type.id))
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'documentTurnover',
					value: data.document_turnover || []
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'editingDeal',
					value: data
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'typeOfTransportation',
					value: data.transportation_type ? data.transportation_type.id : null
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'id',
					value: data.id
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'department',
					value: data.department.id
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'dealStatusID',
					value: data.status_id
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'responsibleSalesManID',
					value: data.responsible_salesman ? data.responsible_salesman.id : null
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'logistID',
					value: data.logistical ? data.logistical.id : null
				})
				commit('UPDATE_GLOBAL_STATE', {
					field: 'description',
					value: data.description
				})

				await commit('UPDATE_CLIENT_STATE', {
					field: 'files',
					value: (data.files || []).filter(i => i.document_type)
						.filter(i => i.document_type.id === 9)
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'files',
					value: (data.files || []).filter(i => i.document_type)
						.filter(i => i.document_type.id === 10)
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'type',
					value: data.client_type
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'companyID',
					value: data.client ? data.client.id : null
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'ourCompanyID',
					value: data.client_our_company? data.client_our_company.id : null
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'isCrossdoc',
					value: data.is_crossdoc
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'cargoTypeID',
					value: data.cargo_type ? data.cargo_type.id : null
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'cargoCost',
					value: data.cargo_cost
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'monitoringPriorityID',
					value: data.monitoring_priority ? data.monitoring_priority.id : null
				})
				commit('UPDATE_CLIENT_STATE', {
					field: 'next_driver_call',
					value: data.next_driver_call
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'transactions',
					value: (data.transaction || []).filter(i => i.type.id === 1)
						.filter(i => !i.parent)
				})
				await commit('UPDATE_CLIENT_STATE', {
					field: 'companyObject',
					value: data.client
				})
				dispatch('clientCompanyIsBlocked', data.client)
				await commit('UPDATE_CLIENT_STATE', {
					field: 'transportationNumber',
					value: data.transportation_number
				})
				if (data.inset) {
					await commit('UPDATE_CLIENT_STATE', {
						field: 'insetID',
						value: data.inset.id
					})
					commit('HELPER_MODULE/SET_INSETS', [data.inset],
						{ root: true }
					)
				}
				await commit('UPDATE_CLIENT_STATE', {
					field: 'delay',
					value: data.client_delay
				})

				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'type',
					value: data.transporter_type
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'companyID',
					value: data.transporter ? data.transporter.id : null
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'ourCompanyID',
					value: data.transporter_our_company
						? data.transporter_our_company.id
						: null
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'transactions',
					value: (data.transaction || []).filter(
						i => i.type.id === 2 || i.type.id === 13).filter(i => !i.parent)
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'companyObject',
					value: data.transporter
				})
				dispatch('transporterCompanyIsBlocked', data.transporter)
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverObject',
					value: data.driver
				})
				dispatch('driverIsBlocked', data.driver)
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'delay',
					value: data.transporter_delay
				})

				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'transporterDriverID',
					value: data.driver ? data.driver.id : null
				})
				if (data.type_transportation === 'land' || data.type_transportation ===
          null) {
					await commit('UPDATE_TRANSPORTER_STATE', {
						field: 'driverVehicleID',
						value: data.vehicle ? data.vehicle.id : null
					})
					await commit('UPDATE_TRANSPORTER_STATE', {
						field: 'driverTrailerID',
						value: data.trailer ? data.trailer.id : null
					})
					dispatch('checkVehicleOrTrailer', {
						data: data.vehicle,
						type: 'vehicle'
					})
					dispatch('checkVehicleOrTrailer', {
						data: data.trailer,
						type: 'trailer'
					})
					await prepareDriverVehiclesAndTrailers(getters, commit, dispatch, data.driver)
				} else {
					await commit('UPDATE_TRANSPORTER_STATE', {
						field: 'transportationType',
						value: 'marine'
					})
				}
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'containerNumber',
					value: data.container_number
				})
				await commit('UPDATE_TRANSPORTER_STATE', {
					field: 'marineLine',
					value: data.marine_line ? data.marine_line.id : null
				})

				await commit('UPDATE_GLOBAL_STATE', {
					field: 'pathLocations',
					value: data.deal_locations
				})

				await commit('UPDATE_GLOBAL_STATE', {
					field: 'loadingPointsDescription',
					value: data.point_loading_description
				})
				await commit('UPDATE_GLOBAL_STATE', {
					field: 'unloadingPointsDescription',
					value: data.point_unloading_description
				})
				commit('UPDATE_CLIENT_STATE', {
					field: 'own_weight',
					value: data.own_weight
				})

				const isTransportDepartment = getters.isTransportDepartment || !getters.isGroundTransportationType
				if (isTransportDepartment && data.driver) {
					commit('UPDATE_TRANSPORTER_STATE', {
						field: 'helperDrivers_',
						value: [
							{
								driver: {
									id: data.driver.id
								},
								...data.driver.contact
							}
						]
					})
				}

				prepareCompanyOurCompanies(getters, commit, 'client', data.client)
				prepareCompanyCargoTypes(getters, commit, 'client', data.client)
				prepareCompanyOurCompanies(getters, commit, 'transporter', data.transporter)
				prepareCompanyDrivers(getters, commit, dispatch, data.transporter_type, data.transporter, isMc)

				return data
			} catch (e) {
				window.console.error(e)

				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения сделки!',
					message: e
				})
			}
		},
		compareLocations ({ state }) {
			const companyLocationsId = state.client.companyObject.locations.map(el => el.id);

			return state.pathLocations.reduce((locations, location) => {
				if (location && location.location) {
					const id = location.location.id

					if (companyLocationsId.indexOf(id) === -1) {
						locations.push({
							id: id,
							name: location.location.address.short_address
						})
					}
				}

				return locations
			}, [])
		},
		// Todo need remove all old functional with copy Deal (FRONT path)
		async newCopyDeal ({ getters, commit }, payload) {
			const dealId = getters.getGlobalState.id
			if (!dealId) {
				this._vm.$crmNotify.error('Нет сделки для копирования!')

				return;
			}
			let req = {dealId: dealId}
			if (payload) {
				req.type = payload
			}
			const {data} = await dealApi.makeReplicate(req)
			if (data.dealId) {
				router.push({
					name: 'CRM.DEAL',
					params: {
						id: data.dealId
					},
					query: {
						copyId: dealId,
						copyType: data.copyType
					}
				})

				if (data.copyType) {
					await commit('UPDATE_GLOBAL_STATE', {
						field: 'copingId',
						value: data.dealId
					})
				}

				if (data.error) {
					this._vm.$crmNotify.error(data.error)
				}
			}
		},

		async copyDeal ({ commit, getters, dispatch }, copyType) {
			await commit('UPDATE_GLOBAL_STATE', {
				field: 'isCoping',
				value: true
			})

			await router.push({
				name: 'CRM.DEAL'
			})

			await commit('UPDATE_GLOBAL_STATE', {
				field: 'id',
				value: null
			})
			await commit('UPDATE_GLOBAL_STATE', {
				field: 'dealStatusID',
				value: null
			})
			await commit('UPDATE_GLOBAL_STATE', {
				field: 'documentTurnover',
				value: []
			})
			await commit('UPDATE_GLOBAL_STATE', {
				field: 'otherTransactions',
				value: []
			})

			const comparedArray = await dispatch('compareLocations')
			const invalidLocationNames = comparedArray.map(i => i.name).join(',')

			if (comparedArray.length) {
				this._vm.$notify({
					type: 'warning',
					text: 'Локации в новую сделку скопированы не будут: ' + invalidLocationNames,
					duration: -1
				})
			}

			const deleteBadTransactions = transactions => transactions
				.filter(transaction => +transaction.status.id !== 4)
				.map(transaction => {
					transaction.id = null
					transaction.parent = null
					transaction.children = []

					return transaction
				})
			await commit('UPDATE_CLIENT_STATE', {
				field: 'transactions',
				value: deleteBadTransactions(getters.getClientState.transactions)
			})
			await commit('UPDATE_TRANSPORTER_STATE', {
				field: 'transactions',
				value: deleteBadTransactions(getters.getTransporterState.transactions)
			})
			await commit('UPDATE_CLIENT_STATE', {
				field: 'transportationNumber',
				value: null
			})
			await commit('UPDATE_CLIENT_STATE', {
				field: 'insetID',
				value: null
			})
			await commit('UPDATE_CLIENT_STATE', {
				field: 'files',
				value: []
			})
			await commit('UPDATE_TRANSPORTER_STATE', {
				field: 'files',
				value: []
			})

			commit('RESET_PATH_LOCATIONS', comparedArray)

			dispatch('setDelayInfo', 'client')
			dispatch('setDelayInfo', 'transporter')

			let copyCallbackMessage = ''

			switch (copyType) {
			case 'ALL':
				copyCallbackMessage = 'Данные по сделке успешно скопированы!'
				break
			case 'CLIENT':
				await commit('RESET_TRANSPORTER_STATE')
				copyCallbackMessage = 'Даныне по сделке без перевозчика успешно скопированы!'
				break
			case 'TRANSPORTER':
				await commit('RESET_CLIENT_STATE')
				copyCallbackMessage = 'Даныне по сделке без заказчика успешно скопированы!'
				break
			default: {
				copyCallbackMessage = ''
				break
			}
			}


			this._vm.$notify({
				type: 'warning',
				text: copyCallbackMessage
			})
		},
		async setDelayInfo ({commit, getters}, type) {
			if (type === 'transporter' &&
        getters.getGlobalState.department &&
        getters.getGlobalState.department === 2
			) {
				return false
			}
			const _state = getters[type === 'client' ? 'getClientState' : 'getTransporterState']
			const ourCompanyID = _state.ourCompanyID

			if (_state.companyObject.contracts && _state.companyObject.contracts.length > 0) {
				let selectedContract = _state.companyObject.contracts
					.filter(i => i.our_company)

				if (getters.getPermissionsState.selectTypeOfTransportation) {
					selectedContract = selectedContract
						.filter(contract => contract.transportation_types)
						.filter(contract => contract.transportation_types
							.some(type => type.id === getters.getGlobalState.typeOfTransportation))
				}

				selectedContract = selectedContract.find(i => i.our_company.id === ourCompanyID)

				if (selectedContract && selectedContract.delay_days) {
					await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
						field: 'delay',
						value: selectedContract.delay_days
					})
				} else {
					await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
						field: 'delay',
						value: _state.companyObject.delay_days || null
					})
				}

			} else {
				if (getters.getGlobalState.editingDeal && getters.getGlobalState.editingDeal[type].delay_days) {
					await commit(`UPDATE_${type.toUpperCase()}_STATE`, {
						field: 'delay',
						value: getters.getGlobalState.editingDeal[type].delay_days
					})
				}
			}
		},

		async _reInitOurCompanies ({commit, getters}, type = 'client') {
			const data = type === 'client' ?
				getters.getClientState.companyObject : getters.getTransporterState.companyObject
			await prepareCompanyOurCompanies(getters, commit, type, data)
		},


		 updateOtherTransactions ({ commit }, payload) {
			commit('UPDATE_GLOBAL_STATE', {
				field: 'otherTransactions',
				value: JSON.parse(JSON.stringify(payload))
			})
		},
		 onChangeTypeOfTransportation ({commit, getters}) {
			commit('UPDATE_CLIENT_STATE', {
				field: 'ourCompanyID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'ourCompanyID',
				value: null
			})
			prepareCompanyOurCompanies(getters, commit, 'client', getters.getClientState.companyObject)
			prepareCompanyOurCompanies(getters, commit, 'transporter', getters.getTransporterState.companyObject)
		},

		async onChangeDriver ({ commit, dispatch }, payload) {
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'driverVehicleID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'driverTrailerID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'driverObject',
				value: {}
			})

			if (payload) {
				await dispatch('fetchDriverByID', payload)
			}
		},
		onChangeTransporterType ({ commit }) {
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'helperCompanies_',
				value: []
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'companyID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'companyObject',
				value: {}
			})
		},
		async onChangeClientCompany ({ commit, dispatch }, id) {
			commit('UPDATE_CLIENT_STATE', {
				field: 'delay',
				value: null
			})
			commit('UPDATE_CLIENT_STATE', {
				field: 'ourCompanyID',
				value: null
			})
			if (id) {
				await dispatch('fetchClientCompany', true)
			}

			return true
		},
		async onChangeTransporterCompany ({ commit, dispatch }, id) {

			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'delay',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'transporterDriverID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'ourCompanyID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'driverVehicleID',
				value: null
			})
			commit('UPDATE_TRANSPORTER_STATE', {
				field: 'driverTrailerID',
				value: null
			})

			if (id) {
				await dispatch('fetchTransporterCompany', true)
			}

			return true
		},

		driverIsBlocked ({ commit }, driver) {
			if (driver && driver.contact && driver.contact.is_blocked) {
				commit('SET_DRIVER_IN_BLOCK_LIST', true)
				this._vm.$crmNotify.error(`Водитель ${driver.contact.full_name} в черном списке!`)
			} else {
				commit('SET_DRIVER_IN_BLOCK_LIST', false)
			}
		},
		clientCompanyIsBlocked ({ commit }, company) {
			if (company && company.is_blocked) {
				commit('CLIENT_COMPANY_IS_BLOCKED', true)
				this._vm.$crmNotify.error(`Компания ${company.name} в черном списке!`)
			} else {
				commit('CLIENT_COMPANY_IS_BLOCKED', false)
			}
		},
		transporterCompanyIsBlocked ({ commit }, company) {
			if (company && company.is_blocked) {
				commit('TRANSPORTER_COMPANY_IS_BLOCKED', true)
				this._vm.$crmNotify.error(`Компания/контакт в черном списке!`)
			} else {
				commit('TRANSPORTER_COMPANY_IS_BLOCKED', false)
			}
		},
		checkVehicleIsBlocked ({ state, getters, dispatch }, type) {
			let key = null
			let id = null
			if (type === 'vehicle') {
				key = 'helperVehicles_'
				id = state.transporter.driverVehicleID
			} else if (type === 'trailer') {
				key = 'helperTrailers_'
				id = state.transporter.driverTrailerID
			}
			const model = getters.getTransporterState[key].find((option) => option.value === id)
			if (model && model.info) {
				dispatch('checkVehicleOrTrailer', {
					data: model.info,
					type: type
				})
			}
		},
		async getVehiclePermissibleLoad ({ state, commit }) {
			const vehicleID = state.transporter.driverVehicleID
			const trailerID = state.transporter.driverTrailerID
			let data

			try {
				if (vehicleID && trailerID) {
					data = await vehicleApi.getPermissibleLoad(vehicleID, trailerID)
				} else if (vehicleID) {
					data = await vehicleApi.getPermissibleLoad(vehicleID)
				}
				commit('SET_PERMISSIBLE_LOAD', data.weight)
				commit('SET_PERMISSIBLE_LOAD_ERROR_MSG', null)
			} catch (e) {
				if (e.response?.data) {
					commit('SET_PERMISSIBLE_LOAD_ERROR_MSG', e.response.data.messages[0])
				}
				commit('SET_PERMISSIBLE_LOAD', null)
			}
		},
		checkVehicleOrTrailer ({commit}, { data, type }) {
			if (data && data.is_blocked && type === 'vehicle') {
				commit('VEHICLE_IS_BLOCKED', true)
				this._vm.$crmNotify.error(`Автомобиль ${data.license_plate} в черном списке!`)
			} else if (data && !data.is_blocked && type === 'vehicle') {
				commit('VEHICLE_IS_BLOCKED', false)
			}

			if (data && data.is_blocked && type === 'trailer') {
				commit('TRAILER_IS_BLOCKED', true)
				this._vm.$crmNotify.error(`Прицеп ${data.license_plate} в черном списке!`)
			} else if (data && !data.is_blocked && type === 'trailer') {
				commit('TRAILER_IS_BLOCKED', false)
			}
		},
		onChangeDriverVehicle ({ getters, commit, dispatch }) {
			if (
				getters.selectedVehicleHasId1 &&
        getters.getTransporterState.helperTrailers_.length === 1
			) {
				dispatch('checkVehicleOrTrailer', {
				  data: getters.getTransporterState.helperTrailers_[0].info, type: 'trailer' })
				commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverTrailerID',
					value: getters.getTransporterState.helperTrailers_[0].value
				})
			} else {
				commit('UPDATE_TRANSPORTER_STATE', {
					field: 'driverTrailerID',
					value: null
				})
			}
		}
	}
}

export default CRM_DEAL
