import cfoApi from '@/api/cfo'

const bonus = {
	namespaced: true,
	state: {
		bonuses: [],
		plans: []
	},
	getters: {
		getLogisticalBonuses: state => state.bonuses.logists,
		getPortfolioManagerBonuses: (state, getters, rootState, rootGetters) => {
			let bonuses = state.bonuses['portfolio-manager']

			if (
				bonuses &&
					rootGetters.getUser &&
					(!rootGetters.hasRoleGetter('sales-chief') &&
							rootGetters.hasRoleGetter('portfolio-manager'))
			) {
				bonuses = bonuses.reduce((memo, bonus) => {
					if (bonus.responsible_salesman.id === rootGetters.getUser.contact.id) {
						memo.push(bonus)
					}

					return memo
				}, [])
			}

			return bonuses
		},
		getSalesMenBonuses: state => state.bonuses['sales-men']
	},
	mutations: {
		SET_BONUSES (state, data) {
			state.bonuses = data
		},
		SET_PLANS (state, data) {
			state.plans = data
		}
	},
	actions: {
		async GET_BONUSES ({ commit }, payload = {}) {
			const response = await cfoApi.getBonusesData(payload)
			const { data } = response

			commit('SET_BONUSES', data)
		},

		async GET_PLANS ({ commit }, payload = {}) {
			const response = await cfoApi.getPlansData(payload)
			const { data } = response

			commit('SET_PLANS', data)
		},

		async UPDATE_PLANS ({ state }, month) {
			const plans = state.plans.map(p => ({
				id: p.active_plan.id,
				contact_id: p.id,
				role_id: p.active_plan.role.id,
				basic: p.active_plan.basic,
				plan: p.active_plan.plan
			}))

			const payload = {
				'plans': plans,
				'month': month
			}

			await cfoApi.updatePlans(payload)
		}
	}
}

export default bonus
