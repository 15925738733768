export default {
	selectedAtLeastOne (state) {
		return Object.keys(state.selected_ids).length
	},
	rowIsSelected (state) {
		return (id) => !!state.selected_ids[`#${id}`]
	},
	allPageIsSelected (state) {
		return (data) => {
			if (!data || !data.length) {
				return false
			}

			return data.every(item => !!state.selected_ids[`#${item.id}`])
		}
	}
}
