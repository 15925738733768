function prepareDataForFilter(data = []){
  return data.map(d => d.id).join(';')
}
const defaultState = () => ({
  selectedCategory: [],
  selectedType: [],
  selectedIndustries: null,
  selectedAddress: null,
  selectedContact: null,
  selectedCompany: null,
  selectedLegalAddress: null,
  selectedActualAddress: null,
  selectedDesc: null,
  selectedID: null,
  selectedCompanyType: [],

  byFields: []
});

const LOCATION_FILTER = {
  namespaced: true,
  state: defaultState(),

  getters: {
    getLocationFilterPayload: (state) => {
      const args = {
        'filter[categories][id]': prepareDataForFilter(state.selectedCategory) || null,
        'filter[location_type][id]': prepareDataForFilter(state.selectedType) || null,
        'filter[industries][id]': state.selectedIndustries || null,
        'filter[contacts][id]': state.selectedContact || null,
        'filter[companies][id]': state.selectedCompany || null,
        'search[address][address]': state.selectedAddress || null,
        'search[legal_address]': state.selectedLegalAddress || null,
        'search[actual_address][address]': state.selectedActualAddress || null,
        'search[description]': state.selectedDesc || null,
        'filter[id]': state.selectedID || null,
        'search[companies][company_types][id]': prepareDataForFilter(state.selectedCompanyType) || null,
      };
      if (state.byFields.length > 0) {
        state.byFields.forEach((i) => {
          const field = i.field ? i.field.value : null

          if (field === 'id') {
            args[`filter[${field}]`] = i.value
          } else {
            args[`search[${field}]`] = i.value
          }
        })
      }
      return args;
    },
    getLocationFilter: state => state,

  },

  mutations: {
    UPDATE_LOCATION_FILTER(state, payload) {
      state[payload.field] = payload.value;
    },
    CLEAR_FILTER(state) {
      Object.assign(state, defaultState());
    },
  },

  actions: {
    resetLocationFilter({ state, commit }) {
      commit('CLEAR_FILTER');
    },
  },
};

export default LOCATION_FILTER;
