export default () => ({
	data: [],
	tableFields: [],
	localizedFields: [],
	additionalData: {
		per_page: 30,
		page: 1,
		total: 0,
		last_page: 0
	},
	isBusyTable: false,
	debtor_type: 'client',
	is_all_debtors: false
})
