export default {
	/**
   * Reset already checked deals (used before each filter request)
   * @param commit
   */
	resetSelectedDeals ({ commit }) {
		commit('SET_DEAL_SELECTED', [])
		commit('SET_HEAD_CHECKBOX', false)
	},
	/**
   * Add deal ID to selected deals array for batch editing
   * @param state
   * @param commit
   * @param dealID
   */
	selectDealForBatchEdit ({ state, commit }, dealID) {
		const indexOfSelectedDeal = state.dealSelected.indexOf(dealID)

		if (indexOfSelectedDeal > -1) {
			// deselect by deal ID
			commit('REMOVE_DEAL_ID_FROM_ARRAY', indexOfSelectedDeal)
		} else {
			// select by deal ID
			commit('PUSH_ID_TO_SELECTED_DEALS', dealID)
		}
	},
	/**
   * Select all deals for batch editing
   * @param state
   * @param commit
   * @param dealID
   */
	selectAllDealsForBatchEdit ({state, commit, rootState}) {
		let dealIDs = []
		if (state.headCheckbox) {
			dealIDs = rootState.cfo.data.map(d => d.id)
		}
		commit('SET_DEAL_SELECTED', dealIDs)
	}
}
