import defaultState from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const MULTI_EDIT_CONTACTS = {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions
}

export default MULTI_EDIT_CONTACTS
