import Vue from 'vue'

export default {
	SET_ORDER_COMPANIES (state, payload) {
		state.orderCompanies = payload;
	},
	SET_ORDER_COMPANIES_LOADING (state, flag) {
		state.orderCompaniesLoading = flag;
	},
	SET_ORDER_PAGE (state, page) {
		state.page = page;
	},
	APPEND_ORDER_COMPANIES (state, companies) {
		state.orderCompanies.push(...companies);
	},
	SET_ADDITIONAL_DATA (state, data) {
		Vue.set(state, 'additionalData', data)
	}
}
