const MULTI_EDIT_LOCK = {
  OPEN_ENTITY: 'entity/opened',
  CLOSED_ENTITY: 'entity/closed',
  EDITING_IS_BLOCKED: 'entity/is_blocked',
  EDITING_IS_GRANTED: 'entity/is_granted',
  REFRESH_QUEUE: 'entity/refresh_queue',
  SAVED: 'entity/saved',
  REFRESH_ENTITY: 'entity/refresh',
  CONNECT_ERROR: 'disconnect',
  LOGOUT: 'logout',
  ADMIN_NOTIFY: 'admin.queue_is_changed',
  REDIRECT_HOMEPAGE: 'redirect_homepage',
  REMOVE_FROM_QUEUE: 'entity/remove_user_from_queue',
  SET_MY_POSITION: 'entity/set_my_position',
  SOCKET_GOT_IN_QUEUE: 'entity/user_got_in_first_queue'
}

export {
  MULTI_EDIT_LOCK
}
