import store from '../store'

const registerStore = (name, module) => {
  if(!(store && store.state && store.state[name])) {
    store.registerModule(name, module)
  }
}
const unregisterStore = (name) => {
  store.unregisterModule(name)
}

export {
  registerStore,
  unregisterStore
}
