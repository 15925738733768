import userApi from '@/api/user'
import loginTypes from '@/config/login-types'
import { StatusCodes } from 'http-status-codes'
import router from '@/router'
import { getDiff, getDurationInMs } from '@/helpers/date'

import state from './state'
import getters from './getters'
import mutations from './mutations'

const verification = {
	namespaced: true,
	state: state(),
	getters: getters,
	mutations: mutations,

	actions: {
		async onSubmit ({ state, commit, rootState }) {
			commit('UPDATE_VERIFICATION_STATE', {
				field: 'loading',
				value: true
			})
			try {
				const response = await userApi.verifySmsCode({
					code: state.code,
					key: rootState.login.sessionId
				})

				if (response && response.code === StatusCodes.OK) {
					// eslint-disable-next-line default-case
					switch (response.data.type) {
					case loginTypes.CODE_APPROVED_TYPE: {
						await this.dispatch('login/authenticateUser')
						break
					}
					}
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)

				if (e &&
          e.response &&
          e.response.data &&
          [StatusCodes.BAD_REQUEST, StatusCodes.FORBIDDEN].includes(e.response.data.code)
				) {
					this.commit('login/SET_ACTION_TYPE', e.response.data.errors.type)

					// eslint-disable-next-line default-case
					switch (e.response.data.errors.type) {
					case loginTypes.INCORRECT_VERIFICATION_CODE:
						this._vm.$crmNotify.warning(e.response.data.errors.messages)

						break
					case loginTypes.BANNED_TYPE: {
						this.commit('login/SET_MESSAGE', e.response.data.errors.messages)

						await router.push({
							name: 'Login',
							query: {
								s: rootState.login.steps.wait
							}
						}).catch(() => {})

						break
					}
					case loginTypes.PHONE_CODE_SEND_TYPE:
						this.commit('login/SET_MESSAGE', e.response.data.errors.messages)

						break
					}
				}
			} finally {
				commit('UPDATE_VERIFICATION_STATE', {
					field: 'loading',
					value: false
				})
			}
		},
		resetCode ({ commit }) {
			commit('UPDATE_VERIFICATION_STATE', {
				field: 'code',
				value: ''
			})
		},
		setUntilCount ({ commit }, msg) {
			const regex = new RegExp(/\d{0,2}:\d{0,2}:\d{0,2}/, 'g')
			const matchedString = msg.match(regex)

			if (matchedString && matchedString.length) {
				commit('UPDATE_VERIFICATION_STATE', {
					field: 'dateUntil',
					value: matchedString[0]
				})
			}
		},
		startCountdown ({ commit, state }) {
			let time = 60000

			if (state.dateUntil) {
				const [hours, minutes, seconds] = state.dateUntil.split(':')

				const dateUntil = new Date()

				dateUntil.setHours(+hours)
				dateUntil.setMinutes(+minutes)
				dateUntil.setSeconds(+seconds)

				time = getDurationInMs(getDiff(dateUntil, new Date()))
			}

			commit('UPDATE_VERIFICATION_STATE', {
				field: 'time',
				value: time
			})
			commit('UPDATE_VERIFICATION_STATE', {
				field: 'countdown',
				value: true
			})
		},
		stopCountdown ({ commit }) {
			commit('UPDATE_VERIFICATION_STATE', {
				field: 'countdown',
				value: false
			})
			commit('UPDATE_VERIFICATION_STATE', {
				field: 'dateUntil',
				value: null
			})
		}
	}
}

export default verification
