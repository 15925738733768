export default {
	SET_FILTER_STATE (state, payload) {
		state[payload.field] = payload.value
	},
	RESET_FILTER_STATE (state) {
		state.credit_card = null
		state.full_name = null
		state.tax_number = null
		state.company = null
		state.contact = null
	}
}