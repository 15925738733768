import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
	postContact (payload = {}, headers = {}) {
		return axios.post(`${HOST_URL}/constructor/contact-driver`, payload, {
			headers: headers
		})
	},
	updateContactPhone (payload = {}) {
		return axios.post(`${HOST_URL}/contact-phone`, payload)
	},
	getContactById (id) {
		return axios.get(`${HOST_URL}/constructor/contact/${id}`)
	},
	getContacts (payload = {}) {
		return axios.get(`${HOST_URL}/contact`, {
			params: payload
		})
	},
	getContactByID (id, payload = {}) {
		return axios.get(`${HOST_URL}/contact/${id}`, {
			params: payload
		})
	},
	getContactsByRelation (relation, id) {
		return axios.get(`${HOST_URL}/get-contacts/${relation}/${id}`)
	},
	getContactsByName (query) {
		return axios.get(`${HOST_URL}/get-contacts/clients`, {
			params: {
				fullName: query
			}
		})
	},
	addNewMessengers (payload = {}) {
		return axios.post(`${HOST_URL}/v${API_VERSION}/messengers-login`, payload)
	},

	saveRelation (id, key, relatedKeyID) {
		return axios.post(`${HOST_URL}/constructor/contact-relations`, {
			id,
			[key]: relatedKeyID
		})
	},
	deleteRelation (id, key, relatedKeyID) {
		return axios.delete(`${HOST_URL}/constructor/contact-relations`, {
			params: {
				id,
				[key]: relatedKeyID
			}
		})
	},
	searchContacts (queryString, type = null) {
		const payload = {}
		if (type) {
			payload.type = type
		}

		return axios.get(`${HOST_URL}/search/contacts/${queryString}`, {
			params: payload
		})
	},
	searchByINN (code) {
		return axios.get(`${HOST_URL}/contact/search/inn/${code}`)
	},
	searchByNameAndBirthdate (payload = {}) {
		return axios.get(`${HOST_URL}/contact/search/name`, {
			params: payload
		})
	},
	searchByPhone (phone) {
		return axios.get(`${HOST_URL}/search/contacts/phones/${phone}`)
	},
	searchByInnInDB (inn) {
		return axios.get(`${HOST_URL}/search/contacts/inn/${inn}`)
	},
	searchByTaxInDB (tax) {
		return axios.get(`${HOST_URL}/search/contacts/tax/${tax}`)
	},

	checkDriverLicense (payload) {
		return axios.post(`${HOST_URL}/driver/driver-license`, payload, {
			headers: { token: process.env.VUE_APP_PARSERS_SECRET }
		})
	},
	getContactDataByCreditCard (card) {
		return axios.get(`${HOST_URL}/search/credit-card/${card}`)
	},
	getHistory (id) {
		return axios.get(`${HOST_URL}/logger/contact/${id}`)
	},
	manageBosses (payload = {}) {
	  return axios.post(`${HOST_URL}/boss-user-relation`, payload)
	},
	getDriverShortInfo (id) {
	  return axios.get(`${HOST_URL}/driver-short-info/${id}`)
	},
	deleteContactPhone (payload = {}) {
		return axios.delete(`${HOST_URL}/contact-phone`, {
			params: payload
		})
	}
}

