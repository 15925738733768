import defaultState from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const store = {
	namespaced: true,
	state: defaultState(),
	mutations,
	actions,
	getters
}

export default store