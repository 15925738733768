export default {
  methods: {
    requestPermission () {
      if (!window.Notification) {
        console.info('Браузер не поддерживает оповещения!');

        return false;
      }

      // eslint-disable-next-line no-undef
      if (Notification.permission === 'granted') {
        return true;
      }

      // eslint-disable-next-line no-undef
      return Notification.requestPermission();
    },
    newNotification (title, body = '', options = {}) {
      if (!window.Notification) {
        console.info('Браузер не поддерживает оповещения!');
      }

      // eslint-disable-next-line no-undef
      if (Notification.permission === 'granted') {
        // eslint-disable-next-line no-undef
        return new Notification(title, {
          body: body,
          tag: 'update-notification',
          icon: require('@/assets/images/fox_logo.png'),
          ...options
        });
      }
    }
  }
}
