import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const TG_URL = process.env.VUE_APP_TG_URL
const TG_TOKEN = process.env.VUE_APP_TG_TOKEN
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  fetchClientOrders(payload = {}) {
    return axios.get(`${HOST_URL}/order/client`, {
      params: payload
    })
  },
  fetchTransporterOrders(payload = {}) {
    return axios.get(`${HOST_URL}/order/transporter`, {
      params: payload
    })
  },
  getOrderByID (id, type = String, payload = {}) {
    return axios.get(`${HOST_URL}/order/${type}/${id}`, {
      params: payload
    })
  },
  getTransporterOrders (payload = {}) {
    return axios.get(`${HOST_URL}/order/transporter`, {
      params: payload
    })
  },
  getClientOrders (payload = {}) {
    return axios.get(`${HOST_URL}/order/client`, {
      params: payload
    })
  },
  createClientOrder (payload = {}, headers = {}) {
    return axios.post(`${HOST_URL}/order/client`, payload, {
      headers: headers
    })
  },
  createTransporterOrder (payload = {}, headers = {}) {
    return axios.post(`${HOST_URL}/order/transporter`, payload, {
      headers: headers
    })
  },
  shariInCrm(payload) {
    return axios.post(`${HOST_URL}/order/shared/company`,
      payload
    )
  },
  shariWithLardi(payload) {
    return axios.post(`${HOST_URL}/order/shared/lardi`,
      payload
    )
  },
  shareWithManagers(payload) {
    return axios.post(`${HOST_URL}/order/shared/manager`,
      payload
    )
  },
  createDealWithTransporter(ids) {
    return axios.get(`${HOST_URL}/order/client/${ids['client']}/transporter${ids['transporter'] ? '/'+ids['transporter'] : ''}`)
  },
  createDealWithClient(ids) {
    return axios.get(`${HOST_URL}/order/transporter/${ids['transporter']}/client${ids['client'] ? '/'+ids['client'] : ''}`)
  },
  deleteOrderById(payload) {
    return axios.delete(`${HOST_URL}/order/${payload['type']}/${payload['id']}`)
  },
  sendDataToTg(payload = {}) {
    const headers = {
      token: TG_TOKEN
    }
    return axios.post(`${TG_URL}/post-order`, payload, {
      headers: headers
    })
  },
}
