import dealApi from '@/api/deal'

const DEAL_HELPERS_MODULE = {
  namespaced: true,
  state: {
    cargoTypes: [],
    dealDepartments: [],
    monitoringPriorities: [],
    users: [],
    marineLines: []
  },
  getters: {

  },
  mutations: {
    SET_HELPERS (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key].map(i => ({
          value: i.id,
          label: i.name
        }))
      })
    },
    SET_USERS (state, payload) {
      state.users = payload.map(i => ({
        value: i.contact_id,
        label: i.full_name
      }))
    },
  },
  actions: {
    async getHelpers ({ commit }) {
      try {
        const { data } = await dealApi.getHelperData()

        commit('SET_HELPERS', {
          cargoTypes: data.cargo_type,
          dealDepartments: data.deal_department,
          monitoringPriorities: data.monitoring_priority,
          marineLines: data.deal_marineLine,
        })
        commit('SET_USERS', data.user)
      } catch (e) {
        console.error(e)
      }
    },
  }
}
export default DEAL_HELPERS_MODULE
