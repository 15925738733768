import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
	login (login = null, password = null, key = null) {
		return axios.post(`${HOST_URL}/user/login`, {
			email: login,
			password,
			key
		})
	},
	getUsersV1 (payload = {}) {
		return axios.get(`${HOST_URL}/user`, {
			params: payload
		})
	},
	getUsers (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/user`, {
			params: payload
		})
	},
	getUserV2 (payload = {}) {
		return axios.get(`${HOST_URL}/users`, {
			params: payload
		})
	},
	verifySmsCode ({ code, key }) {
		return axios.post(`${HOST_URL}/user/confirm/phone`, {
			code,
			key
		})
	},
	logout () {
		return axios.post(`${HOST_URL}/logout`)
	},
	fetchUser () {
		return axios.get(`${HOST_URL}/auth-user`)
	}
}
