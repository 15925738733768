import { getCurrentDate, formats } from '@/helpers/date'
const FileSaver = require('file-saver')

/**
 * Convert data (or response) to blob and generate with save Excel file
 * @param data
 * @param fileName
 * @returns {boolean}
 */
export default (data, fileName = 'cfo') => {
  const blob = new window.Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const name = `${fileName}-${getCurrentDate(formats.EXPORT_DATE_TIME_FORMAT)}`

  FileSaver.saveAs(blob, `${name}.xlsx`)

  return true
}
