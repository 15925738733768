import Vue from 'vue'

const defaultState = () => ({
  liveSearch: null,
  responsible: null,
  lastName: null,
  phone: null,
  company: null,
  contactType: [],
  directCarrier: null,
  workingForHimselfVal: null,
  byFields: [],
  localizedFields: {},
  isContactInBlackList: null,
  companyType: null,
  vehicleType: [],
  ourCompanyId: null,
  capacityFrom: null,
  capacityTo: null,
  volumeFrom: null,
  volumeTo: null,
  loadingFrom: null,
  loadingTo: null,
  ownerDocument: [],
  roles: [],
  isRoleFilterEnabled: false,
  isMailingModeEnabled: false,
  location: '',
  _type: null,
  modelTransportationType: null,
  email: '',
  driverLicenseNumber: '',
  dealsCountFrom: null,
  dealsCountTo: null,
  checkRequired: null,
  transportationTypesOptions: [
    {
      value: 1,
      label: 'По Украине'
    },
    {
      value: 2,
      label: 'Международная'
    }
  ]
})

const CONTACT_FILTER = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getRangeFilter: () => (from, to) => {
      let query = ''

      if (from) {
        query = `>=|${from}`
      }
      if (to) {
        query += `${from && to ? '&' : ''}<=|${to}`
      }

      return query || null
    },

    getTransportationTypeFilter: (state) => {
      const args = {}

      if (state.modelTransportationType) {
        const transportationTypes = state.modelTransportationType.map(e => e.value).join(';')
        if (state._type === 'contact' && transportationTypes) {
          args['filter[companies][transportation_types][id]'] = transportationTypes
        } else if (state._type === 'driver' && transportationTypes) {
          args['filter[contact][companies][transportation_types][id]'] = transportationTypes
        }
      }

      return args
    },

    getVehicleTypeFilter: (state) => {
      const args = {}

      if (state.vehicleType && state._type === 'contact') {
        args['filter[companies][vehicles][vehicle_types][id]'] = state.vehicleType.map(el => el.value).join(';') || null
      } else if (state.vehicleType && state._type === 'driver') {
        args['filter[vehicles][vehicle_types][id]'] = state.vehicleType.map(el => el.value).join(';') || null
      }
      return args
    },

    getRolesFilter: (state) => {
      const args = {}

      if (state.roles && state.roles.length && state.isRoleFilterEnabled) {
        args['filter[user][roles][id]'] = state.roles.map(i => i.id).join(';')
      } else if (state.isRoleFilterEnabled && state._type === 'contact') {
        args['has[user][roles]'] = true
      } else if (state.isRoleFilterEnabled && state._type === 'driver') {
        args['has[contact][user][roles]'] = true
      }

      return args
    },

    getManagersFilter: (state) => {
      const args = {}
      if (state.responsible === -1 && state._type === 'contact') {
        args['doesnt[managers]'] = true
      } else if (state.responsible === -1 && state._type === 'driver') {
        args['doesnt[contact][managers]'] = true
      } else if ((state.responsible !== -1 && (state._type === 'contact' || state._type === 'driver'))) {
        args['search[managers]'] = state.responsible
      }
      return args
    },

    getLocationFilter: (state) => {
      const args = {}
      const value = state.location || null

      if (state._type === 'contact') {
        args['search[companies][locations][address][address]'] = value
      } else if (state._type === 'driver') {
        args['search[contact][companies][locations][address][address]'] = value
      }

      return args
    },

    getContactFilterPayload: (state, getters, rootState, rootGetters) => {
      const payloadForFilter = {
        'global-search': state.liveSearch || null,
        ...getters.getManagersFilter || null,
        'search[full_name]': state.lastName,
        'search[phones]': state.phone,
        'filter[companies_id]': state.company,
        'search[direct_transporter]': state.directCarrier,
        'search[is_lpr]': state.workingForHimselfVal,
        'search[is_blocked]': state.isContactInBlackList,
        'filter[companies][company_types][id]': state.companyType || null,
        'search[emails]': state.email || null,
        'search[driver_license_number]': state.driverLicenseNumber || null,
        'filter[companies][contracts][our_company][id]': state.ourCompanyId ||
          null,
        'search[contact][review_required]': state.checkRequired || null,
        // 'filter[vehicles][max_capacity]': getters.getCapacityFilter,
        ...getters.getTransportationTypeFilter || null,
        ...getters.getRolesFilter || null,
        ...getters.getVehicleTypeFilter || null,
        ...getters.getLocationFilter,
        address_loading: state.loadingFrom || null,
        address_unloading: state.loadingTo || null
      }

      if (state.ownerDocument) {
        if (state.ownerDocument !== -1) {
          payloadForFilter['filter[vehicles][type_communication][id]'] = state.ownerDocument.map(el => el.id).join(';') || null
        } else if (state.ownerDocument === -1) {
          payloadForFilter['doesnt[vehicles][type_communication]'] = true
        }
      }
      if (state._type) {
        if (state._type === 'driver') {
          payloadForFilter['filter[contact][contact_types][id]'] = state.contactType.map(el => el.id).join(';') || null
          payloadForFilter['filter[vehicles][max_capacity]'] = getters.getRangeFilter(state.capacityFrom, state.capacityTo)
          payloadForFilter['filter[vehicles][volume]'] = getters.getRangeFilter(state.volumeFrom, state.volumeTo)
          payloadForFilter['filter[deals_counter]'] = getters.getRangeFilter(state.dealsCountFrom, state.dealsCountTo)
        } else if (state._type === 'contact') {
          payloadForFilter['filter[contact_types][id]'] = state.contactType.map(el => el.id).join(';') || null
          payloadForFilter['filter[companies][vehicles][max_capacity]'] = getters.getRangeFilter(state.capacityFrom, state.capacityTo)
          payloadForFilter['filter[companies][vehicles][volume]'] = getters.getRangeFilter(state.volumeFrom, state.volumeTo)
        }
      }

      if (state.byFields.length > 0) {
        const parsedFilterByFields = state.byFields.reduce((memo, item) => {
          const key = item.field
          if (!memo[key]) {
            memo[key] = []
          }
          memo[key].push(item.value)
          return memo
        }, {})

        for (const field in parsedFilterByFields) {
          if (field === 'id') {
            const str = 'filter[' + field + ']'
            payloadForFilter[str] = parsedFilterByFields[field].join(';')
          } else if (field === 'companies' && state._type === 'contact') {
            const str = 'search[companies][name]'
            payloadForFilter[str] = parsedFilterByFields[field]
          } else {
            const str = 'search[' + field + ']'
            payloadForFilter[str] = parsedFilterByFields[field].join(';')
          }
        }
      }
      return payloadForFilter
    }
  },

  mutations: {
    UPDATE_FILTER (state, payload) {
      Vue.set(state, payload.field, payload.value)
    },
    CLEAR_FILTER (state) {
      const resetState = defaultState()

      Object.keys(resetState).forEach((key) => {
        if (key !== '_type') {
          state[key] = resetState[key]
        }
      })
    },
    SET_TYPE (state, type) {
      state._type = type
    }
  },
  actions: {
    /**
     * Reset entire filter state
     * @param state
     * @param commit
     */
    resetContactFilter ({
      state,
      commit
    }) {
      commit('CLEAR_FILTER')
    },
    setInitialFilterForContact ({ dispatch }) {
      return dispatch('setInitialFilterSettings', {
        type: 'contact'
      })
    },
    setInitialFilterForDriver ({ dispatch }) {
      return dispatch('setInitialFilterSettings', {
        type: 'driver'
      })
    },
    setInitialFilterSettings ({
      commit,
      rootGetters
    }, { type }) {
      let needAttachResponsible = false

      commit('SET_TYPE', type)

      const canSeeEntireData = rootGetters.canGetter('access to complete index list')

      if (!canSeeEntireData) {
        if (type === 'contact') {
          needAttachResponsible = !rootGetters.canGetter('access to all contacts')
        } else if (type === 'driver') {
          needAttachResponsible = !rootGetters.canGetter('access to all drivers')
        }
      }

      if (!rootGetters.canGetter('crm access to the filter by responsible') && !canSeeEntireData) {
        commit('UPDATE_FILTER', {
          field: 'responsible',
          value: rootGetters.getUser.contact.full_name
        })
      }

      if (needAttachResponsible) {
        commit('UPDATE_FILTER', {
          field: 'responsible',
          value: rootGetters.getUser.contact.full_name
        })
      }
    }
  }
}

export default CONTACT_FILTER
