export default {
	SET_TABLE_PAGE (state, page) {
		state.additionalData.page = page
	},
	SET_PAGE_LIMIT (state, limit) {
		state.additionalData.per_page = limit
	},
	SET_ADDITIONAL_DATA (state, payload) {
		state.additionalData = payload
	},
	SET_TABLE_FIELDS (state, data) {
		state.allTableFields = data
	},
	SET_TABLE_LOCALIZED_FIELDS (state, fields) {
		state.localizedFields = fields
	},
	SET_BUSY_TABLE_FLAG (state, flag) {
		state.isBusyTable = flag
	},
	SET_CREDIT_CARDS (state, cards) {
		state.credit_cards = cards
	},
	RESET_STATE (state) {
		state.credit_cards = []
	},
	RESET_ADDITIONAL_DATA (state) {
		state.additionalData = {}
	}
}