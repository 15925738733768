import io from 'socket.io-client'

export default {
	namespaced: true,
	state: {
		socket: null,
		isConnected: null
	},
	mutations: {
		SET_SOCKET_INSTANCE (state, payload) {
			state.socket = payload
		},
		SET_SOCKET_CONNECTION_FLAG (state, payload) {
			state.isConnected = payload
		}
	},
	actions: {
		/**
     * Establish socket connection
     * @param getters
     * @param rootGetters
     * @param commit
     * @param dispatch
     * @returns {Socket}
     */
		connectToSocket ({ state, rootGetters, commit, dispatch }) {
			if (!state.socket) {
				const socket = io.connect(process.env.VUE_APP_SOCKET_URL, {
					query: {
						_id: rootGetters.getUser.id,
						_version: localStorage.getItem('build-version'),
						_roles: JSON.stringify(rootGetters.getRoles.map(i => i.name)),
						_userName: rootGetters.getUser.contact.name_surname,
						_tokenSalt: rootGetters.getToken.slice(rootGetters.getToken.length - 5)
					}
				})

				commit('SET_SOCKET_INSTANCE', socket)
			}

			dispatch('checkBuildVersion')

			return state.socket
		},
		/**
     * Emit event for check user build version
     * @param state
     * @param getters
     */
		checkBuildVersion ({ state}) {
			// eslint-disable-next-line no-undef
			state.socket.emit('check-build-version', localStorage.getItem('build-version'))
		},
		/**
     * Disconnect event from socket
     * @param state
     * @param getters
     * @param commit
     */
		disconnectFromSocket ({state, commit}) {
			if (state.socket) {
				state.socket.emit('user-logout')
			}

			commit('SET_SOCKET_INSTANCE', null)
		},
		addToEditQueue ({ state }, payload) {
			if (state.socket) {
				state.socket.emit('entity/opened', payload)
			}
		},
		saveEntity ({ state }, payload) {
			if (state.socket) {
				state.socket.emit('entity/saved', payload)
			}
		},
		removeFromEditQueue ({ state }, payload) {
			if (state.socket) {
				state.socket.emit('entity/closed', payload)
			}
		},
		iAmInactive ({ state }, payload) {
			if (state.socket) {
				state.socket.emit('entity/i-am-inactive', payload)
			}
		}
	}
}
