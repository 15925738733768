import Vue from 'vue'
import filterState from './state'

export default {
	SET_FILTER_FIELD_DATA (state, { field, value }) {
		Vue.set(state, field, value)
	},
	SET_FILTER_CHECKBOXES_DATA (state, { field, value }) {
		state['checkbox-filter'][field] = value
	},
	UPDATE_FILTER_BY_FIELDS_DATA (state, value = null) {
		state.byFields.push(value)
	},
	PUSH_SELLER_OPTION (state, value = null) {
		state.sellers.push(value)
	},
	RESET_FILTER_STATE (state, flag = false) {
		const s = filterState()
		Object.keys(s).forEach(key => {
			if (!(key === 'selectedSeller' && flag)) {
				Vue.set(state, key, s[key])
			}
		})
	},
	SET_ADDITIONAL_FILTER_OBJECT (state, payload = {}) {
		Vue.set(state, 'additionalFilterQuery', { ...state.additionalFilterQuery, ...payload })
	}
}
