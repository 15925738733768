import orderApi from '@/api/order/orderV2'
import companyApi from '@/api/company'

export default {
	async fetchCompanies ({commit, getters}) {
		let data = []

		try {
			commit('SET_ORDER_COMPANIES_LOADING', true)

			const response = await orderApi.fetchCompanies(getters.getOrdersPayload, getters.getUserContactId)

			commit('SET_ADDITIONAL_DATA', {
				last_page: response.last_page
			})

			data = response.data
		} catch (e) {
			window.console.error(e)
		} finally {
			commit('SET_ORDER_COMPANIES_LOADING', false)
		}

		return data
	},
	async fetchOrderCompanies ({ commit, dispatch}) {
		commit('SET_ORDER_PAGE', 1)

		const data = await dispatch('fetchCompanies')

		commit('SET_ORDER_COMPANIES', data)
	},
	async changePage ({commit, dispatch}, payload) {
		commit('SET_ORDER_PAGE', payload.page)

		let data = await dispatch('fetchCompanies')

		if (!Array.isArray(data)) {
			data = Object.values(data)
		}

		commit('APPEND_ORDER_COMPANIES', data)
	},
	fetchCompanyName (context, id) {
		return companyApi.getCompanyDataByID(id, { 'request-type': 'order' })
	},
	async fetchCompanyOrderDeals ({commit, dispatch}, id) {
		commit(
			'CLIENT_ORDER_INDEX/UPDATE_FILTER',
			{
				field: 'client',
				value: id
			},
			{
				root: true
			}
		)

		await dispatch('CLIENT_ORDER_INDEX/GET_CLIENT_ORDERS', {}, {root: true})
	},
	addOrder ({ commit }, orderModel) {
		commit('CLIENT_ORDER_INDEX/ADD_CLIENT_ORDER', orderModel, {root: true})
	}
}
