import Vue from 'vue'

const defaultState = () => ({
  liveSearch: null,
  responsible: null,
  driverLastName: null,
  company: null,
  licensePlate: null,
  techCertificate: null,
  byFields: [],
  location: null,
  directionAddress: null,
  isVehicleInBlackList: null,

  capacityTo: null,
  capacityFrom: null,
  volumeFrom: null,
  volumeTo: null,
  vehicleType: null,

  ownerDocument: null
})

const VEHICLE_FILTER = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getCapacityOrVolumeFilter: () => (from, to) => {
      let query = ''

      if (from) {
        query = `>=|${from}`
      }
      if (to) {
        query += `${from && to ? '&' : ''}<=|${to}`
      }

      return query || null
    },
    getVehicleFilterPayload: (state, getters, rootState, rootGetters) => {
      const payloadForFilter = {
        'global-search': state.liveSearch || null,
        ...getters.getManagersFilter || null,
        'search[driver_id]': state.driverLastName || null,
        'search[company_id]': state.company || null,
        'search[license_plate]': state.licensePlate || null,
        'search[registration_certificate_number]': state.techCertificate ||
          null,
        locations_address: state.location || null,
        direction_addresses: state.directionAddress || null,
        'search[is_blocked]': state.isVehicleInBlackList,
        'filter[max_capacity]': getters.getCapacityOrVolumeFilter(state.capacityFrom, state.capacityTo),
        'filter[volume]': getters.getCapacityOrVolumeFilter(state.volumeFrom, state.volumeTo),
        'search[vehicle_type_id]': state.vehicleType || null
      }

      if (state.ownerDocument) {
        if (state.ownerDocument !== -1) {
          payloadForFilter['filter[type_communication][id]'] = state.ownerDocument
        } else if (state.ownerDocument === -1) {
          payloadForFilter['doesnt[type_communication]'] = true
        }
      }

      if (state.byFields.length > 0) {
        const parsedFilterByFields = state.byFields.reduce(
          (memo, item) => {
            const key = item.field
            if (!memo[key]) {
              memo[key] = []
            }
            memo[key].push(item.value)
            return memo
          }, {})

        for (const field in parsedFilterByFields) {
          if (field === 'id') {
            const str = 'filter[' + field + ']'
            payloadForFilter[str] = parsedFilterByFields[field].join(';')
          } else {
            const str = 'search[' + field + ']'
            payloadForFilter[str] = parsedFilterByFields[field].join(';')
          }
        }
      }

      return payloadForFilter
    },
    getManagersFilter: (state) => {
      const args = {}
      if (state.responsible === -1) {
        args['doesnt[managers]'] = true
      } else {
        args['search[managers]'] = state.responsible
      }
      return args
    },
  },
  mutations: {
    UPDATE_VEHICLE_FILTER (state, payload) {
      Vue.set(state, payload.field, payload.value)
    },
    CLEAR_FILTER (state) {
      Object.assign(state, defaultState())
    },
  },
  actions: {
    setInitialFilterSettings ({
      commit,
      rootGetters
    }) {
      const canSeeEntireData = rootGetters.canGetter('access to complete index list')

      if (!canSeeEntireData && !rootGetters.canGetter('access to all transport')) {
        commit('UPDATE_VEHICLE_FILTER', {
          field: 'responsible',
          value: rootGetters.getUser.contact.full_name
        })
      } else if (!rootGetters.canGetter('crm access to the filter by responsible') && !canSeeEntireData) {
        commit('UPDATE_VEHICLE_FILTER', {
          field: 'responsible',
          value: rootGetters.getUser.contact.full_name
        })
      }
    }
  }
}

export default VEHICLE_FILTER
