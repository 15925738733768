export default function getVehicleCopyData (state, separatedVehicles, field) {
	const vehicle = separatedVehicles && separatedVehicles[field]?.length
		? separatedVehicles[field].find(i => i.license_plate === state[field]) : null

	let res = ''
	if (vehicle) {
		if (vehicle.car_brand?.name) {
			res += `${vehicle.car_brand.name} `
		}
		if (vehicle.car_model) {
			res += `${vehicle.car_model} `
		}
		if (vehicle.license_plate) {
			res += `${vehicle.license_plate} `
		}
		if (vehicle?.additional_info?.kind) {
			res += `${vehicle.additional_info.kind}`
		}
		res += res ? '\n' : ''
		if (vehicle.year) {
			res += `Рік випуску ${vehicle.year}\n`
		}
		if (vehicle.vin_code) {
			res += `VIN-код ${vehicle.vin_code}\n`
		}
		if (vehicle.own_weight) {
			res += `Маса без навантаження ${vehicle.own_weight} кг\n`
		}
		if (vehicle.total_weight) {
			res += `Повна маса ${vehicle.total_weight} кг\n`
		}
		res += res ? '\n' : ''
	}

	return res
}