export default function closedForAuth ({
	next,
	store,
	nextMiddleware
}) {
	return store.getters.getToken
		? next({
			name: 'Home'
		})
		: nextMiddleware()
}
