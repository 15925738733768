import helperApi from '@/api/helper'
import dealApi from '@/api/deal'
import turnoverConfig from '@/config/document-turnover-config'
import jsonToFormData from '@/mixins/jsonToFormData'
import fileTypes from '@/config/file-types'

export default {
	async fetchReceivingMethods ({commit}, payload = {}) {
		const response = await helperApi.getDocumentReceivingMethods(payload)
		const {data} = response
		commit('SET_RECEIVING_OPTIONS', data.data)
	},
	async postDocumentReceivingMethod (context, payload = {}) {
		const response = await dealApi.storeDealDocumentTurnover(payload)

		return response.data
	},

	async setPostalServicesList ({commit}) {
		const response = await helperApi.getPostalServicesList()
		const {data} = response

		commit('SET_POSTAL_SERVICES', data.data)
	},
	setActiveTransaction ({commit, getters, state}, payload) {
		commit('SET_ACTIVE_TRANSACTION', payload);

		// find docs that has choose type
		const docs = state.documents.filter(i => i.type === state.choosedType);
		const docForSave = getters.getSelectedDocumentInstance;

		// if create new doc and some docs already exists
		if (!payload && docs.length) {
			// get data from last created doc and set to new doc
			const { invoice_number, invoice_creation_date, is_order } = docs[0];

			docForSave.invoice_number = invoice_number;
			docForSave.invoice_creation_date = invoice_creation_date;
			docForSave.is_order = is_order;
		}

		commit('SET_DOCUMENT', docForSave);
	},
	async setListDocs ({commit}) {
		const response = await helperApi.getListDocs()
		const {data} = response

		commit('SET_LIST_DOC', data.data)
	},

	async setFirstTransactionIfExist ({commit, getters, state}) {
		if (state.documents && Object.keys(state.documents.filter(d => d.type === state.choosedType)).length !== 0) {
			const latestTransactionId = state.documents.filter(d => d.type === state.choosedType).shift().id
			await commit('SET_ACTIVE_TRANSACTION', latestTransactionId)
			await commit('SET_DOCUMENT', getters.getSelectedDocumentInstance)
			await commit('UPDATE_DOCUMENT_STATE', {
				field: 'date',
				value: getters.getSelectedDocumentInstance.date
			})
		}
	},

	getTurnoverPayload ({ state }) {
		let form = {}
		const defaultData = {
			deal: {
				id: state.dealID,
				description: state.dealDescription
			},
			is_order: state.document.is_order,
			type: state.document.type
		}

		if (state.choosedType === turnoverConfig.CLIENT_FREIGHT) {
			form = {
				postal_service: state.document.postal_service,
				date: state.document.date,
				description: state.document.description,
				notes_for_doc: state.document.notes_for_doc,
				invoice_number: state.document.invoice_number,
				invoice_creation_date: state.document.invoice_creation_date,
				number_declaration: state.document.number_declaration,
				amount: state.document.client_cost_post
			}
		}

		if (state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT) {
			form = {
				date: state.document.date,
				list_docs: state.document.list_docs,
				number_declaration: state.document.number_declaration,
				transaction: {
					amount: state.document.transporter_cost_post
				}
			}
		}

		if (state.document.id) {
			defaultData.id = state.document.id
		} else {
			if (state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT) {
				const latestDocsByStatusTransporter = state.documents
					.filter(t => t.type === turnoverConfig.TRANSPORTER_FREIGHT)

				if (latestDocsByStatusTransporter.length && state.activeTransaction) {
					let { invoice_number, invoice_creation_date, tax_invoice_date } = latestDocsByStatusTransporter[0]
					Object.assign(defaultData, {
						invoice_number: invoice_number,
						invoice_creation_date: invoice_creation_date,
						tax_invoice_date: tax_invoice_date
					})
				}
			}
		}

		return Object.assign(defaultData, form)
	},

	async saveTurnover ({ state, dispatch }) {
		const getTurnoverPayload = await dispatch('getTurnoverPayload')

		let _payload = {
			...getTurnoverPayload,
			...{ date: state.document.date },
			...{ type: state.choosedType }
		}
		delete _payload.files

		/* CLIENT DATA */
		if (state.choosedType === turnoverConfig.CLIENT_FREIGHT) {
			_payload = {
				..._payload,
				...{
					client_get_docs_date: state.document.client_get_docs_date,
					ttn_accepted_date: state.document.ttn_accepted_date
				}
			}

			if (state.document && state.document.client_get_docs_contact) {
				_payload = {
					..._payload,
					...{
						client_get_docs_contact: {
							id: state.document.client_get_docs_contact.id
								? state.document.client_get_docs_contact.id
								: null
						}
					}
				}
			}
		}

		/* TRANSPORTER DATA */
		if (state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT) {
			_payload = {
				..._payload,
				...{
					number_declaration: state.document.number_declaration,
					receiving_method: state.document.receiving_method
				}
			}
		}

		return dealApi.dealDocumentTurnover(_payload)
	},
	saveDeal ({ state }) {
		const fileReduceCallback = (memo, file) => {
			if (file.fileObject) {
				memo.push({
					document_type_id: file.document_type.id,
					weight: 0,
					file: file.fileObject
				})
			}

			return memo
		}
		const dealPayload = {
			id: state.dealID,
			description: state.dealDescription,
			manager_comment_client_docs: state.managerCommentClientDocs,
			files: [
				...state.clientOrderFiles.reduce(fileReduceCallback, []),
				...state.clientBrokenActsFiles.reduce(fileReduceCallback, []),
				...state.clientScoreFiles.reduce(fileReduceCallback, []),
				...state.clientTtnFiles.reduce(fileReduceCallback, [])
			]
		}

		const dealFormData = jsonToFormData(dealPayload, {
			includeNullValues: true,
			fieldsForNullValue: Object.keys(dealPayload)
		})

		dealPayload.files.forEach((value, index) => {
			dealFormData.append(`files[${index}][document_type_id][]`,
				value.document_type_id)
			dealFormData.append(`files[${index}][weight][]`, value.weight)
			dealFormData.append(`files[${index}][files][]`, value.file)
		})

		return dealApi.createDeal(dealFormData, {
			'Content-Type': 'multipart/form-data'
		})
	},

	async saveDocumentTurnoverData ({ dispatch }) {
		let turnoverResponse = null
		let dealResponse = null

		try {
			turnoverResponse = await dispatch('saveTurnover')
		} catch (e) {
			throw new Error('Ошибка сохранения данных о документах! ' + e)
		}

		try {
			dealResponse = await dispatch('saveDeal')
		} catch (e) {
			throw new Error('Ошибка сохранения данных в сделку! ' + e)
		}

		return {
			turnoverResponse: turnoverResponse,
			dealResponse: dealResponse
		}
	},

	/**
   * Prepare turnover data and additional data to state fields
   * @param state
   * @param commit
   * @param dispatch
   * @param rootGetters
   */
	prepareTurnoverData ({state, commit, dispatch, rootGetters}) {
		dispatch('setListDocs')
		dispatch('setPostalServicesList')

		const files = rootGetters['registry/getDeal'].files.reduce((memo, f) => {
			if (f.document_type) {
				if (f.document_type.id === fileTypes.CLIENT_ORDER) {
					memo.order_files.push(f)
				} else if (f.document_type.id === fileTypes.BROKEN_ACT) {
					memo.broken_acts.push(f)
				} else if (f.document_type.id === fileTypes.SCORE) {
					memo.score.push(f)
				} else if (f.document_type.id === fileTypes.TTN) {
					memo.ttn.push(f)
				}
			}

			return memo
		}, {
			order_files: [],
			broken_acts: [],
			score: [],
			ttn: []
		})

		commit('SET_FILES', files)
		commit('SET_DOCUMENT_DEAL_ID', rootGetters['registry/getDeal'].id)
		commit('UPDATE_GLOBAL_STATE', {
			field: 'dealDescription',
			value: rootGetters['registry/getDeal'].description
		})
		commit('UPDATE_GLOBAL_STATE', {
			field: 'managerCommentClientDocs',
			value: rootGetters['registry/getDeal'].manager_comment_client_docs
		})
		commit('SET_TRANSPORTER_DATA', rootGetters['registry/getDeal'])

		if (rootGetters['registry/getDeal'].document_turnover) {
			const loadDealData = rootGetters['registry/getDeal']
				.document_turnover
				.filter(d => d.type === state.choosedType)

			commit('SET_DOCUMENT_TURNOVER', Object.assign({
				documents: rootGetters['registry/getDeal'].document_turnover
			}, { document: loadDealData[0] }))

			dispatch('setFirstTransactionIfExist')
		}
	},
	/**
   * Set data for deal document turnover modal
   * @param commit
   * @param dispatch
   * @param type
   * @param debt
   */
	prepareDocumentsDataForEditing ({ commit, dispatch }, { type, debt }) {
		commit('SET_DOCUMENT_TYPE', type)
		commit('SET_CHOOSED_TYPE', type)
		commit('SET_TRANSACTION_LIST')
		commit('SET_TRANSPOTER_DEBT', debt)

		dispatch('setFirstTransactionIfExist')
		dispatch('prepareTurnoverData')
	}
}
