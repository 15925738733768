import stuffApi from '@/api/stuff'
import userApi from '@/api/user'
import lardiOrderApi from '@/api/order/lardi'
import orderApi from '@/api/order'
import driverApi from '@/api/driver'
import insetApi from '@/api/inset'
import cargoApi from '@/api/cargo'
import dealApi from '@/api/deal'
import helperApi from '@/api/helper'
import companyApi from '@/api/company'
import contactApi from '@/api/contact'

export default {
  async fetchPaymentTerms ({ commit }) {
    const response = await helperApi.getPaymentTerms()
    const { data } = response

    commit('SET_PAYMENT_TERMS', data.data)
  },
  async fetchPaymentCondition ({ commit }) {
    const response = await helperApi.getPaymentCondition()
    const { data } = response

    commit('SET_PAYMENT_CONDITION', data.data)
  },
  async fetchTransactionTypes ({ commit }) {
    const response = await helperApi.getTransactionTypes()
    const { data } = response

    commit('SET_TRANSACTION_TYPES', data.data)
  },
  async fetchRoles ({ commit }) {
    const response = await helperApi.getRoles()
    const { data } = response

    commit('SET_ROLES_OPTIONS', data.data)
  },
  async fetchPaymentForms ({ commit }) {
    const response = await helperApi.getPaymentForms()
    const { data } = response

    commit('SET_PAYMENT_FORMS', data.data)
  },
  async fetchCurrencies ({ commit }) {
    const response = await stuffApi.getCurrencies({
      'page-limit': 9999
    })
    const { data } = response

    commit('SET_CURRENCIES', data.data)
  },

  async fetchPostalServices ({ commit }) {
    const response = await helperApi.getPostalServicesList()
    const { data } = response

    commit('SET_POSTAL_SERVICES_OPTIONS', data.data)
  },
  async fetchContacts ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await contactApi.getContacts(payload)
    const { data } = response

    commit('SET_CONTACTS', data.data)
  },

  async fetchDriversAsContacts ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await driverApi.getDrivers(payload)
    let { data } = response

    commit('SET_CONTACTS', data.data.map(i => ({
      ...i,
      id: i.contact_id,
      driver_id: i.id
    })))
  },

  async fetchUsers ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await userApi.getUsersV1(payload)
    const { data } = response

    commit('SET_USERS', data.data)
  },
  async fetchUsers2 ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await userApi.getUsers(payload)
    const { data } = response

    commit('SET_USERS', data.data)
  },
  async fetchInsets ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await insetApi.getInsets(payload)
    const { data } = response

    commit('SET_INSETS', data.data)
  },
  async fetchCargoTypes ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await cargoApi.getCargoTypes(payload)
    const { data } = response

    commit('SET_CARGO_TYPES', data.data)
  },

  async fetchCompanies ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await companyApi.getCompanies(payload)
    const { data } = response

    commit('SET_COMPANIES', data.data)
  },

  async fetchCompanyTypes ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await helperApi.getCompanyTypes(payload)
    const { data } = response

    commit('SET_COMPANY_TYPES', data.data)
  },

  async fetchCompanyCargoTypes ({ commit }, payload = {
    'page-limit': -1
  }) {
    const response = await helperApi.getCompanyCargoTypes(payload)
    const { data } = response

    commit('SET_COMPANY_CARGO_TYPES', data.data)
  },

  async fetchContactTypes ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await helperApi.getContactTypes(payload)
    const { data } = response

    commit('SET_CONTACT_TYPES', data.data)
  },

  async fetchMCPriorities ({ commit }, payload = {
    'page-limit': 9999
  }) {
    const response = await helperApi.getMCPriorities(payload)
    const { data } = response

    commit('SET_MC_PRIORITIES', data.data)
  },
  async fetchOurCompanies ({ commit }, query = null) {
    const { data } = await helperApi.getOurCompanies(query)

    commit('SET_OUR_COMPANIES', data.data)
  },

  async fetchBanks ({ commit }, query = null) {
    const { data } = await helperApi.getBanks(query)

    commit('SET_BANKS', data.data)
  },
  async fetchMarineLines ({ commit }) {
    const response = await dealApi.getMarineLines()
    const { data } = response

    commit('SET_MARINE_LINES', data.data)
  },

  async fetchLinkTypes ({ commit }, query = null) {
    const { data } = await helperApi.getLinkTypes(query)

    commit('SET_LINK_TYPES', data.data)
  },

  async fetchLocationTypes ({ commit }, query = null) {
    const { data } = await helperApi.getLocationTypes(query)

    commit('SET_LOCATION_TYPES', data.data)
  },
  async fetchIndustry ({ commit }) {
    const { data } = await helperApi.getIndustry()

    commit('SET_INDUSTRY', data.data)
  },

  async fetchLocationCategories ({ commit }, query = null) {
    const { data } = await helperApi.getLocationCategories(query)

    commit('SET_LOCATION_CATEGORIES', data.data)
  },
  async fetchLardiData ({ commit }, query = null) {
    const { data } = await helperApi.getLardiData()

    commit('SET_LARDI_DATA', data.data)
  },
  async fetchCompaniesByOrderType ({ commit }, query = null) {
    const params = {
      'type_order': query
    }
    const { data } = await helperApi.getCompaniesByOrderData(params)

    commit('SET_COMPANIES_BY_ORDER_TYPE', data.data)
  },
  async fetchClientOrdersById ({ commit }, query) {
    const { data } = await orderApi.getClientOrders(query)
    commit('SET_ORDER_OPTIONS', data.data)
  },
  async fetchTransporterOrdersById ({ commit }, query) {
    const { data } = await orderApi.getTransporterOrders(query)
    commit('SET_ORDER_OPTIONS', data.data)
  },
  async fetchContactsByRoles ({ commit }, ids = []) {
    if (ids && ids.length < 1) return false
    const obj = {}
    ids.forEach((id, index) => {
      Object.assign(obj, { [`role[${index}][id]`]: id })
    })

    const { data } = await helperApi.getContactsByRoles(obj)

    commit('SET_CONTACTS_BY_ROLE', data.data)
  },

  async fetchContactsByOrderType ({ commit }, type = '') {
    if (!type) return []
    const obj = {
      'type_order': type
    }
    const { data } = await helperApi.getContactsByOrderType(obj)
    commit('SET_CONTACTS_BY_ORDER_TYPE', data.data)
  },

  async FETCH_ADDRESSES ({ commit, state }, query) {
    let payload = {
      'q': query
    }
    const { data } = await lardiOrderApi.searchLardiAddresses(payload)
    commit('SET_ADDRESSES', data)
  },
  async syncAddress({}, payload) {
    return await lardiOrderApi.syncLardiAddressWithOur(payload)
  },
  async fetchDealDepartments({commit}, payload = {}) {
    const response = await dealApi.getDealDepartments(payload)
    const {data} = response

    commit('SET_DEAL_DEPARTMENTS', data.data)
  },
}
