export default {
  SET_PAYMENT_TERMS (state, data) {
    state.paymentTerms = data
  },
  SET_TRANSACTION_TYPES (state, data) {
    state.transactionTypes = data
  },
  SET_ROLES_OPTIONS (state, data) {
    state.roles = data
  },

  SET_POSTAL_SERVICES_OPTIONS (state, data) {
    state.postalServiceOptions = data
  },
  SET_PAYMENT_FORMS (state, data) {
    state.paymentForms = data
  },
  SET_CURRENCIES (state, data) {
    state.currency = data
  },
  SET_CONTACTS (state, data) {
    state.contacts = data
  },
  SET_USERS (state, data) {
    state.users = data
  },
  SET_INSETS (state, data) {
    state.insets = data
  },
  SET_ORDER_OPTIONS (state, data) {
    state.ordersOptions = data
  },
  SET_CARGO_TYPES (state, data) {
    state.cargoTypes = data
  },
  SET_COMPANIES (state, data) {
    state.companies = data
  },
  SET_COMPANY_TYPES (state, data) {
    state.companyTypes = data
  },
  SET_CONTACT_TYPES (state, data) {
    state.contactTypes = data
  },
  SET_MC_PRIORITIES (state, data) {
    state.mcPriorities = data
  },
  SET_OUR_COMPANIES (state, data) {
    state.ourCompanies = data
  },
  SET_BANKS (state, data) {
    state.banks = data
  },
  SET_MARINE_LINES (state, data) {
    state.marineLines = data
  },
  SET_LINK_TYPES (state, data) {
    state.link_types = data
  },
  SET_PAYMENT_CONDITION (state, data) {
    state.paymentCondition = data
  },
  SET_LOCATION_TYPES (state, data) {
    state.location_types = data
  },
  SET_INDUSTRY (state, data) {
    state.industry = data
  },
  SET_COMPANY_CARGO_TYPES (state, data) {
    state.companyCargoTypes = data
  },
  SET_LOCATION_CATEGORIES (state, data) {
    state.locationCategories = data
  },
  SET_LARDI_DATA (state, data) {
    state.lardiUsers = data
  },
  SET_CONTACTS_BY_ROLE (state, data) {
    state.contactsByRole = data
  },
  SET_CONTACTS_BY_ORDER_TYPE (state, data) {
    state.contactsByOrderType = data
  },
  SET_COMPANIES_BY_ORDER_TYPE (state, data) {
    state.companiesByOrderType = data
  },
  SET_CURRENT_USER_CONTACT (state, data) {
    state.userContact = data
  },
  SET_CURRENT_USER_PHONES (state, data) {
    state.userPhones = data
  },
  SET_ADDRESSES (state, payload) {
    state.addresses = payload
  },
  SET_DEAL_DEPARTMENTS(state, data) {
    state.dealDepartments = data
  },
  SET_FILE_UPLOADING_STATUS(state, data) {
    state.isFileUploading = data
  }
}
