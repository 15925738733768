export default {
	/**
   * Save to state all available modes (from back-end)
   * @param state
   * @param commit
   * @param modes
   * @param fields
   * @param list
   */
	setFieldsAndModes ({ state, commit }, { modes }) {
		const modes_ = modes || []
		const ids = modes_.map(mode => mode.id)

		commit('SET_OPERATING_MODES', modes_)

		if (!modes_.length) {
			return false
		}

		if (state.selectedModeID === -1) {
			return false
		}

		const modeIndex = ids.indexOf(state.selectedModeID)

		if (modeIndex === -1) {
			commit('SET_SELECTED_MODE_ID', modes[0].id)

			const modeCols = modes[0].columns.length ? modes[0].columns : null
			commit('SET_FIELDS', modeCols || state.listOfFields)
		} else {
			commit('SET_FIELDS', state.modes[modeIndex].columns)
		}
	},
	/**
   * Manage CFO operation mode (that show different fields for different types)
   * @param state
   * @param commit
   * @param dispatch
   * @param indexOfSelectedMode
   */
	setCfoOperatingMode ({ state, commit }, indexOfSelectedMode) {
		const { id, columns } = state.modes[indexOfSelectedMode]
		const excludedFields = ['client_date_order', 'transporter_date_order']

		commit('SET_SELECTED_MODE_ID', id)
		commit('SET_FIELDS', columns.length ? columns.filter(i => !excludedFields.includes(i)) : state.listOfFields)
	},
	/**
   * Save to state all selected fields for show
   * @param commit
   * @param fields
   */
	setAvailableFields ({ commit }, fields) {
		commit('SET_FIELDS', fields)
		commit('SET_SELECTED_MODE_ID', -1)
	},
	setCustomCfoFields ({ commit }, fields = []) {
		commit('SET_FIELDS', fields)
	},
	setCustomCfoFieldsWithoutStorage ({ commit }, fields = []) {
		commit('SET_FIELDS_WITHOUT_STORAGE', fields)
	},
	setAllFields ({commit}, {fieldsObj, localization}) {
		commit('SET_FULL_LIST', fieldsObj.fields_all)
		commit('SET_FIELDS_LOCALIZATION', localization)
	},
	setIsRating ({ commit }) {
		commit('SET_IS_RATING', true)
	}
}
