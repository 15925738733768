import cfoApi from '@/api/cfo'

function setDataFromResponse (commit, data, response) {
  commit('SET_DATA', data.data)
  commit('SET_AMOUNTS', data.data_custom.amounts)
  commit('SET_TABLE_FIELDS', data.data_custom.fields)
  commit('SET_TABLE_LOCALIZED_FIELDS', response.localization.tables)
  commit('SET_TABLE_LOCALIZED_FILTER', {
    'checkbox-filter': response.localization['checkbox-filter'],
    'radio-filter': response.localization['radio-filter'],
    'custom-filter': response.localization['custom-filter']
  })

  commit('SET_RADIO_FILTER', response.localization['radio-filter'])
  commit('SET_ADDITIONAL_DATA', {
    from: data.from,
    to: data.to,
    last_page: data.last_page,
    per_page: data.per_page,
    total: data.total,
    page: data.current_page
  })
  commit('UPDATE_BUSY_TABLE', false)
};

const defaultIds = () => ({
  cash: [],
  soft: [],
  cashless: [],
  fuel: [],
})

const FINANCE_MODULE = {
  namespaced: true,
  state: {
    amounts: [],
    data: [],
    tableFields: [],
    localizedFields: [],
    additionalData: {
      per_page: 5,
      page: 1,
      total: 0,
      last_page: 0
    },
    filterLocalization: {
      'checkbox-filter': {},
      'radio-filter': {},
      'custom-filter': {}
    },
    filter: {
      liveSearch: '',
      'checkbox-filter': {},
      'radio-filter': {
        'radio-filter': null
      },
      approvedDateFilter: null
    },

    isBusyTable: false,

    ids: defaultIds()
  },
  mutations: {
    SET_DATA (state, data) {
      state.data = data
    },
    SET_AMOUNTS (state, amounts) {
      state.amounts = amounts
    },
    SET_TABLE_FIELDS (state, fields) {
      state.tableFields = fields
    },
    SET_TABLE_LOCALIZED_FIELDS (state, fields) {
      state.localizedFields = fields
    },
    SET_TABLE_LOCALIZED_FILTER (state, data) {
      state.filterLocalization = data
    },
    SET_ADDITIONAL_DATA (state, data) {
      state.additionalData = data
    },
    SET_FILTER_DATA_BY_FIELD (state, data) {
      state.additionalData[data.field] = data.value
    },
    SET_RADIO_FILTER (state, data) {
      if (state.filter['radio-filter']['radio-filter'] === null) {
        state.filter['radio-filter']['radio-filter'] = data ? Object.keys(data)[0] : null
      }
    },

    SET_FINANCE_IDS(state, data) {
      state.ids[data.field] = data.value
    },
    RESET_FINANCE_IDS(state) {
      state.ids = defaultIds()
    },

    // filter mutations
    UPDATE_FILTER_LIVESEARCH (state, value) {
      state.filter.liveSearch = value
    },

    CLEAR_FILTER (state) {
      state.filter = {
        liveSearch: '',
        paidFrom: null,
        paidTo: null,
        'checkbox-filter': {},
        'radio-filter': {
          'radio-filter': null
        },
        approvedDateFilter: null
      }
    },
    UPDATE_BUSY_TABLE (state, value = false) {
      state.isBusyTable = value
    },
    UPDATE_FINANCE_FILTER_KEY (state, payload) {
      state.filter[payload.field] = payload.value
    },
  },
  actions: {
    async GET_CASH_DATA ({commit, state}, payload = {}) {
      commit('UPDATE_BUSY_TABLE', true)

      const payloadArgs = {
        'page-limit': state.additionalData.per_page || 5,
        'page': state.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,
        'radio-filter[paid]': state.filter['radio-filter']['radio-filter'] === 'paid' || null,
        'radio-filter[approved_manager]': state.filter['radio-filter']['radio-filter'] === 'approved_manager' || null,
        'radio-filter[suspended_payment]': state.filter['radio-filter']['radio-filter'] === 'suspended_payment' || null
      }

      if (payload.sortBy) {
        payloadArgs[`sorting[${payload.sortBy}]`] = payload.sortDesc
          ? 'desc'
          : 'asc'
      }

      const response = await cfoApi.getFinanceCashData(payloadArgs)
      const {data} = response

      setDataFromResponse(commit, data, response)
    },

    async GET_CASHLESS_DATA ({commit, state}, payload = {}) {
      commit('UPDATE_BUSY_TABLE', true)

      const payloadArgs = {
        'page-limit': state.additionalData.per_page || 5,
        'page': state.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,
        'radio-filter[awaiting]': state.filter['radio-filter']['radio-filter'] === 'awaiting' || null,
        'radio-filter[suspended_client_bank]': state.filter['radio-filter']['radio-filter'] === 'suspended_client_bank' || null
      }

      if (payload.sortBy) {
        payloadArgs[`sorting[${payload.sortBy}]`] = payload.sortDesc
          ? 'desc'
          : 'asc'
      }

      const response = await cfoApi.getFinanceCashlessData(payloadArgs)
      const {data} = response

      setDataFromResponse(commit, data, response)
    },

    async GET_SOFT_DATA ({commit, state}, payload = {}) {
      commit('UPDATE_BUSY_TABLE', true)

      const payloadArgs = {
        'page-limit': state.additionalData.per_page || 5,
        'page': state.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,
        'radio-filter[awaiting]': state.filter['radio-filter']['radio-filter'] === 'awaiting' || null,
        'radio-filter[suspended_client_bank]': state.filter['radio-filter']['radio-filter'] === 'suspended_client_bank' || null,
        'radio-filter[paid]': state.filter['radio-filter']['radio-filter'] === 'paid' || null,
        'date_client_bank': state.filter.approvedDateFilter || null
      }

      if (payload.sortBy) {
        payloadArgs[`sorting[${payload.sortBy}]`] = payload.sortDesc
          ? 'desc'
          : 'asc'
      }

      const response = await cfoApi.getFinanceSoftData(payloadArgs)
      const {data} = response

      setDataFromResponse(commit, data, response)
    },

    async GET_FUEL_DATA ({commit, state}, payload = {}) {
      commit('UPDATE_BUSY_TABLE', true)

      const payloadArgs = {
        'page-limit': state.additionalData.per_page || 5,
        'page': state.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,
        'radio-filter[awaiting]': state.filter['radio-filter']['radio-filter'] === 'awaiting' || null,
        'radio-filter[partially_paid]': state.filter['radio-filter']['radio-filter'] === 'partially_paid' || null,
        'radio-filter[fully_paid]': state.filter['radio-filter']['radio-filter'] === 'fully_paid' || null
      }

      if (payload.sortBy) {
        payloadArgs[`sorting[${payload.sortBy}]`] = payload.sortDesc
          ? 'desc'
          : 'asc'
      }

      const response = await cfoApi.getFinanceFuelData(payloadArgs)
      const {data} = response

      setDataFromResponse(commit, data, response)
    },
  }
}

export default FINANCE_MODULE
