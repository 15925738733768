import Vue from 'vue'

import editTypes from '@/config/edit-types'
import fileTypes from '@/config/file-types'
import dealApi from '@/api/deal'

const commonDataState = () => ({
	description: null,
	transportation_number: null,
	container_number: null,
	marine_line: null,
	inset: null,
	cargo_type: null,
	cargo_cost: null,
	manager_comment_client_docs: null,
	transportDepartmentInfo: {
		id: null,
		fuel_remaining: null,
		speedometer_start: null,
		speedometer_end: null
	},
	generalEditingInfo: {
		deal_department: null,
		own_weight: null,
		cargo_type: null,
		logistical: null,
		responsible_salesman: null,
		manager_comment_client_docs: null,
		transportation_number: null,
		description: null,
		inset: null
	},
	isLoading: false
})
const documentTurnoversData = () => ({
	id: null,
	invoice_number: null,
	invoice_creation_date: null,
	tax_invoice_date: null,
	payment_docs_accepted_date: null,
	ttn_accepted_date: null
})
const finMonitoringState = () => ({
	comment: null,
	client_rnn_blocked: null
})
const defaultState = () => ({
	// Редактируемая сделка
	deal: null,
	// Тип редактирования
	editingType: null,
	// Тип редактирования: "Общее редактирование"
	common: commonDataState(),
	// Тип редактирования: "Разнос счетов по перевозчику"
	accountNumbers: documentTurnoversData(),
	documentTurnovers: [],
	finMonitoring: finMonitoringState()
})

const currentDate = () => {
	const date = new Date()
	const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'})
	const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(date)

	return `${day}.${month}.${year}`
}

const DATA_EDITING_MODULE = {
	namespaced: true,
	state: defaultState(),
	getters: {
		getDeal: state => state.deal,
		getDealId: state => state.deal ? state.deal.id : null,
		editingTypeIsClientFiles: state => state.editingType === editTypes.CLIENT_FILES_TYPE,
		editingTypeIsTransporterFiles: state => state.editingType === editTypes.TRANSPORTER_FILES_TYPE,
		editingTypeIsBrokenProductFiles: state => state.editingType === editTypes.BROKEN_PRODUCT_FILES_TYPE,
		editingTypeIsTeoAccNumbers: state => state.editingType === editTypes.TEO_ACCOUNT_NUMBERS_EDIT_TYPE,
		editingTypeIsTTNCMR: state => state.editingType === editTypes.TTN_CMR_EDIT_TYPE,
		editinngTypeIsFreightManagerInterface: state => state.editingType === editTypes.FREIGHT_MANAGER_INTERFACE,

		// Document turnovers
		getTransporterDocumentTurnovers: (state) => state.documentTurnovers.filter(i => {
			if (state.editingType === editTypes.TEO_ACCOUNT_NUMBERS_EDIT_TYPE) {
				return i.type === 'teo'
			}

			return i.type === 'transporter'
		}),

		getTransporterTransactions: (state) => {
			if (!state.deal) {
				return []
			}

			return state.deal.transaction.reduce((memo, item) => {
				if (item.type && item.type.id === 2 && !item.parent) {
					memo.push({
						payment_form: item.payment_form,
						amount: item.amount
					})
				}

				return memo
			}, [])
		},
		getDriverFullName: (state) => {
			if (!state.deal) {return null}
			if (!state.deal.driver) {return null}

			return state.deal.driver.contact
				? state.deal.driver.contact.full_name
				: null
		},
		getPaymentCondition: (state) => (key) => {
			if (!state.deal) {
				return null
			}

			const model = state.deal[key]

			if (!model) {
				return null
			}

			const ourCompany = state.deal[`${key}_our_company`]
			const modelContracts = model.contracts

			if (!ourCompany || !modelContracts || modelContracts.length === 0) {
				return null
			}

			const contract = modelContracts.find(contract => contract.our_company && contract.our_company.id === ourCompany.id)
			if (!contract) {
				return null
			}

			return contract.payment_condition ? contract.payment_condition.name : null
		},
		getClientPaymentCondition: (state, getters) => getters.getPaymentCondition('client'),
		getTransporterPaymentCondition: (state, getters) => getters.getPaymentCondition('transporter'),
		getGeneratedInvoiceAndActsFiles: (state) => {
			if (state.deal) {
				let files = state.deal.files
				let invoiceAndAct
				if (files.length) {
					invoiceAndAct = files.filter(file => file.document_type.id === fileTypes.INVOICE || file.document_type.id === fileTypes.ACT)
				}

				return invoiceAndAct
			}

			return []
		},
		getGeneratedInvoiceID: (state) => {
			if (state.deal) {
				const files = state.deal.files
				let invoice = []

				if (files && files.length) {
					invoice = files.filter(file => file.document_type.id === fileTypes.INVOICE)
				}

				return invoice && invoice.length ? invoice[0].id : false
			}

			return false
		},
		isTransportDepartmentDeal (state) {
			return state.common.generalEditingInfo?.deal_department?.id === 2
		}
	},
	mutations: {
		SET_DEAL (state, value) {
			state.deal = value
		},
		/**
     *
     * @param state
     * @param payload - type of editing described in '@/config/edit-types' file
     * @constructor
     */
		SET_EDIT_TYPE (state, payload) {
			state.editingType = payload
		},
		UPDATE_COMMON_FIELD (state, { field, value }) {
			state.common[field] = value
		},
		UPDATE_ACCOUNT_NUMBERS_FIELD (state, { field, value }) {
			state.accountNumbers[field] = value
		},
		SET_DOCUMENT_TURNOVERS (state, value) {
			state.documentTurnovers = value
		},
		UPDATE_TRANSPORTATION_DEPARTMENT_INFO (state, payload) {
			state.common.transportDepartmentInfo[payload.field] = payload.value
		},
		CLEAR_STATE (state) {
			Object.assign(state, defaultState())
		},
		UPDATE_FIN_MONITORING_STATE (state, payload) {
			Vue.set(state.finMonitoring, payload.field, payload.value)
		},
		CLEAR_FIN_MONITORING_STATE (state) {
			Vue.set(state, 'finMonitoring', finMonitoringState())
		},

		UPDATE_ACCOUNT_NUMBERS_STATE (state, payload) {
			Vue.set(state, 'accountNumbers', {
				id: payload.id,
				invoice_number: payload.invoice_number,
				invoice_creation_date: payload.invoice_creation_date,
				tax_invoice_date: payload.tax_invoice_date,
				ttn_accepted_date: payload.ttn_accepted_date,
				payment_docs_accepted_date: payload.payment_docs_accepted_date
			})
		},
		UPDATE_FULL_FIN_MONITORING_STATE (state, {fin_monitoring = null, client_rnn_blocked = null}) {
			Vue.set(state, 'finMonitoring', {
				comment: fin_monitoring,
				client_rnn_blocked: client_rnn_blocked
			})
		},
		UPDATE_TRANSPORTATION_DEPARTMENT_INFO_STATE (state, payload) {
			Vue.set(state.common, 'transportDepartmentInfo', {
				id: payload.id,
				fuel_remaining: payload.fuel_remaining,
				speedometer_start: payload.speedometer_start,
				speedometer_end: payload.speedometer_end
			})
		},
		UPDATE_COMMON_STATE (state, payload) {
			Vue.set(state, 'common', {
				description: payload.description,
				transportation_number: payload.transportation_number,
				container_number: payload.container_number,
				marine_line: payload.marine_line ? payload.marine_line.id : null,
				inset: payload.inset ? payload.inset.id : null,
				cargo_type: payload.cargo_type ? payload.cargo_type.id : null,
				cargo_cost: payload.cargo_cost,
				manager_comment_client_docs: payload.manager_comment_client_docs,
				transportDepartmentInfo: state.common.transportDepartmentInfo
			})
		},
		SET_GENERAL_EDITING_INFO (state, data) {
			state.common.generalEditingInfo = data
		},
		SET_GENERAL_EDITING_INFO_FIELD (state, {field, value}) {
			state.common.generalEditingInfo[field] = value
		}
	},
	actions: {
		setValidTurnoverDocuments ({ state, commit, getters }) {
			const turnover = getters.getTransporterDocumentTurnovers.find(i => i.id === state.accountNumbers.id)

			commit('UPDATE_ACCOUNT_NUMBERS_STATE', turnover || documentTurnoversData())
		},
		/**
     * Save deal data to state
     * @param state
     * @param commit
     * @param getters
     * @param dealId
     * @returns {Promise<void>}
     */
		async setDeal ({ state, commit, getters }, dealId) {
			const response = await dealApi.getDealById(dealId)
			const { data } = response

			commit('SET_DEAL', data)

			if (state.editingType === editTypes.COMMON_DATA_EDIT_TYPE) {
				if (data.transport_info) {
					commit('UPDATE_TRANSPORTATION_DEPARTMENT_INFO_STATE', data.transport_info)
				}
			} else if (
				(state.editingType === editTypes.ACCOUNT_NUMBERS_EDIT_TYPE ||
        state.editingType === editTypes.TEO_ACCOUNT_NUMBERS_EDIT_TYPE) &&
        data.document_turnover
			) {
				const filterIterator = i => state.editingType ===
        editTypes.ACCOUNT_NUMBERS_EDIT_TYPE ? true : i.type === 'teo'

				commit('SET_DOCUMENT_TURNOVERS', data.document_turnover.filter(filterIterator))

				if (getters.getTransporterDocumentTurnovers.length) {
					commit('UPDATE_ACCOUNT_NUMBERS_STATE', getters.getTransporterDocumentTurnovers[0])
				}
			} else if (state.editingType === editTypes.FIN_MONITORING_EDIT_TYPE) {
				commit('UPDATE_FULL_FIN_MONITORING_STATE', data)
			}
		},

		async posDeal ({ state, commit, getters }, { id, closeModalCb }) {
			try {
				commit('UPDATE_COMMON_FIELD', {
					field: 'isLoading',
					value: true
				})
				let payload = {
					own_weight: state.common.generalEditingInfo.own_weight,
					cargo_type_id: state.common.generalEditingInfo.cargo_type?.id || null,
					responsible_salesman_id: state.common.generalEditingInfo.responsible_salesman?.id || null,
					logistical_id: state.common.generalEditingInfo.logistical?.id || null,
					description: state.common.generalEditingInfo.description,
					manager_comment_client_docs: state.common.generalEditingInfo.manager_comment_client_docs,
					transportation_number: state.common.generalEditingInfo.transportation_number,
					inset_id: state.common.generalEditingInfo.inset?.id || null
				}

				if (getters.isTransportDepartmentDeal) {
					payload = {
						...payload,
						fuel_remaining: state.common.transportDepartmentInfo.fuel_remaining,
						speedometer_start: state.common.transportDepartmentInfo.speedometer_start,
						speedometer_end: state.common.transportDepartmentInfo.speedometer_end
					}
				}

				await dealApi.putCommonEditingData(id, payload)
				this._vm.$crmNotify.success('Успешно отредактировано!')
				closeModalCb()

				await this.dispatch('cfo/refreshEntireCfo')
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка редактирования! ' + e)
			} finally {
				commit('UPDATE_COMMON_FIELD', {
					field: 'isLoading',
					value: false
				})
			}
		},
		/**
     * Send save request for deal monitoring
     * @param state
     * @param getters
     * @returns {*}
     */
		editFinMonitoringData ({state, getters}) {
			return dealApi.editDealById({
				id: getters.getDealId,
				fin_monitoring: state.finMonitoring.comment,
				client_rnn_blocked: state.finMonitoring.client_rnn_blocked
			})
		},
		/**
     * Save account numbers data for deal
     * @param state
     * @param getters
     * @param type
     * @returns {*}
     */
		editAccountNumbersData ({state, getters}, {type}) {
			const payload = {
				deal: {
					id: getters.getDealId
				},
				type: type,
				...state.accountNumbers
			}
			delete payload.documentTurnovers

			return dealApi.storeDealDocumentTurnover(payload)
		},
		/**
     * Edit common deal data
     * @param state
     * @param getters
     */
		editCommonData ({ state, getters }) {
			return dealApi.editDealById({
				...state.common,
				id: getters.getDealId,
				cargo_type: {
					id: state.common.cargo_type
				},
				inset: {
					id: state.common.inset
				},
				marine_line: {
					id: state.common.marine_line
				},
				isGeneralEditing: true
			})
		},
		/**
     * Edit deal transportation data
     * @param state
     * @param getters
     * @param commit
     */
		async editTransportationData ({state, getters, commit}) {
			const transportDepartmentState = state.common.transportDepartmentInfo

			const stateHasDepartmentID = getters.getDeal &&
        getters.getDeal.department &&
        getters.getDeal.department.id &&
        getters.getDeal.department.id === 2;

			const transportDepartmentStateNotEmpty = transportDepartmentState.id ||
        transportDepartmentState.fuel_remaining ||
        transportDepartmentState.speedometer_start ||
        transportDepartmentState.speedometer_end;

			if (stateHasDepartmentID && transportDepartmentStateNotEmpty) {
				const transportationResponse = await dealApi.storeTransportationData({
					...transportDepartmentState,
					deal: {
						id: getters.getDealId
					}
				})

				delete transportationResponse.data.deal

				commit('UPDATE_COMMON_FIELD', {
					field: 'transportDepartmentInfo',
					value: transportationResponse.data
				})
			}
		},
		async editDeal ({ state, dispatch }) {
			let response

			switch (state.editingType) {
			case editTypes.FIN_MONITORING_EDIT_TYPE:
				response = await dispatch('editFinMonitoringData')

				break
			case editTypes.COMMON_DATA_EDIT_TYPE:
				response = await dispatch('editCommonData')
				await dispatch('editTransportationData')

				break
			case editTypes.ACCOUNT_NUMBERS_EDIT_TYPE:
				response = await dispatch('editAccountNumbersData', {
					type: 'transporter'
				})

				break
			case editTypes.TEO_ACCOUNT_NUMBERS_EDIT_TYPE:
				response = await dispatch('editAccountNumbersData', {
					type: 'teo'
				})

				break
			}

			// eslint-disable-next-line no-undef
			const { data } = response

			await this.dispatch('cfo/refreshEntireCfo')

			return data
		},

		/**
     * Set edit type and fetch deal for editing
     * @param commit
     * @param dispatch
     * @param deal
     * @param type
     * @returns {*}
     */
		getDealForEditType ({ commit, dispatch }, { deal, type }) {
			commit('SET_EDIT_TYPE', type)

			return dispatch('setDeal', deal)
		},
		resetState ({commit}) {
			commit('CLEAR_STATE')
		},
		async getGeneralEditingData ({ commit }, payload) {
			try {
				const { data } = await dealApi.getGeneralEditingInfo(payload)
				console.log('getGeneralEditingData', data);
				commit('SET_GENERAL_EDITING_INFO', data)
			} catch (e) {
				console.error(e)
			}
		}
	}
}

export default DATA_EDITING_MODULE
