import Vue from 'vue'
import Vuex from 'vuex'
import lsKeys from '@/config/local-storage-keys'
import router from '@/router'

import DATABOT_MODULE from '@/store/modules/CRM/databot'
import LIVE_CONNECTIONS_MODULE from '@/store/modules/live-connections'
import LEADS_MODULE from '@/store/modules/leads'
import LOGIN_MODULE from '@/store/modules/login'
import userApi from '@/api/user'
import CFO_MODULE from './modules/cfo'
import MODULE_DEAL_DOCUMENT_TURNOVER from './modules/deal_document_turnover'
import CASHLESS_SEPARATION_MODULE from './modules/cashless_separation'
import REGISTRY_MODULE from './modules/registry'
import CASHIER_MODULE from './modules/cashier'
import HELPER_MODULE from './modules/helper.module'
import METRICS_MODULE from './modules/metrics'
import BONUSES_MODULE from './modules/bonus'
import CREDIT_CARDS_MODULE from './modules/credit_cards'

import CRM_CLIENT_ORDER from './modules/order/client_order.module'
import CRM_CLIENTS_ORDERS_V2 from './modules/order/clients_ordersV2'
import CRM_TRANSPORTER_ORDER from './modules/order/transporter_order.module'
import CLIENT_ORDER_INDEX from './modules/order/client_order.index.module'
import TRANSPORTER_ORDER_INDEX from './modules/order/transporter_order.index.module'
import CRM_LOCATION from './modules/location/location.module'
import PRICE_MATRIX_STORE from './modules/price-matrix.store'

import socket from './socket'

import state from './main/state'
import getters from './main/getters'
import mutations from './main/mutations'

import * as Sentry from '@sentry/browser'

Vue.use(Vuex)

const MC_ROLE_ID = 8

export default new Vuex.Store({
	modules: {
		login: LOGIN_MODULE,
		cfo: CFO_MODULE,
		deal_document_turnover: MODULE_DEAL_DOCUMENT_TURNOVER,
		registry: REGISTRY_MODULE,
		cashless_separation: CASHLESS_SEPARATION_MODULE,
		cashier: CASHIER_MODULE,
		HELPER_MODULE,
		bonus: BONUSES_MODULE,
		metrics: METRICS_MODULE,
		CREDIT_CARDS_MODULE,

		CLIENT_ORDER_INDEX,
		TRANSPORTER_ORDER_INDEX,

		CRM_CLIENT_ORDER,
		CRM_CLIENTS_ORDERS_V2,
		CRM_TRANSPORTER_ORDER,
		CRM_LOCATION,
		PRICE_MATRIX_STORE,

		socket,
		databot: DATABOT_MODULE,
		liveConnections: LIVE_CONNECTIONS_MODULE,
		leads: LEADS_MODULE
	},
	state: state(),
	getters: getters,
	mutations: mutations,
	actions: {
		async fetchUser ({ commit }) {
			try {
				const user = await userApi.fetchUser()

				if (!user) {
					return false
				}

				commit('SET_USER', user)

				return user
			} catch (e) {
				return false
			}
		},
		async logout ({ commit, getters, dispatch }) {
			try {
				commit('SET_LOGOUT_PROCESSING', true)

				await dispatch('cfo/resetLazyCfo')
				await userApi.logout()

				if (getters.getRoles.map(role => role.id).includes(MC_ROLE_ID)) {
					window.localStorage.removeItem('mc-table-fields')
				}

				commit('LOGOUT_USER')

				localStorage.removeItem(lsKeys.AGENT_STORAGE_KEY)
				await dispatch('socket/disconnectFromSocket')

				Sentry.configureScope(scope => scope.setUser(null))

				await router.push({ name: 'Login' })

				return true
			} catch (e) {
				window.console.error(e)
			} finally {
				commit('SET_LOGOUT_PROCESSING', false)
			}

			return false
		}
	}
})
