import jsonToFormData from '@/mixins/jsonToFormData'
import router from '@/router'
import Vue from 'vue'
import vehicleApi from '@/api/vehicle'
import companyApi from '@/api/company'
import contactApi from '@/api/contact'
import { getAvatarsFromFiles } from '@/business-logic/contact'
import stuffApi from '@/api/stuff'
import VEHICLE_FILTER from '@/store/modules/CRM/vehicle/filter'
import VEHICLE_INDEX from '@/store/modules/CRM/vehicle/index'
import VEHICLE_HELPER from '@/store/modules/CRM/vehicle/helper'
import {CLIENT_TYPE} from '@/config/company-type.config'
import MULTI_EDIT_STATE from '@/store/modules/CRM/vehicle/multi-edit/index'
import { StatusCodes } from 'http-status-codes'

const TRANSPORT_TYPE_ID = 1
const TRANSPORT_SUBTYPE_ID = 2
const TRANSPORT_CONTEINER_ID = 3

const extendVehicle = (data, state) => {
	const obj = data.vehicle_types.reduce((memo, type) => {
		if (type && type.category) {
			if (type.category.id === TRANSPORT_TYPE_ID) {
				memo.car_type = state.helper.vehicleTypes.find(t => t.id === type.id)
			}
			if (type.category.id === TRANSPORT_SUBTYPE_ID) {
				memo.car_subtype = state.helper.vehicleTypes.find(t => t.id === type.id)
			}
			if (type.category.id === TRANSPORT_CONTEINER_ID) {
				memo.car_container_type.push({
					id: type.id,
					name: type.name,
					description: type.description
				})
			}
		}

		return memo
	}, {
		car_type: null,
		car_subtype: null,
		car_container_type: []
	})

	// let url = { url: setVehicleUrl(data) }

	// return Object.assign(data, obj, url)
	return Object.assign({}, data, obj)
}

const extendVehicleWithResponsible = (data, state, rootGetters) => {
	const user = rootGetters && rootGetters.getUser && rootGetters.getUser.contact
		? rootGetters.getUser.contact
		: null

	const args = {
		...data,
		...extendVehicle(data, state)
	}

	if ((!data.managers || !data.managers.length) && user) {
		args.managers = [{ ...user }]
	}

	return args
}

const _mapIterator = (file) => {
	const obj = {
		document_type_id: file.document_type.id,
		weight: 0
	}

	if (file.tmp_path) {
		obj.tmp_path = file.tmp_path
		obj.name = file.original_name
	} else {
		obj.files = file.fileObject
	}

	return obj
}

const vehicle_types = (car_type, car_subtype, car_container_type) => {
	const list = []

	if (car_type) { list.push({ id: car_type.id }) }

	if (car_subtype) {
		list.push({ id: car_subtype.id })

		if (car_container_type) {
			car_container_type.forEach((type) => {
				list.push({ id: type.id })
			})
		}
	}

	return list
}

// todo REFACTOR ENTIRE SHIT STATE
const vehicleRequest = vehicle => ({
	id: vehicle.id,
	max_capacity: vehicle.max_capacity ? vehicle.max_capacity : '',
	volume: vehicle.volume ? vehicle.volume : '',
	number_of_axles: vehicle.number_of_axles || '',
	// year: vehicle.year,
	owner_type: vehicle.owner_type ? vehicle.owner_type : 'companies',
	license_plate: vehicle.license_plate ? vehicle.license_plate : '',
	vin_code: vehicle.vin_code ? vehicle.vin_code : '',
	description: vehicle.description ? vehicle.description : '',
	registration_certificate_number: vehicle.registration_certificate_number
		? vehicle.registration_certificate_number
		: '',
	// color: vehicle.color,
	car_model: vehicle.car_model ? vehicle.car_model : '',
	type_communication: vehicle.type_communication
		? { id: vehicle.type_communication.id }
		: '',
	owner: vehicle.owner ? { id: vehicle.owner.id || vehicle.owner.value } : null,
	vehicle_types: vehicle_types(vehicle.car_type, vehicle.car_subtype,
		vehicle.car_container_type),
	loading_types: vehicle.loading_types,
	drivers: vehicle.drivers.map(d => ({ id: d.id })),
	companies: vehicle.companies.map(c => ({ id: c.id })),
	contacts: vehicle.contacts.map(c => ({ id: c.id })),
	car_brand: vehicle.car_brand ? { id: vehicle.car_brand.id } : '',
	managers: vehicle.managers.map(m => ({ id: m.id })),
	is_blocked: vehicle.is_blocked,
	is_not_standard: vehicle.is_not_standard,
	vehicle_duplicates: [],
	height: vehicle.height,
	length: vehicle.length,
	own_weight: vehicle.own_weight,
	width: vehicle.width,
	year: vehicle.year,
	total_weight: vehicle.total_weight
})

const shortVehicleState = () => ({
	car_type: null,
	car_subtype: null,
	car_container_type: [],
	id: null,
	car_brand: null,
	companies: [],
	contacts: [],
	vehicle_types: [],
	managers: [],
	date_of_first_registration: null,
	date_of_last_registration: null,
	drivers: [],
	is_blocked: false,
	license_plate: null,
	max_capacity: null,
	vin_code: null,
	volume: null,
	loading_types: [],
	last_deal: null,
	height: null,
	length: null,
	own_weight: null,
	width: null,
	year: null,
	total_weight: null
})

const vehicleState = () => ({
	max_capacity: null,
	volume: null,
	number_of_axles: null,
	year: null,
	owner_id: null,
	owner_type: 'companies',
	license_plate: null,
	vin_code: null,
	description: null,
	car_model: null,
	registration_certificate_number: null,
	color: null,
	additional_info: {},
	date_of_first_registration: null,
	date_of_last_registration: null,
	drivers: [],
	vehicle_types: [],
	loading_types: [],
	files: [],
	type_communication: null,
	owner: {},
	companies: [],
	contacts: [],
	car_brand: null,
	managers: [],
	car_type: null,
	car_subtype: null,
	car_container_type: [],
	id: null,
	is_blocked: false,
	is_not_standard: false,
	height: null,
	length: null,
	own_weight: null,
	width: null,
	total_weight: null
})

const CRM_VEHICLE = {
	namespaced: true,
	modules: {
		filter: VEHICLE_FILTER,
		index: VEHICLE_INDEX,
		helper: VEHICLE_HELPER,
		multi_edit: MULTI_EDIT_STATE
	},
	state: {
		vehicle: vehicleState(),
		isCreate: false,
		isVisibleLicensePlate: false,
		ownerFind: [],
		processing: false,
		dbSearchProcessing: false,
		modelType: null,
		needOpenDatabotModal: false,
		savedData: {}
	},
	getters: {
		isFullVehicle: (state) => Object.keys(state.vehicle).length > Object.keys(shortVehicleState()).length,
		getIsCreateVehicle: state => state.isCreate,
		getVehicleState: state => state.vehicle,
		/* Photos */
		// getVehiclePhotos: state => state.vehicle.files || [],
		/* check type */
		checkIsTractor: state => state.vehicle && state.vehicle.car_type &&
      state.vehicle.car_type.id === 1,
		vehicleTypesOptions (state) {
			return state.helper.vehicleTypes.reduce((memo, type) => {
				if (type && type.category && type.category.id === 1) {
					switch (state.modelType) {
					case 'vehicle':
						if ([1, 3, 14].includes(type.kind.id)) {
							memo.push(type)
						}
						break
					case 'trailer':
						if ([2, 5].includes(type.kind.id)) {
							memo.push(type)
						}
						break
					default: {
						memo.push(type)

						break
					}
					}
				}

				return memo
			}, [])
		},
		getProcessing: state => state.processing,
		getVehiclePhoto: (state) => {
			const photos = getAvatarsFromFiles(state.vehicle.files || [], 'vehicle')

			if (photos.length > 0) {
				return photos[photos.length - 1]
			}

			return null
		},
		vehicleCompanyIsNotClient: (state) => state.vehicle.companies.reduce((memo, cur) => {
			if (cur && cur.company_types) {
				memo.push(...cur.company_types)
			}

			return memo
		}, []).every(id => id.id !== CLIENT_TYPE),
		getCurrentLogoUrl: (state, getters) => getters.getVehiclePhoto ? getters.getVehiclePhoto.link : null,
		selectedLoadingTypes (state) {
			return state.vehicle.loading_types.map(i => i.id)
		}
	},
	mutations: {
		UPDATE_VEHICLE_STATE (state, payload) {
			Vue.set(state.vehicle, payload.field, payload.value)
		},
		SET_MODEL_TYPE (state, payload = null) {
			state.modelType = payload
		},
		SET_EDITING_VEHICLE (state, payload) {
			Vue.set(state, 'vehicle', payload)
		},
		SET_VEHICLE_SUB_TYPE (state, payload) {
			if (payload && payload.id) {
				state.vehicle.car_subtype = state.helper.vehicleTypes.find(t => t.id === payload.id)
			} else {
				state.vehicle.car_subtype = payload
			}
		},
		SET_VEHICLE_CONTAINER_TYPE (state, payload) {
			state.vehicle.car_container_type = payload || []
		},
		SET_VEHICLE_OWNER_NAME (state, payload) {
			state.vehicle.owner = payload
		},
		SET_OWNERS_FINDER (state, payload) {
			state.ownerFind = payload
		},
		SET_CREATE_NEW_VEHICLE (state, payload) {
			state.isCreate = payload
		},
		RESET_VEHICLE_FORM (state) {
			const s = vehicleState()
			Object.keys(s).forEach((key) => {
				Vue.set(state.vehicle, key, s[key])
			})
		},
		UPDATE_PROCESSING_FLAG (state, value) {
			state.processing = value
		},
		PUSH_DRIVERS_TO_VEHICLE (state, payload) {
			state.vehicle.drivers.push(payload)
		},
		SET_GLOBAL_STATE (state, payload) {
			state[payload.field] = payload.value
		},
		SET_SEARCH_IN_DB_FLAG (state, payload) {
			state.dbSearchProcessing = payload
		},
		PUSH_TO_VEHICLE_KEY (state, payload) {
			state.vehicle[payload.field].push(payload.value)
		},
		PUSH_TO_FILES (state, file) {
			state.vehicle.files.push(file)
		},
		SPLICE_BY_KEY (state, payload) {
			state.vehicle[payload.field].splice(payload.index, 1)
		},
		SET_COMPANY_STATUS (state, payload) {
			state.vehicle.companies.find(company => company.id === payload).status = 'в стані припинення'
		},
		SET_OWNER_STATUS (state) {
			Vue.set(state.vehicle.owner, 'status', 'в стані припинення')
		}
	},
	actions: {
		async updateMainData ({
			state,
			commit
		}, payload) {
			try {
				if (state.vehicle.id) {
					return await vehicleApi.updateVehicleById(state.vehicle.id, payload)
				}
			} catch (e) {
				console.error(e)
				if (e.response.data.data.length) {
					commit('UPDATE_VEHICLE_STATE', {
						field: 'vehicle_duplicates',
						value: e.response.data.data
					})
				}
				this._vm.$crmNotify.warning('Ошибка сохранения данных по авто! ' + e)
			}
		},
		async checkVehicleExists ({
			commit,
			state,
			rootGetters,
			rootState
		}, payload) {
			try {
				commit('SET_SEARCH_IN_DB_FLAG', true)

				const { data } = await vehicleApi.checkVehicle(payload.req)

				if (data && Array.isArray(data) && !data.length) {
					commit('SET_GLOBAL_STATE', {
						field: 'isVisibleLicensePlate',
						value: true
					})

					return false
				}

				if (data && typeof data === 'object') {
					commit('SET_GLOBAL_STATE', {
						field: 'savedData',
						value: {
							...state.savedData,
							registration_certificate_number: state.vehicle.registration_certificate_number
						}
					})

					if (state.savedData.registration_certificate_number) {
						data.registration_certificate_number = state.savedData.registration_certificate_number
					}

					if (!data.managers || data.managers.length === 0) {
						data.managers = [
							{
								...rootState.user.contact
							}
						]
					}
					commit('SET_EDITING_VEHICLE', extendVehicle(data, state))
					commit('SET_CREATE_NEW_VEHICLE', true)

					return true
				}
			} catch (err) {
				if (err.response.data.code === StatusCodes.BAD_REQUEST) {
					if (err.response.data.data && err.response.data.data.length) {
						commit('UPDATE_VEHICLE_STATE', {
							field: 'vehicle_duplicates',
							value: err.response.data.data
						})

						return
					}

					const defaultData = vehicleState()
					defaultData.license_plate = state.vehicle.license_plate
					defaultData.registration_certificate_number = state.vehicle.registration_certificate_number

					if (state.savedData) {
						Object.keys(state.savedData).forEach((key) => {
							defaultData[key] = state.savedData[key]
						})

						commit('SET_GLOBAL_STATE', {
							field: 'savedData',
							value: {}
						})
					}

					if (!defaultData.managers || defaultData.managers.length === 0) {
						defaultData.managers = [
							{
								...rootState.user.contact
							}
						]
					}

					commit('SET_EDITING_VEHICLE',
						extendVehicleWithResponsible(defaultData, state, rootGetters))

					if (payload.save) {
						const res = await vehicleApi.updateVehicle(vehicleRequest(state.vehicle),{
							'Content-Type': 'multipart/form-data'
						})

						if (res.code === 200) {
							await Object.keys(res.data).forEach(key => {
								commit('UPDATE_VEHICLE_STATE', {
									field: key,
									value: res.data[key]
								})
							})

							if (!payload.modal) {
								router.push({
									name: 'CRM.VEHICLE.UPDATE',
									params: {id: res.data.id}
								})
							}
						}

					}
					commit('SET_CREATE_NEW_VEHICLE', true)
				}
			} finally {
				commit('SET_SEARCH_IN_DB_FLAG', false)
			}
		},
		async userFind ({
			commit,
			state
		}, searchValue = null) {
			let response
			switch (state.vehicle.owner_type) {
			case 'companies':
			case null:
				response = await companyApi.searchCompanies(searchValue)
				break
			case 'contacts':
				response = await contactApi.searchContacts(searchValue)
				break
			default:
				break
			}

			const { data } = response
			let type = null
			commit('SET_OWNERS_FINDER', data.map((vehicle) => {
				if (state.vehicle.owner_type === 'companies') {
					type = vehicle.is_blocked || (vehicle.status !== 'зареєстровано' && !vehicle.is_not_resident)
				} else if (state.vehicle.owner_type === 'contacts') {
					type = vehicle.is_blocked
				}

				return {
					id: vehicle.id,
					full_name: vehicle.name,
					avatar: vehicle.avatar,
					type: type ? 'not-selectable' : '',
					status: vehicle.status
				}
			}))

			return data
		},
		async setCurrentVehicleById ({
			commit,
			state,
			rootState
		}, id) {
			try {
				commit('UPDATE_PROCESSING_FLAG', true)

				const { data } = await vehicleApi.setVehicleData(id)

				if (!data.managers || data.managers.length === 0) {
					data.managers = [
						{
							...rootState.user.contact
						}
					]
				}
				const vehicle = extendVehicle(data, state)

				commit('SET_EDITING_VEHICLE', vehicle)

				if (data.owner_type && data.owner && data.owner_type === 'companies') {
					commit('SET_OWNERS_FINDER', [
						{
							id: data.owner.id,
							full_name: data.owner.name
						}
					])
				}
			} catch (e) {
				console.error(e)
				this._vm.$crmNotify(
					'Ошибка получения транспортного средства из БД!' + e)
			} finally {
				commit('UPDATE_PROCESSING_FLAG', false)
			}
		},
		async CREATE_OR_UPDATE_VEHICLE (
			{
				commit,
				state
			},
			{
				needRouter = true,
				cb
			}
		) {
			try {
				await commit('UPDATE_PROCESSING_FLAG', true)

				const headers = {
					'Content-Type': 'multipart/form-data'
				}
				// eslint-disable-next-line no-undef
				const vehicle = vehicleRequest(state.vehicle)
				const vehicleFormData = jsonToFormData(vehicle)

				const vehicleStateArr = state.vehicle && state.vehicle.files
					? state.vehicle.files
					: []
				const obj = vehicleStateArr.reduce((memo, val) => {
					if (val.fileObject || val.tmp_path) {
						memo.vehicleFiles.push(_mapIterator(val))
					}

					return memo
				}, { vehicleFiles: [] })

				const filesFormAppend = (val, index, keys) => {
					keys.forEach((k) => {
						if (!val[k]) {
							return
						}
						const path = `files[${index}][${k}][]`
						let value = val[k]

						if (val.tmp_path) {
							if (k === 'files') {
								value = ''
							}
							if (k === 'tmp_path') {
								vehicleFormData.append(`files[${index}][name]`, val.name)
								vehicleFormData.append(`files[${index}][tmp_path]`,
									val.tmp_path)

								return
							}
						}

						vehicleFormData.append(path, value)
					})
				}
				obj.vehicleFiles.forEach((value, index) => {
					if (value.tmp_path) {
						filesFormAppend(value, index,
							['document_type_id', 'weight', 'files', 'tmp_path'])
					}
				})

				const res = await vehicleApi.updateVehicle(vehicleFormData, headers)

				if (res.code === 200) {
					if (cb) {
						cb()
					}

					if (needRouter) {
						router.push({ name: 'CRM.VEHICLE.INDEX' })
					}

					this._vm.$notify({
						type: 'success',
						text: 'Успешно создана/обновлена!'
					})

					return {
						id: res.data.id,
						vehicle: extendVehicle(res.data, state)
					}
				}
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка сохранения транспортного средства в БД!'
				})

				if (e.response.data.data && e.response.data.data.length) {
					commit('UPDATE_VEHICLE_STATE', {
						field: 'vehicle_duplicates',
						value: e.response.data.data
					})
				}
			} finally {
				await commit('UPDATE_PROCESSING_FLAG', false)
			}
		},
		async setCompanyBlockedStatus ({state, commit}) {
			try {
				commit('UPDATE_PROCESSING_FLAG', true)

				await vehicleApi.updateVehicle({
					is_blocked: state.vehicle.is_blocked,
					id: state.vehicle.id
				})

				this._vm.$crmNotify.success(
					state.vehicle.is_blocked
						? 'Вы добавили транспорт в черный список'
						: 'Вы удалили транспорт с черного списка'
				)
			} catch (e) {
				this._vm.$crmNotify.warning('Whoopse! не получилось, пожалуйста обратитесь в IT отдел!' + e)
				console.error(e)
			} finally {
				commit('UPDATE_PROCESSING_FLAG', false)
			}
		},

		async updateVehicleLogo ({ commit }, file) {
			try {
				const response = await stuffApi.makeTempFile({ file })

				if (response && response.data) {
					commit('PUSH_TO_FILES', {
						document_type: { id: 18 },
						weight: 0,
						original_name: response.data.name || file.name || null,
						newlyAdded: true,
						name: response.data.name || file.name || null,
						tmp_path: response.data.tmp_path ? response.data.tmp_path : null,
						base64: response.data.base64 ? response.data.base64 : null
					})
				}
			} catch (e) {
				window.console.error(e)
				this._vm.$crmNotify.warning('Ошибка загрузки аватара!' + e)
			}
		},
		async addResponsible ({state}, contactId) {
			try {
				if (contactId) {
					await stuffApi.addResponsible({
						contact_id: contactId,
						type: 'vehicles',
						id: state.vehicle.id
					})
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка добавления ответственного!' + e)
			}
		},
		async deleteResponsible ({state}, index) {
			try {
				if (state.vehicle.id && index >= 0 && state.vehicle.managers[index]) {
					await stuffApi.deleteResponsible(
						state.vehicle.managers[index].id,
						'vehicles',
						state.vehicle.id
					)
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка удаления ответственного!' + e)
			}
		},
		resetVehicleState ({ commit }) {
			commit('SET_CREATE_NEW_VEHICLE', false)
			commit('RESET_VEHICLE_FORM')
			commit('SET_GLOBAL_STATE', {
				field: 'isVisibleLicensePlate',
				value: false
			})
		},
		resetOwnerInfo ({ commit }) {
			commit('SET_VEHICLE_OWNER_NAME', null)
			commit('SET_OWNERS_FINDER', [])
		},
		pushContactToManagers ({ commit }, payload) {
			commit('PUSH_TO_VEHICLE_KEY', {
				field: 'managers',
				value: payload
			})
		},
		selectLoadingType ({ commit, getters }, type) {
			const index = getters.getVehicleState.loading_types.findIndex(i => i.id === type.id)

			if (index === -1) {
				commit('PUSH_TO_VEHICLE_KEY', {
					field: 'loading_types',
					value: type
				})
			} else {
				commit('SPLICE_BY_KEY', {
					field: 'loading_types',
					index
				})
			}
		},
		resetVehicleDuplicates ({commit}) {
			commit('UPDATE_VEHICLE_STATE', {
				field: 'vehicle_duplicates',
				value: []
			})
		}
	}
}

export default CRM_VEHICLE
