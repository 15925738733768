var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"fox-checkbox-wrapper",class:{
    'fox-checkbox-wrapper__inline': _vm.inline,
    'fox-checkbox-wrapper__empty-label': !_vm.label && !_vm.$slots.label,
    'fox-checkbox-wrapper__as-switch': _vm.switcher,
    'fox-checkbox-wrapper__as-button': _vm.button,
    'fox-checkbox-wrapper__as-checkbox': !_vm.button && !_vm.switcher,
  }},[(!_vm.button)?[(_vm.label || _vm.$slots.label)?[(_vm.label && !_vm.$slots.label)?_c('span',{staticClass:"fox-checkbox-label"},[_vm._v(_vm._s(_vm.label))]):(_vm.$slots.label)?_vm._t("label"):_vm._e()]:_vm._e(),_c('input',{staticClass:"fox-checkbox",attrs:{"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.isSelected ? 'checked' : ''},on:{"change":_vm.onChange}}),(!_vm.switcher)?[_c('div',{staticClass:"control-indicator"})]:[_vm._m(0)]]:_c('fox-button',{attrs:{"disabled":_vm.disabled,"size":_vm.buttonSize,"variant":_vm.selectedBtnVariant},nativeOn:{"click":function($event){return _vm.onBtnBoxClick.apply(null, arguments)}}},[(_vm.label && !_vm.$slots.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):(_vm.$slots.label)?_vm._t("label"):_vm._e()],2)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switcher-label"},[_c('div',{staticClass:"switcher-inner"}),_c('div',{staticClass:"switcher-switch"})])
}]

export { render, staticRenderFns }