import VueImagePreview from './components/VueImagePreview'
import VuePdfPreview from './components/VuePdfPreview'
import Vue from 'vue'

const VueFilePreviewer = {
	install (Vue) {
		Vue.component(VueImagePreview.name, VueImagePreview)
		Vue.component(VuePdfPreview.name, VuePdfPreview)
	}
}

Vue.use(VueFilePreviewer)

export default VueFilePreviewer
