import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  getCargoTypes (payload = {}) {
    return axios.get(`${HOST_URL}/cargo-type`, {
      params: payload
    })
  },
  createCargoType (payload) {
    return axios.post(`${HOST_URL}/company-cargo-type`, payload)
  },
  editCargoType (payload = {}, headers = {}) {
    return axios.post(`${HOST_URL}/company-cargo-type`, payload, {
      headers
    })
  }
}
