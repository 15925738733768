import jsonToFormData from '@/mixins/jsonToFormData'
import companyApi from '@/api/company'
import locationApi from '@/api/location'
import orderApi from '@/api/order'
import helperApi from '@/api/helper'
import contactApi from '@/api/contact'

const VEHICLE_SUB_TYPE_ID = 2
const DEFAULT_CLIENT_STATE = () => ({
  dateLoading: null,
  numbersToday: false,
  clientId: null,
  clientType: 'companies',
  orderVehicleType: null,
  lineName: null,
  containerType: null,
  cargoType: null,
  maxCapacity: null,
  volume: null,
  manager: null,
  description: null,
  parent: null,
  details: '',
  auction: false,
  shared: [],
  lardi_shared: [],
  sharedCompanies: [],
  children: [],

  clientPaymentForm: null,
  clientFreight: null,
  transporterPaymentForm: null,
  transporterFreight: null,

  isCopyDeal: false,

  loadingTypes: [],
  locationUnloadings: [null],
  locationLoadings: [null],
  // managerId: null,
  dealId: null,
  status: null,
  id: null,

  capacityTo: null,
  capacityFrom: null,
  volumeTo: null,
  volumeFrom: null,

  defCargoCompany: null
})

const mappedLoc = (loc, field) => {
  return loc && loc[field].filter(l => l).length > 0 ? loc[field].map(l => ({id: l.id, name_: (l.address.address || l.address.short_address)})) : [null]
}

const CRM_CLIENT_ORDER = {
  namespaced: true,
  state: {
    order: DEFAULT_CLIENT_STATE(),

    companies: [],
    contacts: [],
    vehicleTypes: [],
    loadingTypesList: [],
    companyLocations: [],
  },
  getters: {
    getState: state => state,
    getClientOrder: state => state.order,
    getCompanies: state => state.companies ? state.companies.map(c => ({
      label: c.name || c.full_name,
      value: c.id,
      avatar: c.image,
      code: c.code
    })) : [],
    getContacts: state => state.contacts ? state.contacts.map(c => ({
      label: c.name || c.full_name,
      value: c.id
    })) : [],
    getLoadingTypeOptions: state => state.loadingTypesList ? state.loadingTypesList.map(c => ({
      name: c.name,
      id: c.id
    })) : [],
    getVehicleTypes: state => state.vehicleTypes ? state.vehicleTypes.map(c => ({
      label: c.name,
      value: c.id,
      capacity: c.max_capacity ? c.max_capacity : null,
      vehicleTypeId: c.vehicle_type ? c.vehicle_type.id : null
    })) : [],

    getCompanyLocations: state => state.companyLocations ? state.companyLocations.map(c => ({
      label: c.address ? (c.address.short_address || c.address.address) : c.label || '',
      value: c.id
    })) : [],
  },
  mutations: {
    UPDATE_CLIENT_STATE(state, payload) {
      if(Array.isArray(payload)) {
        let length = payload.length;
        while(length--){
          state.order[payload[length].field] = payload[length].value;
        }
      } else {
        state.order[payload.field] = payload.value
      }
    },
    SET_FINDED_COMPANIES(state, payload) {
      state.companies = payload
    },
    SET_FINDED_CONTACTS(state, payload) {
      state.contacts = payload
    },
    SET_VEHICLE_TYPES(state, payload) {
      state.vehicleTypes = payload
    },
    SET_LOADING_TYPES(state, payload) {
      state.loadingTypesList = payload
    },
    SET_EDITING_ORDER(state, payload) {
      state.order = payload
    },
    SET_COMPANY_LOCATIONS(state, payload) {
      state.companyLocations = payload
    },
    RESET_ORDER_DATA(state, payload) {
      state.order = DEFAULT_CLIENT_STATE()
      state.companies = []
    },
    RESET_LOCATIONS(state) {
      state.order.locationUnloadings = [null]
      state.order.locationLoadings = [null]
    },
    PUSH_EMPTY_LOC(state, payload) {
      state.order[payload].push(null)
    }
  },
  actions: {
    async getCompany({commit, state, dispatch}, payload) {
      if (state.order.clientType === 'companies') {
        const {data} = await companyApi.getCompanyDataByID(payload, { 'request-type': 'order' })
        const defCargo = data.cargo_types.filter(i => i.cargo_type).find(c => c.is_default)
        commit('UPDATE_CLIENT_STATE', {
          field: 'defCargoCompany',
          value: defCargo
        })

        commit('SET_COMPANY_LOCATIONS', data.locations)

        dispatch('setDefCargoFields')
      } else {
        commit('UPDATE_CLIENT_STATE', {
          field: 'cargoType',
          value: null
        })
      }
    },

    setDefCargoFields({ commit, state }) {
      if (state.order.defCargoCompany && !state.order.id && !state.order.isCopyDeal) {
        commit('UPDATE_CLIENT_STATE', {
          field: 'cargoType',
          value: state.order.defCargoCompany.cargo_type.id
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'orderVehicleType',
          value: state.order.defCargoCompany.vehicle_type ? state.order.defCargoCompany.vehicle_type.id : null
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'loadingTypes',
          value: state.order.defCargoCompany.loading_types ? state.order.defCargoCompany.loading_types : []
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'capacityTo',
          value: state.order.defCargoCompany.max_capacity ? state.order.defCargoCompany.max_capacity : null
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'capacityFrom',
          value: state.order.defCargoCompany.min_capacity ? state.order.defCargoCompany.min_capacity : null
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'volumeTo',
          value: state.order.defCargoCompany.max_volume ? state.order.defCargoCompany.max_volume : null
        })
        commit('UPDATE_CLIENT_STATE', {
          field: 'volumeFrom',
          value: state.order.defCargoCompany.min_volume ? state.order.defCargoCompany.min_volume : null
        })
      }
    },

    async fetchLocations({commit, state}, id) {
      const {data} = await locationApi.getCompaniesLocation({
        'filter[companies][id]': id
      })

      commit('SET_COMPANY_LOCATIONS', data.data)
    },

    resetLocationsState({commit, state}) {
      commit('RESET_LOCATIONS')
    },
    /** Поиск помпаний */
    async onCompanySearch({commit, state}, query = {}) {
      if (query) {
        let res
        try {
          if (state.order.clientType === 'companies') {
            res = await companyApi.getCompaniesData({
              'search[name]': query
            })
            const {data} = res
            commit('SET_FINDED_COMPANIES', data.data)
          } else  {
            res = await contactApi.getContacts({
              'search[full_name]': query
            })
            const {data} = res
            commit('SET_FINDED_CONTACTS', data.data)
          }

        } catch (e) {
          console.error(e)
          this.$notify({
            type: 'error',
            text: 'Ошибка получения списка компаний!'
          })
        }
      }
    },

    /** Фетчем типы машин */
    async fetchVehicleTypes({commit}, state) {
      try {
        const { data } = await helperApi.vehicleTypes()
        if (data && data.data) {
          const vehicleTypesOptions = data.data.reduce((memo, item) => {
            if (item.category && item.category.id === 2) {
              memo.push({
                id: item.id,
                name: item.name
              })
            }
            return memo
          }, [])
          await commit('SET_VEHICLE_TYPES', vehicleTypesOptions)
        }
      } catch (e) {
        console.error(e)
        this._vm.$notify({
          type: 'error',
          text: 'Ошибка получения типов машины!'
        })
      }
    },

    async fetchLoadingTypes ({commit}) {
      const {data} = await helperApi.loadingTypes()
      await commit('SET_LOADING_TYPES', data.data)
    },

    async submitForm({commit, state}, payload = false) {
      const orderState = state.order
      const mapFun = (data) => {
        return data.filter(lt => lt).length ? data.filter(lt => lt).map(lt => ({id: lt.id})) : null
      }

      let req = {
        id: state.order.id || null,
        date_loading: orderState.dateLoading,
        numbers_today: orderState.numbersToday,
        client_id: orderState.clientId,
        client_type: orderState.clientType,
        vehicle_type: {id: orderState.orderVehicleType},
        cargo_type: {id: orderState.cargoType},
        max_capacity: orderState.capacityTo,
        min_capacity: orderState.capacityFrom,
        max_volume: orderState.volumeTo,
        min_volume: orderState.volumeFrom,
        volume: orderState.volume || 0,
        description: orderState.description,
        auction: orderState.auction,
        loading_types: orderState.loadingTypes.length > 0 ? orderState.loadingTypes.map(lt => ({id: lt.id})) : [],
        // manager: {id: orderState.managerId},

        location_loadings: mapFun(orderState.locationLoadings),
        location_unloadings: mapFun(orderState.locationUnloadings),

        client_freight: orderState.clientFreight ? orderState.clientFreight : '',
        transporter_freight: orderState.transporterFreight ? orderState.transporterFreight : '',
        client_payment_form: orderState.clientPaymentForm ? {id: orderState.clientPaymentForm} : '',
        transporter_payment_form: orderState.transporterPaymentForm ? {id: orderState.transporterPaymentForm} : '',
        parent: orderState.parent,
      }
      const parsedRequest = {};
      for(let key in req){
        if(req.hasOwnProperty(key) && req[key] !== null ) {
          parsedRequest[key] = req[key];
        }
      }
      req = parsedRequest;
      const request = jsonToFormData(req, {
        includeNullValues: true,
        fieldsForNullValue: ['location_loadings', 'location_unloadings'],
      })

      if (!req.location_loadings) request.append('location_loadings[0]', '')
      if (!req.location_unloadings) request.append('location_unloadings[0]', '')

      try {
        const data = await orderApi.createClientOrder(request)

        if (payload) {
          if (orderState.shared && orderState.shared.length) {
            let obj = {
              contacts: orderState.shared.map(s => ({id: s.id})),
              id: data.data.id,
              type: 'order_clients'
            }
            await orderApi.shareWithManagers(obj)
          }
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },

    async fetchOrder({ commit, dispatch }, payload) {
      const id = payload.id
      const needly = payload.needly

      try {
        const {data} = await orderApi.getOrderByID(id, 'client')
        await commit('UPDATE_CLIENT_STATE', {
          field: 'id',
          value: data.id
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'dateLoading',
          value: data.date_loading
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'numbersToday',
          value: data.numbers_today
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'clientType',
          value: data.client_type
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'clientId',
          value: data.client_id
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'description',
          value: data.description
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'orderVehicleType',
          value: data.order_vehicle_type ? data.order_vehicle_type.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'lineName',
          value: data.line_name
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'children',
          value: data.children
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'containerType',
          value: data.containerType ? data.containerType.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'cargoType',
          value: data.cargo_type ? data.cargo_type.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'maxCapacity',
          value: data.max_capacity
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'volume',
          value: data.volume
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'auction',
          value: data.auction
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'parent',
          value: data.parent
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'clientPaymentForm',
          value: data.client_payment_form ? data.client_payment_form.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'clientFreight',
          value: data.client_freight
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'transporterPaymentForm',
          value: data.transporter_payment_form ? data.transporter_payment_form.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'transporterFreight',
          value: data.transporter_freight
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'status',
          value: data.status ? data.status.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'loadingTypes',
          value: data.loading_types
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'sharedCompanies',
          value: data.shared_companies
        })

        await commit('UPDATE_CLIENT_STATE', {
          field: 'locationUnloadings',
          value: mappedLoc(data, 'location_unloadings')
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'locationLoadings',
          value: mappedLoc(data, 'location_loadings')
        })

        await commit('UPDATE_CLIENT_STATE', {
          field: 'dealId',
          value: data.deal? data.deal.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'manager',
          value: data.manager ? data.manager.id : null
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'shared',
          value: data.shared ? data.shared : []
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'lardi_shared',
          value: data.lardi_shared ? data.lardi_shared : []
        })
        await commit('UPDATE_CLIENT_STATE', {
          field: 'details',
          value: data.details ? data.details : ''
        })

        if (data.client_id && needly) {
          if (data.client_type === 'contacts') {
            commit('SET_FINDED_CONTACTS', [data.client])
          }
          if (data.client_type === 'companies') {
            commit('SET_FINDED_COMPANIES', [data.client])
          }
        }
      } catch (e) {
        console.log(e)
      }
      // eslint-disable-next-line no-undef

    },

    async updateCompaniesLocation({commit}, id = null) {
      const {data} = await locationApi.getCompaniesLocation({
        'filter[companies][id]': id,
        'page-limit': 999,
      })

      commit('SET_COMPANY_LOCATIONS', data.data)
    },

    async setConnectWithTransporter({}, ids) {
      return orderApi.createDealWithTransporter(ids)
    }
  }
}

export default CRM_CLIENT_ORDER
