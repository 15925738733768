const intersection = (array1 = [], array2 = []) => {
  return array1.filter(x => array2.includes(x))
}
const orderBy = (array = [], key = null) => {
  const sortBy = (key) => {
    return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)
  }

  return array.concat().sort(sortBy(key))
}

const uniq = (array = []) => {
  return [...new Set(array)]
}

const uuniqBy = (arr, predicate) => {
  if (!arr) return []
  if (!predicate) return uniq(arr)
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

  return [
    ...arr.reduce((map, item) => {
      const key = (item === null || item === undefined) ?
        item : cb(item)

      map.has(key) || map.set(key, item)

      return map
    }, new Map()).values()]
}

const get = (obj, path, defaultValue) => {
  if (!path) return undefined
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj)
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

const find = (array = [], key = null, value = null) => {
  if (!array) return null
  return array.find(item => item.hasOwnProperty(key) && item[key] === value)
}

const customDebouncer = (func, wait, immediate) => {
  let timeout
  return function () {
    let context = this, args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

const flattenDeep = arr => {
  return Array.isArray(arr)
    ? arr.reduce((a, b) => [...flattenDeep(a), ...flattenDeep(b)], [])
    : [arr]
}

export {
  intersection,
  orderBy,
  uniq,
  uuniqBy,
  get,
  find,
  customDebouncer,
  isEmpty,
  groupBy,
  flattenDeep
}
