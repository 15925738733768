import jsonToFormData from '@/mixins/jsonToFormData'
import {uuniqBy} from '@/mixins/light_lodash'
import locationApi from '@/api/location'
import orderApi from '@/api/order'
import driverApi from '@/api/driver'
import helperApi from '@/api/helper'
import companyApi from '@/api/company'
import contactApi from '@/api/contact'

const DEFAULT_TRANSPORTER_STATE = () => ({
  dateAutoFree: null,
  vehicleId: null,
  trailerId: null,
  transporterFreight: null,
  transporterPaymentFormId: null,
  // managerId: null,
  driverId: null,
  manager: null,
  parent: null,
  addressfromsIds: null,
  addressTosIds: null,
  description: null,
  transporterId: null,
  transporterType: 'companies',
  details: '',
  id: null,
  status: null,
  shared: [],
  lardi_shared: [],
  shared_companies: []
})

const defaultState = () => ({
  order: DEFAULT_TRANSPORTER_STATE(),

  driversOptions: [],
  driverVehicles: [],
  driverTrailers: [],
  companies: [],
  contacts: [],
  DRIVERS_: [],
  PHONES_: [],
  lprContact: []
})

const CRM_TRANSPORTER_ORDER = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getTransporterOrder: state => state.order,

    getDriversOptions: state => state.driversOptions ? state.driversOptions.map(d => ({ label: d.full_name, value: d.id || d.driver_id, contact_id: d.contact_id })) : [],

    getDriverTrailers: state => state.driverTrailers && state.driverTrailers.length > 0 ? state.driverTrailers.map(t => ({ value: t.id, label: t.license_plate })) : [],
    getDriverVehicles: state => state.driverVehicles && state.driverVehicles.length > 0 ? state.driverVehicles.map(t => ({ value: t.id, label: t.license_plate })) : [],

    getCompanies: state => state.companies ? state.companies.map(c => ({
      label: c.name || c.full_name,
      value: c.id,
      code: c.code,
      avatar: c.image
    })) : [],

    getContacts: state => state.contacts ? state.contacts.map(c => ({
      label: c.name || c.full_name,
      value: c.id,
      isDriver: c.type ? c.type.indexOf('Водитель') !== -1 : (c.contact_types ? c.contact_types.indexOf('Водитель') !== -1 : false),
      is_lpr: c.is_lpr,
      phones: c.phones
    })) : [],

    getDriversHelperList: state => state.DRIVERS_

  },
  mutations: {
    UPDATE_TRANSPORTER_STATE (state, payload) {
      state.order[payload.field] = payload.value
    },

    SET_DRIVERS_OPTIONS (state, payload) {
      state.driversOptions = payload
    },

    SET_FINDED_COMPANIES (state, payload) {
      state.companies = payload
    },
    SET_FINDED_CONTACTS (state, payload) {
      state.contacts = payload
    },

    SET_DRIVER_VEHICLES (state, payload) {
      state.driverTrailers = payload.separated_vehicles.trailer.filter(t => t)
      state.driverVehicles = payload.separated_vehicles.vehicle.filter(v => v)

      /** Когда есть водитель, проверяем транспорт в наличии (одной единицы) */
      if (payload.separated_vehicles.vehicle.filter(v => v).length === 1) {
        state.order.vehicleId = payload.separated_vehicles.vehicle.filter(v => v).pop().id
      }
      if (payload.separated_vehicles.trailer.filter(t => t).length === 1) {
        state.order.trailerId = payload.separated_vehicles.trailer.filter(t => t).pop().id
      }
    },
    RESET_VEHICLES (state, payload = false) {
      state.driverTrailers = []
      state.driverVehicles = []
      state.PHONES_ = []
      state.order.vehicleId = null
      state.order.trailerId = null
      if (payload) {
        state.order.driverId = null
      }
    },
    RESET_CHOOSES_TRANSPORTS (state) {
      state.order.vehicleId = null
      state.order.driverId = null
      state.order.trailerId = null
    },

    RESET_STATE (state) {
      const s = defaultState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },

    SET_DRIVER_PHONES (state, payload) {
      state.PHONES_ = payload
    },
    SET_DRIVER_HELPERS (state, payload) {
      state.DRIVERS_ = payload
    },
    SET_LPR_DRIVERS (state, payload) {
      state.lprContact = payload
    }
  },
  actions: {
    async fetchEditingData ({ commit, state, getters }, payload) {
      const id = payload.id
      try {
        const { data } = await orderApi.getOrderByID(id, 'transporter')

        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'id',
          value: data.id
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'transporterType',
          value: data.transporter_type ? data.transporter_type : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'transporterId',
          value: data.transporter ? data.transporter.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'addressTosIds',
          value: data.address_tos ? data.address_tos : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'addressfromsIds',
          value: data.address_froms ? data.address_froms : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'driverId',
          value: data.driver ? data.driver.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'transporterPaymentFormId',
          value: data.transporter_payment_form ? data.transporter_payment_form.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'transporterFreight',
          value: data.transporter_freight ? data.transporter_freight : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'trailerId',
          value: data.trailer ? data.trailer.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'vehicleId',
          value: data.vehicle ? data.vehicle.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'description',
          value: data.description
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'dateAutoFree',
          value: data.date_auto_free ? data.date_auto_free : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'status',
          value: data.status ? data.status.id : null
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'children',
          value: data.children
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'manager',
          value: data.manager ? data.manager.id : null
        })
        // await commit('UPDATE_TRANSPORTER_STATE', {
        //   field: 'managerId',
        //   value: data.manager ? data.manager.id : null
        // })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'shared',
          value: data.shared ? data.shared : []
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'parent',
          value: data.parent
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'lardi_shared',
          value: data.lardi_shared ? data.lardi_shared : []
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'shared_companies',
          value: data.shared_companies ? data.shared_companies : []
        })
        await commit('UPDATE_TRANSPORTER_STATE', {
          field: 'details',
          value: data.details ? data.details : ''
        })

        await commit('SET_DRIVER_HELPERS', [Object.assign(data.driver.contact, {driver_id: data.driver.id})])
        await commit('SET_DRIVER_VEHICLES', {separated_vehicles: {vehicle: [data.vehicle], trailer: [data.trailer]}})

        await commit('SET_DRIVERS_OPTIONS', [
          {
            id: data.driver.id,
            full_name: data.driver.contact.full_name,
            contact_id: data.driver && data.driver.contact ? data.driver.contact.id : null
          }
        ])
        if (data.transporter_type === 'contacts') {
          await commit('SET_FINDED_CONTACTS', [data.transporter ] || [])
        } else {
          await commit('SET_FINDED_COMPANIES', [data.transporter] || [])
        }
      } catch (e) {
        console.log(e, 'error')
      }
    },

    async setIfDriverAlone ({ commit, state, dispatch }) {
      /** Проверяем единственный ли найденный водитель в компании */

      if (state.driversOptions.length === 1) {
        /** Если да то сетим первый элемент масива */
        commit('UPDATE_TRANSPORTER_STATE', { field: 'driverId', value: state.driversOptions[0].id })

        await dispatch('fetchDriverVehicles')
      } else if (state.order.id) {
        commit('RESET_CHOOSES_TRANSPORTS', true)
      } else {
        commit('RESET_VEHICLES', true)
      }
    },

    async onCompanySearch ({ commit, state }, query = {}) {
      if (query) {
        let res
        try {
          if (state.order.transporterType === 'companies') {
            res = await companyApi.getCompaniesData({
              'search[name]': query
            })
            const { data } = res
            commit('SET_FINDED_COMPANIES', data.data)
          } else {
            res = await contactApi.getContacts({
              'search[full_name]': query
            })
            const { data } = res
            commit('SET_FINDED_CONTACTS', data.data)
          }
        } catch (e) {
          console.error(e)
          this.$crmNotify.error('Ошибка получения списка компаний!')
        }
      }
    },

    async searchDrivers ({ commit, state, getters }, payload = {}) {
      let res
      let drivers
      switch (state.order.transporterType) {
        case 'companies':
          res = await driverApi.getDrivers({
            object: true,
            'page-limit': 999,
            'filter[forwarders][id]': state.order.transporterId
          })
          break
        case 'contacts':
          if (payload.isLpr) {
            let dispatchers = []
            res = await helperApi.getDriverByID(payload.id)
            let {data} = await driverApi.getDrivers({
              object: true,
              'page-limit': 999,
              'filter[dispatchers][id]': state.order.transporterId
            })


            if (data.data && data.data.length) {
              dispatchers = data.data.reduce((contacts, val) => {
                if (val) {
                  contacts.push(val)
                }
                return contacts
              }, [])

            }
            res = [...dispatchers, res.data]
          } else {

            res = await driverApi.getDrivers({
              object: true,
              'page-limit': 999,
              'filter[dispatchers][id]': payload.id
            })
            res = res.data
          }

          break
      }
      let mappedInfo = []
      if (state.order.transporterType === 'companies') {
        if (payload.isLpr) {
          const c = res
          mappedInfo = [{ id: c.id, license_plate: c.driver_license_number, full_name: c.contact.full_name || '', phones: c.contact.phones }]
        } else {
          mappedInfo = res.data.total !== 0 ? res.data.data.map(c => ({ id: c.id, license_plate: c.driver_license_number, full_name: c.contact.full_name || '', phones: c.contact.phones })) : []
        }

        const mappedFromGetter = getters.getDriversHelperList && getters.getDriversHelperList.length
          ? getters.getDriversHelperList.map(c => ({ id: c.driver_id || (c.driver ? c.driver.id : c.id), full_name: c.full_name }))
          : []

        drivers = [
          ...mappedFromGetter,
          ...mappedInfo
        ]
      } else {
        if (payload.isLpr) {
          mappedInfo = res.reduce((memo, c) => {
            if (c) {
              memo.push({ id: c.id, license_plate: c.driver_license_number, full_name: c.contact.full_name || '', phones: c.contact.phones })
            }
            return memo
          },[])
        } else {
          mappedInfo = res.data.length ? res.data.map(c => ({ id: c.id, license_plate: c.driver_license_number, full_name: c.contact.full_name || '', phones: c.contact.phones })) : []
        }
        const mappedFromGetter = state.DRIVERS_ && state.DRIVERS_.length ? state.DRIVERS_.map(c => ({ id: c.id, license_plate: c.license_number, full_name: c.full_name, phones: c.phones })) : []
        const lprContact = state.lprContact && state.lprContact.length ? state.lprContact.map(c => ({ id: c.id, full_name: c.contact.full_name })) : []

        drivers = [
          ...mappedFromGetter,
          ...mappedInfo,
          ...lprContact
        ]
      }

      commit('SET_DRIVERS_OPTIONS', uuniqBy(drivers, 'id'))
    },
    async fetchCompanyInfo ({ commit, state, dispatch }) {
      let cType = null
      let drivers = []
      switch (state.order.transporterType) {
        case 'companies':
          const response = await companyApi.getCompanyDataByID(state.order.transporterId)

          if (response.data.is_lpr) {
            drivers = response.data.contact_types.filter(i => i.id === 8).length > 0 ? response.data.driver : []
          } else {
            drivers = response.data.contacts ? response.data.contacts.filter(i => i.contact_types.filter(i => i.id === 8).length > 0)
              .filter(i => i.driver) : []
          }

          commit('SET_DRIVER_HELPERS', drivers)

          return response.data
        case 'contacts':
          const res = await contactApi.getContactByID(state.order.transporterId)
          const data = res.data
          commit('SET_LPR_DRIVERS', data.lpr_drivers)
          if (data.is_lpr && data.driver) {
            drivers.push({
              full_name: data.full_name,
              id: data.driver.id,
              license_number: data.driver.driver_license_number,
              phones: data.phones
            })
          }


          commit('SET_DRIVER_HELPERS', drivers)
          return data
      }
    },
    async fetchDriverVehicles ({ commit, state }, payload = true) {
      if (payload) {
        commit('RESET_VEHICLES')
      }

      // if (state.order.transporterType === 'contacts') {
      // eslint-disable-next-line no-undef
      try {
        const { data } = await driverApi.getDriverID(state.order.driverId)

        commit('SET_DRIVER_PHONES', data.contact ? data.contact.phones : [])
        commit('SET_DRIVER_VEHICLES', data)
      } catch (e) {
        console.log(e)
      }
      // }
    },
    async submitForm ({ commit, state }, payload) {
      const orderState = state.order
      const req = {
        id: orderState.id,
        date_auto_free: orderState.dateAutoFree,
        vehicle: { id: orderState.vehicleId },
        trailer: { id: orderState.trailerId },
        transporter_freight: orderState.transporterFreight ? orderState.transporterFreight : '',
        transporter_payment_form: orderState.transporterPaymentFormId ? { id: orderState.transporterPaymentFormId } : '',
        driver: { id: orderState.driverId },
        transporter_id: orderState.transporterId,
        description: orderState.description,
        transporter_type: orderState.transporterType,

        address_froms: orderState.addressfromsIds && orderState.addressfromsIds.length ? orderState.addressfromsIds.map(l => ({
          id: l.id
        })) : [],
        address_tos: orderState.addressTosIds && orderState.addressTosIds.length ? orderState.addressTosIds.map(l => ({
          id: l.id
        })) : [],
        // manager: {id: orderState.managerId},
        parent: orderState.parent
      }

      try {
        const data = await orderApi.createTransporterOrder(jsonToFormData(req))

        if (payload) {
          if (orderState.shared.length) {
            const obj = {
              contacts: orderState.shared.map(s => ({ id: s.id })),
              id: data.data.id,
              type: 'order_transporters'
            }
            await orderApi.shareWithManagers(obj)
          }
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async updateCompaniesLocation ({ commit }, id = null) {
      const { data } = await locationApi.getCompaniesLocation({
        'filter[companies][id]': id
      })

      commit('SET_COMPANY_LOCATIONS', data.data)
    },
    async setConnectWithClient ({}, ids) {
      return orderApi.createDealWithClient(ids)
    }
  }
}

export default CRM_TRANSPORTER_ORDER
