import Vue from 'vue'
import {
	BAlert,
	BBadge,
	BSpinner,
	ButtonGroupPlugin,
	CardPlugin,
	CollapsePlugin,
	DropdownPlugin,
	FormFilePlugin,
	FormGroupPlugin,
	FormInputPlugin,
	FormSelectPlugin,
	FormTextareaPlugin,
	InputGroupPlugin,
	ListGroupPlugin,
	ModalPlugin,
	PopoverPlugin,
	TablePlugin,
	TabsPlugin,
	TooltipPlugin,
	SidebarPlugin,
	OverlayPlugin
} from 'bootstrap-vue'

Vue.component('b-alert', BAlert)
Vue.component('b-badge', BBadge)
Vue.component('b-spinner', BSpinner)

Vue.use(ButtonGroupPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(PopoverPlugin, {
	BPopover: {
		delay: 0
	}
})
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(TooltipPlugin, {
	BTooltip: {
		delay: {
			show: 500,
			hide: 0
		}
	}
})
Vue.use(SidebarPlugin)
Vue.use(OverlayPlugin)