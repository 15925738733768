import leadsApi from '@/api/lead'

const leads = {
  namespaced: true,
  state: {
    leads: [],
    processing: false,
    lead: null,
    activeTab: 'active',
    processingLeadStatus: false
  },
  getters: {},
  mutations: {
    SET_PROCESSING_FLAG (state, payload = false) {
      state.processing = payload
    },
    SET_PROCESSING_LEAD_FLAG (state, payload = false) {
      state.processingLeadStatus = payload
    },
    SET_LEADS (state, payload) {
      state.leads = payload
    },
    SET_LEAD_DATA (state, payload) {
      state.lead = payload
    },
    SET_ACTIVE_TAB (state, payload) {
      state.activeTab = payload
    },
  },
  actions: {
    async updateStatus ({ state, commit }) {
      try {
        commit('SET_PROCESSING_LEAD_FLAG', true)

        await leadsApi.updateStatusById(state.lead.id)

        this._vm.$crmNotify.success('Статус успешно обновлен!')
      } catch (e) {
        this._vm.$crmNotify.warning('Ошибка обработки лида!', e)
        console.error(e)
      } finally {
        commit('SET_PROCESSING_LEAD_FLAG', false)
      }
    },
    async getLead ({ commit }, id) {
      try {
        const response = await leadsApi.getLeadById(id)

        if (response && response.data) {
          commit('SET_LEAD_DATA', response.data)
        }
      } catch (e) {
        this._vm.$crmNotify.warning('Ошибка получения лида!', e)
        console.error(e)
      }
    },
    async dispatchFetchDeals({commit}, payload = {}) {
      try {
        commit('SET_PROCESSING_FLAG', true)

        const response = await leadsApi.fetchLeads(payload)

        if (response && response.data) {
          commit('SET_LEADS', response.data)
        }
      } catch (e) {
        this._vm.$crmNotify.warning('Ошибка получения лидов!', e)
        console.error(e)
      } finally {
        commit('SET_PROCESSING_FLAG')
      }
    },
    async fetchLeads ({ dispatch, commit }) {
      commit('SET_ACTIVE_TAB', 'active')
      return dispatch('dispatchFetchDeals')
    },
    async fetchArchivedLeads ({ dispatch, commit }) {
      commit('SET_ACTIVE_TAB', 'archived')
      return dispatch('dispatchFetchDeals', {
        status_id: 2
      })
    },
  },
}

export default leads
