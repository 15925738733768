import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  lardiOrderToTrash(payload = {}) {
    return axios.delete(`${HOST_URL}/order/shared/lardi`, {data: payload})
  },
  lardiOrderReplay(payload = {}) {
    return axios.post(`${HOST_URL}/order/shared/lardi/replay`, payload)
  },
  lardiOrderRestore(payload = {}) {
    return axios.post(`${HOST_URL}/order/shared/lardi/restore`, payload)
  },
  searchLardiAddresses(payload = {}) {
    return axios.get(`${HOST_URL}/lardi-address/search`, { params: payload })
  },
  syncLardiAddressWithOur(payload = {}) {
    return axios.post(`${HOST_URL}/lardi-address`, payload)
  },
}
