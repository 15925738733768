import defaultState from './state'

export default {
	UPDATE_FILTER_DATA (state, {field, value}) {
		state[field] = value
	},
	RESET_FILTER (state) {
		const _state = defaultState()

		Object.keys(_state).forEach(key => {
			state[key] = _state[key]
		})
	}
}
