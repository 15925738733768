const DEFAULT_DOCUMENT_STATE = () => ({
	date: null,
	delay_date: null,
	number_declaration: null,
	postal_service: {},
	description: null,
	invoice_number: null,
	invoice_creation_date: null,
	tax_invoice_date: null,
	status_id: null,
	is_order: false,
	notes_for_doc: null,
	list_docs: [],
	type: null,
	client_cost_post: null,
	transporter_cost_post: null,
	transactionList: [],
	clientDate: null,
	client_get_docs_date: null,
	transporterDate: null,
	transporter_documents_list: null,
	client_get_docs_contact: null,
	transporter_documents_list_date: null,
	payment_docs_accepted_date: null,
	ttn_accepted_date: null,
	receiving_method: null
})

export { DEFAULT_DOCUMENT_STATE }
export default () => ({
	data: [],
	postal_services: [],
	document: DEFAULT_DOCUMENT_STATE(),
	list_doc: [],
	clientFiles: [],
	radioButton: null,
	checkedButtons: [],
	dealDescription: null,
	managerCommentClientDocs: null,
	documents: [],
	choosedType: null,
	activeTransaction: null,
	dealID: null,
	isBusyTable: false,
	receivingOptions: [],
	selectedReceivedMethod: null,
	transporter_documents_list: null,
	transporter_documents_list_date: null,
	transporter_debt: null,
	clientOrderFiles: [],
	clientBrokenActsFiles: [],
	clientScoreFiles: [],
	clientTtnFiles: [],
	ttn_clients_accepted_date: null,
	payments_accepted_date: null
})
