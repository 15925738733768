import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
    sendEmail(payload) {
        return axios.post(`${HOST_URL}/send/email`, payload)
    },
    sendSms(payload) {
        return axios.post(`${HOST_URL}/send/sms`, payload)
    },
}
