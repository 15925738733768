import cfoApi from '@/api/cfo'

const cashless_separation = {
  namespaced: true,
  state: {
    data: [],
    selectedDeals: [],
    dealsForSelects: [],
    isBusyTable: false
  },
  getters: {
    getData: state => state.data,
    isBusyTable: state => state.isBusyTable
  },
  mutations: {
    SET_CASHLESS_DATA (state, data) {
      state.data = data
    },

    UPDATE_BUSY_TABLE (state, value = false) {
      state.isBusyTable = value
    }
  },
  actions: {
    async GET_CASHLESS_DATA ({commit}, payload = {}) {
      commit('UPDATE_BUSY_TABLE', true)

      let headers = {
        'Content-Type': 'multipart/form-data'
      }
      const response = await cfoApi.postCashlessSeparationData(payload, headers)
      const {data} = response

      commit('SET_CASHLESS_DATA', data)
      commit('UPDATE_BUSY_TABLE', false)
    }
  }
}

export default cashless_separation
