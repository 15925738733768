import {intersection} from '@/mixins/light_lodash'
import {mapGetters} from 'vuex'

export const permission = {
  computed: {
    ...mapGetters(['getPermissions', 'getRoles', 'getUser'])
  },
  methods: {
    // check if user has role
    hasRole(roles = []) {
      if (!roles) {
        return false
      }

      const userRoles = this.getRoles.map(i => i.name)
      const intersect = intersection(userRoles, roles)

      return intersect.length > 0
    },
    // check if user can do something
    can(permission = '') {
      const userPermissions = this.getPermissions
      return userPermissions.indexOf(permission) !== -1
    },

    isOrderOwner(orderOwnerId = null) {
      if (!orderOwnerId) return false
      return orderOwnerId === this.getUser.contact.id
    }
  }
};
