import getVehicleCopyData from '@/helpers/get-vehicle-copy-data';

const COPY_STATE = {
	namespaced: true,
	state: {
		vehicle: null,
		trailer: null,
		phone: null
	},
	getters: {
		getOptionsForCopy: (state, getters, rootState) => (type) => {
			const contact = rootState.CRM_CONTACT.contact
			if (
				contact.driver &&
        contact.driver.separated_vehicles &&
        contact.driver.separated_vehicles[type]
			) {
				const opts = contact.driver.separated_vehicles[type].reduce((memo, i) => {
					if (i) {
						memo.push({
							value: i.id,
							label: `${i && i.car_brand ? i.car_brand.name : ''} ${i ? i.license_plate : ''}`,
							license_plate: i ? i.license_plate : ''
						})
					}

					return memo
				}, [])

				if (opts && opts.length > 0) {
					state[type] = opts[0].license_plate
				}

				return opts
			}

			return []
		},
		driverPhonesForCopy: (state, getters, rootState) => {
			const contact = rootState.CRM_CONTACT.contact
			if (contact && contact.phones) {
				const opts = contact.phones.map(i => ({
					value: i.id,
					label: i.phone,
					isDefault: i.is_default
				}))
        
				return opts
			}

			return []
		},
		textForCopy: (state, getters, rootState) => {
			const contact = rootState.CRM_CONTACT.contact
			const separatedVehicles = contact.driver.separated_vehicles
			let res = ''
			res += `вод.${contact.last_name} ${contact.first_name} `
			if (contact && contact.middle_name) {
				res += `${contact.middle_name} `
			}
			if (state.phone) {
				res += `тел. ${state.phone} `
			}
			if (contact.driver) {
				res += `Посвiдчення ${contact.driver.driver_license_number}\n`
			}

			res += getVehicleCopyData(state, separatedVehicles, 'vehicle')
			res += getVehicleCopyData(state, separatedVehicles, 'trailer')

			return res
		}
	},
	mutations: {
		UPDATE_COPY_STATE (state, payload) {
			state[payload.field] = payload.value
		},
		RESET_COPY_STATE (state) {
			state.vehicle = null
			state.trailer = null
			state.phone = null
		}
	},
	actions: {
		preUpdateDefaultPhones ({commit, getters}) {
			if (getters.driverPhonesForCopy && getters.driverPhonesForCopy.length > 0) {
				const defaultPhone = getters.driverPhonesForCopy.find(i => i.isDefault)
      
				if (defaultPhone && defaultPhone.isDefault) {
					commit('UPDATE_COPY_STATE', {
						field: 'phone',
						value: defaultPhone.label
					})
				} else {
					commit('UPDATE_COPY_STATE', {
						field: 'phone',
						value: getters.driverPhonesForCopy[0].label
					})
				}
			}
		},
		resetContactCopyState ({ commit }) {
			commit('RESET_COPY_STATE')
		}
	}
}

export default COPY_STATE
