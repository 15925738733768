import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	getMetricsData (payload = {}) {
		return axios.get(`${HOST_URL}/cfo/metrics`, {
			params: payload
		})
	},
	getPersonalStatsData (payload = {}) {
		return axios.get(`${HOST_URL}/personal-stats`, {
			params: payload
		})
	},
	getCompaniesStatsData (payload = {}) {
		return axios.get(`${HOST_URL}/companies-stats`, {
			params: payload
		})
	},
	getChartData (payload = {}) {
		return axios.get(`${HOST_URL}/charts`, {
			params: payload
		})
	},
	getDocAccountants () {
		return axios.get(`${HOST_URL}/accountants-send-doc`)
	},
	getDocAccountantsStats (payload = {}) {
		return axios.get(`${HOST_URL}/accountant-docs-stats`, {
			params: payload
		})
	}
}
