
import locationApi from '@/api/location';
import LOCATION_FILTER from '@/store/modules/CRM/location/filter';
import MERGE_LOCATIONS_STATE from '@/store/modules/merge_locations'
import Vue from 'vue';
import locationAPI from "@/api/location";
const CRM_LOCATION = {
  namespaced: true,
  modules: {
    filter: LOCATION_FILTER,
    mergeLocations: MERGE_LOCATIONS_STATE
  },
  state: {
    allTableFields: [],
    locations: [],
    isBusyTable: false,
    selectedLocation: null,
    isLoading: false,
    isLocationExist: false,

    currentLocation: [],
    locationOptions: [],

    queryFields: [
      'actions',
      'id',
      'description',
      'location_type',
      'address',
      'categories',
      'industries',
      'companies',
      'contacts',
      'legal_address',
      'actual_address',
      
    ],
    additionalData: {
      per_page: 30,
      page: 1,
      total: 0,
      last_page: 0,
    },
    localizedFields: {
      actions: 'Действия',
      description: 'Название',
      id: 'ID',
      categories: 'Категории',
      location_type: 'Тип локации',
      industries: 'Тип промышленности',
      companies: 'Компании',
      contacts: 'Контакты',
      address: 'Адрес для маршрута в фоксе',
      legal_address: 'Юр. адрес',
      actual_address: 'Фактический адрес'
      
    },
  },

  getters: {
    getTableFields: (state) => state.queryFields,
    getLocalizedFields: (state) => state.localizedFields,
    getIsBusyTable: (state) => state.isBusyTable,
    getAdditionalData: (state) => state.additionalData,
    getName: () => (element, name) => {
      return element.length ? element.map((el) => el[name]).join('; ') : '-';
    },
    
    getParsedData: (state, getters) => {
      return state.locations.map((el) => {
        return {
          id: el.id,
          categories: getters.getName(el.categories, 'name'),
          location_type: el.location_type ? el.location_type.name : '-',
          industries: getters.getName(el.industries, 'name'),
          address: el.address ? el.address.address : '-',
          contacts: getters.getName(el.contacts, 'full_name'),
          contactsId: el.contacts.map(el => el.id).join('; '),
          companies: getters.getName(el.companies, 'name'),
          companiesId: el.companies.map(el => el.id).join('; '),
          description: el.description ? el.description : '-'
        };
      });
    },
  },

  actions: {
    async GET_LOCATIONS({ commit, getters, state }, payload = {}) {
      try {
        commit('UPDATE_BUSY_TABLE', true);
        const response = await locationApi.getLocations_v1({
          'page-limit': state.additionalData.per_page || 30,
          'request-type': 'location',
          page: state.additionalData.page || 1,
          ...getters['filter/getLocationFilterPayload']
        });
        const { data } = response;
        commit('SET_LOCATIONS', data.data);
        commit('UPDATE_GLOBAL_STATE', {
          field: 'additionalData',
          value: {
            from: data.from,
            to: data.to,
            last_page: data.last_page,
            per_page: data.per_page,
            total: data.total,
            page: data.current_page,
          },
        });
      } catch (e) {
        console.error(e);
        this._vm.$notify({
          type: 'error',
          text: 'Ошибка получения локаций!',
        });
      } finally {
        commit('UPDATE_BUSY_TABLE', false);
      }
    },


    async GET_LOCATION_BY_ID({ commit, getters, state }, id) {
      try {
        let response = await locationApi.getLocationById(id)
        let { data } = response;
        return data
      } catch(e) {
        console.log(e);
        this._vm.$notify({
          type: 'error',
          text: 'Ошибка получения локации!',
        });
      }
    },

    setLocationPage({ commit, dispatch }, page) {
      commit('SET_TABLE_PAGE', page);
      return dispatch('GET_LOCATIONS');
    },

    setLocationPageLimit({commit, dispatch}, perPage = 30){
      commit('SET_PER_PAGE_LIMIT', perPage);
      return dispatch('GET_LOCATIONS');
    },

    async linkLocationToCompany({commit, state, rootGetters, rootState}) {
      try {
        const companyID = rootGetters['CRM_COMPANY/getCompanyState'].id
        commit('UPDATE_GLOBAL_STATE', {
          field: 'currentLocation',
          value: state.locationOptions ? state.locationOptions.find(c => c.id === state.selectedLocation) : []
        })
        commit('SET_LOADING_FLAG', true)
        await locationApi.editLocation({
          id: state.selectedLocation,
          companies: state.currentLocation ? [...state.currentLocation.location_companies.map(c => ({id: c.id})), {id: companyID}] : []
        })
      } catch (e) {
        console.error(e)
        this._vm.$crmNotify.error('Невозможно подвязать локацию!')
      } finally {
        commit('SET_LOADING_FLAG', false)
      }
    },

    checkIfLocationExists({commit, state, rootState}) {
      const isLocationExistInCompany = rootState.CRM_COMPANY.company.locations.find(loc => loc.id === state.selectedLocation)
      commit('UPDATE_GLOBAL_STATE', {
        field: 'isLocationExist',
        value: !!isLocationExistInCompany
      })
      if (isLocationExistInCompany) {
        alert('Локация уже закреплена за компанией!')
      }
    },


    async searchLocation({commit, state}, value) {
      try {
        const {data} = await locationApi.searchLocation({
          'address': value,
        })
        if (data) {
          const locationOpt = data.reduce((memo, cur) => {
            if (cur) {
              const companyName = cur.companies.map(c => c.name).join(' | ')
              const locationDesc = cur.description || '—'
              memo.push({
                id: cur.id,
                address: cur.address.address + ' | ' + companyName  + locationDesc,
                location_companies: cur.companies
              })
            }
            return memo
          }, [])
          commit('UPDATE_GLOBAL_STATE', {
            field: 'locationOptions',
            value: locationOpt
          })
        }
      } catch (e) {
        this._vm.$crmNotify.error('Невозможно получить список локаций!')
      }
    }
  },

  mutations: {
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    SET_TABLE_FIELDS(state, data) {
      state.allTableFields = data;
    },
    UPDATE_BUSY_TABLE(state, value = false) {
      state.isBusyTable = value;
    },
    UPDATE_GLOBAL_STATE(state, payload) {
      state[payload.field] = payload.value;
    },
    SET_TABLE_PAGE(state, page) {
      state.additionalData.page = page;
    },
    SET_PER_PAGE_LIMIT(state, limit){
      state.additionalData.per_page = limit
    },
    RESET_SELECTED_LOCATION(state) {
      state.selectedLocation = null
    },
    SET_LOADING_FLAG(state, payload) {
      state.isLoading = payload
    },

  }
};

export default CRM_LOCATION;
