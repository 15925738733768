import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  fetchCompanies (payload = {}, userID) {
    const str = userID ? `?userId=${userID}` : '';
    return axios.get(`${HOST_URL}/order/all${str}`, {
      params: payload
    });
  },
  addVehicleToOrder(payload = {}){
    return axios.post(`${HOST_URL}/order/client/deal`, payload)
  }
}
