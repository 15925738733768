export const CLIENT_TYPE = 1
export const TRANSPORTER_TYPE = 2
export const EXPEDITOR_TYPE = 3
export const CONTRAGENT_TYPE = 5

export default {
  companyTypesOptions: [
    {
      id: CLIENT_TYPE,
      name: 'Заказчик'
    },
    {
      id: TRANSPORTER_TYPE,
      name: 'Перевозчик'
    },
    {
      id: EXPEDITOR_TYPE,
      name: 'Экспедитор'
    },
    {
      id: CONTRAGENT_TYPE,
      name: 'Контрагент ТО'
    }
  ]
}
