import dealApi from '@/api/deal'
import stuffApi from '@/api/stuff'

export default {
  /**
   * Update deal status in CFO table
   * @param commit
   * @param dispatch
   * @param statusId
   * @param deal
   * @returns {Promise<*>}
   */
  async setDealStatus ({commit, dispatch}, {statusId, deal}) {
    await dealApi.updateDealStatus({
      deal: deal,
      status: statusId
    })

    return dispatch('refreshEntireCfo')
  },
  /**
   * Upload files to deal
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  async uploadDealFilter({commit, dispatch}, payload) {
    await stuffApi.loadFiles(payload)

    return dispatch('refreshEntireCfo')
  },
  /**
   * Upload RNN file with deal IDS to backend
   * @param dispatch
   * @param file
   * @returns {Promise<*>}
   */
  async uploadBlockedRnnFile ({ dispatch }, file) {
    const formData = new window.FormData()
    formData.append('file', file)

    await dealApi.uploadBlockedRnnFile(formData)

    return dispatch('refreshEntireCfo')
  },
  /**
   * Delete deal from CFO table
   * @param dispatch
   * @param dealID
   * @returns {Promise<void>}
   */
  async deleteDeal ({ dispatch }, dealID) {
    await dealApi.deleteDeal(dealID)

    return dispatch('refreshCfoAfterChanges')
  }
}
