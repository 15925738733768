import DEBTORS_MODULE from './cfo_debtors'
import DATA_EDITING_MODULE from './cfo/data-editing.module.js'
import CFO_FILTER_MODULE from './cfo/filter'
import FIELDS_MANAGEMENT_MODULE from './cfo/fields-management'
import BATCH_EDITING_MODULE from './cfo/batch-editing'
import cfoApi from '@/api/cfo'
import exportToExcelHelper from '@/mixins/export-to-excel'
import dealActions from './cfo/deal-actions.js'
import router from '@/router'
import Vue from 'vue'

const CFO_MODULE = {
	namespaced: true,
	modules: {
		DEBTORS_MODULE: DEBTORS_MODULE,
		DATA_EDITING_MODULE: DATA_EDITING_MODULE,
		CFO_FILTER_MODULE: CFO_FILTER_MODULE,
		FIELDS_MANAGEMENT_MODULE: FIELDS_MANAGEMENT_MODULE,
		BATCH_EDITING_MODULE: BATCH_EDITING_MODULE
	},
	state: {
		amounts: [],
		data: [],
		CFO_HIDDEN_COLS: ['id'],
		page: 1,
		per_page: +window.localStorage.getItem('cfo_table_count_per_page') || 30,
		total: 0,
		last_page: 0,
		from: null,
		to: null,
		sortBy: null,
		sortType: null,
		isBusyCfoTable: false,
		cache: {},
		lazyLoadPage: 1,
		startedLazyLoading: false,
		lazyLoadTimeout: null,
		infiniteScrolling: !+window.localStorage.getItem('disableInfiniteScrolling'),
		hardStopLazyLoad: false,
		isRating: false,
		isBlockAmountsHidden: false
	},
	getters: {
		getCfoPayload: (state, getters, rootState, rootGetters) => {
			const args = {
				...rootGetters['cfo/CFO_FILTER_MODULE/getFilterArgs'],
				...rootGetters['cfo/FIELDS_MANAGEMENT_MODULE/getOperationModePayload'],
				'page-limit': state.per_page || 1,
				page: state.page || 1
			}

			if (state.sortBy) {
				args[`sorting[${state.sortBy}]`] = state.sortType
			}

			return args
		},
		getExportExcelCfoPayload: (state, getters, rootState, rootGetters) => ({
			...rootGetters['cfo/CFO_FILTER_MODULE/getFilterArgs'],
			'page-limit': state.per_page || 1,
			page: state.page || 1,
			hidden_fields: state.CFO_HIDDEN_COLS || null,
			get_excel: true
		})
	},
	mutations: {
		SET_DATA (state, data) {
			state.data = Object.freeze(data);
		},
		SET_AMOUNTS (state, amounts) {
			state.amounts = amounts
		},
		SET_ADDITIONAL_DATA (state, data) {
			if (data.per_page) {
				window.localStorage.setItem('cfo_table_count_per_page', data.per_page)
			}

			state.from = data.from;
			state.to = data.to;
			state.last_page = data.last_page;
			state.total = data.total;
		},
		SET_PER_PAGE_LIMIT (state, limit) {
			window.localStorage.setItem('cfo_table_count_per_page', limit)
			state.per_page = limit
		},
		SET_PER_PAGE_LIMIT_WITHOUT_STORAGE (state, limit) {
			state.per_page = limit
		},
		SET_TABLE_PAGE (state, page) {
			state.page = page
		},
		UPDATE_BUSY_CFO_TABLE (state, value = false) {
			state.isBusyCfoTable = value
		},
		CACHE_DATA (state, {key, payload}) {
			state.cache[key] = Object.freeze(payload);
		},
		CLEAR_CACHE_DATA (state) {
			state.cache = {};
			state.lazyLoadPage = state.page || 1;
			state.startedLazyLoading = false;
		},
		START_LAZY_LOAD (state) {
			state.startedLazyLoading = true;
		},
		CHANGE_LAZY_LOAD_PAGE (state, page) {
			state.lazyLoadPage = page;
		},
		SET_LAZY_LOAD_TIMEOUT (state, timeout) {
			state.lazyLoadTimeout = timeout;
		},
		STOP_LAZY_LOAD (state) { //TODO abort requests on routing?
			if (state.lazyLoadTimeout) {
				window.clearTimeout(state.lazyLoadTimeout);
				state.lazyLoadTimeout = null;
			}
		},
		SET_SORTING_KEY (state, key) {
			state.sortBy = key
		},
		SET_SORTING_TYPE (state, key) {
			state.sortType = key
		},
		SET_INFINITE_SCROLLING (state, flag) {
			window.localStorage.setItem('disableInfiniteScrolling', +!flag);
			state.infiniteScrolling = flag;
		},
		ROLLBACK_TO_PREV_STATE (state, prevState = {}) {
			Object.keys(prevState).forEach(key => {
				Vue.set(state, key, prevState[key])
			})
		},
		HARD_STOP_LAZY_LOAD (state, payload = true) {
			state.hardStopLazyLoad = payload
		},
		SET_AMOUNTS_BLOCK_VISIBILITY (state) {
			state.isBlockAmountsHidden = !state.isBlockAmountsHidden
		}
	},
	actions: {
		/**
         * Export and download Excel representation from CFO table
         * @param state
         * @param dispatch
         * @param getters
         * @param rootGetters
         * @param fields
         * @param name
         * @param template
         * @returns {*}
         */
		async exportCfoToExcel ({getters}, {fields, name, template = null}) {
			const response = await cfoApi.getCFODataAsExcel({
				...getters.getExportExcelCfoPayload,
				fields: fields.join(','),
				template_name: template
			})

			return exportToExcelHelper(response, name)
		},
		async CFO_UPDATE_WITH_DATA ({commit, dispatch}, {response}) {
			const {data} = response

			await dispatch('FIELDS_MANAGEMENT_MODULE/setFieldsAndModes', {
				modes: data.data_custom.operating_mode
			})
			await dispatch('FIELDS_MANAGEMENT_MODULE/setAllFields', {
				fieldsObj: data.data_custom,
				localization: response.localization.tables
			})

			commit('SET_DATA', data.data)
			commit('SET_AMOUNTS', data.data_custom.amounts)

			commit('SET_ADDITIONAL_DATA', {
				from: data.from,
				to: data.to,
				last_page: data.last_page,
				total: data.total
			})
		},
		GET_CFO_DATA_NO_CACHE ({getters}, payload) {
			const protectedCfoPayload = JSON.parse(JSON.stringify(getters.getCfoPayload));
			payload = Object.assign(protectedCfoPayload, payload);

			return cfoApi.getCFOData(payload);
		},
		/**
         * memoization i.e. caching
         * TODO heavily debug. This seems like affecting ALL of the tables,
         *  though it might just benefit them
         * */
		async GET_CFO_DATA ({commit, state, dispatch, getters}) {
			const memoKey = JSON.stringify(getters.getCfoPayload);
			commit('HARD_STOP_LAZY_LOAD', false)
			if (state.cache[memoKey]) {
				dispatch('CFO_UPDATE_WITH_DATA', {response: state.cache[memoKey]});
			} else {
				const response = await dispatch('GET_CFO_DATA_NO_CACHE');
				commit('CACHE_DATA', {
					key: memoKey,
					payload: response
				});
				dispatch('CFO_UPDATE_WITH_DATA', {response: response});
			}
			dispatch('lazyLoad', !state.startedLazyLoading);
		},
		lazyLoad ({state, dispatch, commit, getters}, original) {
			if (state.hardStopLazyLoad) {
				return false
			}
			commit('START_LAZY_LOAD');
			if (!original && state.startedLazyLoading) {
				return;
			}
			const timeout = setTimeout(() => {
				const protectedPayload = JSON.parse(JSON.stringify(getters.getCfoPayload));
				const payload = Object.assign(protectedPayload, {page: state.lazyLoadPage});
				const memoKey = JSON.stringify(payload);
				if (!state.cache[memoKey]) {
					dispatch('GET_CFO_DATA_NO_CACHE', payload)
						.then((response) => {
							commit('CACHE_DATA', {
								key: memoKey,
								payload: response
							});

							if (state.lazyLoadPage - 3 <= state.page) {
								commit('CHANGE_LAZY_LOAD_PAGE', state.lazyLoadPage + 1);
								dispatch('lazyLoad', original);
							}
						});
				} else {
					commit('CHANGE_LAZY_LOAD_PAGE', state.lazyLoadPage + 1);
					dispatch('lazyLoad', original);
				}
			}, 250);
			commit('SET_LAZY_LOAD_TIMEOUT', timeout);
		},
		/**
         * Set page limit for CFO table and refresh data
         * @param commit
         * @param dispatch
         * @param pageLimit
         * @returns {*}
         */
		setCfoPageLimit ({commit, dispatch}, pageLimit = 30) {
			commit('SET_PER_PAGE_LIMIT', pageLimit)

			return dispatch('GET_CFO_DATA')
		},
		setCfoPageLimitWithoutStorage ({commit, dispatch}, pageLimit = 30) {
			commit('SET_PER_PAGE_LIMIT_WITHOUT_STORAGE', pageLimit)

			return dispatch('GET_CFO_DATA')
		},
		/**
         * Set page for CFO
         * @param commit
         * @param dispatch
         * @param page
         * @returns {*}
         */
		setCfoPage ({commit, dispatch}, page) {

			if (+router.currentRoute.query.page !== page) {

				router.push({
					query: {
						page: page
					}
				});
			}

			commit('SET_TABLE_PAGE', page);
			commit('CHANGE_LAZY_LOAD_PAGE', page);

			return dispatch('GET_CFO_DATA');
		},
		/**
         * Refresh Cfo table data from backend
         * @param commit
         * @param dispatch
         */
		refreshEntireCfo ({commit, dispatch}) {
			dispatch('setBusyTable', true);
			commit('STOP_LAZY_LOAD');
			commit('CLEAR_CACHE_DATA');

			return dispatch('GET_CFO_DATA').then(() => {
				dispatch('setBusyTable', false);
			});
		},
		/**
         * Refresh CFO and reset page. Used for filter
         * @param commit
         * @param dispatch
         * @returns {*}
         */
		resetPageAndRefreshEntireCfo ({commit, dispatch}) {
			commit('SET_TABLE_PAGE', 1);
			commit('STOP_LAZY_LOAD');
			commit('CLEAR_CACHE_DATA');

			return dispatch('GET_CFO_DATA')
		},
		/**
         * Method that fired when user change deal data
         * Maybe, this method will be update state without request to backend
         * @param commit
         * @param dispatch
         * @param dealID
         * @returns {*}
         */
		refreshCfoAfterChanges ({commit, dispatch}) {
			commit('STOP_LAZY_LOAD');
			commit('CLEAR_CACHE_DATA'); //TODO update only one deal?

			return dispatch('GET_CFO_DATA');
		},

		resetLazyCfo ({commit}) {
			commit('STOP_LAZY_LOAD')
			commit('CLEAR_CACHE_DATA')
			commit('HARD_STOP_LAZY_LOAD')
		},

		/**
         * Change sorting data for CFO
         * @param state
         * @param commit
         * @param dispatch
         * @param getters
         * @param sortingKey - field for sort
         * @returns {*}
         */
		setSortDataAndRefresh ({state, commit, dispatch}, sortingKey) {
			commit('STOP_LAZY_LOAD')

			let type = null
			if (!state.sortType) {
				type = 'asc'
			} else if (state.sortType === 'asc') {
				type = 'desc'
			}

			commit('SET_SORTING_TYPE', state.sortBy !== sortingKey ? 'asc' : type)
			commit('SET_SORTING_KEY', sortingKey)

			return dispatch('GET_CFO_DATA')
		},
		setBusyTable ({commit}, flag) {
			commit('UPDATE_BUSY_CFO_TABLE', !!flag);
		},
		toggleInfiniteScrolling ({commit, state}) {
			commit('SET_INFINITE_SCROLLING', !state.infiniteScrolling);
		},
		rollbackToPrevState ({commit}, prevState = {}) {
			commit('ROLLBACK_TO_PREV_STATE', prevState)
		},
		...dealActions
	}
}

export default CFO_MODULE
