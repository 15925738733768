import getters from './helper_module/getters'
import state from './helper_module/state'
import mutations from './helper_module/mutations'
import actions from './helper_module/actions'

const HELPER_MODULE = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}

export default HELPER_MODULE
