import Vue from 'vue'

export default {
	SET_MULTI_EDIT_DATA (state, {field, value}) {
		Vue.set(state, field, value)
	},
	SELECT_ID (state, {id, page, phone, name}) {
		Vue.set(state.selected_ids, `#${id}`, {
			page,
			phone,
			name
		})
	},
	DESELECT_ID (state, id) {
		Vue.delete(state.selected_ids, id)
	}
}
