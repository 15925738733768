import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
  getDriverID (id, payload = {}) {
    return axios.get(`${HOST_URL}/driver/${id}`, {
      params: payload
    })
  },
  getDrivers (payload = {}) {
    return axios.get(`${HOST_URL}/driver`, {
      params: payload
    })
  },
  searchDrivers (queryString) {
    return axios.get(`${HOST_URL}/search/drivers/${queryString}`)
  },
}
