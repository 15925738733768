import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/main.sass'
import '@/plugins/vee-validate'
import '@/plugins/vue-file-previewer/index'
import '@/plugins/bootsrap-vue'
import '@/plugins/fox-ui-kit'
import '@/plugins/vue-croppie'
import getMessageFromAxiosError from '@/helpers/axios-error-handler/index'
import toLoginWithPayload from '@/router/helpers/toLoginWithPayload'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import sentryCaptureError from '@/helpers/sentry/sentry-capture-error'
import './service-worker/sw-register'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

Vue.config.productionTip = false

import axios from 'axios'

const interceptorExcepts = [
	// 'api/user/login',
	'api/order/shared/lardi'
]
interceptorExcepts.urlExists = function (url) {
	return this.filter(_url => url.indexOf(_url) > -1).length > 0
}
let vm

axios.interceptors.request.use((config) => {
	Vue.notify({
		clean: true
	})

	return config
})

axios.interceptors.response.use(res => {
	const token = res.headers.authorization

	if (token) {
		store.commit('SET_TOKEN', token)

		axios.defaults.headers.common.Authorization = token
	}

	return res.data
}, err => {
	sentryCaptureError(err)

	/** Не выводим ошибку если пришла при отпавке на ларди */
	if (interceptorExcepts.urlExists(err.config.url)) {
		return Promise.reject(err)
	}

	const { data } = err.response
	let errorsMessage

	switch (err.response.status) {
	case 401: {
		// kostil for double requests
		// todo cancel all requests after 401
		if (vm.$route.name !== 'Login') {
			errorsMessage = getMessageFromAxiosError(data)
			vm.$store.commit('LOGOUT_USER')
			vm.$router.push(toLoginWithPayload(vm.$route))
		}

		break
	}
	case 400:
	case 500:
		errorsMessage = getMessageFromAxiosError(data)
		break
	case 403:
		break
	default:
		errorsMessage = getMessageFromAxiosError(data)
		break
	}

	/**
   * БЕКЕНДЕР СПАСИ И СТАНДАРТИЗИРУЙ ЕКСЕПШЕНЫ
   */
	if (err.config.url.indexOf('update-status') > -1) {
		return Promise.reject(errorsMessage)
	}

	if (errorsMessage) {
		vm.$crmNotify.error(errorsMessage)
	}

	return Promise.reject(err)
})

if (process.env.VUE_APP_SENTRY_DSN) {
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: []
			})
		],
		tracesSampleRate: 1.0
	})
}

vm = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

Vue.prototype.$cfoFilter = {
	toggle (id) {
		vm.$emit('NAVIGATION_INPUT::TOGGLE', 'navigation-input')
		vm.$emit('cfoFilter::toggle', id)
	},
	show (id) {
		vm.$emit('NAVIGATION_INPUT::TOGGLE', 'navigation-input', true)
		vm.$emit('cfoFilter::show', id)
	},
	hide (id) {
		vm.$emit('NAVIGATION_INPUT::TOGGLE', 'navigation-input', false)
		vm.$emit('cfoFilter::hide', id)
	},
	filter (id) {
		vm.$emit('NAVIGATION_INPUT::TOGGLE', 'navigation-input')
		vm.$emit('cfoFilter::filter', id)
	}
}

Vue.prototype.$crmNotify = {
	success (msg = '') {
		Vue.notify({
			type: 'success',
			text: msg
		})
	},
	error (msg = '', duration = -1) {
		Vue.notify({
			type: 'error',
			text: msg,
			duration: duration
		})
	},
	warning (msg = '') {
		Vue.notify({
			type: 'warning',
			text: msg
		})
	},
	clearAll () {
		Vue.notify({
			clean: true
		})
	}
}
