export default {
	resetSelected ({commit}) {
		commit('SET_MULTI_EDIT_DATA', {
			field: 'selected_ids',
			value: {}
		})
	},
	resetAll ({commit}) {
		commit('SET_MULTI_EDIT_DATA', {
			field: 'is_multi_edit_enabled',
			value: false
		})
		commit('SET_MULTI_EDIT_DATA', {
			field: 'selected_ids',
			value: {}
		})
	},
	onDeselectRow ({commit}, id) {
		commit('DESELECT_ID', id)
	},
	onSelectRow ({state, commit}, {id, page, code, name}) {
		if (state.selected_ids[`#${id}`]) {
			commit('DESELECT_ID', `#${id}`)
		} else {
		  commit('SELECT_ID', {
		  	id,
				page,
				code,
				name
		  })
		}
	},
	selectAllRows ({commit}, data) {
		data.forEach(contact => {
			commit('SELECT_ID', {
				id: contact.id,
				phone: contact.phone,
				name: contact.name
			})
		})
	},
	onSelectRows ({state, commit, getters}, contacts = []) {
		const isAlreadySelected = getters.allPageIsSelected(contacts)

		if (isAlreadySelected) {
			contacts.forEach(contact => {
				commit('DESELECT_ID', `#${contact.id}`)
			})
		} else {
			contacts.forEach(contact => {
				const element = state.selected_ids[`#${contact.id}`]

				if (!element) {
					commit('SELECT_ID', {
						id: contact.id,
						phone: contact.phone,
						name: contact.name
					})
				}
			})
		}
	}
}
