import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	getPersonInfo (name, date, id) {
		return axios.get(`${HOST_URL}/opendatabot/contact-data/${name}`, {
			params: {
				birth_date: date,
				id
			}
		})
	}
}
