import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  getLocationsByTypeID(payload = {}) {
    return axios.get(`${HOST_URL}/locations`, {
      params: payload
    })
  },
  createLocation(payload = {}) {
    return axios.post(`${HOST_URL}/v${API_VERSION}/location`, payload)
  },
  getCompaniesLocation(payload = {}) {
    return axios.get(`${HOST_URL}/v${API_VERSION}/location/`, {
      params: payload
    })
  },
  getLocations(payload = {}) {
    return axios.get(`${HOST_URL}/v${API_VERSION}/location`, {
      params: payload
    })
  },
  getLocations_v1(payload = {}) {
    return axios.get(`${HOST_URL}/v${API_VERSION}/location`, {
      params: payload
    })
  },
  getLocationById(id) {
    return axios.get(`${HOST_URL}/v${API_VERSION}/location/${id}`)
  },

  editLocation(payload = {}) {
    return axios.post(`${HOST_URL}/v${API_VERSION}/location/`, payload)
  },

  deleteLocations(payload = {}) {
      return axios.post(`${HOST_URL}/delete-locations`, payload)
  },

  mergeLocations(payload = {}) {
    return axios.post(`${HOST_URL}/sync-locations`, payload)
  },

  searchLocation(payload = {}){
    return axios.get(`${HOST_URL}/search-locations`, {
      params: payload
    })
  },
  getLocationsByAddress(query = {}) {
    return axios.get(`${HOST_URL}/search-locations`, {
      params: query
    })
  }
}
