export default function getMessageFromAxiosError (errorObject) {
	if (!errorObject || !Object.keys(errorObject).length) {
		return 'Упс! Проблемы на сервере, пожалуйста, подождите!'
	}

	const {errors, message, messages} = errorObject
	let error = message
	const errorKeys = Object.values(errors || {})

	if (messages && messages.length) {
		error = messages.join('; \n')
	} else if (errors && errorKeys && errorKeys.length) {
		error = errorKeys.join('; \n')
	}

	return error
}