const toDate = (date) => {
  return (new Date(date)).toISOString()
}

const KEYS_WITH_IDS = [
  'our_company',
  'our_company_role',
  'payment_condition',
  'contractable'
]
const IGNORED_KEYS = [
  'transportation_types',
  'files'
]

export default function appendContractToFormData (contracts = [], formData) {
  if (!formData) {
    return false
  }

  const append = (key, value) => {
    formData.append(key, value)
  }

  contracts.forEach((contract, index) => {
    const contractKey = `contracts[${index}]`

    Object.keys(contract).forEach((key, keyIdx) => {
      let dataKey = null
      let dataValue = null

      if (key.indexOf('date') > -1) {
        if (contract[key]) {
          dataKey = `${contractKey}[${key}]`
          dataValue = toDate(contract[key])
        }
      } else if (KEYS_WITH_IDS.includes(key)) {
        if (contract[key] && contract[key].id) {
          dataKey = `${contractKey}[${key}][id]`
          dataValue = contract[key].id
        }
      } else if (!IGNORED_KEYS.includes(key)) {
        if (contract[key]) {
          dataKey = `${contractKey}[${key}]`
          dataValue = contract[key]
        }
      }

      append(dataKey, dataValue)
    })

    contract.transportation_types.forEach((type, typeIdx) => {
      append(`${contractKey}[transportation_types][${typeIdx}][id]`, type.id)
    })

    if (contract.files && contract.files.length) {
      contract.files.filter(i => i.newlyAdded).forEach((value, fileIdx) => {
        append(`${contractKey}[files][${fileIdx}][document_type_id][]`, value.document_type_id || value.document_type.id)
        append(`${contractKey}[files][${fileIdx}][weight][]`, value.weight)
        append(`${contractKey}[files][${fileIdx}][files][]`, value.file || value.fileObject)
      })
    }
  })

  return true
}