import cfoApi from '@/api/cfo'
import exportToExcelHelper from '@/mixins/export-to-excel'

export default {
	/**
   * Export and download Excel representation from table
   * @param state
   * @param getters
   * @returns {Promise<void>}
   */
	async exportDebtorsToExcel ({ getters }) {
		const response = await cfoApi.getCFODataAsExcel({
			...getters.getDebtorsPayload,
			fields: getters.getExportedDataByDebtorType.fields,
			get_excel: true
		})

		return exportToExcelHelper(response, getters.getExportedDataByDebtorType.name)
	},
	/**
   * Fetch debtors data from CFO with filter arguments
   * @param commit
	* @param payload
   * @param getters
   * @returns {Promise<void>}
   */
	async getDebtorsData ({ commit, getters }) {
		commit('DEBTORS_UPDATE_BUSY_TABLE', true)


		const response = await cfoApi.getCFOData(getters.getDebtorsPayload)
		const { data } = response

		commit('UPDATE_DEBTORS_STATE', {
			data: data.data,
			fields: data.data_custom.fields,
			localization: response.localization.tables,
			additionalData: {
				last_page: data.last_page,
				per_page: data.per_page,
				total: data.total,
				page: data.current_page
			}
		})

		commit('DEBTORS_UPDATE_BUSY_TABLE', false)
	},
	getDebtorsDataByType ({ commit, dispatch }, payload) {
		commit('UPDATE_DEBTORS_TYPE', payload)

		return dispatch('getDebtorsData')
	},
	/**
   * Just reset entire debtors state
   * @param commit
   */
	resetDebtorsState ({ commit }) {
		commit('RESET_DEBTORS_STATE')
	},
	/**
   * Set page and fetch debtors
   * @param commit
	 * @param state
   * @param dispatch
   * @param page
   * @returns {*}
   */
	setPage ({ commit, dispatch }, page) {
		commit('SET_DEBTORS_PAGE', page)

		return dispatch('getDebtorsData')
	},
	/**
   * Set page limit and fetch debtors
   * @param commit
   * @param dispatch
   * @param limit
	 @param state
   * @returns {*}
   */
	setPageLimit ({ commit, dispatch }, limit) {
		commit('SET_DEBTORS_PAGE_LIMIT', limit)
		commit('SET_DEBTORS_PAGE', 1)

		return dispatch('getDebtorsData')
	}
}
