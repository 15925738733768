import localStorageKeys from '@/config/local-storage-keys'

export default {
	SET_OPERATING_MODES (state, payload = []) {
		state.modes = payload
	},
	SET_SELECTED_MODE_ID (state, id) {
		localStorage.setItem(localStorageKeys.OPERATING_MODE_ID, id)
		state.selectedModeID = id
	},
	SET_FIELDS (state, payload) {
		localStorage.setItem(localStorageKeys.CFO_TABLE_FIELDS, JSON.stringify(payload))
		state.fields = payload
	},
	SET_FIELDS_WITHOUT_STORAGE (state, payload) {
		state.fields = payload
	},
	SET_FULL_LIST (state, payload) {
		state.listOfFields = payload
	},
	SET_FIELDS_LOCALIZATION (state, payload) {
		state.fieldsLocalization = payload
	},
	SET_IS_RATING (state, flag) {
		state.isRating = flag
	}
}
