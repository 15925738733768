import Vue from 'vue'
import defaultState from './state'

export default {
	DEBTORS_UPDATE_BUSY_TABLE (state, value = false) {
		state.isBusyTable = value
	},
	SET_DEBTORS_PAGE (state, page) {
		state.additionalData.page = page
	},
	SET_DEBTORS_PAGE_LIMIT (state, limit) {
		state.additionalData.per_page = limit
	},
	UPDATE_DEBTORS_STATE (state, { data = [], fields = [], localization = [], additionalData = null }) {
		state.data = data
		state.tableFields = fields
		state.localizedFields = localization
		Vue.set(state, 'additionalData', additionalData || {
			per_page: 30,
			page: 1,
			total: 0,
			last_page: 0
		})
	},
	UPDATE_DEBTORS_TYPE (state, type) {
		state.debtor_type = type
	},
	UPDATE_DEBTORS_FLAG (state, flag) {
		state.is_all_debtors = flag
	},
	RESET_DEBTORS_STATE (state) {
		Object.assign(state, defaultState())
	}
}
