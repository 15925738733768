import { MULTI_EDIT_LOCK } from '@/config/socket-events.config'
import stuffApi from '@/api/stuff'

const liveConnections = {
  namespaced: true,
  state: {
    data: {},
    entity: null,
    id: null,
  },
  getters: {
    getTabs: (state) => {
      return Object.keys(state.data)
    },
    getEntitiesIdsByKey: (state) => {
      if (!state.entity) {
        return []
      }
      return Object.keys(state.data[state.entity])
    },
    getConnectionsById: (state) => {
      if (!state.entity || !state.id) {
        return []
      }
      return state.data[state.entity][state.id]
    },
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload
    },
    SET_ENTITY (state, payload) {
      state.entity = payload
    },
    SET_ID (state, payload) {
      state.id = payload
    },
  },
  actions: {
    selectEntityTab ({ commit }, key) {
      commit('SET_ENTITY', key)
    },
    selectIdTab ({ commit }, key) {
      commit('SET_ID', key)
    },
    setData ({
      state,
      commit,
    }, payload) {
      commit('SET_DATA', payload)

      if (!payload[state.entity]) {
        commit('SET_ENTITY', null)
        commit('SET_ID', null)
      } else if (payload[state.entity] && !payload[state.entity][state.id]) {
        commit('SET_ID', null)
      }
    },
    async subscribeToLiveConnections ({
      rootState,
      dispatch
    }) {
      const response = await stuffApi.getSocketQueue()
      await dispatch('setData', response)

      if (rootState.socket.socket) {
        rootState.socket.socket.on(MULTI_EDIT_LOCK.ADMIN_NOTIFY, data => {
          dispatch('setData', data)

          this._vm.$crmNotify.success('Данные в очереди обновлены!')
        })
      }
    },
    unsubscribeFromLiveConnections ({ rootState }) {
      if (rootState.socket.socket) {
        rootState.socket.socket.off(MULTI_EDIT_LOCK.ADMIN_NOTIFY)
      }
    },
    async kickFromQueue ({ commit }, payload) {
      try {
        await stuffApi.removeSocketFromQueue(payload.connection_id, payload)
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.warning('Ошибка удаления из очереди!', e)
      }
    },
    async moveToLastPosition ({ commit }, payload) {
      try {
        await stuffApi.moveToLastPosition(payload.connection_id, payload)
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.warning('Ошибка перемещения в очереди!', e)
      }
    },
    async resetQueue({commit}, payload) {
      try {
        await stuffApi.resetEntityQueue(payload)
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.warning('Ошибка очистки очереди!', e)
      }
    },
    async moveToFirstPosition ({ commit }, payload) {
      try {
        await stuffApi.moveToFirstPosition(payload.connection_id, payload)
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.warning('Ошибка перемещения в очереди!', e)
      }
    },
  },
}

export default liveConnections
