var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"fox-input-wrapper"},[(_vm.$slots.prepend)?_c('div',{staticClass:"prepend-element"},[_vm._t("prepend")],2):_vm._e(),(_vm.label || _vm.$slots.label)?_c('div',{staticClass:"fox-input-label"},[(_vm.label && !_vm.$slots.label)?_c('div',[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):(_vm.$slots.label)?_vm._t("label"):_vm._e()],2):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"fox-input",class:{
      'fox-input__sm': _vm.size === 'sm'
    },attrs:{"id":_vm.id,"step":_vm.type === 'number' ? _vm.step : 'any',"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.vModel)?_vm._i(_vm.vModel,null)>-1:(_vm.vModel)},on:{"keypress":_vm.onKeyPress,"change":function($event){var $$a=_vm.vModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.vModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.vModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.vModel=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"fox-input",class:{
      'fox-input__sm': _vm.size === 'sm'
    },attrs:{"id":_vm.id,"step":_vm.type === 'number' ? _vm.step : 'any',"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.vModel,null)},on:{"keypress":_vm.onKeyPress,"change":function($event){_vm.vModel=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],staticClass:"fox-input",class:{
      'fox-input__sm': _vm.size === 'sm'
    },attrs:{"id":_vm.id,"step":_vm.type === 'number' ? _vm.step : 'any',"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.vModel)},on:{"keypress":_vm.onKeyPress,"input":function($event){if($event.target.composing)return;_vm.vModel=$event.target.value}}}),(_vm.$slots.append)?_c('div',{staticClass:"append-element"},[_vm._t("append")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }