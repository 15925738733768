import jsonToFormData from '@/mixins/jsonToFormData'
import {get} from '@/mixins/light_lodash'
import {uuniqBy} from '@/mixins/light_lodash'
import {can} from '@/mixins/permission'
import locationApi from '@/api/location'
import orderApi from '@/api/order'
import driverApi from '@/api/driver'
import dealApi from '@/api/deal'
import companyApi from '@/api/company'
import contactApi from '@/api/contact'

function _parseData(keyPathObjects, keyPathArrays, data) {
  let newData = []
  if (typeof image_array !== 'undefined' && data.length > 0) return newData

  /** Приводим данные к табличному виду */
  data.map(o => {
    let obj = {}

    /** Поиск по ключу и путю */
    Object.entries(keyPathObjects).forEach(([key, value], index) => {
      obj[key] = get(o, value)
    })

    keyPathArrays.forEach(val => {
      if (val.addition && val.additionKey) {
        /** Поиск по ключу с доп. параметром */
        obj[val.key] =  get(o, val.addition) ? get(o, val.addition).map(l => l[val.additionKey]).join('; ') : '-'
      } else  {
        /** Поиск по ключу с перебором */
        obj[val.key] = o[val.key] ? o[val.key].map(l => get(l, val.path)).join('; ') : '-'
      }
    })

    newData.push(obj)
  })

  return newData
}

const filterState = () => ({
  liveSearch: null,
  responsible: null,

  status: [1,2],
  id: null,
  date_auto_free: null,
  address_froms: null,
  address_tos: null,
  transporter: null,
  transporter_contact: null,
  driver_id: null,
  deal_id: null,
  order_client_id: null,
  manager_id: null,

  byFields: [],
  localizedFields: {},
  focusFilter: null,

  localManager_: null
})

const defaultState = () => ({
  /**
   * Данные из вьюшки
   */
  data: [],
  /**
   * Для отображения сумм
   */
  amounts: [],
  /**
   * Поля для отображения и обработки
   */
  tableFields: [],
  /**
   * Поля которые надо отображать или обработать в таблице
   */
  queryFields: [
    'id',
    'date_auto_free',
    'address_froms',
    'address_tos',
    'phones',
    'driver',
    'transporter',
    'vehicle',
    'trailer',
    'transporter_freight',
    'transports',
    'deal',
    'status',
    'order',
    'manager',
    'shared',
    'description',
  ],
  /**
   * Объект соответсвий названия поля и его перевода
   */
  localizedFields: {
    id: 'ID',
    status: 'Статус',
    date_auto_free: 'Дата загрузки',
    address_froms: 'Город (откуда)',
    address_tos: 'Город (куда)',
    driver: 'Водитель',
    transporter: 'Компания',
    phones: 'Телефон',
    vehicle: 'Авто',
    trailer: 'Прицеп',
    transports: 'Тип транспорта',
    transporter_freight: 'Фрахт перевозчика',
    deal: 'Сделка',
    order: 'Заказ з',
    manager: 'Ответственный',
    shared: 'С кем поделились',
    description: 'Комментарий',
  },
  /**
   * Для пагинации объект
   */
  additionalData: {
    per_page: 30,
    page: 1,
    total: 0,
    last_page: 0
  },

  client_orders: [],
  allTableFields: [],
  /**
   * Флаг отвечающий за блокировку таблицы
   */
  isBusyTable: false,

  /**
   * Ключ - путь для формирования таблицы
   */
  keyPathObjects: {
    date_auto_free: 'date_auto_free',
    driver: 'driver.contact.full_name',
    vehicle: 'vehicle',
    description: 'description',
    transporter: 'transporter',
    transporter_type: 'transporter_type',
    trailer: 'trailer',
    cargo_type: 'cargo_type.name',
    children: 'children',
    status: 'status',
    transporter_freight: 'transporter_freight',
    transporter_payment_form: 'transporter_payment_form.name',
    manager: 'manager',
    date_loading: 'date_loading',
    deal: 'deal.id',
    order: 'order_client.id',
    id: 'id',
    parent: 'parent',
  },

  keyPathArrays: [
    {path: 'short_address', key: 'address_froms'},
    {path: 'short_address', key: 'address_tos'},
    {path: 'contact.phones', key: 'phones', addition: 'driver.contact.phones', additionKey: 'phone'},
    {path: 'full_name', key: 'shared'},
  ],

  filter: filterState(),
  ordersList: [],
  clientOrdersList: [],
  addressFroms: [],
  addressTos: [],
  companies: [],
  contacts_: [],
  deals: [],
  drivers: [],
  vehicleTypes: [],
})

const TRANSPORTER_ORDER_INDEX = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getState: state => state,
    getAdditionalData: state => state.additionalData,
    getClientOrders: state => _parseData(state.keyPathObjects, state.keyPathArrays, state.client_orders),
    getTableFields: state => state.allTableFields,
    getLocalizedFields: state => state.localizedFields,
    getIsBusyTable: state => state.isBusyTable,

    getClearTableData: state => state.client_orders,

    getFilter: state => state.filter,
    getDeals: state => state.deals.map(d => ({value:d.id, label: d.id})),
    getOrdersList: state => state.ordersList.map(d => ({value:d.id, label: d.id})),
    getDrivers: state => state.drivers.map(d => ({value:d.id, label: d.full_name})),
    getTransporterOrdersList: state => state.clientOrdersList.map(d => ({value:d.id, label: d.id})),
    getContactsList: state => state.contacts_.map(l => ({value: l.id, label: l.full_name})),
    getAddressFromsList: state => uuniqBy(state.addressFroms.map(l => ({value: l.id, label: l.address.short_address})), 'label'),
    getAddressTosList: state => uuniqBy(state.addressTos.map(l => ({value: l.id, label: l.address.short_address})), 'label'),
    getCompaniesList: state => state.companies.map(l => ({value: l.id, label: l.name})),
  },
  mutations: {
    UPDATE_GLOBAL_STATE(state, payload) {
      state[payload.field] = payload.value
    },
    UPDATE_BUSY_TABLE(state, value) {
      state.isBusyTable = value
    },
    UPDATE_ADDITIONAL_DATA_PAGE(state, value) {
      state.additionalData.page = value
    },
    SET_TABLE_FIELDS (state, data) {
      state.allTableFields = data
    },
    SET_CLIENT_ORDERS (state, data) {
      state.client_orders = data
    },
    SET_TABLE_LOCALIZED_FIELDS (state, fields) {
      state.localizedFields = fields
    },

    UPDATE_FILTER(state, payload) {
      state.filter[payload.field] = payload.value
    },
    CLEAR_FILTER(state) {
      /** Не очищать менеджера */
      const manager = state.filter.manager_id
      Object.assign(state.filter, filterState(), {manager_id: manager})
    },
  },
  actions: {
    async fetchTransporterOrders ({ getters, state }, payload = {}) {
      let args = {
        'page-limit': payload.perPage || getters.getState.additionalData.per_page || 30,
        'page': payload.page || getters.getState.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,

        'search[statuses]': state.filter.status,
        'search[id]': state.filter.id || null,
        'search[date_auto_free]': state.filter.date_auto_free || null,
        'search[address_froms]': state.filter.address_froms || null,
        'search[address_tos]': state.filter.address_tos || null,

        'search[driver_id]': state.filter.driver_id || null,
        'search[deal_id]': state.filter.deal_id || null,
        'search[order_client_id]': state.filter.order_client_id || null,
        'search[manager_id]': state.filter.manager_id || null,
      }

      if (state.filter.transporter_contact) {
        Object.assign(args, {'search[transporter_id]': state.filter.transporter_contact, 'search[transporter_type]': 'contacts'})
      }
      if (state.filter.transporter) {
        Object.assign(args, {'search[transporter_id]': state.filter.transporter, 'search[transporter_type]': 'companies'})
      }
      if (payload.localManager_) {
        Object.assign(args, {'search[manager_id]': state.localManager_})
      }

      return await orderApi.fetchTransporterOrders(args)
    },

    async deleteTransporterOrder({}, id) {
      const obj = {
        type: 'transporter',
        id: id
      }
      try {
        const data = await orderApi.deleteOrderById(obj)

        this._vm.$crmNotify.success(data.messages[0])

      } catch (e) {
        console.error(e)
      }
    },

    async GET_TRANSPORTER_ORDERS ({ commit, getters, dispatch }, payload = {}) {
      if (payload.manager_id) {
        commit('UPDATE_GLOBAL_STATE', {field: 'localManager_', value: payload.manager_id})
      }
      commit('UPDATE_BUSY_TABLE', true)

      try {
        const response = await dispatch('fetchTransporterOrders', payload)
        let {data} = response

        commit('SET_CLIENT_ORDERS', data.data)
        commit('SET_TABLE_LOCALIZED_FIELDS', getters.getState.localizedFields)
        commit('SET_TABLE_FIELDS', getters.getState.queryFields)

        await commit('UPDATE_GLOBAL_STATE', {
          field: 'additionalData',
          value: {
            from: data.from,
            to: data.to,
            last_page: data.last_page,
            per_page: data.per_page,
            total: data.total,
            page: data.current_page
          }
        })
      } catch(e) {
        console.error(e)
        this._vm.$crmNotify.error('Ошибка получения заказов!')
      } finally {
        commit('UPDATE_BUSY_TABLE', true)
      }

      commit('UPDATE_BUSY_TABLE', false)
    },

    /** Filters */
    async liveSearchOrderId({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await orderApi.getTransporterOrders(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'ordersList', value: data.data})
    },
    async liveSearchClientOrders({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await orderApi.getClientOrders(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'clientOrdersList', value: data.data})
    },
    async liveSearchContacts({commit}, payload) {
      const obj = {
        'search[full_name]': payload,
        'page-limit': -1
      }
      const {data} = await contactApi.getContacts(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'contacts_', value: data.data})
    },
    async liveSearchLocationsFrom({commit, dispatch}, payload) {
      const res = await dispatch('liveSearchLocation', payload)

      commit('UPDATE_GLOBAL_STATE', {field: 'addressFroms', value: res})
    },
    async liveSearchLocationsTo({commit, dispatch}, payload) {
      const res = await dispatch('liveSearchLocation', payload)

      commit('UPDATE_GLOBAL_STATE', {field: 'addressTos', value: res})
    },
    async liveSearchLocation({}, payload) {
      const obj = {
        'search[address][short_address]': payload,
        'page-limit': -1
      }
      const {data} = await locationApi.getLocations(obj)
      return data.data
    },
    async liveSearchCompanies({commit}, payload) {
      const obj = {
        'search[name]': payload,
        'page-limit': -1
      }
      const {data} = await companyApi.getCompanies(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'companies', value: data.data})
    },
    async liveSearchDeal({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await dealApi.getDeals(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'deals', value: data.data})
    },
    async liveSearchDriver({commit}, payload) {
      const obj = {
        'search[full_name]': payload,
        'page-limit': -1
      }
      const {data} = await driverApi.getDrivers(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'drivers', value: data.data})
    },
  },

}

export default TRANSPORTER_ORDER_INDEX
