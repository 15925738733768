import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	checkVehicle (params = {}) {
		return axios.get(`${HOST_URL}/opendatabot/full-vehicle`, {
			params: params
		})
	},
	setVehicleData (id) {
		return axios.get(`${HOST_URL}/vehicle/${id}`)
	},
	updateVehicle (payload, headers) {
		return axios.post(`${HOST_URL}/vehicle`, payload, headers)
	},
	updateVehicleById (id, payload) {
		return axios.post(`${HOST_URL}/vehicle/${id}`, payload)
	},
	getVehicles (payload = {}) {
		return axios.get(`${HOST_URL}/vehicle`, {
			params: payload
		})
	},
	getHistory (id) {
		return axios.get(`${HOST_URL}/logger/vehicle/${id}`)
	},
	getPermissibleLoad (vehicle_id, trailer_id = null) {
		const params = vehicle_id && trailer_id ? `${vehicle_id}/${trailer_id}` : `${vehicle_id}`

		return axios.get(`${HOST_URL}/vehicle-calculator/${params}`)
	}
}
