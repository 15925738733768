export default {
	getOrdersPayload: (state, getters) => ({
		...getters['filter/getFilterPayload'],
		page: state.page,
		'page-limit': state.per_page
	}),
	getUserContactId: (state, getters, rootState, rootGetters) => {
		const permission = rootGetters.getPermissions.indexOf('access to view all orders') !== -1;

		return rootGetters.getUser && !permission ? rootGetters.getUser.contact.id : false;
	}
}
