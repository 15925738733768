import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
  downloadMcComments(dealID) {
    return axios.get(`${HOST_URL}/mc/comments-to-excel/${dealID}`, {
      responseType: 'arraybuffer'
    })
  },
  storeMCComment (payload = {}) {
    return axios.post(`${HOST_URL}/deal-mc-comment`, payload)
  },
  getMCStatuses (payload = {
    'sorting[id]': 'asc'
  }) {
    return axios.get(`${HOST_URL}/mc-statuses`, {
      params: payload
    })
  },
  getMCDeals (payload = {}) {
    return axios.get(`${HOST_URL}/mc`, {
      params: payload
    })
  },
  getMCCommentsByDealID (dealID) {
    return axios.get(`${HOST_URL}/deal-mc-comment`, {
      params: {
        'page-limit': 999,
        filter: true,
        deal_id: dealID
      }
    })
  },
  getMCFields(payload) {
    return axios.get(`${HOST_URL}/get-mc-fields`, {
      params: payload
    })
  },
  getOnlineCall () {
    return axios.get(`${HOST_URL}/get-mc-list`)
  },
  getListOfCallTimes (date, hour) {
    return axios.get(`${HOST_URL}/list-of-call-times/${date}/${hour}`)
  },
  updatePriorityStatus(payload) {
    return axios.post(`${HOST_URL}/deal/priority-editing`, payload)
  },
  changeCrossdoc(id) {
    return axios.post(`${HOST_URL}/deal/crossdoc-editing`, {
      deal_id: id
    })
  }
}
