import Vue from 'vue'
import jsonToFormData from '@/mixins/jsonToFormData'
import router from '@/router'
import companyApi from '@/api/company'
import appendContractToFormData from '@/business-logic/form-data/contract-form-data'
import { getAvatarsFromFiles } from '@/business-logic/contact'
import INDEX_MODULE from '@/store/modules/CRM/company/index'
import FILTER_MODULE from '@/store/modules/CRM/company/filter'
import MULTI_EDIT_MODULE from '@/store/modules/CRM/company/multi-edit/index'
import stuffApi from '@/api/stuff'
import cargoApi from '@/api/cargo'
import { CLIENT_TYPE } from '@/config/company-type.config'
import CreditCardsAPI from '@/api/credit-cards'


const getArray = (item = []) => item || []
const prepareArrayOfIDs = (items = []) => getArray(items).map(item => ({
	id: item.id
}))
const _mapIterator = file => ({
	document_type_id: file.document_type
		? file.document_type.id
		: (file.document_type_id || null),
	weight: 0,
	file: file.fileObject || null,
	name: file.name || file.original_name || null,
	tmp_path: file.tmp_path || null
})

const reduceNewFilesInCompanyAccess = (memo, access) => {
	let file = null

	if (access && access.files && access.files.length) {
		file = {
			...access
		}
		file.files = access.files.filter(file => file && !file.id)
	}

	memo.push(file)

	return memo
}
const deleteFilesKey = (i) => {
	delete i.files

	return i
}

const DEFAULT_COMPANY_STATE = () => ({
	id: null,
	name: null,
	code: null,
	status: null,
	received_date: null,
	additionally: null,
	additional_activities: null,
	full_name: null,
	company_types: [],
	managers: [],
	portfolio_manager: null,
	contracts: [],
	activity: null,
	company_activity: null,
	deals_counter: 0,
	company_vehicles: [],
	is_not_resident: false,
	transportation_types: [],
	reviews: [],
	is_check_lawyer: false,
	is_blocked: false,
	subsidiaries: [],
	last_request: null,
	writer_contacts: [],

	phones: [],
	emails: [],
	company_access: [],
	locations: [],
	directions: [],
	contacts: [],
	company_requisites: [],
	cargo_types: [],
	dispatchers: [],
	company_vehicle_types: [],
	company_duplicates: [],
	credit_cards: []
})

const CRM_COMPANY = {
	namespaced: true,
	modules: {
		index: INDEX_MODULE,
		filter: FILTER_MODULE,
		multi_edit: MULTI_EDIT_MODULE
	},
	state: {
		id: null,
		company: DEFAULT_COMPANY_STATE(),
		processing: false
	},
	getters: {
		isFullCompany: (state) => Object.keys(state.company).length > Object.keys(DEFAULT_COMPANY_STATE()).length,
		getCompanyState: state => state.company,
		getStatutoryFiles: (state) => {
			const files = state.company.files || []

			return files.filter((f) => {
				if (f.document_type_id) {
					return +f.document_type_id === 1
				} else if (f.document_type) {
					return +f.document_type.id === 1
				}

				return false
			})
		},
		getContactPhoto: (state) => {
			const photos = getAvatarsFromFiles(state.company.files || [], 'company')

			if (photos.length > 0) {
				return photos[photos.length - 1]
			}

			return null
		},
		getCompanyReviews: state => (key) => {
			if (!state.company || !state.company.reviews) {
				return []
			}

			return state.company.reviews.filter(i => i.type === key)
		},
		getReviewsPositive: (state, getters) => getters.getCompanyReviews('positive'),
		getReviewsNegative: (state, getters) => getters.getCompanyReviews('negative'),
		isCompanyRegistered: (state, getters) => getters.getCompanyState && getters.getCompanyState.id &&
      !getters.getCompanyState.is_not_resident
			? getters.getCompanyState.status === 'зареєстровано'
			: true,
		getCurrentLogoUrl: (state, getters) => getters.getContactPhoto ? getters.getContactPhoto.link : null,
		getDefaultCargoType: (state) => {
			const defaultCargo = state.company.cargo_types.find(cargo => cargo.is_default)

			return defaultCargo || state.company.cargo_types[0]
		},
		companyIsNotClient: (state) => state.company.company_types.every(i => i && i.id !== CLIENT_TYPE)
	},
	mutations: {
		UPDATE_COMPANY_STATE (state, payload) {
			state.company[payload.field] = payload.value
		},
		SET_ACTIVE_COMPANY (state, payload) {
			Vue.set(state, 'company', payload)
		},
		SET_COMPANY_TYPES (state, payload) {
			state.company.company_types = payload
		},
		SET_NEW_SUBSCRIBER (state, payload) {
			state.company.writer_contacts.push(payload)
		},

		SET_LEGAL_ADDRESS (state, payload) {
			state.company.legal_address = payload
		},

		SET_PORTFOLIO_MANAGER (state, payload) {
			if (!payload.id) {
				state.company.portfolio_manager = null
			} else {
				state.company.portfolio_manager = { ...payload, ...state.company.portfolio_manager }
			}
		},

		SET_STATUTORY_FILES (state, payload = []) {
			Vue.set(state.company, 'files', payload)
		},

		DELETE_ITEM_BY_KEY (state, data) {
			if (!data.filtered) {
				state.company[data.field].splice(data.value, 1)
			} else {
				const idx = state.company[data.field].findIndex(
					i => i.id === data.filtered)

				if (idx !== undefined) {
					state.company[data.field].splice(idx, 1)
				}
			}
		},

		RESET_COMPANY_STATE (state) {
			state.company = DEFAULT_COMPANY_STATE()
		},
		UPDATE_COMPANY_PROCESSING_FLAG (state, value) {
			state.processing = value
		},
		UPDATE_AFTER_REFRESH (state, data) {
			state.company.name = data.name
			state.company.full_name = data.full_name
			state.company.code = data.code
			state.company.pdv_code = data.pdv_code
			state.company.pdv_status = data.pdv_status
			state.company.status = data.status
			state.company.received_date = data.received_date
			state.company.beneficiaries = data.beneficiaries
			state.company.activity = data.activity
			state.company.property_form = data.property_form
			state.company.requisite = data.requisite
			state.company.contacts = data.contacts
		},
		PUSH_FORWARDERS (state, payload) {
			if (!state.company.all_forwarders) {
				Vue.set(state.company, 'all_forwarders', [])
			}
			state.company.all_forwarders.push(payload)
		},
		PUSH_WAY_FORWARDERS (state, payload) {
			if (!state.company.forwarders) {
				Vue.set(state.company, 'forwarders', [])
			}
			state.company.forwarders.push(payload)
		},
		PUSH_DISPATCHERS (state, payload) {
			if (!state.company.dispatchers) {
				Vue.set(state.company, 'dispatchers', [])
			}
			state.company.dispatchers.push(payload)
		},
		MUTATION_ADD_REVIEW (state, review) {
			state.company.reviews.push(review)
		},
		UPDATE_COUNTRY (state, payload) {
			if (!state.company.directions) {
				Vue.set(state.company, 'directions', [])
			}
			if (payload.index !== null && payload.index >= 0) {
				Vue.set(state.company.directions, payload.index, payload.value)
			} else {
				state.company.directions.push(payload.value)
			}
		},
		UPDATE_REQUISITES (state, payload) {
			if (payload.index >= 0 && payload.value.is_default && state.company.company_requisites) {
				state.company.company_requisites.forEach(requisite => {
					if (requisite.is_default) {
						Vue.set(requisite, 'is_default', false)
					}
				})
			}
			if (!state.company.company_requisites) {
				Vue.set(state.company, 'company_requisites', [])
			}
			if (payload.index !== null && payload.index >= 0) {
				Vue.set(state.company.company_requisites, payload.index, payload.value)
			} else {
				state.company.company_requisites.push(payload.value)
			}
		},
		UPDATE_CONTRACTS (state, payload) {
			if (!state.company.contracts) {
				Vue.set(state.company, 'contracts', [])
			}
			if (payload.index !== null && payload.index >= 0) {
				Vue.set(state.company.contracts, payload.index, payload.value)
			} else {
				state.company.contracts.push(payload.value)
			}
		},
		UPDATE_LOCATIONS (state, payload) {
			if (!state.company.locations) {
				Vue.set(state.company, 'locations', [])
			}
			const idIdx = state.company.locations.findIndex(
				i => i.id === payload.value.id)
			if (idIdx > -1) {
				Vue.set(state.company.locations, idIdx, payload.value)
			} else {
				state.company.locations.push(payload.value)
			}
		},
		UPDATE_VEHICLE_TYPES (state, payload) {
			const newVehicleType = {
				id: state.company.company_vehicle_types[payload.index]
					? state.company.company_vehicle_types[payload.index].id
					: null,
				capacity: payload.capacity,
				volume: payload.volume,
				vehicle_type: {
					id: payload.id,
					name: payload.name
				}
			}
			if (payload.index || payload.index === 0) {
				Vue.set(state.company.company_vehicle_types, payload.index, newVehicleType)
			} else {
				state.company.company_vehicle_types.push(newVehicleType)
			}
		},
		SET_COMPANY_VEHICLES (state, payload) {
			Vue.set(state.company, 'company_vehicles', payload)
		},
		PUSH_COMPANY_RELATION_DATA_BY_KEY (state, payload) {
			if (!state.company[payload.field]) {
				state.company[payload.field] = []
			}
			state.company[payload.field].push(payload.value)
		},
		PUSH_TO_FILES (state, file) {
			state.company.files.push(file)
		},
		UPDATE_EMAILS (state, payload) {
			if (payload.index !== null) {
				Vue.set(state.company.emails, payload.index, payload.value)
			} else {
				state.company.emails.push(payload.value)
			}
		},
		UPDATE_COMPANY_ACCESS (state, payload) {
			if (payload.index !== null) {
				Vue.set(state.company.company_access, payload.index, payload.value)
			} else {
				state.company.company_access.push(payload.value)
			}
		},
		UPDATE_CARGO_TYPES (state, payload) {
			if (payload.index !== null) {
				Vue.set(state.company.cargo_types, payload.index, payload.value)
			} else {
				state.company.cargo_types.push(payload.value)
			}
		},
		UPDATE_COMPANY_CONTRACTS (state, payload) {
			if (payload.index !== null) {
				Vue.set(state.company.contracts, payload.index, payload.value)
			} else {
				state.company.contracts.push(payload.value)
			}
		},
		DELETE_RELATION (state, payload) {
			state.company[payload.key].splice(payload.index, 1)
		},
		PUSH_TO_MANAGERS (state, payload) {
			state.company.managers.push(payload)
		},
		PUSH_TO_CARDS (state, payload) {
			state.company.credit_cards.push(payload)
		},
		UPDATE_CREDIT_CARD (state, payload) {
			const index = state.company.credit_cards.findIndex(card => card.id === payload.id)
			if (index !== -1) {
				Vue.set(state.company.credit_cards, index, payload.data)
			}
		},
		UPDATE_SUBSCRIBER (state, id) {
			state.company.writer_contacts.forEach(el => {
				el.default = el.id === id;
			})
		},
		PUSH_TO_CONTACTS (state, payload) {
			if (!state.company.contacts || !state.company.contacts.length) {
				Vue.set(state.company, 'contacts', [])
			}
			if (payload && Array.isArray(payload)) {
				state.company.contacts.push(...payload)
			} else {
				state.company.contacts.push(payload)
			}
		},
		UPDATE_OR_PUSH_BY_MODEL (state, {
			file,
			index,
			model,
			modelIndex
		}) {
			if (index === null || index === undefined) {
				const files = state.company[model][modelIndex].files

				if (!files) {
					state.company[model][modelIndex].files = []
				}

				state.company[model][modelIndex].files.push(file)
			} else {
				Vue.set(state.company[model][modelIndex].files, index, file)
			}
		},
		DELETE_BY_MODEL (state, {
			index,
			model,
			modelIndex
		}) {
			Vue.delete(state.company[model][modelIndex].files, index)
		},
		SET_FILES_TO_MODEL(state, {model, files, id}) {
			const modelIdx = state.company[model].findIndex(m => m.id === id)

			if (modelIdx !== -1) {
				Vue.set(state.company[model][modelIdx], 'files', files)
			}
		}
	},
	actions: {
		async findByInn ({
			commit,
			state
		}, force = false) {
			let company
			try {
				await commit('UPDATE_COMPANY_PROCESSING_FLAG', true)
				const { data } = await companyApi.getCompanyDataByInn({
					inn: state.company.code,
					force
				})

				if (data.length > 0) {
					company = data[0]
				} else {
					company = data
				}

				// commit('SET_ACTIVE_COMPANY', company)
				//! Return back if need

				if (company.last_request) {
					commit('UPDATE_COMPANY_STATE', {
						field: 'last_request',
						value: company.last_request
					})
				}

				if (company.legal_address) {
					await commit('UPDATE_COMPANY_STATE', {
						field: 'legal_address',
						value: company.legal_address
					})
				}

				if (company.writer_contacts) {
					commit('UPDATE_COMPANY_STATE', {
						field: 'writer_contacts',
						value: company.writer_contacts
					})
				}

				if (company.additional_activities) {
					commit('UPDATE_COMPANY_STATE', {
						field: 'additional_activities',
						value: company.additional_activities
					})
				}

				if (!force) {
					await commit('UPDATE_COMPANY_STATE', {
						field: 'id',
						value: company.id
					})
				} else {
					await commit('UPDATE_AFTER_REFRESH', company)
				}
			} catch (err) {
				if (err.response && err.response.data.errors &&
          err.response.data.errors.company) {
					const company = err.response.data.errors.company
					commit('UPDATE_COMPANY_STATE', {
						field: 'status',
						value: company.status
					})
					commit('UPDATE_COMPANY_STATE', {
						field: 'id',
						value: company.id
					})
					commit('UPDATE_COMPANY_STATE', {
						field: 'last_request',
						value: company.last_request
					})
				}
				if (err.response && err.response.data.errors &&
          err.response.data.errors.id) {
					commit('UPDATE_COMPANY_STATE', {
						field: 'id',
						value: err.response.data.errors.id
					})
				}
			} finally {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', false)
			}
		},
		async setCompanyBlockedStatus ({getters, commit}) {
			try {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', true)

				await companyApi.createOrUpdateCompany({
					id: getters.getCompanyState.id,
					is_blocked: getters.getCompanyState.is_blocked
				})

				this._vm.$crmNotify.success(
					getters.getCompanyState.is_blocked
						? 'Вы добавили компанию в черный список'
						: 'Вы удалили компанию с черного списка'
				)
			} catch (e) {
				this._vm.$crmNotify.warning('Whoopse! не получилось, пожалуйста обратитесь в IT отдел!' + e)
				window.console.error(e)
			} finally {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', false)
			}
		},
		async fetchEditingCompany ({
			commit,
			dispatch,
			getters
		}, id) {
			try {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', true)

				const { data } = await companyApi.getCompanyDataByID(id, {
					'request-type': 'company'
				})

				commit('SET_ACTIVE_COMPANY', data)

				if (getters.isFullCompany) {
					dispatch('fetchCompanyVehicles')
				}
			} catch (e) {
				window.console.error(e)

				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения данных из БД!',
					message: e
				})
			} finally {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', false)
			}
		},
		async onSaveCompany ({
			commit,
			getters
		}, payload = {}) {
			const needToRedirect = payload.needToRedirect === undefined
				? true
				: payload.needToRedirect

			const autoFillableFields = [
				'id',
				'name',
				'code',
				'pdv_code',
				'pdv_status',
				'status',
				'received_date',
				'description',
				'balance',
				'is_our',
				'is_check_lawyer',
				'contractor_transport_department',
				'is_access_for_all',
				'additionally',
				'beneficiaries',
				'additional_activities',
				'review_required',
				'is_not_resident',
				'company_access',
				'portfolio_manager',
				'property_form',
				'activity',
				'phones',
				'emails',
				'parent',
				'child',
				'legal_address',
				'is_blocked',
				'cargo_types',
				'directions',
				'locations',
				'credit_cards'
			]

			const vehicle = {
				full_name: getters.getCompanyState.name || getters.getCompanyState.full_name,
				company_types: prepareArrayOfIDs(getters.getCompanyState.company_types),
				contacts: prepareArrayOfIDs(getters.getCompanyState.contacts),
				managers: prepareArrayOfIDs(getters.getCompanyState.managers),
				directions: getArray(getters.getCompanyState.directions).map(country => ({
					id: country.id,
					address: { id: country.address.id }
				})),
				transportation_types: prepareArrayOfIDs(getters.getCompanyState.transportation_types),
				company_vehicle_types: getArray(getters.getCompanyState.company_vehicle_types)
					.map(company_vehicle_type => {
						const vehicleTypePayload = {
							capacity: company_vehicle_type.capacity,
							volume: company_vehicle_type.volume,
							vehicle_type_id: company_vehicle_type.vehicle_type.id
						}
						if (company_vehicle_type.id) {
							return {
								id: company_vehicle_type.id,
								...vehicleTypePayload
							}
						}

						return vehicleTypePayload
					})
			}

			autoFillableFields.forEach((key) => {
				vehicle[key] = getters.getCompanyState[key]
			})

			try {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', true)

				const companyAccessNewFiles = getArray(getters.getCompanyState.company_access)
					.reduce(reduceNewFilesInCompanyAccess, [])

				vehicle.company_access = getArray(vehicle.company_access).map(deleteFilesKey)

				const vehicleFormData = jsonToFormData(vehicle, {
					includeNullValues: true,
					fieldsForNullValue: ['portfolio_manager']
				})

				if (!getters.getCompanyState.id) {
					appendContractToFormData(
						getters.getCompanyState.contracts,
						vehicleFormData
					)
				}

				companyAccessNewFiles.forEach((access, index) => {
					if (access && access.files) {
						const files = access.files.map(_mapIterator)

						files.forEach((f, i) => {
							vehicleFormData.append(
								`company_access[${index}][files][${i}][document_type_id][]`,
								f.document_type_id)
							vehicleFormData.append(
								`company_access[${index}][files][${i}][weight][]`, f.weight)
							vehicleFormData.append(
								`company_access[${index}][files][${i}][files][]`, f.file)
						})
					}
				})

				const filesFormAppend = (val, index, keys) => {
					keys.forEach((k) => {
						if (!val[k]) {
							return
						}
						const path = `files[${index}][${k}][]`
						let value = val[k]

						if (val.tmp_path) {
							if (k === 'files') {
								value = ''
							}
							if (k === 'tmp_path') {
								vehicleFormData.append(`files[${index}][name]`, val.name)
								vehicleFormData.append(`files[${index}][tmp_path]`,
									val.tmp_path)

								return
							}
						}

						vehicleFormData.append(path, value)
					})
				}

				const allFiles = getters.getCompanyState.files || []
				allFiles
					.filter(f => f.fileObject || f.tmp_path || f.newlyAdded)
					.map(_mapIterator).forEach((value, index) => {
						if (value.tmp_path) {
							filesFormAppend(
								value,
								index,
								['document_type_id', 'weight', 'files', 'tmp_path']
							)
						}
					})

				const data = await companyApi.createOrUpdateCompany(vehicleFormData)

				if (data.code === 200) {
					commit('SET_ACTIVE_COMPANY', data.data)

					if (payload.cb) {
						payload.cb()
					}

					if (needToRedirect) {
						await router.push({ name: 'CRM.COMPANY.INDEX' })
					}

					this._vm.$notify({
						type: 'success',
						text: 'Компания успешно сохранена!'
					})

					return data.data
				}

				return {}
			} catch (e) {
				if (e.response.data.data) {
					commit('UPDATE_COMPANY_STATE', {
						field: 'company_duplicates',
						value: e.response.data.data
					})
				}
				window.console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Компания не сохранена!'
				})
			} finally {
				commit('UPDATE_COMPANY_PROCESSING_FLAG', false)
			}
		},
		async updateCompanyFields ({
			getters
		}, payload = {}) {
			let company = {
				id: getters.getCompanyState.id
			}

			const data_ = getters.getCompanyState[payload.field]
				? getters.getCompanyState[payload.field]
				: []
			company = {
				...company,
				[payload.field]: [...data_, payload.value].map(i => ({ id: i.id }))
			}

			const companyFormData = jsonToFormData(company, {
				includeNullValues: true,
				fieldsForNullValue: [
					'all_forwarders',
					'dispatchers'
				]
			})

			try {
				await companyApi.createOrUpdateCompany(companyFormData,
					{
						'Content-Type': 'multipart/form-data'
					})

				this._vm.$notify({
					type: 'success',
					text: 'Компания успешно сохранена!'
				})

				return true
			} catch (e) {
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка закрепления!',
					message: e
				})

				return false
			}
		},

		async fetchCompanyVehicles ({
			getters,
			commit
		}) {
			try {
				const response = await companyApi.getCompanyVehicles(
					getters.getCompanyState.id)

				if (response && response.data) {
					commit('SET_COMPANY_VEHICLES', response.data)
				}
			} catch (e) {
				window.console.error(e)

				this._vm.$crmNotify('Ошибка получение транспорта у компании! ' + e)
			}
		},
		async attachSubsidiary ({
			state,
			commit
		}, payload) {
			try {
				if (state.company.id) {
					await companyApi.addSubsidiary({
						parent_id: state.company.id,
						child_id: payload.id
					})
				}
			} catch (e) {
				window.console.error(e)
				this._vm.$crmNotify.warning(
					'Ошибка сохранения дочерней связи! Пожалуйста, сохраните карточку полностью! ' +
          e)
			} finally {
				commit('PUSH_COMPANY_RELATION_DATA_BY_KEY', {
					field: 'subsidiaries',
					value: payload
				})
			}
		},
		resetCompanyState ({ commit }) {
			commit('RESET_COMPANY_STATE')
		},

		async savePhoneWithFormer ({ state }, phone) {
			try {
				await companyApi.updateCompanyPhone({
					phone_id: phone.id,
					company_id: state.company.id,
					former: phone.former
				})
			} catch (e) {
				window.console.error(e)

				this._vm.$crmNotify.warning('Ошибка сохранения телефона у компании!', e)
			}
		},

		async updateCompanyLogo ({ commit }, file) {
			try {
				const response = await stuffApi.makeTempFile({ file })

				if (response && response.data) {
					commit('PUSH_TO_FILES', {
						document_type: { id: 21 },
						weight: 0,
						original_name: response.data.name || file.name || null,
						newlyAdded: true,
						name: response.data.name || file.name || null,
						tmp_path: response.data.tmp_path ? response.data.tmp_path : null,
						base64: response.data.base64 ? response.data.base64 : null,
						file,
						fileObject: file
					})
				}
			} catch (e) {
				window.console.error(e)
				this._vm.$crmNotify.warning('Ошибка загрузки аватара!' + e)
			}
		},

		updateCompanyEmails ({ commit }, payload) {
			commit('UPDATE_EMAILS', payload)
		},
		deleteCompanyEmail ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'emails',
				index
			})
		},
		updateCompanyAccessLink ({ commit }, payload) {
			commit('UPDATE_COMPANY_ACCESS', payload)
		},
		deleteCompanyAccessLink ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'company_access',
				index
			})
		},
		updateCompanyContracts ({ commit }, payload) {
			commit('UPDATE_COMPANY_CONTRACTS', payload)
		},
		deleteCompanyContracts ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'contracts',
				index
			})
		},
		deleteCompanyPhones ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'phones',
				index
			})
		},
		pushToContacts ({commit}, payload) {
			commit('PUSH_TO_CONTACTS', payload)
		},
		pushOnlyUnique ({state, commit}, payload = []) {
			if (payload && payload.length) {
				payload.forEach(contact => {
					if (!state.company.contacts.map(c => c.id).includes(contact.id)) {
						commit('PUSH_TO_CONTACTS', contact)
					}
				})
			}
		},
		async updateCompanyCargoTypes ({ state, commit }, payload) {
			/**
       * This kostil exists with COmpanyCargoTypeModal
       * TODO fix this modal and shit below
       * TODO пусть Артем фиксит (upd: Nope!)
       * TODO Ваня пофисил
       */
			const data = {
				amount: payload.value.amount,
				is_default: payload.value.is_default,
				min_capacity: payload.value.min_capacity,
				max_capacity: payload.value.max_capacity,
				min_volume: payload.value.min_volume,
				max_volume: payload.value.max_volume,
				vehicle_type_id: payload.value.vehicle_type_id ? payload.value.vehicle_type_id.value : null,
				loading_types: payload.value.loading_types.length ? payload.value.loading_types : null,
				cargo_type_id: payload.value.cargo_type.value,
				company_id: state.company.id,
				id: payload.value.id
			}
			if (payload.value.loading_type_id) {
				data.loading_types = payload.value.loading_type_id
			} else {
				data.loading_type_id = null
			}

			try {
				if (state.company && state.company.id && !payload.value.id) {
					const response = await cargoApi.createCargoType(data)
					commit('UPDATE_CARGO_TYPES', { index: payload.index, value: { ...response.data } })
				}
				if (payload.value.id && state.company.id) {
					const response = await cargoApi.editCargoType(data, { _method: 'PUT' })
					commit('UPDATE_CARGO_TYPES', { index: payload.index, value: { ...response.data } })
				}
				if (!state.company.id) {
					const response = await cargoApi.createCargoType(data)
					commit('UPDATE_CARGO_TYPES', { index: payload.index, value: { ...response.data } })
				}
			} catch (e) {
				window.console.error(e)
				this._vm.$crmNotify.warning('Возникла ошибка' + e)
			}
		},
		pushContactToManagers ({ commit }, payload) {
			commit('PUSH_TO_MANAGERS', payload)
		},
		async deleteResponsible ({state}, index) {
			try {
				if (state.company.id && index >= 0 && state.company.managers[index]) {
					await stuffApi.deleteResponsible(
						state.company.managers[index].id,
						'companies',
						state.company.id
					)
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка удаления ответственного!' + e)
			}
		},
		async addResponsible ({state}, contactId) {
			try {
				if (contactId && state.company.id) {
					await stuffApi.addResponsible({
						contact_id: contactId,
						type: 'companies',
						id: state.company.id
					})
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка добавления ответственного!' + e)
			}
		},
		async addSubscriber ({commit, state, dispatch}, payload) {
			try {
				const { data } = await companyApi.addNewSubscriber({
					company_writer: true,
					company_id: state.company.id,
					writer_id: payload.writerId,
					default: payload.isDefault
				})
				commit('SET_NEW_SUBSCRIBER', data)
				if (payload.isDefault) {
					dispatch('updateSubscribersData', payload.writerId)
				}
				this._vm.$crmNotify.success(' Подписант успешно добавлен!')
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка при добавлении подписанта!')
				window.console.error(e)
			}
		},
		async updateSubscriber ({state, dispatch}, payload) {
			try {
				await companyApi.updateSubscriber({
					company_id: state.company.id,
					writer_id: payload.writerId
				})
				dispatch('updateSubscribersData', payload.writerId)
			} catch (e) {
				this._vm.$crmNotify.error('Ошибка обновления подписанта!')
				window.console.error(e)
			}
		},
		 updateSubscribersData ({commit}, writerId) {
			commit('UPDATE_SUBSCRIBER', writerId)
		},
		async addCreditCard ({ commit }, payload) {
			try {
				const { data } = await CreditCardsAPI.createCreditCard(payload)
				commit('PUSH_TO_CARDS', data)

			} catch (e) {
				this._vm.$crmNotify.error('Ошибка добавления карты!')
				window.console.error(e)
			}
		},
		async updateCreditCard ({commit}, payload) {
			try {
				const { data } = await CreditCardsAPI.updateCreditCard(payload.id, payload)
				if (payload.forceUpdate) {
					commit('PUSH_TO_CARDS', data)
				} else {
					commit('UPDATE_CREDIT_CARD', {id: payload.id, data: data})
				}
			} catch (e) {
				this._vm.$crmNotify.error('Ошибка обновления карты!')
				window.console.error(e)
			}
		},
		resetCompanyDuplicates ({commit}) {
			commit('UPDATE_COMPANY_STATE', {
				field: 'company_duplicates',
				value: []
			})
		},
		addFileToModel ({state, commit}, {file, index, model, modelId}) {
			const modelIndex = state.company[model].findIndex(m => m.id === modelId)

			if (modelIndex === -1) {
				return false
			}

			commit('UPDATE_OR_PUSH_BY_MODEL', {
				file,
				index,
				model,
				modelIndex
			})
		},
		removeFileFromModel ({state, commit}, {file, index, model, modelId}) {
			const modelIndex = state.company[model].findIndex(m => m.id === modelId)

			if (modelIndex === -1) {
				return false
			}

			commit('DELETE_BY_MODEL', {
				file,
				index,
				model,
				modelIndex
			})
		}
	}
}

export default CRM_COMPANY
