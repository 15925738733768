import helperApi from '@/api/helper'

const defaultState = () => ({
	vehicleTypes: [],
	loadingTypes: [],
	vehicleCategories: [],
	vehicleTypeCommunication: [],
	brandVehicleFind: []
})

const VEHICLE_HELPER = {
	namespaced: true,
	state: defaultState(),
	getters: {},
	mutations: {
		SET_VEHICLE_TYPES (state, payload) {
			state.vehicleTypes = payload
		},
		SET_LOADING_TYPES (state, payload) {
			state.loadingTypes = payload
		},
		SET_VEHICLE_CATEGORIES (state, payload) {
			state.vehicleCategories = payload
		},
		SET_VEHICLE_TYPE_COMMUNICATION_LIST (state, payload) {
			state.vehicleTypeCommunication = payload
		},
		SET_VEHICLE_BRAND_FINDER (state, payload) {
			state.brandVehicleFind = payload
		}
	},
	actions: {

		async getVehicleAdditionalData ({ commit }) {
			try {
				const { data } = await helperApi.getVehicleAdditionalData()

				if (data) {
					commit('SET_LOADING_TYPES', data['loading-type'])
					commit('SET_VEHICLE_TYPES', data['vehicle-type'])
					commit('SET_VEHICLE_CATEGORIES', data['vehicle-type-category'])
					commit('SET_VEHICLE_TYPE_COMMUNICATION_LIST', data['vehicle-type-communication'])
				}

			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка получения данных! ' + e)
			}

		},

		async fetchLoadingTypes ({ commit }) {
			try {
				const response = await helperApi.loadingTypes()

				if (response && response.data) {
					commit('SET_LOADING_TYPES', response.data.data)
				}
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка получения данных! ' + e)
			}

			return this
		},
		async fetchVehicleTypes ({ commit }) {
			try {
				const response = await helperApi.vehicleTypes()

				if (response && response.data) {
					commit('SET_VEHICLE_TYPES', response.data.data)
				}
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка получения данных! ' + e)
			}

			return this
		},
		async fetchVehicleCategories ({ commit }) {
			try {
				const response = await helperApi.vehicleCategories()

				if (response && response.data) {
					commit('SET_VEHICLE_CATEGORIES', response.data.data)
				}
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка получения данных! ' + e)
			}

			return this
		},
		async fetchCommunicationTypes ({ commit }) {
			try {
				const response = await helperApi.vehicleTypeCommunication()

				if (response && response.data) {
					commit('SET_VEHICLE_TYPE_COMMUNICATION_LIST', response.data.data)
				}
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка получения данных! ' + e)
			}

			return this
		},
		async onSearchVehicleBrands ({
			commit,
			state
		}, query = null) {
			if (!query) {
				return []
			}

			try {
				const response = await helperApi.findVehicleBrand({
					'search[name]': query
				})

				const data = response && response.data ? response.data.data : []

				if (response && response.data) {
					console.log(response.data)
					commit('SET_VEHICLE_BRAND_FINDER', data)
				}

				return data
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка поиска марок машин! ' + e)
			}
		}
	}
}

export default VEHICLE_HELPER
