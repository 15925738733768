import Vue from 'vue'
import jsonToFormData from '@/mixins/jsonToFormData'
import dealApi from '@/api/deal'
import locationApi from '@/api/location'
import mcApi from '@/api/mc'
import { convertDate, formats } from '@/helpers/date'

const tableData = () => ({
	data: [],
	tableFields: localStorage.getItem('mc-table-fields') ? JSON.parse(localStorage.getItem('mc-table-fields')) : [],
	localizedFields: [],
	additionalData: {
		per_page: 100,
		page: 1,
		total: 0,
		last_page: 0
	},
	sortBy: null,
	sortDesc: null,
	isBusyTable: false
})
const filterState = () => ({
	filtered: false,
	isLoading: false,
	liveSearch: null,
	monitoringPriorityID: null,
	statusID: null,
	loadingFrom: null,
	loadingTo: null,
	notOnControl: false,
	not_on_control_range_id: null
})
const dealState = () => ({
	isLoading: false,
	defaultDeal: null,
	editingData: {
		insetID: null,

		// date_loading: null,
		// date_loading_departure: null,
		// date_unloading: null,

		// comment obj
		commentStatusID: null,
		commentSubStatusID: null,
		commentDescription: null,
		commentNextCallDate: null,
		commentCurrentLocation: null,
		commentCurrentLocationID: null,
		commentProcessing: false,
		// loadingPoints: [],
		// unloadingPoints: [],

		commentValidator_: {}
	},
	routeDistance: null,
	routeDuration: null,
	routePointsInfo: []
})
const helpersState = () => ({
	statuses: null,
	isVisibleSubStatuses: false
})
const commentsTableState = () => ({
	isLoading: false,
	data: []
})
const defaultState = () => ({
	tableData: tableData(),
	filter: filterState(),
	deal: dealState(),
	helpers: helpersState(),
	commentsTable: commentsTableState()
})

const MC_MODULE = {
	namespaced: true,
	state: defaultState(),
	getters: {
		filterState: state => state.filter,
		tableState: state => state.tableData,
		dealState: state => state.deal,
		editingState: state => state.deal.editingData,
		helpersState: state => state.helpers,
		commentsTableState: state => state.commentsTable,
		getSelectedStatusName: (state, getters) => {
			if (!getters.editingState.commentStatusID) {return null}

			return getters.helpersState.statuses.find(
				status => status.id === getters.editingState.commentStatusID).name
		},
		getSubStatuses: (state, getters) => {
			if (!getters.editingState.commentStatusID) {return []}

			return getters.helpersState.statuses.find(
				status => status.id === getters.editingState.commentStatusID).children
		}
	},
	mutations: {
		UPDATE_FILTER_STATE (state, payload) {
			state.filter[payload.field] = payload.value
		},
		UPDATE_TABLE_STATE (state, payload) {
			state.tableData[payload.field] = payload.value
		},
		UPDATE_ADDITIONAL_DATA (state, payload) {
			Vue.set(state.tableData, 'additionalData', payload)
		},
		SET_TABLE_PAGE (state, page) {
			Vue.set(state.tableData.additionalData, 'page', page)
		},
		UPDATE_DEAL_STATE (state, payload) {
			state.deal[payload.field] = payload.value
		},
		UPDATE_EDITING_DEAL_DATA (state, payload) {
			state.deal.editingData[payload.field] = payload.value
		},
		UPDATE_COMMENTS_TABLE_STATE (state, payload) {
			Vue.set(state.commentsTable, payload.field, payload.value)
		},
		RESET_EDITING_COMMENT_STATE (state) {
			state.deal.editingData.commentDescription = null
			state.deal.editingData.commentNextCallDate = null
			state.deal.editingData.commentCurrentLocation = null
			state.deal.editingData.commentCurrentLocationID = null
			state.deal.editingData.commentValidator_ = {}
		},
		UPDATE_HELPERS_STATE (state, payload) {
			state.helpers[payload.field] = payload.value
		},
		SET_ACTIVE_STATUS (state, statusID) {
			state.deal.editingData.commentStatusID = statusID
			state.helpers.isVisibleSubStatuses = true
		},
		SET_ACTIVE_SUBSTATUS (state, statusID) {
			state.deal.editingData.commentSubStatusID = statusID
			if (!statusID) {
				state.helpers.isVisibleSubStatuses = false
			}
		},
		RESET_MC_DEAL_STATE (state) {
			const s = dealState()
			Object.keys(s).forEach(key => {
				Vue.set(state.deal, key, s[key])
			})
		},
		RESET_FILTER_STATE (state) {
			Vue.set(state, 'filter', filterState())
		},
		RESET_MC_COMMENTS_FORM (state) {
			state.deal.editingData.commentStatusID = null
			state.deal.editingData.commentSubStatusID = null
			state.deal.editingData.commentDescription = null
			state.deal.editingData.commentNextCallDate = null
			state.deal.editingData.commentCurrentLocation = null
			state.deal.editingData.commentCurrentLocationID = null
			state.helpers.isVisibleSubStatuses = false
		},
		RESET_COMMENTS_TABLE (state) {
			const s = commentsTableState()
			Object.keys(s).forEach(key => {
				Vue.set(state.commentsTable, key, s[key])
			})
		},
		SET_CROSSDOC_FLAG (state, payload) {
			Vue.set(state.deal.defaultDeal, 'is_crossdoc', payload)
		}
	},
	actions: {
		async fetchDeal ({ commit, dispatch }, dealID) {
			await commit('RESET_MC_DEAL_STATE')

			if (!dealID) {return false}

			try {
				await commit('UPDATE_DEAL_STATE', {
					field: 'isLoading',
					value: true
				})

				const data = await dispatch('CRM_DEAL/fetchEditingDeal', { id: dealID, mc: true }, {root: true})
				const locationsFromCompany = await locationApi.getLocationsByTypeID({
					type: data.client_type,
					type_id: data.client.id
				})
				commit('CRM_DEAL/UPDATE_CLIENT_LOCATIONS', locationsFromCompany.data, {root: true})

				await commit('UPDATE_DEAL_STATE', {
					field: 'defaultDeal',
					value: data
				})

				// await commit('UPDATE_EDITING_DEAL_DATA', {
				//   field: 'loadingPoints',
				//   value: data.point_loadings
				// })
				// await commit('UPDATE_EDITING_DEAL_DATA', {
				//   field: 'unloadingPoints',
				//   value: data.point_unloading
				// })

				await commit('UPDATE_EDITING_DEAL_DATA', {
					field: 'insetID',
					value: data.inset ? data.inset.id : null
				})
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения сделки!'
				})
			} finally {
				await commit('UPDATE_DEAL_STATE', {
					field: 'isLoading',
					value: false
				})
			}
		},
		// async updateDealDateByKey ({ commit, getters }, {key, date}) {
		//   try {
		//     const buildDealData = () => ({
		//       id: getters.dealState.defaultDeal.id,
		//       [key]: date ? date : getters.editingState[key]
		//     })
		//
		//     await api.editDealById(await buildDealData())
		//
		//     this._vm.$notify({
		//       type: 'success',
		//       text: 'Дата успешно изменена!'
		//     })
		//   } catch (e) {
		//     console.error(e)
		//     this._vm.$notify({
		//       type: 'error',
		//       text: 'Ошибка редактирования даты!'
		//     })
		//   }
		// },

		async updateDealInset ({ getters }, payload) {
			try {
				await dealApi.editDealById({
					id: getters.dealState.defaultDeal.id,
					inset: payload ? { id: payload } : null
				})

				this._vm.$notify({
					type: 'success',
					text: 'Вкладка успешно закреплена за сделкой!'
				})
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка сохранения вкладки в сделке!'
				})
			}
		},
		async submitComment ({ commit, getters, rootGetters, dispatch }) {
			try {
				await commit('UPDATE_EDITING_DEAL_DATA', {
					field: 'commentProcessing',
					value: true
				})

				let nextCallDate = null
				if (getters.editingState.commentNextCallDate) {
					nextCallDate = convertDate(
						getters.editingState.commentNextCallDate,
						'YYYY-MM-DD HH:mm',
						formats.DEFAULT_DATE_TIME_FORMAT
					)
				}

				const args = {
					deal: { id: getters.dealState.defaultDeal.id },
					status: {id: getters.editingState.commentStatusID},
					substatus:{id:  getters.editingState.commentSubStatusID},
					comment: getters.editingState.commentDescription,
					date_next_call: nextCallDate,
					location: { id: getters.editingState.commentCurrentLocationID },
					author: { id: rootGetters.getUser.contact.id }
				}

				await mcApi.storeMCComment(args)

				await commit('RESET_EDITING_COMMENT_STATE')
				await commit('SET_ACTIVE_STATUS', null)
				await commit('SET_ACTIVE_SUBSTATUS', null)
				await commit('RESET_COMMENTS_TABLE')

				await dispatch('fetchCommentsByDeal')
				await dispatch('fetchDealsForMonitoring')

				this._vm.$notify({
					type: 'success',
					text: 'Комментарий успешно сохранен!'
				})
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка сохранения комментария!'
				})
			} finally {
				await commit('UPDATE_EDITING_DEAL_DATA', {
					field: 'commentProcessing',
					value: false
				})
			}
		},
		async fetchMCStatuses ({ commit }) {
			try {
				const { data } = await mcApi.getMCStatuses()

				await commit('UPDATE_HELPERS_STATE', {
					field: 'statuses',
					value: data
				})
			} catch (e) {
				console.error(e)

				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения статусов мониторинга!'
				})
			}
		},
		async onFilterDeals ({ commit, dispatch }, dontShowClearBtn = true) {
			try {
				await commit('UPDATE_FILTER_STATE', {
					field: 'filtered',
					value: dontShowClearBtn
				})
				await commit('UPDATE_FILTER_STATE', {
					field: 'isLoading',
					value: true
				})

				await dispatch('fetchDealsForMonitoring')
			} catch (e) {
				console.error(e)
			} finally {
				await commit('UPDATE_FILTER_STATE', {
					field: 'isLoading',
					value: false
				})
			}
		},
		async fetchDealsForMonitoring ({ commit, getters }, payload = {}) {
			try {
				await commit('UPDATE_TABLE_STATE', {
					field: 'isBusyTable',
					value: true
				})

				if (payload.sortBy) {
					await commit('UPDATE_TABLE_STATE', {
						field: 'sortBy',
						value: payload.sortBy
					})
				}
				if (payload.sortDesc !== null && payload.sortDesc !== undefined) {
					await commit('UPDATE_TABLE_STATE', {
						field: 'sortDesc',
						value: payload.sortDesc
					})
				}

				const args = {
					page: payload.page || getters.tableState.additionalData.page || 1,
					'page-limit': payload.perPage || getters.tableState.additionalData.per_page || 1,
					'global-search': getters.filterState.liveSearch || null,
					'filter[monitoring_priority_id]': getters.filterState.monitoringPriorityID || null,
					'filter[last_status_id]': getters.filterState.statusID || null,
					fields: getters.tableState.tableFields.join(',') || [],
					'loading-from': getters.filterState.loadingFrom || null,
					'loading-to': getters.filterState.loadingTo || null,
					discontinued: getters.filterState.notOnControl || null,
					'date-range-id': getters.filterState.not_on_control_range_id || null
				}

				if (getters.tableState.sortBy) {
					Object.assign(args, {
						[`sorting[${getters.tableState.sortBy}]`]: getters.tableState.sortDesc ? 'desc' : 'asc'
					})
				} else if (('sortBy' in payload && !!payload.sortBy)) {
					Object.assign(args, {
						[`sorting[${payload.sortBy}]`]: payload.sortDesc ? 'desc' : 'asc'
					})
				}

				const response = await mcApi.getMCDeals(args)
				const { data } = response

				await commit('UPDATE_TABLE_STATE', {
					field: 'data',
					value: data.data
				})

				await commit('UPDATE_TABLE_STATE', {
					field: 'tableFields',
					value: data.data_custom.fields ? data.data_custom.fields : []
				})
				localStorage.setItem('mc-table-fields', JSON.stringify(data.data_custom.fields))
				await commit('UPDATE_TABLE_STATE', {
					field: 'localizedFields',
					value: response.localization.tables
				})
				await commit('UPDATE_ADDITIONAL_DATA', {
					from: data.from,
					to: data.to,
					last_page: data.last_page,
					per_page: data.per_page,
					total: data.total,
					page: data.current_page
				})
			} catch (e) {
				console.error(e)
			} finally {
				await commit('UPDATE_TABLE_STATE', {
					field: 'isBusyTable',
					value: false
				})
			}
		},
		async fetchCommentsByDeal ({ commit, getters }, dealID = null) {
			try {
				await commit('UPDATE_COMMENTS_TABLE_STATE', {
					field: 'isLoading',
					value: true
				})

				await commit('RESET_COMMENTS_TABLE')

				const { data } = await mcApi.getMCCommentsByDealID(dealID || getters.dealState.defaultDeal.id)

				await commit('UPDATE_COMMENTS_TABLE_STATE', {
					field: 'data',
					value: data.data
				})
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения комментариев МЦ по сделке!'
				})
			} finally {
				await commit('UPDATE_COMMENTS_TABLE_STATE', {
					field: 'isLoading',
					value: false
				})
			}
		},
		// async updateDealAddressPoints({commit, getters, dispatch}) {
		//   try {
		//     await commit('UPDATE_EDITING_DEAL_DATA', {
		//       field: 'commentProcessing',
		//       value: true
		//     })
		//
		//     const args = {
		//       id: getters.dealState.defaultDeal.id,
		//       point_loadings: getters.editingState.loadingPoints.map(i => ({
		//         id: i.id
		//       })),
		//       point_unloading: getters.editingState.unloadingPoints.map(i => ({
		//         id: i.id
		//       })),
		//     }
		//
		//     const response = await api.editDealById(args)
		//
		//     if(response.code === 200) {
		//
		//       await dispatch('fetchDeal', getters.dealState.defaultDeal.id)
		//
		//       this._vm.$notify({
		//         type: 'success',
		//         text: 'Маршрут успешно сохранен!'
		//       })
		//     }
		//   } catch (e) {
		//     console.error(e)
		//     this._vm.$notify({
		//       type: 'error',
		//       text: 'Ошибка сохранения адресов!'
		//     })
		//   } finally {
		//     await commit('UPDATE_EDITING_DEAL_DATA', {
		//       field: 'commentProcessing',
		//       value: false
		//     })
		//   }
		// },
		async updateDealLocations ({ getters, rootGetters }) {
			try {
				const dealFormData = jsonToFormData({
					id: getters.dealState.defaultDeal.id,
					deal_locations: rootGetters['CRM_DEAL/getPathLocations'].map((i, index) => {
						i.position = index

						return i
					})
				})

				const { data } = await dealApi.createDeal(dealFormData, {
					'Content-Type': 'multipart/form-data'
				})

				return data
			} catch (e) {
				console.error(e)

				this._vm.$notify({
					type: 'error',
					text: 'Ошибка изменения адресов!',
					message: e
				})
			}
		}
	}
}

export default MC_MODULE
