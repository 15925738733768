import filter from './credit-cards/filter/index'
import index from './credit-cards/index/index'
import store from './credit-cards/store/index'

const creditCards = {
	namespaced: true,
	modules: {
		filter: filter,
		store: store,
		index: index
	}
}

export default creditCards