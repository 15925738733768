import lsKeys from '@/config/local-storage-keys'

export default () => ({
	steps: {
		login: 'l',
		submitSmsCode: 'v',
		wait: 'w'
	},
	userAgentStorageData: localStorage.getItem(lsKeys.AGENT_STORAGE_KEY)
		? JSON.parse(localStorage.getItem(lsKeys.AGENT_STORAGE_KEY))
		: null,
	sessionId: null,
	message: '',
	actionType: null
})
