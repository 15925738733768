/**
 * Copied by link:
 * https://github.com/bootstrap-vue/bootstrap-vue/blob/2f9b14a105fa12e68299c101cd794023ed79eb7b/src/mixins/listen-on-root.js#L24
 */
export default {
  methods: {
    /**
     * Safely register event listeners on the root Vue node.
     * While Vue automatically removes listeners for individual components,
     * when a component registers a listener on root and is destroyed,
     * this orphans a callback because the node is gone,
     * but the root does not clear the callback.
     *
     * When registering a $root listener, it also registers a listener on
     * the component's `beforeDestroy` hook to automatically remove the
     * event listener from the $root instance.
     *
     * @param {string} event
     * @param {function} callback
     * @chainable
     */
    listenOnRoot (event, callback) {
      this.$root.$on(event, callback)
      this.$on('hook:beforeDestroy', () => {
        this.$root.$off(event, callback)
      })
      // Return this for easy chaining
      return this
    },
  }
}
