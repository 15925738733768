import CreditCardsAPI from '@/api/credit-cards'


export default {
	async createCreditCard ({ state, commit, getters}) {
		let response
		try {
			commit('SET_CREDIT_CARD_STATE', {
				field: 'processing',
				value: true
			})
			const data = await CreditCardsAPI.createCreditCard({
				card: state.card,
				first_name: state.first_name,
				last_name: state.last_name,
				middle_name: state.middle_name,
				tax_number: state.tax_number,
				companies: getters.preparePayloadData('companies'),
				contacts: getters.preparePayloadData('contacts')
			})
			this._vm.$crmNotify.success('Карта успешно создана!')
			commit('SET_CREDIT_CARD_STATE', {
				field: 'id',
				value: data.data.id
			})
			response = data
		} catch (e) {
			response = e.response
			this._vm.$crmNotify.warning('Ошибка создания карты! ' + e)
			console.error(e)
		} finally {
			commit('SET_CREDIT_CARD_STATE', {
				field: 'processing',
				value: false
			})
		}

		return response
	},

	async getCreditCardById ({ commit }, payload) {
		try {
			const { data } = await CreditCardsAPI.getCreditCard(payload)

			Object.keys(data).forEach(state_key => {
				commit('SET_CREDIT_CARD_STATE', {
					field: state_key,
					value: data[state_key]
				})
			})

		} catch (e) {
			this._vm.$crmNotify.warning('Ошибка получения карты! ' + e)
			console.error(e)
		}
	},

	async editCard ({state, getters, commit}) {
		let response
		try {
			commit('SET_CREDIT_CARD_STATE', {
				field: 'processing',
				value: true
			})
			response = await CreditCardsAPI.updateCreditCard(state.id, {
				card: state.card,
				first_name: state.first_name,
				last_name: state.last_name,
				middle_name: state.middle_name,
				tax_number: state.tax_number,
				companies: getters.preparePayloadData('companies'),
				contacts: getters.preparePayloadData('contacts')
			})
			this._vm.$crmNotify.success('Успешно измененно!')
		} catch (e) {
			response = e.response
			this._vm.$crmNotify.warning('Ошибка редактирования карты! ' + e)
			console.error(e)
		} finally {
			commit('SET_CREDIT_CARD_STATE', {
				field: 'processing',
				value: false
			})
		}

		return response
	},

	async addResponsibleToCard ({state, commit}, payload) {
		try {
			await CreditCardsAPI.addResponsible(state.id, payload)
			this._vm.$crmNotify.success('Ответственный успешно добавлен!')

			commit('SET_CREDIT_CARD_STATE', {
				field: 'is_short_info',
				value: false
			})
		} catch (e) {
			this._vm.$crmNotify.warning('Ошибка добавления ответсвенного! ' + e)
			console.error(e)
		}
	}



}
