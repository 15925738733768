export default {
	getCreditCardsFilterPayload: (state) => ({
		card: state.credit_card || null,
		company_full_name: state.company || null,
		tax_number: state.tax_number || null,
		full_name: state.full_name || null,
		contact_full_name: state.contact || null
	})   
}

