import lsKeys from '@/config/local-storage-keys'
import Vue from 'vue'

export default {
	SET_USER_AGENT_DATA (state, data) {
		localStorage.setItem(lsKeys.AGENT_STORAGE_KEY, JSON.stringify(data))
		Vue.set(state, 'userAgentStorageData', data)
	},
	SET_SESSION_ID (state, id) {
		Vue.set(state, 'sessionId', id)
	},
	SET_MESSAGE (state, message) {
		state.message = message
	},
	SET_ACTION_TYPE (state, type) {
		state.actionType = type
	}
}
