import userStatsApi from '@/api/user-stats'

export default {
	async GET_METRICS_DATA ({commit}) {
		const response = await userStatsApi.getMetricsData()
		const {data} = response

		commit('SET_DATA', data)
	},
	GET_PERSONAL_STATS ({commit}, payload = {}) {
		commit('SET_METRICS_LOADING_PROCESSING_FLAG', true)

		return userStatsApi.getPersonalStatsData(payload).then((response) => {
			const {data} = response

			commit('SET_PERSONAL_STATS', data)
			commit('SET_METRICS_LOADING_PROCESSING_FLAG', false)
		})
	}
}
