import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
  fetchLeads(payload = {}) {
    return axios.get(`${HOST_URL}/registries`, {
      params: payload
    })
  },
  getLeadById (id) {
    return axios.get(`${HOST_URL}/registry/${id}`)
  },
  updateStatusById (id) {
    return axios.post(`${HOST_URL}/registry/update-status`, {
      id
    })
  }
}
