import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  storeAddress (payload = {}) {
    return axios.post(`${HOST_URL}/address`, {
      address: payload,
    })
  },
  getAddress (payload = {}) {
    return axios.get(`${HOST_URL}/v${API_VERSION}/address`, {
      params: payload,
    })
  },
  fetchRegions (payload = {}) {
    return axios.get(`${HOST_URL}/regions`, {
      params: payload,
    })
  },
  filterShortAddresses(payload = {}) {
    return axios.get(`${HOST_URL}/region-short-adresses`, {
      params: payload
    })
  }
}
