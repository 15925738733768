export default function can () {
	const perms = arguments[0]
	const {next, store, nextMiddleware} = arguments[1]

	const userPerms = store.getters.getPermissions

	for (let userPerm of userPerms) {
		if (perms.includes(userPerm)) {
			return nextMiddleware()
		}
	}

	return next({
		name: 'Home'
	})
}
