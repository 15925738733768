import cfoExport from '@/config/cfo-export-schema'
export default {
	getDebtorsPayload: (state, getters, rootState, rootGetters) => ({
		...rootGetters['cfo/CFO_FILTER_MODULE/getFilterArgs'],
		debtor: true,
		'page-limit': state.additionalData.per_page,
		page: state.additionalData.page,
		debtor_type: state.debtor_type,
		[`checkbox-filter[is_${state.debtor_type}_court]`]: !state.is_all_debtors ? state.is_all_debtors : null
	}),
	getExportedDataByDebtorType: (state) => ({
		fields: cfoExport.DEBTORS[state.debtor_type].fields.join(','),
		name: cfoExport.DEBTORS[state.debtor_type].name
	})
}

