<template>
  <div class="fox-checkbox-group">
    <fox-checkbox
      v-for="option in options"
      :key="__$getId(option)"
      :value="getIfSelected(option)"
      :selected="__$getId(option)"
      :not-selected="null"
      :label="__$getLabel(option)"
      :disabled="option.disabled"
      v-bind="$attrs"
      @input="select(option)"
    />
  </div>
</template>

<script>
import FoxCheckbox from '@/UIKit/components/checkbox/FoxCheckbox'

export default {
  name: 'FoxCheckboxGroup',
  components: { FoxCheckbox },
  props: {
    value: {
      required: true,
      type: [Array, null]
    },
    options: {
      required: true,
      type: Array
    },
    reducer: Boolean
  },
  computed: {
    vModel: {
      get () {
        return this.value || []
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    vModelIds () {
      if (this.reducer) {
        return this.vModel
      }

      return this.vModel.map(model => this.__$getId(model))
    }
  },
  methods: {
    __$getId (option) {
      return option.id || option.value
    },
    __$getLabel (option) {
      return option.name || option.label || option.text
    },
    getIfSelected (option) {
      const key = this.__$getId(option)

      if (!option || !key) {
        return false
      }

      return this.vModelIds.includes(key) ? key : null
    },
    select (option) {
      const data = this.reducer ? this.vModel : this.vModelIds
      const index = data.indexOf(this.__$getId(option))

      if (index === -1) {
        this.vModel.push(this.reducer ? this.__$getId(option) : option)
      } else {
        this.vModel.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="sass">
@import '../../scss/components/checkbox-group/index'
</style>
