import axios from 'axios'
import toLoginWithPayload from '@/router/helpers/toLoginWithPayload'
import sentrySetUser from '@/helpers/sentry/sentry-set-user'

export default async function auth ({ next, store, nextMiddleware, router, to }) {
	const token = store.getters.getToken

	if (!token) {
		return next(toLoginWithPayload(to))
	}

	axios.defaults.headers.common.Authorization = token

	const user = await store.dispatch('fetchUser')

	if (!user) {
		store.commit('SET_TOKEN', null)

		return next(toLoginWithPayload(to))
	}

	sentrySetUser(user)

	router.app.$root.$emit('ESTABLISH_SOCKET_CONNECTION')

	return nextMiddleware()
}
