<template>
    <div id="app">

        <notifications position="bottom right"/>

        <user-need-update-modal id="user-notification-modal" />

        <template v-if="hasAccessToNavigation">
            <TopHeader/>
            <Navigation/>
        </template>

        <router-view :key="routerKey"/>
    </div>
</template>

<script>

  import Vue from 'vue'
  import Notifications from 'vue-notification'
  import axios from 'axios'

  import notifications from '@/mixins/notifications'

  Vue.use(Notifications)

  const TopHeader = () => import('@/components/Header/TopHeader')
  const Navigation = () => import('@/components/Header/Navigation')
  const UserNeedUpdateModal = () => import('@/components/UserNeedUpdateModal')

  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
  import listenOnRootMixin from '@/mixins/listen_on_root'
  import { MULTI_EDIT_LOCK } from '@/config/socket-events.config'

  export default {
    mixins: [notifications, listenOnRootMixin],
    components: {
      UserNeedUpdateModal,
      TopHeader,
      Navigation,
    },
    data() {
      return {
        SocketEvents: MULTI_EDIT_LOCK
      }
    },
    computed: {
      ...mapState([
        'token'
      ]),
      ...mapGetters([
        'isAuthorized',
        'getUser',
        'getRoles'
      ]),
      isCashier () {
        return this.$route.name === 'cashier'
      },

      hasAccessToNavigation () {
        return this.isAuthorized && !this.isCashier
      },

      routerKey() {
        if(this.$route.name === 'MONITORING_CENTER') {
          return this.$route.name
        }

        return this.$route.path
      }
    },
    methods: {
      ...mapActions([
          'logout'
      ]),
      ...mapActions('socket', [
        'connectToSocket'
      ]),
      ...mapMutations('socket', [
          'SET_SOCKET_CONNECTION_FLAG'
      ]),
      openUpdateModal(type, payload) {
        this.$root.$emit('WS::UPDATE_NOTIFICATION', 'user-notification-modal', type, payload);
      },
      /**
       * Create connection, check build version, subscribe on basic events
       * @returns {Promise<void>}
       */
      async establishSocketConnection() {
        if (this.getUser) {
          // коннектимся к сокету
          const socket = await this.connectToSocket();

          socket.on('connect', () => {
            this.SET_SOCKET_CONNECTION_FLAG(true)
          })

          socket.on('disconnect', () => {
            this.SET_SOCKET_CONNECTION_FLAG(false)
          })

          socket.on('update/update-soon', () => {
            this.openUpdateModal('update-soon');
            this.newNotification('Ближайшее обновление!', 'В течении ближайшего времени будет обновление!');
          })
          socket.on('update/begins', () => {
            this.openUpdateModal('update-begins');
            this.newNotification('Началось обслуживание Фокса!', 'Началось обслуживание Фокса!');
          })
          socket.on('update/update-completed', (version) => {
            localStorage.setItem('build-version', version)
            this.openUpdateModal('update-completed');
            this.newNotification('Фокс успешно обновлен!', 'Фокс успешно обновлен!');
          })

          socket.on(this.SocketEvents.REDIRECT_HOMEPAGE, (data = {}) => {
            this.$router.push({
              name: 'CFO'
            })
            let msg = null

            if (data && data.redirected_by) {
              msg = `Вы были перенаправлены на главную страницу пользователем ${data.redirected_by}!`
            }

            this.openUpdateModal('redirect-to-home', msg)
            this.newNotification(msg)
          })

          socket.on(this.SocketEvents.SOCKET_GOT_IN_QUEUE, name => {
            this.openUpdateModal('redirect-to-home', `Пользователь ${name} переместил Вашу позицию!`)
          })

          socket.on(this.SocketEvents.LOGOUT, () => {
            if (this.logout()) {
              location.reload()
            }
          })
        }
      }
    },
    async beforeMount () {
      const token = window.localStorage.getItem('token')

      if (token) {
        axios.defaults.headers.common['Authorization'] = token
      }

      console.table({
        GIT_BRANCH: process.env.GIT_BRANCH,
        GIT_COMMITHASH: process.env.GIT_COMMITHASH,
        GIT_VERSION: process.env.GIT_VERSION
      })

      console.info(`Hello! User build version ${localStorage.getItem('build-version')}.`);

      /**
       * Event for create connection after login (or other use case)
       */
      this.listenOnRoot('ESTABLISH_SOCKET_CONNECTION', this.establishSocketConnection);
      await this.establishSocketConnection();

      /**
       * Request permission for web api notifications
       */
      this.requestPermission();

      this.$store.dispatch('login/handleAwesomeSessionId')
    }
  }
</script>

<style lang="sass">

    @import '~bootstrap/dist/css/bootstrap.min.css'
    @import '~bootstrap-vue/dist/bootstrap-vue.min.css'

    @import '~normalize.css/normalize.css'
    @import '@/assets/fonts/icons/font.scss'
    @import '@/assets/styles/_checkbox.sass'

    body
        position: relative
        min-width: 320px
        background-color: #F0F0F0 !important
        font-family: $font
        font-weight: 400
        overflow-x: hidden
        -webkit-font-smoothing: antialiased
        -moz-osx-font-smoothing: grayscale

    #jsd-widget
        z-index: 9 !important

    .hack-hidden-element
        position: absolute
        left: -99999px

</style>
