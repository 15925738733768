import companyApi from '@/api/company'
import helperApi from '@/api/helper'
import exportToExcelHelper from '@/mixins/export-to-excel'
import Vue from 'vue'

const defaultState = () => ({
	companies: [],
	allTableFields: [],
	localizedFields: [],
	isBusyTable: false,
	additionalData: {
		per_page: 30,
		page: 1,
		total: 0,
		last_page: 0,
		sortBy: null,
		sortDesc: false
	}
})

const INDEX_MODULE = {
	namespaced: true,
	state: defaultState(),
	getters: {
		getLocalPayload: (state) => {
			const args = {
				'page-limit': state.additionalData.per_page || 30,
				page: state.additionalData.page || 1
			}

			if (state.additionalData.sortBy) {
				args[`sorting[${state.additionalData.sortBy}]`] = !state.additionalData.sortDesc ? 'asc' : 'desc'
			}

			return args
		}
	},
	mutations: {
		MUTATION_SET_COMPANIES (state, companies) {
			state.companies = companies
		},
		SET_ALL_TABLE_FIELDS (state, fields) {
			state.allTableFields = fields
		},
		SET_TABLE_LOCALIZED_FIELDS (state, fields) {
			state.localizedFields = fields
		},
		UPDATE_BUSY_TABLE (state, value = false) {
			state.isBusyTable = value
		},
		SET_TABLE_PAGE (state, page) {
			state.additionalData.page = page
		},
		SET_PAGE_LIMIT (state, limit) {
			state.additionalData.per_page = limit
		},
		SET_ADDITIONAL_DATA (state, data) {
			state.additionalData = data
		},
		SET_TABLE_FIELDS (state, data) {
			state.allTableFields = data
		},
		SET_SORT_DATA (state, {sortBy, sortDesc}) {
			Vue.set(state.additionalData, 'sortBy', sortBy)
			Vue.set(state.additionalData, 'sortDesc', sortDesc)
		}
	},
	actions: {
		async GET_COMPANIES ({
			commit,
			rootGetters,
			getters,
			state
		}) {
			commit('UPDATE_BUSY_TABLE', true)

			const payloadForFilter = {
				...rootGetters['CRM_COMPANY/filter/getCompanyFilterPayload'],
				...getters.getLocalPayload
			}

			try {
				let response = await companyApi.getCompaniesData(payloadForFilter)
				let {
					data,
					localization
				} = response
				localization.deals_counter = '⭐'
				commit('MUTATION_SET_COMPANIES', data.data)
				commit('SET_TABLE_LOCALIZED_FIELDS', localization)
				commit('SET_TABLE_FIELDS', Object.keys(localization))

				commit('SET_ADDITIONAL_DATA', {
					from: data.from,
					to: data.to,
					last_page: data.last_page,
					per_page: data.per_page,
					total: data.total,
					page: data.current_page,
					sortBy: state.additionalData.sortBy || null,
					sortDesc: state.additionalData.sortDesc || null
				})
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка получения компаний!'
				})
			} finally {
				commit('UPDATE_BUSY_TABLE', false)
			}
		},
		setCompanyPageLimit ({
			commit,
			dispatch
		}, payload) {
			commit('SET_PAGE_LIMIT', payload)

			return dispatch('GET_COMPANIES')
		},
		setCompanyPage ({
			commit,
			dispatch
		}, payload) {
			commit('SET_TABLE_PAGE', payload)

			return dispatch('GET_COMPANIES')
		},
		setCompanySorting ({
			commit,
			dispatch
		}, payload) {
			commit('SET_SORT_DATA', payload)

			return dispatch('GET_COMPANIES')
		},
		async exportCompaniesToExcel ({getters, rootGetters}, fileNamePrefix = 'companies') {
			try {
				const response = await helperApi.getCRMDataAsExcel('company', {
					...rootGetters['CRM_COMPANY/filter/getCompanyFilterPayload'],
					...getters.getLocalPayload,
					excel: true
				})

				return exportToExcelHelper(response, fileNamePrefix)
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка выгрузки файла' + e)
			}
		}
	}
}

export default INDEX_MODULE
