import api from '@/api/price-matrix'
import helperApi from '@/api/helper'
import addressApi from '@/api/address'

function initialState () {
  return {
    data: [],

    filterByAddressesFrom: false,
    filterByAddressesTo: false,
    filterLoadingDateRangeFrom: null,
    filterLoadingDateRangeTo: null,
    filterVehicleType: [],
    filterCapacityFrom: null,
    filterCapacityTo: null,

    filterCityFrom: null,
    filterCityTo: null,
    filterRouteDistance: null,

    filterVolumeFrom: null,
    filterVolumeTo: null,

    averageClientAmount: 0,
    averageTransporterAmount: 0,
    recommendedData: null,

    regionsFrom: [],
    regionsTo: [],
    addressesFrom: [],
    addressesTo: [],
    loading: false,
    helpersLoading: false,
    vehicleTypes: [],
    detailedInfo: false,
    selectedDeals: [],
    isFiltered: false
  }
}

const PRICE_MATRIX_STORE = {
  namespaced: true,
  state: initialState(),
  getters: {
    isEmptyFilterParams: (state) => {
      if (state.isFiltered) {
        return true
      }

      return !(state.filterByAddressesFrom ||
        state.filterByAddressesTo ||
        state.filterLoadingDateRangeFrom ||
        state.filterLoadingDateRangeTo ||
        state.filterVehicleType.length ||
        state.filterCapacityFrom ||
        state.filterCapacityTo ||
        state.filterCityFrom ||
        state.filterCityTo ||
        state.filterVolumeFrom ||
        state.filterVolumeTo)
    },
    getFilterPayload: (state) => {
      const payload = {
        'loading-from': state.filterLoadingDateRangeFrom || null,
        'loading-to': state.filterLoadingDateRangeTo || null,
        'capacity-from': state.filterCapacityFrom || null,
        'capacity-to': state.filterCapacityTo || null,
        'city-from': state.filterCityFrom || null,
        'city-to': state.filterCityTo || null,
        'volume-from': state.filterVolumeFrom || null,
        'volume-to': state.filterVolumeTo || null,
      }

      if (state.filterVehicleType.length) {
        payload['vehicle-type-ids'] = state.filterVehicleType.map(i => i.value)
      }
      if (state.filterCityFrom) {
        payload['by-addresses-from'] = state.filterByAddressesFrom || null
      }
      if (state.filterCityTo) {
        payload['by-addresses-to'] = state.filterByAddressesTo || null
      }
      if (state.selectedDeals.length) {
        payload['selected-deals'] = state.selectedDeals
      }
      if (state.filterRouteDistance) {
        payload['route-distance'] = state.filterRouteDistance || null
      }

      return payload
    },
  },
  mutations: {
    SET_LOADING_FLAG (state, flag = false) {
      state.loading = flag
    },
    SET_DATA (state, payload) {
      state[payload.field] = payload.value
    },
    RESET_FILTER (state) {
      const excludedFields = [
        'regionsFrom',
        'regionsTo',
        'vehicleTypes'
      ]
      const defaultState = initialState()

      Object.keys(defaultState).forEach(key => {
        if (!excludedFields.includes(key)) {
          state[key] = defaultState[key]
        }
      })
    },
    SELECT_DEALS(state, ids) {
      state.selectedDeals = ids
    },
    SELECT_DEAL(state, id) {
      if (state.selectedDeals.includes(id)) {
        const idx = state.selectedDeals.indexOf(id)
        state.selectedDeals.splice(idx, 1)
      } else {
        state.selectedDeals.push(id)
      }
    }
  },
  actions: {
    resetMatrix ({ commit }) {
      commit('RESET_FILTER')
    },
    async fetchPriceMatrix ({ getters, commit }) {
      try {
        commit('SET_LOADING_FLAG', true)
        commit('SET_DATA', {
          field: 'detailedInfo',
          value: false,
        })

        const response = await api.getPriceMatrix(getters.getFilterPayload)

        commit('SET_DATA', {
          field: 'data',
          value: response.data,
        })
        commit('SELECT_DEALS', response.selected_deals.length
          ? response.selected_deals.map(id => +id)
          : response.data.map(deal => +deal.id))

        commit('SET_DATA', {
          field: 'averageClientAmount',
          value: response.client_average_sum,
        })
        commit('SET_DATA', {
          field: 'averageTransporterAmount',
          value: response.transporter_average_sum,
        })
        if(response.recommend_data) {
          commit('SET_DATA', {
            field: 'recommendedData',
            value: response.recommend_data
          })
          commit('SET_DATA', {
            field: 'filterRouteDistance',
            value: response.recommend_data.route_distance
          })
        }

        commit('SET_DATA', {
          field: 'isFiltered',
          value: true
        })
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.warning(
          'Ошибка получения матрицы цен по параметрам: ' + e)
      } finally {
        commit('SET_LOADING_FLAG')
      }
    },
    async fetchHelpers ({ commit, dispatch }) {
      commit('SET_DATA', {
        field: 'helpersLoading',
        value: true,
      })

      window.Promise.all([
        dispatch('fetchVehicleTypes'),
        dispatch('fetchRegions'),
      ]).then(() => {
        commit('SET_DATA', {
          field: 'helpersLoading',
          value: false,
        })
      })
    },
    async fetchVehicleTypes ({ commit }) {
      try {
        const { data } = await helperApi.vehicleTypes()

        commit('SET_DATA', {
          field: 'vehicleTypes',
          value: data.data.filter(i => i.category && i.category.id === 2).
            map(i => ({
              value: i.id,
              label: i.name,
            })),
        })
      } catch (e) {
        console.error(e)
      } finally {

      }
    },
    async fetchAddress ({ commit }, { payload, method }) {
      const response = await addressApi.filterShortAddresses({
        address: payload,
      })

      const regions = response.map(i => ({
        value: i.short_address,
        label: i.short_address,
      }))

      commit('SET_DATA', {
        field: `addresses${method}`,
        value: regions,
      })

      return this
    },
    async fetchRegions ({ commit }, payload) {
      try {
        commit('SET_DATA', {
          field: 'helpersLoading',
          value: true,
        })

        const response = await addressApi.fetchRegions()
        const regions = response.map(item => ({
          value: item.area_long_name,
          label: `${item.area_long_name} (${item.country_long_name})`,
        }))

        if (payload && payload.method) {
          commit('SET_DATA', {
            field: `regions${payload.method}`,
            value: regions,
          })
        } else {
          commit('SET_DATA', {
            field: 'regionsFrom',
            value: regions,
          })
          commit('SET_DATA', {
            field: 'regionsTo',
            value: regions,
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_DATA', {
          field: 'helpersLoading',
          value: false,
        })
      }
    },
    changeAddressFilterType ({ state, commit, dispatch }, { method }) {
      if (state[`filterByAddresses${method}`] &&
        !state[`regions${method}`].length) {
        dispatch('fetchRegions', { method: method })
      }

      commit('SET_DATA', {
        field: `filterCity${method}`,
        value: null,
      })
      commit('SET_DATA', {
        field: `filterByAddresses${method}`,
        value: !state[`filterByAddresses${method}`],
      })
    },
    toggleDetailedInfo ({ state, commit }) {
      commit('SET_DATA', {
        field: 'detailedInfo',
        value: !state.detailedInfo,
      })
    },
    selectDeal ({ commit }, id) {
      commit('SELECT_DEAL', id)
    },
    resetSelectedDeals ({ commit }) {
      commit('SELECT_DEALS', [])
      commit('SET_DATA', {
        field: 'isFiltered',
        value: false
      })
    }
  },
}

export default PRICE_MATRIX_STORE
