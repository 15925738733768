export default function toLoginWithPayload (to = {}) {
	const payload = {
		name: 'Login',
		query: {}
	}

	if (to.name) {
		payload.query.redirect = to.name
	}

	if (to.params && Object.keys(to.params).length) {
	  payload.query.redirect_params = to.params
	}

	if (to.query && Object.keys(to.query).length) {
		payload.query.redirect_query = to.query
	}

	return payload
}
