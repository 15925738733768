export default {
	ndsMetricPercent: (state) => {
		let result = 0
		const totalNdsAmount = state.data.reduce((a, { cashless_w_vat }) => a + +cashless_w_vat, 0)

		if (totalNdsAmount > 0) {
			// eslint-disable-next-line max-len
			const totalFreights = state.data.reduce((a, { cashless_w_vat, cash_type, cashless_wo_vat }) => a + +cashless_w_vat + +cash_type + +cashless_wo_vat, 0)

			result = (totalNdsAmount / totalFreights * 100).toFixed(2)
		}

		return +result
	}
}
