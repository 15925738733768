import turnoverConfig from '@/config/document-turnover-config'
import { DEFAULT_DOCUMENT_STATE } from '@/store/modules/document-turnover/state'

export default {
	SET_RADIO_BUTTON (state, data) {
		state.radioButton = data
	},
	SET_CHECKED_BUTTONS (state, data) {
		state.checkedButtons = data
	},
	SET_TRANSACTION_LIST (state) {
		state.document.transactionList = state.documents.reduce((memo, i) => {
			if (i.type === state.choosedType) {
				memo.push({
					id: i.id,
					type: i.type
				})
			}

			return memo
		}, [])
	},
	SET_TRANSPORTER_DATA (state, data) {
		const turnoverWithTransporterFreight = data.document_turnover
			.filter(i => i.type === turnoverConfig.TRANSPORTER_FREIGHT)

		if (turnoverWithTransporterFreight.length) {
			const { list_docs, date } = turnoverWithTransporterFreight[0]

			state.transporter_documents_list = list_docs.map(l => l.name).join(', ')
			state.transporter_documents_list_date = date
		}

		const filteredTurnoverClient = data.document_turnover
			.filter(d => d.type === turnoverConfig.CLIENT_FREIGHT && d.ttn_accepted_date)
		const filteredTurnoverTransporter = data.document_turnover
			.filter(d => d.type === turnoverConfig.TRANSPORTER_FREIGHT && d.payment_docs_accepted_date)

		if (filteredTurnoverClient.length) {
			state.ttn_clients_accepted_date = filteredTurnoverClient[0].ttn_accepted_date
		}
		if (filteredTurnoverTransporter.length) {
			state.payments_accepted_date = filteredTurnoverTransporter[0].payment_docs_accepted_date
		}
	},
	SET_DATA (state, data) {
		state.data = data
	},
	SET_SELECTED_DOC_TYPE (state, value) {
		state.document.type = value
	},
	UPDATE_BUSY_TABLE (state, value = false) {
		state.isBusyTable = value
	},
	SET_DOCUMENT_DEAL_ID (state, data) {
		state.dealID = data
	},
	SET_TRANSPOTER_DEBT (state, data) {
		state.transporter_debt = data
	},
	SET_DOCUMENT_TYPE (state, data) {
		state.document.type = data
		state.choosedType = data
	},
	SET_DOCUMENT_DATE (state, data) {
		state.document.date = data
	},
	SET_POSTAL_SERVICES (state, data) {
		state.postal_services = data
	},
	SET_LIST_DOC (state, data) {
		state.list_doc = data
	},
	SET_POSTAL_SERVICE_ID (state, data) {
		state.document.postal_service = data
	},
	SET_CLIENT_GET_DOCS_DATE (state, data) {
		state.document.client_get_docs_date = data
	},
	SET_TRANSPORTER_DATE (state, data) {
		state.document.date = data
	},
	SET_CHOOSED_TYPE (state, data) {
		state.choosedType = data
	},
	SET_CLIENT_GET_DOCS_CONTACT (state, data) {
		state.document.client_get_docs_contact = {id: data}
	},
	SET_ACTIVE_TRANSACTION (state, data) {
		state.activeTransaction = data
	},
	SET_CLIENT_COST_POST (state, data) {
		state.document.client_cost_post = data
	},
	SET_TRANSPORTER_COST_POST (state, data) {
		state.document.transporter_cost_post = data
	},
	SET_LIST_DOCS (state, data) {
		state.document.list_docs = data
	},
	SET_DOCUMENT_TURNOVER (state, data) {
		state.documents = data.documents
		state.document = data.document ? data.document : DEFAULT_DOCUMENT_STATE()

		if (state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT && !state.document.date) {
			state.document.date = new Date()
		}
	},
	SET_DOCUMENT (state, data) {
		state.document = data
	},
	/**
	 * Omg shit it looks terrible
	 * @param state
	 * @constructor
	 */
	RESET_DOCUMENT_STATE (state) {
		Object.assign(state, DEFAULT_DOCUMENT_STATE())
		state.dealDescription = null
		state.activeTransaction = null
		state.choosedType = null
		state.document.type = null
		state.document = DEFAULT_DOCUMENT_STATE()
		state.document.date = null
		state.document.delay_date = null
		state.document.invoice_creation_date = null
		state.documents = []
		state.radioButton = null
		state.checkedButtons = []
		state.ttn_clients_accepted_date = null
		state.payments_accepted_date = null
	},
	SET_RECEIVING_METHOD (state, value) {
		state.document.receiving_method = value
	},
	SET_RECEIVING_OPTIONS (state, value) {
		state.receivingOptions = value
	},
	UPDATE_SELECTED_RECEIVED_METHODS (state, value) {
		state.selectedReceivedMethod = value
	},
	UPDATE_DOCUMENT_STATE (state, payload) {
		state.document[payload.field] = payload.value
	},
	UPDATE_GLOBAL_STATE (state, payload) {
		state[payload.field] = payload.value
	},
	SET_FILES (state, payload) {
		state.clientOrderFiles = payload.order_files
		state.clientBrokenActsFiles = payload.broken_acts
		state.clientScoreFiles = payload.score
		state.clientTtnFiles = payload.ttn
	}
}
