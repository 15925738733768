import state from './state'
import getters from './getters'
import mutations from './mutations'

const phone = {
	namespaced: true,
	state: state(),
	getters: getters,
	mutations: mutations,
	actions: {
		changeTypeOfPasswordInput ({ commit, state }) {
			if (state.passwordType === state.passwordTypes.password) {
				commit('UPDATE_LOGIN_STATE', {
					field: 'passwordType',
					value: state.passwordTypes.text
				})
			} else {
				commit('UPDATE_LOGIN_STATE', {
					field: 'passwordType',
					value: state.passwordTypes.password
				})
			}
		}
	}

}

export default phone
