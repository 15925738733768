<template>

    <div class="w-100">
        <div v-if="!isFile()" :id="localID" class="vue-pdf-previewer"></div>
        <embed v-else id="embeded-pdf" class="w-100 h-100" :src="file">
    </div>

</template>

<script>

  import pdfObject from 'pdfobject'

  export default {
    name: 'VuePdfPreview',
    props: {
      file: {
        required: true
      }
    },
    data () {
      return {
        localID: 'vue-pdf-previewer-1'
      }
    },
    watch: {
      file () {
        this.handleFile()
      }
    },
    methods: {
      isFile() {
        return this.file instanceof File
      },
      async handleFile () {
        const options = {
          fallbackLink: `<p>Этот браузер не поддерживает просмотр PDF <a href='${this.file}'>скачайте файл</a></p>`
        }

        if(!this.isFile()) {
          await pdfObject.embed(this.file, `#${this.localID}`, options)
        } else {
          const $node = document.getElementById('embeded-pdf')
          $node.src = URL.createObjectURL(this.file)
          $node.onload = () => {
            URL.revokeObjectURL($node.src)
          }
        }
      }
    },
    mounted () {
      this.handleFile()
    }
  }
</script>

<style lang="sass">

    .vue-pdf-previewer
        display: block
        height: 100%
        width: 100%

</style>
