import jsonToFormData from '@/mixins/jsonToFormData'
import {get} from '@/mixins/light_lodash'
import {uuniqBy} from '@/mixins/light_lodash'
import locationApi from '@/api/location'
import orderApi from '@/api/order'
import dealApi from '@/api/deal'
import helperApi from '@/api/helper'
import companyApi from '@/api/company'
import contactApi from '@/api/contact'

const _parseData = (data, isChild = false) => {
  const state = defaultState()
  const keyPathObjects = state.keyPathObjects
  const keyPathArrays = state.keyPathArrays

  let newData = []
  if (!Array.isArray(data) || data.length === 0) return newData

  data.map(o => {
    let obj = {}

    Object.entries(keyPathObjects).forEach(([key, value], index) => {
      obj[key] = get(o, value)

      if (isChild && key === 'children' && obj[key].length) {
        obj[key] = _parseData(get(o, value), false)
      }
    })

    keyPathArrays.forEach(val => {
      obj[val.key] = o[val.key].map(l => get(l, val.path)).join('; ')
    })

    newData.push(obj)
  })

  return newData
}

const filterState = () => ({
  liveSearch: null,
  responsible: null,

  status: [1,2],
  id: null,
  date_loading: null,
  location_loadings: null,
  location_unloadings: null,
  client: null,
  client_contact: null,
  order_vehicle_type_id: null,
  deal_id: null,
  order_transporter_id: null,
  manager_id: null,
  parent: null,

  byFields: [],
  localizedFields: {}
})

const defaultState = () => ({
  /**
   * Данные из вьюшки
   */
  data: [],
  /**
   * Для отображения сумм
   */
  amounts: [],
  /**
   * Поля для отображения и обработки
   */
  tableFields: [],
  /**
   * Поля которые надо отображать или обработать в таблице
   */
  queryFields: [
    'id',
    'date_loading',
    'location_loadings',
    'location_unloadings',
    'client',
    'order_vehicle_type',
    'order_transporter',
    'deal',
    'status',
    'clientOrder',
    'transporterOrder',
    'orderTransporter',
    'manager',
    'description',
    'shared',
  ],
  /**
   * Объект соответсвий названия поля и его перевода
   */
  localizedFields: {
    id: 'ID',
    status: 'Статус',
    date_loading: 'Дата загрузки',
    location_loadings: 'Загрузки',
    location_unloadings: 'Выгрузки',
    client: 'Компания',
    order_vehicle_type: 'Тип авто',
    order_transporter: 'Транспорт',
    clientOrder: 'Фрахт заказчика',
    deal: 'Сделка',
    orderTransporter: 'Заказ П',
    transporterOrder: 'Фрахт перевозчика',
    manager: 'Ответственный',
    description: 'Комментарий',
    shared: 'С кем поделились',
    transporter_freight: 'Фрахт заказчика',
    action_buttons: ' '
  },
  /**
   * Для пагинации объект
   */
  additionalData: {
    per_page: 999,
    page: 1,
    total: 0,
    last_page: 0
  },

  client_orders: [],
  allTableFields: [],
  /**
   * Флаг отвечающий за блокировку таблицы
   */
  isBusyTable: false,

  /**
   * Ключ - путь для формирования таблицы
   */
  keyPathObjects: {
    order_vehicle_type: 'order_vehicle_type.name',
    cargo_type: 'cargo_type.name',
    status: 'status',
    parent: 'parent',

    client: 'client',
    children: 'children',
    client_type: 'client_type',

    manager: 'manager',
    description: 'description',
    date_loading: 'date_loading',
    deal: 'deal.id',
    id: 'id',

    client_payment_form: 'client_payment_form.name',
    client_freight: 'client_freight',
    order_transporter: 'order_transporter',

    transporter_payment_form: 'transporter_payment_form.name',
    transporter_freight: 'transporter_freight',
    orderTransporter: 'order_transporter.id',
  },

  keyPathArrays: [
    {path: 'address.short_address', key: 'location_loadings'},
    {path: 'address.short_address', key: 'location_unloadings'},
    {path: 'full_name', key: 'shared'}
  ],

  filter: filterState(),
  ordersList: [],
  loadingLocations: [],
  loadingUnlocations: [],
  companies: [],
  contacts_: [],
  deals: [],
  vehicleTypes: [],
  transporterOrdersList: [],
  localManager_: null
})

const CLIENT_ORDER_INDEX = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getState: state => state,
    getAdditionalData: state => state.additionalData,
    getClientOrders: state => _parseData(state.client_orders, true),
    getTableFields: state => state.allTableFields,
    getLocalizedFields: state => state.localizedFields,
    getIsBusyTable: state => state.isBusyTable,

    getFilter: state => state.filter,

    getClearTableData: state => state.client_orders,

    getOrdersList: state => state.ordersList.map(d => ({value: d.id, label: d.id})),
    getTransporterOrdersList: state => state.transporterOrdersList.map(o => ({value: o.id, label: o.id})),
    getDeals: state => state.deals.map(d => ({value: d.id, label: d.id})),
    getTransporterOrders: state => state.deals.map(d => ({value: d.id, label: d.id})),
    getCompaniesList: state => state.companies.map(l => ({value: l.id, label: l.name})),
    getContactsList: state => state.contacts_.map(l => ({value: l.id, label: l.full_name})),
    getVehicleTypes: state => state.vehicleTypes.map(l => ({value: l.id, label: l.name})),
    getLoadingLocationsList: state => uuniqBy(state.loadingLocations.map(l => ({
      value: l.id,
      label: l.address.short_address
    })), 'label'),
    getUnloadingLocationsList: state => uuniqBy(state.loadingUnlocations.map(l => ({
      value: l.id,
      label: l.address.short_address
    })), 'label'),
  },
  mutations: {
    UPDATE_GLOBAL_STATE(state, payload) {
      state[payload.field] = payload.value
    },
    UPDATE_BUSY_TABLE(state, value) {
      state.isBusyTable = value
    },
    UPDATE_ADDITIONAL_DATA_PAGE(state, value) {
      state.additionalData.page = value
    },
    SET_TABLE_FIELDS(state, data) {
      state.allTableFields = data
    },
    SET_CLIENT_ORDERS(state, data) {
      state.client_orders = data
    },
    ADD_CLIENT_ORDERS(state, orders) {
      state.client_orders.push(...orders);
    },
    ADD_CLIENT_ORDER(state, model) { //just one
      let client_orders = JSON.parse(JSON.stringify(state.client_orders));
      const searchIndex = client_orders.findIndex(i => i.id === model.id);
      if(searchIndex !== -1) {
        client_orders[searchIndex] = model;
      } else {
        client_orders = [model, ...client_orders];
      }
      state.client_orders = client_orders;
    },
    SET_TABLE_LOCALIZED_FIELDS(state, fields) {
      state.localizedFields = fields
    },


    UPDATE_FILTER(state, payload) {
      state.filter[payload.field] = payload.value
    },
    CLEAR_FILTER(state) {
      /**
       * Не очищать менеджера - manager
       * Не очищать клиента - client
       * */
      const manager = state.filter.manager_id
      const client = state.filter.client
      Object.assign(state.filter, filterState(), { manager_id: manager, client })
    },

  },
  actions: {
    async fetchClientOrders({state}, payload = {}) {
      let args = {
        'page-limit': payload.perPage || state.additionalData.per_page || 30,
        'page': payload.page || state.additionalData.page || 1,
        'global-search': state.filter.liveSearch || null,

        'search[statuses]': state.filter.status,
        'search[id]': state.filter.id || null,
        'search[date_loading]': state.filter.date_loading || null,
        'search[location_loadings]': state.filter.location_loadings || null,
        'search[location_unloadings]': state.filter.location_unloadings || null,
        'search[order_vehicle_type_id]': state.filter.order_vehicle_type_id || null,
        'search[deal_id]': state.filter.deal_id || null,
        'search[order_transporter_id]': state.filter.order_transporter_id || null,
        'search[manager_id]': state.filter.manager_id || null,
      }

      if (payload.localManager_) {
        Object.assign(args, {'search[manager_id]': state.localManager_})
      }
      if (state.filter.client_contact) {
        Object.assign(args, {'search[client_id]': state.filter.client_contact, 'search[client_type]': 'contacts'})
      }
      if (state.filter.client) {
        Object.assign(args, {'search[client_id]': state.filter.client, 'search[client_type]': 'companies'})
      }



      if ('sortBy' in payload && !!payload.sortBy) {
        Object.assign(args, {
          [`sorting[${payload.sortBy}]`]: payload.sortDesc ? 'desc' : 'asc'
        })
      }

      if (state.filter.byFields.length > 0) {
        const parsedFilterByFields = state.filter.byFields.map(i => ({
          value: i.value,
          field: i.field ? i.field.value : null
        }))
        for (let field of parsedFilterByFields) {
          if (field.field === 'id') {
            args[`filter[${field.field}]`] = field.value
          } else {
            args[`search[${field.field}]`] = field.value
          }
        }
      }

      return await orderApi.fetchClientOrders(args)
    },

    async deleteClientOrder({}, id) {
      const obj = {
        type: 'client',
        id: id
      }
      try {
        const data = await orderApi.deleteOrderById(obj)

        this._vm.$crmNotify.success(data.messages[0])

      } catch (e) {
        console.error(e)
      }
    },

    async clientOrderDuplicate({commit, getters}, id) {

      if (getters.getClearTableData && getters.getClearTableData.length === 0) return false

      let newOrder = getters.getClearTableData.find(o => o.id === id)
      Object.assign(newOrder, {parent: {id: newOrder.id}})
      delete newOrder.status
      delete newOrder.id
      const {data} = await orderApi.createClientOrder(jsonToFormData(newOrder))
    },
    async FETCH_CLIENT_ORDERS({commit, getters, dispatch}, payload = {}) {
      let res;
      commit('UPDATE_BUSY_TABLE', true)
      if (payload.manager_id ) {
        commit('UPDATE_GLOBAL_STATE', {field: 'localManager_', value: payload.manager_id})
      }
      if (payload.manager_id && !payload.permission) {
        commit('UPDATE_FILTER', {field: 'manager_id', value: payload.manager_id})
      }

      try {
        const response = await dispatch('fetchClientOrders', payload)
        let {data} = response;
        res = data;

        commit('SET_TABLE_LOCALIZED_FIELDS', getters.getState.localizedFields)
        commit('SET_TABLE_FIELDS', getters.getState.queryFields)

        await commit('UPDATE_GLOBAL_STATE', {
          field: 'additionalData',
          value: {
            from: data.from,
            to: data.to,
            last_page: data.last_page,
            per_page: 30,//data.per_page,
            total: data.total,
            page: data.current_page
          }
        })
      } catch (e) {
        console.error(e)
        this._vm.$crmNotify.error('Ошибка получения заказов!')
      } finally {
        commit('UPDATE_BUSY_TABLE', true)
      }

      commit('UPDATE_BUSY_TABLE', false)
      return res;
    },

    async GET_CLIENT_ORDERS({commit, getters, dispatch}, payload = {}) { //actually this is a RESET method
      await commit('UPDATE_GLOBAL_STATE', { //page should reset
        field: 'additionalData',
        value: {
          page: 1
        }
      })
      const orders = await dispatch('FETCH_CLIENT_ORDERS', payload);
      commit('SET_CLIENT_ORDERS', orders.data);
    },

    async GET_CLIENT_ORDERS_ADDITIVE({commit, getters, dispatch}, payload = {}) {
      const orders = await dispatch('FETCH_CLIENT_ORDERS', payload);
      commit('ADD_CLIENT_ORDERS', orders.data);
    },

    async liveSearchOrderId({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await orderApi.getClientOrders(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'ordersList', value: data.data})
    },
    async liveSearchCompanies({commit}, payload) {
      const obj = {
        'search[name]': payload,
        'page-limit': -1
      }
      const {data} = await companyApi.getCompanies(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'companies', value: data.data})
    },
    async liveSearchContacts({commit}, payload) {
      const obj = {
        'search[full_name]': payload,
        'page-limit': -1
      }
      const {data} = await contactApi.getContacts(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'contacts_', value: data.data})
    },
    async liveSearchVehicleTypes({commit}, payload) {
      const obj = {
        'search[name]': payload,
        'page-limit': -1
      }
      const {data} = await helperApi.vehicleOrderTypes(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'vehicleTypes', value: data.data})
    },
    async liveSearchDeal({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await dealApi.getDeals(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'deals', value: data.data})
    },
    async liveSearchLocationLoading({commit, dispatch}, payload) {
      const res = await dispatch('liveSearchLocation', payload)

      commit('UPDATE_GLOBAL_STATE', {field: 'loadingLocations', value: res})
    },
    async liveSearchLocationUnloading({commit, dispatch}, payload) {
      const res = await dispatch('liveSearchLocation', payload)

      commit('UPDATE_GLOBAL_STATE', {field: 'loadingUnlocations', value: res})
    },
    async liveSearchLocation({}, payload) {
      const obj = {
        'search[address][short_address]': payload,
        'page-limit': -1
      }
      const {data} = await locationApi.getLocations(obj)
      return data.data
    },
    async liveSearchTransporterOrders({commit}, payload) {
      const obj = {
        'search[id]': payload,
        'page-limit': -1
      }
      const {data} = await orderApi.getTransporterOrders(obj)
      commit('UPDATE_GLOBAL_STATE', {field: 'transporterOrdersList', value: data.data})
    },
    async sendToChannel({commit}, payload) {
      await orderApi.sendDataToTg(payload)
    },
    resetFilter ({ commit }) {
      commit('UPDATE_FILTER', {
        field: 'status',
        value: [],
      })
    },
  },

}

export default CLIENT_ORDER_INDEX
