export default {
  COMMON_DATA_EDIT_TYPE: 1,
  ACCOUNT_NUMBERS_EDIT_TYPE: 2,
  CLIENT_FILES_TYPE: 3,
  TRANSPORTER_FILES_TYPE: 4,
  FIN_MONITORING_EDIT_TYPE: 5,
  BROKEN_PRODUCT_FILES_TYPE: 6,
  TEO_ACCOUNT_NUMBERS_EDIT_TYPE: 7,
  FREIGHT_MANAGER_INTERFACE: 8,
  TTN_CMR_EDIT_TYPE: 9
}
