import defaultState from './state'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

const cardFilter = {
	namespaced: true,
	state: defaultState,
	mutations,
	getters,
	actions
}

export default cardFilter