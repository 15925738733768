import state from './cfo/debtors/state'
import getters from './cfo/debtors/getters'
import mutations from './cfo/debtors/mutations'
import actions from './cfo/debtors/actions'

const DEBTORS_MODULE = {
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
}

export default DEBTORS_MODULE
