import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION
const SOCKET_NODE_URL = process.env.VUE_APP_SOCKET_NODE_URL
const SOCKET_SECRET = process.env.VUE_APP_SOCKET_SECRET

export default {
	getNBURate (code, date) {
		return axios.get(`${HOST_URL}/nbu`, {
			params: {
				code: code,
				date: date
			}
		})
	},
	downloadZipFile (modelKey, id, documentId = null) {
		return axios.get(`${HOST_URL}/zip-file/download/${modelKey}/${id}/${documentId}`, {
			responseType: 'arraybuffer',
			headers: {
				'Content-type': 'application/zip'
			}
		})
	},
	softDeleteFile (modelName, modelId) {
		let data
		if (typeof modelId === 'number') {
			data = {
				id: modelId
			}
		}
		if (typeof modelId === 'object' && modelId.length) {
			data = {
				ids: [...modelId]
			}
		}

		return axios.post(`${HOST_URL}/${modelName}`, data)
	},
	clearInvoice (dealID) {
		return axios.patch(`${HOST_URL}/invoice-clear/${dealID}`)
	},
	postReview (payload) {
		return axios.post(`${HOST_URL}/review`, payload)
	},
	deleteByKey (key, id, params = {}) {
		return axios.delete(`${HOST_URL}/${key}/${id}`, {params: params})
	},
	deleteContactMessenger (contactId, messengerId) {
		return axios.delete(`${HOST_URL}/contact/${contactId}/${messengerId}`)
	},
	createAndDownloadOrder (payload = {}) {
		return axios.post(`${HOST_URL}/get-order-file`, payload)
	},
	getCurrencies (payload = {}) {
		return axios.get(`${HOST_URL}/v${API_VERSION}/currency`, {
			params: payload
		})
	},
	getBorderCrossingPoints (payload = {}) {
		return axios.get(
			`${HOST_URL}/border-crossing-point`, {
				params: payload
			})
	},
	makeTempFile (file) {
		let formData = new FormData()
		formData.append('file', file.file || file.fileObject)
		let headers = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}

		return axios.post(`${HOST_URL}/upload-file`, formData, headers)
	},
	deleteEntity (entity, id) {
		return axios.delete(`${HOST_URL}/v${API_VERSION}/${entity}/${id}`)
	},
	loadFiles (payload = {}) {
		let formData = new FormData()
		let headers = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}

		formData.append('model_id', payload.model_id)
		formData.append('model', payload.model_type)

		for (let file of payload.files) {
			formData.append('document_type_id[]', file.document_type_id || file.document_type.id)
			formData.append('weight[]', file.weight)
			formData.append('files[]', file.file || file.fileObject)
		}

		return axios.post(`${HOST_URL}/file`, formData, headers)
	},
	getSocketQueue () {
		return axios.get(`${SOCKET_NODE_URL}/admin/queue`, {
			headers: {
				token: SOCKET_SECRET
			}
		})
	},
	removeSocketFromQueue (socketID, payload = {}) {
		return axios.delete(`${SOCKET_NODE_URL}/admin/queue/socket/${socketID}`, {
			params: payload,
			headers: {
				token: SOCKET_SECRET
			}
		})
	},
	resetEntityQueue (payload = {}) {
		return axios.delete(`${SOCKET_NODE_URL}/admin/queue`, {
			params: payload,
			headers: {
				token: SOCKET_SECRET
			}
		})
	},
	moveToLastPosition (socketID, payload = {}) {
		return axios.get(`${SOCKET_NODE_URL}/admin/queue/socket/move-to-end/${socketID}`, {
			params: payload,
			headers: {
				token: SOCKET_SECRET
			}
		})
	},
	moveToFirstPosition (socketID, payload = {}) {
		return axios.get(`${SOCKET_NODE_URL}/admin/queue/socket/move-to-start/${socketID}`, {
			params: payload,
			headers: {
				token: SOCKET_SECRET
			}
		})
	},
	deleteResponsible (contactId, type, id) {
		return axios.delete(`${HOST_URL}/responsible/${contactId}/${type}/${id}`)
	},
	addResponsible (payload) {
		return axios.post(`${HOST_URL}/responsible`, payload)
	},
	changeFileName (payload = {}) {
		return axios.post(`${HOST_URL}/change-file-name`, payload)
	},
	cropImage (payload = {}, headers = {}) {
		const formData = new FormData()
		formData.append('id', payload.fileID)
		formData.append('file', payload.file)

		return axios.post(`${HOST_URL}/upload-change-file`, formData, {
			headers
		})
	},
	storeFile (payload = {}) {
		let formData = new FormData()

		formData.append('file', payload.file)
		formData.append('id', payload.id)
		formData.append('model', payload.model)
		formData.append('document_type_id', payload.document_type_id)

		return axios.post(`${HOST_URL}/store-file`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}
}
