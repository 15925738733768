export default () => ({
	processing: false,

	id: null,
	card: null,
	first_name: null,
	middle_name: null,
	last_name: null,
	tax_number: null,
	companies: [],
	contacts: [],
	not_active: null,
	created_at: null,
	updated_at: null,
	full_name: null,
	is_short_info: null,
	managers: []
})