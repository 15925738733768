import Vue from 'vue'
import contactApi from '@/api/contact'
import driverApi from '@/api/driver'
import helperApi from '@/api/helper'
import MULTI_EDIT_CONTACTS from '@/store/modules/CRM/contact/multi-edit/index'
import exportToExcelHelper from '@/mixins/export-to-excel'

const defaultState = () => ({
	contacts: [],
	drivers: [],
	allTableFields: [],
	localizedFields: {},
	isBusyTable: false,

	additionalData: {
		per_page: 30,
		page: 1,
		total: 0,
		last_page: 0,
		sortBy: null,
		sortDesc: false
	}
})

const INDEX_MODULE = {
	namespaced: true,
	modules: {
		multi_edit: MULTI_EDIT_CONTACTS
	},
	state: defaultState(),
	getters: {
		getLocalPayload: (state) => {
			const args = {
				'page-limit': state.additionalData.per_page || 30,
				page: state.additionalData.page || 1
			}

			if (state.additionalData.sortBy) {
				args[`sorting[${state.additionalData.sortBy}]`] = !state.additionalData.sortDesc ? 'asc' : 'desc'
			}

			return args
		}
	},
	mutations: {
		MUTATION_SET_DRIVERS (state, drivers) {
			state.drivers = drivers
		},
		MUTATION_SET_CONTACTS (state, contacts) {
			state.contacts = contacts
		},
		UPDATE_BUSY_TABLE (state, value = false) {
			state.isBusyTable = value
		},
		SET_TABLE_LOCALIZED_FIELDS (state, fields) {
			state.localizedFields = fields
		},
		SET_TABLE_FIELDS (state, data) {
			state.allTableFields = data
		},
		SET_ADDITIONAL_DATA (state, data) {
			state.additionalData = data
		},
		SET_TABLE_PAGE (state, page) {
			state.additionalData.page = page
		},
		SET_PAGE_LIMIT (state, limit) {
			state.additionalData.per_page = limit
		},
		SET_SORT_DATA (state, {sortBy, sortDesc}) {
			Vue.set(state.additionalData, 'sortBy', sortBy)
			Vue.set(state.additionalData, 'sortDesc', sortDesc)
		}
	},
	actions: {
		/**
     * Decorator for fetch data for contacts or drivers model
     * @param commit
     * @param state
     * @param getters
     * @param rootGetters
     * @param type
     * @param errorMsg
     * @param payload
     * @returns {Promise<void>}
     */
		async fetchDataByType ({ state, commit, getters, rootGetters }, {
			type,
			errorMsg
		}) {
			commit('UPDATE_BUSY_TABLE', true)

			const args = {
				...rootGetters['CRM_CONTACT/filter/getContactFilterPayload'],
				...rootGetters['CRM_CONTACT/mailing/getLocalPayload'],
				...getters.getLocalPayload
			}

			try {
				let response = null

				if (type === 'contact') {
					response = await contactApi.getContacts(args)
				} else if (type === 'driver') {
					response = await driverApi.getDrivers(args)
				}

				const { data, localization } = response
				if (type === 'contact') {
					commit('MUTATION_SET_CONTACTS', data.data)
				} else if (type === 'driver') {
					commit('MUTATION_SET_DRIVERS', data.data)
					localization.deals_counter = '⭐'
				}
				commit('SET_TABLE_LOCALIZED_FIELDS', localization)
				commit('SET_TABLE_FIELDS', Object.keys(localization))

				commit('SET_ADDITIONAL_DATA', {
					from: data.from,
					to: data.to,
					last_page: data.last_page,
					per_page: data.per_page,
					total: data.total,
					page: data.current_page,
					sortBy: state.additionalData.sortBy || null,
					sortDesc: state.additionalData.sortDesc || null
				})
			} catch (e) {
				window.console.error(e)
				this._vm.$notify({
					type: 'error',
					text: errorMsg
				})
			} finally {
				commit('UPDATE_BUSY_TABLE', false)
			}
		},
		/**
     * Fetch contacts from backend
     * @param commit
     * @param state
     * @param dispatch
     * @param rootGetters
     * @param payload
     * @returns {Promise<*>}
     */
		getContacts ({ dispatch }, payload = {}) {
			return dispatch('fetchDataByType', {
				type: 'contact',
				errorMsg: 'Ошибка получения контактов!',
				payload: payload
			})
		},
		/**
     * Fetch drivers from backend
     * @param commit
     * @param state
     * @param dispatch
     * @param rootGetters
     * @param payload
     * @returns {Promise<*>}
     */
		getDrivers ({ dispatch }, payload = {}) {
			return dispatch('fetchDataByType', {
				type: 'driver',
				errorMsg: 'Ошибка получения водителей!',
				payload: payload
			})
		},
		/**
     * Set page limit and fetch contact
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setContactPageLimit ({ commit, dispatch }, payload) {
			commit('SET_PAGE_LIMIT', payload)

			return dispatch('getContacts')
		},
		/**
     * Set page limit and fetch drivers
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setDriverPageLimit ({ commit, dispatch }, payload) {
			commit('SET_PAGE_LIMIT', payload)

			return dispatch('getDrivers')
		},
		/**
     * Set page and fetch contacts
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setContactPage ({ commit, dispatch }, payload) {
			commit('SET_TABLE_PAGE', payload)

			return dispatch('getContacts')
		},
		/**
     * Set page and fetch drivers
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setDriverPage ({ commit, dispatch }, payload) {
			commit('SET_TABLE_PAGE', payload)

			return dispatch('getDrivers')
		},
		/**
     * Reset page and filter contacts
     * @param dispatch
     * @returns {*}
     */
		onFilterContacts ({ dispatch }) {
			return dispatch('setContactPage', 1)
		},
		/**
     * Reset page and filter drivers
     * @param dispatch
     * @returns {*}
     */
		onFilterDrivers ({ dispatch }) {
			return dispatch('setDriverPage', 1)
		},
		/**
     * Set sorting field and type of sort and fetch contacts
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setContactSorting ({ commit, dispatch }, payload) {
			commit('SET_SORT_DATA', payload)

			return dispatch('getContacts')
		},
		/**
     * Set sorting field and type of sort and fetch drivers
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {*}
     */
		setDriverSorting ({ commit, dispatch }, payload) {
			commit('SET_SORT_DATA', payload)

			return dispatch('getDrivers')
		},
		/**
		 * Export and download Excel representation from contacts table
		 * @param getters
		 * @param rootGetters
		 * @param name
		 * @returns {*}
		 */
		async exportTableToExcelByType ({ getters, rootGetters }, tableUrl) {
			try {
				const response = await helperApi.getCRMDataAsExcel(tableUrl, {
					...rootGetters['CRM_CONTACT/filter/getContactFilterPayload'],
					...getters.getLocalPayload,
					excel: true
				})

				return exportToExcelHelper(response, tableUrl + 's')
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка выгрузки файла' + e)
			}
		},

		exportContactsToExcel ({ dispatch }) {
			return dispatch('exportTableToExcelByType', 'contact')
		},

		exportDriversToExcel ({ dispatch }) {
			return dispatch('exportTableToExcelByType', 'driver')
		}
	}
}

export default INDEX_MODULE
