export default {
	getToken: (state) => state.token || '',
	isAuthorized: (state) => !!(state.token && state.user),
	getRoles: (state) => state.user ? state.user.roles : [],
	getPermissions: (state) => {
		if (!state.user) { return [] }

		const userPermissions = state.user.permissions.map(i => i.name)
		const permsArr = [].concat.apply([], state.user.roles.map(i => i.permissions))
		const rolePermissions = permsArr.map(i => i.name)

		return userPermissions.concat(rolePermissions)
	},
	userFullName: (state) => {
		if (state.user && state.user.contact) {
			return `${state.user.contact.first_name} ${state.user.contact.last_name}`
		}

		return null
	},

	userAvatar: (state) => {
		if (state.user && state.user.contact && state.user.contact.files && state.user.contact.files.length) {
			return state.user.contact.files.filter(el => el.document_type.id === 20)[0].link
		}

		return null
	},
	getUserEmail: (state) => state.user ? state.user.email : null,
	getUser: (state) => state.user,

	hasRoleGetter: (state, getters) => (permission) => getters.getRoles.map(i => i.name).includes(permission),
	canGetter: (state, getters) => (permission) => getters.getPermissions.includes(permission)
}
