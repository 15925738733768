import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
	getCompaniesData (payload = {}) {
		return axios.get(`${HOST_URL}/company`, {
			params: payload
		})
	},
	updateCompanyPhone (payload = {}) {
		return axios.post(`${HOST_URL}/company-phone`, payload)
	},
	getCompanies (payload = {'page-limit': 9999}) {
		return axios.get(`${HOST_URL}/company`, {
			params: payload
		})
	},
	searchCompanyByName (params = {}) {
		return axios.get(`${HOST_URL}/company`, {
			params: params
		})
	},
	getCompanyVehicles (id) {
		return axios.get(`${HOST_URL}/company-vehicles/${id}`)
	},
	getCompanyDataByID (id, payload = {}) {
		return axios.get(`${HOST_URL}/company/${id}`, {
			params: payload
		})
	},
	getCompanyDataByInn (payload) {
		return axios.get(`${HOST_URL}/opendatabot/company-data/${payload.inn}?force=${payload.force}`)
	},
	createOrUpdateCompany (payload) {
		return axios.post(`${HOST_URL}/constructor/company`, payload)
	},
	createCompanyRequisites (payload = {}, headers = {}) {
		return axios.post(`${HOST_URL}/requisites-of-company`, payload, {
			headers: headers
		})
	},
	updateCompanyRequisites (payload = {}, headers = {}) {
		return axios.put(`${HOST_URL}/requisites-of-company/${payload.id}`, payload, {
			headers: headers
		})
	},
	saveRelation (id, key, relatedKeyID) {
		return axios.post(`${HOST_URL}/constructor/company-relations`, {
			id,
			[key]: relatedKeyID
		})
	},
	deleteRelation (id, key, relatedKeyID) {
		return axios.delete(`${HOST_URL}/constructor/company-relations`, {
			params: {
				id,
				[key]: relatedKeyID
			}
		})
	},
	searchCompanies (queryString) {
		return axios.get(`${HOST_URL}/search/companies/${queryString}`)
	},
	addSubsidiary (payload = {}) {
		return axios.post(`${HOST_URL}/company/subsidiary`, payload)
	},
	deleteSubsidiary (parentId, childId) {
		return axios.delete(`${HOST_URL}/company/subsidiary/${parentId}/detach/${childId}`)
	},
	getHistory (id) {
		return axios.get(`${HOST_URL}/logger/company/${id}`)
	},
	checkCompanyForAvailability (id) {
	  return axios.get(`${HOST_URL}/company/is-available/${id}`)
	},
	addNewSubscriber (payload = {}) {
		return axios.post(`${HOST_URL}/writer-create`, payload)
	},
	deleteSubscriber (payload = {}) {
		return axios.delete(`${HOST_URL}/writer-destroy`, {
			params: payload
		})
	},
	updateSubscriber (payload = {}) {
		return axios.put(`${HOST_URL}/writer-update`, payload, {
			'_method': 'PUT'
		})
	}
}
