export default {
  id: 'ID',
  deal_status_id: 'Статус ID',
  date_loading: 'Дата загрузки',
  point_loadings: 'Город (откуда)',
  point_unloading: 'Город (куда)',
  client_name: 'Компания (З)',
  client_code: 'ЕДРПОУ (З)',
  client_legal_entity: 'Юр. лицо. (З)',
  client_cash_true: 'Фрахт. нал (З)',
  client_cash_true_payment_form: 'Ф.О. нал (З)',
  client_cash_false: 'Фрахт. безнал (З)',
  client_cash_false_payment_form: 'Ф.О. безнал (З)',
  client_delay: 'Отсрочка дней. (З)',
  client_dispatching: 'Сумма дисп. клиента (З)',
  client_document_is_order: 'Заявка (З)',
  client_transportation_number: '№ транспортировки (З)',
  docs_track: 'Трек доков',
  client_document_primary: 'Первичные доки (З)',
  client_notes_for_doc: 'Памятка к докам (З)',
  client_document_is_broken_document: 'Акты боя\\недостачи',
  manager_comment_client_docs: 'Коммент. М. к докам (З)',
  client_document_description: 'Комментарий к получ. докам (З)',
  client_document_date_send: 'Дата отправки документа (З)',
  client_document_invoice_number: 'Номер счета(З)',
  client_document_invoice_date: 'Дата форм.счета (З)',
  client_rnn: 'РНН (З)',
  client_rnn_blocked: 'Блокировка РНН (З)',
  client_document_number_declaration: '№ декларации Нов. Поч. (З)',
  client_get_docs_contact: 'Контакт, подтвердивший получение доков (З)',
  client_get_docs_date: 'Дата получения доков (З)',
  client_paid: 'Оплачено (З)',
  client_paid_date: 'Дата оплаты (З)',
  client_debt: 'Долг (З)',
  client_expiration_date: 'Дата просрочки (З)',
  transporter_name: 'Компания (П)',
  transporter_code: 'ЕДРПОУ (П)',
  transporter_driver_name: 'ФИО водителя (П)',
  transporter_driver_phone: 'Тел. водителя (П)',
  transporter_vehicle_types: 'Тип транспорта (П)',
  transporter_container_number: '№ контейнера (П)',
  transporter_marine_line: 'Название линии (П)',
  transporter_eco_standard: 'Стандарт выбросов',
  transporter_max_capacity: 'Тонаж (П)',
  transporter_volume: 'Объем (П)',
  transporter_car_brand: 'Марка авто (П)',
  transporter_numbers: 'Номера машины/полуприцепа (П)',
  transporter_legal_entity: 'Юр. лицо. (П)',
  transporter_cash_true: 'Фрахт. нал (П)',
  transporter_cash_true_payment_form: 'Ф.О. нал (П)',
  transporter_cash_false: 'Фрахт. безнал (П)',
  transporter_cash_false_payment_form: 'Ф.О. безнал (П)',
  transporter_delay: 'Отсрочка дней. (П)',
  transporter_document_is_order: 'Заявка (П)',
  is_files_cashiers: 'ТТН/CMR',
  transporter_soft_credit_card: '№ карты (по софту) (П)',
  transporter_soft_name: 'ФИО владельца карты (по софту) (П)',
  transporter_date_unloading: 'Дата выгрузки',
  ttn_track: 'Трек ТТН',
  transporter_document_ttn: 'Наличие доков (ТТН, оригиналы) (П)',
  transporter_document_date_send: 'Дата получения документов (П)',
  transporter_document_number_declaration: '№ декларации получ. доков (П)',
  transporter_postage: 'Затраты на получение (П)',
  transporter_medical_book: 'Мед.книжка',
  transporter_paid: 'Оплачено (П)',
  transporter_paid_date: 'Дата оплаты (П)',
  transporter_debt: 'Долг (П)',
  transporter_document_invoice_number: 'Номер счета (П)',
  transporter_document_invoice_date: 'Дата форм. счета (П)',
  transporter_rnn: 'РНН (П)',
  teo_company: 'Компания (ТЭО)',
  teo_freight: 'Фрахт (ТЭО)',
  teo_payment_form: 'Ф.О. (ТЭО)',
  teo_paid: 'Оплачено (ТЭО)',
  teo_paid_date: 'Дата оплаты (ТЭО)',
  teo_debt: 'Долг (ТЭО)',
  teo_document_invoice_number: 'Номер счета (ТЭО)',
  teo_document_invoice_date: 'Дата форм. счета (ТЭО)',
  teo_rnn: 'РНН (ТЭО)',
  description: 'Комментарий',
  mc_comment: 'Комментарии МЦ',
  inset: 'Номер вкладки',
  responsible_salesman: 'Отв. менеджер',
  logistical: 'Логист',
  commission: 'Комиссия',
  cargo_type: 'Груз',
  cargo_cost: 'Стоимость груза, грн',
  seller_weaving_amount: 'Сотка продажнику',
  seller_weaving_name: 'ФИО Продажника',
  logistic_weaving_amount: 'Сотка логиста',
  logistic_weaving_name: 'ФИО логиста',
  brokerage_name: 'ФИО Брокера',
  brokerage: 'Брокерские',
  department: 'Направление',
  speedometer_start: 'Выезд спидометр',
  speedometer_end: 'Заезд спидометр',
  fuel_remaining: 'Остаток топлива',
  fin_monitoring: 'Фин. мониторинг',
}
