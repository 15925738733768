import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
  getPriceMatrix (payload = {}) {
    return axios.get(`${HOST_URL}/matrix`, {
      params: payload,
    })
  },
}
