import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import {registerStore} from '@/mixins/registerStore'
import FINANCE_MODULE from '@/store/modules/finance'
import CRM_COMPANY from '@/store/modules/CRM/company.module'
import CRM_VEHICLE from '@/store/modules/CRM/vehicle.module'
import CRM_CONTACT from '@/store/modules/CRM/contact.module'
import CRM_DEAL from '@/store/modules/CRM/deal.module'

import store from '../store'

import authMiddleware from './middleware/auth'
import closedForAuth from './middleware/closedForAuth'
import defaultRedirectsMiddleware from './middleware/defaultRedirects'
import canMiddleware from './middleware/can'
import middlewarePipeline from './middleware/middlewarePipeline.js'

import CRM_ROUTES from './crm-routes'
import MC_ROUTER from './mc-router'
import TRANSPORT_DEPARTMENT_ROUTER from './analytics/transport-department.js'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: {
			title: 'Авторизация',
			middleware: [closedForAuth]
		}
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			middleware: [
				authMiddleware,
				defaultRedirectsMiddleware
			]
		}
	},
	{
		path: '/cfo',
		name: 'CFO',
		component: () => import(/* webpackChunkName: "cfo" */ '../views/CFO/BaseLayout.vue'),
		props: (route) => ({
			driver_id: route.query.driver,
			loading_from: route.query['loading-from'],
			loading_to: route.query['loading-to'],
			client_name: route.query.client
		}),
		meta: {
			title: 'Fox - Сделки',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to cfo table'])
			]
		}
	},
	{
		path: '/finance',
		name: 'Finance',
		component: () => import(/* webpackChunkName: "finance" */ '../views/Finance/BaseLayout.vue'),
		meta: {
			title: 'Fox - Реестр',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to finance registries'])
			]
		},
		beforeEnter (to, from, next) {
			registerStore('finance', FINANCE_MODULE)
			next()
		}
	},
	{
		path: '/rnn',
		name: 'RNN',
		component: () => import(/* webpackChunkName: "rnn" */ '../views/RNN/BaseLayout.vue'),
		meta: {
			title: 'Fox - РНН',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to rnn import'])
			]
		}
	},
	{
		path: '/cashless-separation',
		name: 'cashless_separation',
		component: () => import(
		  /* webpackChunkName: "cashless-separation" */ '../views/CashlessSeparation/BaseLayout.vue'
		),
		meta: {
			title: 'Fox - Разнос безнала',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to cashless import'])
			]
		}
	},
	{
		path: '/cashier',
		name: 'cashier',
		component: () => import(/* webpackChunkName: "cashier" */ '../views/Cashier/BaseLayout.vue'),
		meta: {
			title: 'Fox - Касса',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to cashier registry'])
			]
		}
	},
	{
		path: '/metrics',
		name: 'metrics',
		component: () => import(/* webpackChunkName: "metrics" */ '../views/Metrics/BaseLayout.vue'),
		meta: {
			title: 'Fox - Метрика',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to metrics'])
			]
		}
	},
	{
		path: '/bonus',
		name: 'bonus',
		component: () => import(/* webpackChunkName: "bonus" */ '../views/Bonus/BaseLayout.vue'),
		meta: {
			title: 'Fox - Премии',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to bonuses'])
			]
		}
	},
	{
		path: '/transactions',
		name: 'transactions',
		component: () => import(/* webpackChunkName: "bonus" */ '../views/Transactions/BaseLayout.vue'),
		meta: {
			title: 'Fox - Транзакции',
			middleware: [canMiddleware.bind(undefined, ['access to transactions'])]
		}
	},

	{
		path: '/admin',
		name: 'SocketConnections',
		component: () => import(/* webpackChunkName: "socket-connections" */ '../views/LiveConnectionsLayout.vue'),
		meta: {
			title: 'Fox - Сессии',
			middleware: [canMiddleware.bind(undefined, ['access to admin panel'])]
		}
	},

	{
		path: '/leads',
		name: 'LeadsManage',
		component: () => import(/* webpackChunkName: "leads" */ '../views/Leads/LeadView.vue'),
		meta: {
			title: 'Fox - Лиды',
			middleware: [canMiddleware.bind(undefined, ['access to leads registry'])]
		}
	},
	{
		path: '/leads/:id',
		name: 'LeadManage',
		component: () => import(/* webpackChunkName: "leads" */ '../views/Leads/LeadItemView.vue'),
		meta: {
			title: 'Лид',
			middleware: [canMiddleware.bind(undefined, ['access to leads registry'])]
		}
	},
	{
		path: '/changelog',
		name: 'Changelog',
		component: () => import(/* webpackChunkName: "changelog" */ '../views/Changelog.vue'),
		meta: {
			title: 'Обновления',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to view the list of updates'])
			]
		}
	},

	...CRM_ROUTES,
	...MC_ROUTER,
	...TRANSPORT_DEPARTMENT_ROUTER
]

if (process.env.NODE_ENV !== 'production') {
	routes.push({
		path: '/ui',
		name: 'UI',
		component: () => import(/* webpackChunkName: "ui" */ '../views/DEV/UIView.vue'),
		meta: {
			title: 'UI'
		}
	})
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || 'Не определено'

	if (to && to.path && to.path.indexOf('/cfo') > -1) {
		registerStore('CRM_DEAL', CRM_DEAL)
	}

	if (to && to.path && to.path.indexOf('/crm/') > -1) {
		registerStore('CRM_DEAL', CRM_DEAL)
		registerStore('CRM_VEHICLE', CRM_VEHICLE)
		registerStore('CRM_CONTACT', CRM_CONTACT)
		registerStore('CRM_COMPANY', CRM_COMPANY)

		const fromContactToDriverPage = from.name === 'CRM.CONTACT.INDEX' && to.name === 'CRM.DRIVER.INDEX'
		const fromDriverToContactPage = from.name === 'CRM.DRIVER.INDEX' && to.name === 'CRM.CONTACT.INDEX'
		if (fromContactToDriverPage || fromDriverToContactPage) {
			store.dispatch('CRM_CONTACT/filter/resetContactFilter')
			store.dispatch('CRM_CONTACT/index/multi_edit/resetAll')
		}
	}

	if (!to.meta.middleware) {
		return next()
	}
	const middleware = to.meta.middleware

	const context = {
		to,
		from,
		next,
		store,
		router
	}

	return middleware[0]({
		...context,
		nextMiddleware: middlewarePipeline(context, middleware, 1)
	})
})

export default router
