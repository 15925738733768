import Vue from 'vue'

const defaultState = () => ({
	liveSearch: null,
	byFields: [],
	responsible: null,
	type: null,
	transportation_types: [],
	is_not_resident: null,
	has_contract: null,
	have_original_flag: null,
	is_contract_active: null,
	companyName: null,
	ecode: null,
	directions: null,
	locations: null,
	vehicleType: null,
	have_tender_link: null,
	isCompanyInBlackList: null,
	capacityFrom: null,
	capacityTo: null,
	volumeFrom: null,
	volumeTo: null,
	ourCompany: null,
	phone: null,
	email: null,
	link: null,
	isContactSearch: false,
	contactFio: '',
	contactPhone: '',
	contactEmail: ''
})

const COMPANY_FILTER = {
	namespaced: true,
	state: defaultState(),
	getters: {
		getCompanyFilter: state => state,

		getManagersFilter: (state) => {
			const args = {}
			if (state.responsible === -1) {
				args['doesnt[managers]'] = true
			} else {
				args['search[managers]'] = state.responsible
			}

			return args
		},
		getCapacityOrVolumeFilter: () => (from, to) => {
			let query = ''

			if (from) {
				query = `>=|${from}`
			}
			if (to) {
				query += `${from && to ? '&' : ''}<=|${to}`
			}

			return query || null
		},
		getCompanyFilterPayload: (state, getters) => {
			const reorderTransportationTypes = (array) => {
				if (!array) { return [] }
				if (array.length === 1) { return array }
				if (array.length === 2) {
					return [
						'По Украине',
						'Международная'
					]
				}

				return []
			}


			const payloadForFilter = {
				'global-search': state.liveSearch || null,
				'search[code]': state.ecode || null,
				...getters.getManagersFilter || null,
				'search[type]': state.type || null,
				'search[transportation_types]': reorderTransportationTypes(
				  state.transportation_types.map(i => i.value)
				).join('; ') || null,
				'search[is_not_resident]': state.is_not_resident,
				'search[has_contract]': state.has_contract,
				'search[is_contract_active]': state.is_contract_active ? false : null,
				'search[name]': state.companyName || null,
				locations_address: state.locations || null,
				directions_address: state.directions || null,
				'filter[vehicles][vehicle_types][id]': state.vehicleType || null,
				'search[is_blocked]': state.isCompanyInBlackList,
				'search[have_tender_link]': state.have_tender_link || null,
				'search[our_company]': state.ourCompany || null,
				'search[phones]': state.phone || null,
				'search[emails]': state.email || null,
				'search[url]': state.link || null,
				'search[contacts][full_name]': state.contactFio || null,
				'search[contacts][emails][email]': state.contactEmail || null,
				'search[contacts][phones][phone]': state.contactPhone || null,
				'filter[vehicles][max_capacity]': getters.getCapacityOrVolumeFilter(
				  state.capacityFrom, state.capacityTo
				),
				'filter[vehicles][volume]': getters.getCapacityOrVolumeFilter(state.volumeFrom, state.volumeTo)
			}

			if (state.have_original_flag) {
				payloadForFilter['search[have_original]'] = true
			} else if (state.have_original_flag === false) {
				payloadForFilter['search[not_have_original]'] = true
			}

			if (state.byFields.length > 0) {
				const parsedFilterByFields = state.byFields.reduce((memo, item) => {
					const key = item.field
					if (!memo[key]) {
						memo[key] = []
					}
					memo[key].push(item.value)

					return memo
				}, {})
				for (const field in parsedFilterByFields) {
					if (field === 'id') {
						const str = 'filter[' + field + ']'
						payloadForFilter[str] = parsedFilterByFields[field].join(';')
					} else {
						const str = 'search[' + field + ']'
						payloadForFilter[str] = parsedFilterByFields[field].join(';')
					}
				}
			}

			return payloadForFilter
		}
	},
	mutations: {
		UPDATE_COMPANY_FILTER (state, payload) {
			Vue.set(state, payload.field, payload.value)
		},
		COMPANY_CLEAR_FILTER (state) {
			Object.assign(state, defaultState())
		},
		RESET_CONTACT_SUB_FILTER (state) {
			state.contactFio = ''
			state.contactPhone = ''
			state.contactEmail = ''
		}
	},
	actions: {
		setInitialFilterSettings ({
			commit,
			rootGetters
		}) {
			const canSeeEntireData = rootGetters.canGetter('access to complete index list')

			if (!canSeeEntireData && !rootGetters.canGetter('access to all companies')) {
				commit('UPDATE_COMPANY_FILTER', {
					field: 'responsible',
					value: rootGetters.getUser.contact.full_name
				})
			} else if (!rootGetters.canGetter('crm access to the filter by responsible') && !canSeeEntireData) {
				commit('UPDATE_COMPANY_FILTER', {
					field: 'responsible',
					value: rootGetters.getUser.contact.full_name
				})
			}
		}
	}
}

export default COMPANY_FILTER
