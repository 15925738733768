import Vue from "vue";
import locationAPI from "@/api/location"

const mergeLocations = {
    namespaced: true,
    state: {
        selectedLocations: {},
        locationToMerge: {}
    },
    getters: {
        getSelectedLocationsIds(state) {
            return Object.values(state.selectedLocations).reduce((memo, cur) => {
                memo.push(...cur)
                return memo
            }, [])
        },
        isAllCheckboxesChecked: (state, getters, rootState) => {
            const currentPageLocations = state.selectedLocations[rootState.CRM_LOCATION.additionalData.page]
            const rootStateLocations = rootState.CRM_LOCATION.locations
            return rootStateLocations.map(el => el.id).every(item => currentPageLocations ? currentPageLocations.includes(item) : false)
        },

        checkIfSelectedExistInLocationToMerge(state, getters, rootState) {
            return Object.values(state.selectedLocations).reduce((memo, cur) => {
                memo.push(...cur)
                return memo
            }, []).some(loc => loc === state.locationToMerge.id)
        }
    },
    mutations: {
        PUSH_LOCATION_TO_SELECTED(state, payload) {
            if (!state.selectedLocations[payload.page]) {
                Vue.set(state.selectedLocations, payload.page, []);
                state.selectedLocations[payload.page].push(payload.value)
            } else if (state.selectedLocations[payload.page] && !state.selectedLocations[payload.page].includes(payload.value)) {
                state.selectedLocations[payload.page].push(payload.value)
            } else {
                const index = state.selectedLocations[payload.page].indexOf(payload.value)
                if (index >= 0) {
                    state.selectedLocations[payload.page].splice(index, 1)
                }
            }
        },

        PUSH_ALL_TO_SELECTED(state, payload) {
            Vue.set(state.selectedLocations, payload.page, payload.locations)
        },

        SET_LOCATION_TO_MERGE(state, payload) {
            Vue.set(state, 'locationToMerge', payload)
        },

        RESET_SELECTED_ON_PAGE(state, page) {
            Vue.set(state.selectedLocations, page, []);
        },

        RESET_LOCATION_TO_MERGE(state, payload){
            Vue.set(state, 'locationToMerge', {})
        },

        RESET_STATE(state){
            state.selectedLocations = {}
            state.locationToMerge = {}
        }

    },
    actions: {
        selectAll({commit, getters, rootState}, payload) {
            const locations = rootState.CRM_LOCATION.locations.map(l => l.id)
            const page = rootState.CRM_LOCATION.additionalData.page
            if (!payload) {
                commit('PUSH_ALL_TO_SELECTED', {
                    page,
                    locations
                })
            } else {
                commit('RESET_SELECTED_ON_PAGE', page)
            }
        },

        selectLocationIdToMerge({commit, state}, payload) {
            if (payload.id !== state.locationToMerge.id) {
                commit('SET_LOCATION_TO_MERGE', {
                    id: payload.id,
                    address: payload.address
                })
            } else {
                commit('RESET_LOCATION_TO_MERGE')
            }
        },

        async mergeLocations({commit, state, getters}) {
            try {
                await locationAPI.mergeLocations({
                    ids: getters.getSelectedLocationsIds,
                    id: state.locationToMerge.id
                })
                this._vm.$crmNotify.success('Локации успешно объеденины!')
            } catch (e) {
                this._vm.$crmNotify.error('Не удалось объеденить локации!', e)
                console.error(e)
            } finally {
                commit('RESET_STATE')
            }
        },

        async deleteLocations({commit, getters}) {
            try {
                const response = await locationAPI.deleteLocations({
                    ids: getters.getSelectedLocationsIds
                })
                    if (response.data.blocked.length) {
                    this._vm.$crmNotify.warning(`Локации ${response.data.blocked} участвуют в сделке. Удаление не возможно!`)
                } else {
                    this._vm.$crmNotify.success('Локации успешно удалены!')
                }
            } catch (e) {
                this._vm.$crmNotify.error('Не удалось удалить локации!', e)
                console.error(e)
            } finally {
                commit('RESET_STATE')
            }
        }
    }
}

export default mergeLocations