import CreditCardsAPI from '@/api/credit-cards';

export default {
	async getCreditCards ({commit, state, rootGetters}) {
		commit('SET_BUSY_TABLE_FLAG', true)
		try {
			let args = {
				'object': true,
				'page-limit': state.additionalData.per_page || 30,
				'page': state.additionalData.page || 1,
				...rootGetters['CREDIT_CARDS_MODULE/filter/getCreditCardsFilterPayload']
			}
			const { data, localization } = await CreditCardsAPI.getCreditCards(args)
			commit('SET_CREDIT_CARDS', data.data)
			commit('SET_TABLE_FIELDS', Object.keys(localization))
			commit('SET_TABLE_LOCALIZED_FIELDS', localization)
			commit('SET_ADDITIONAL_DATA', {
				per_page: data.per_page,
				last_page: data.last_page,
				total: data.total,
				page: data.current_page,
				from: data.from,
				to: data.to
			})
		} catch (e) {
			console.error(e);
			this._vm.$crmNotify.warning('Ошибка получения карт! ' + e)
		} finally {
			commit('SET_BUSY_TABLE_FLAG', false)
		}
	},
	async deleteCreditCard ({ dispatch }, payload) {
		try {
			if (!confirm('Вы действительно хотите удалить карту?')) {
				return false
			}
			await CreditCardsAPI.deleteCreditCard(payload)
			this._vm.$crmNotify.success('Карта успешно удалена!')

			return dispatch('getCreditCards')

		} catch (e) {
			console.error(e)
			this._vm.$crmNotify.warning('Ошибка удаления карты! ' + e)
		}
	}
}