import defaultState from './fields-management/state'
import getters from './fields-management/getters'
import mutations from './fields-management/mutations'
import actions from './fields-management/actions'

const FIELDS_MANAGEMENT_MODULE = {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions
}

export default FIELDS_MANAGEMENT_MODULE
