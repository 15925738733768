import databotApi from '@/api/databot'

const DATABOT_STATE = {
	namespaced: true,
	state: {
		processing: false,
		data: null,
		resultsMapper: {
			'ceo': 'Компанiй',
			'old_ceo': 'Минулий власник в компанiях',
			'beneficiaries': 'Власникiв компанiй',
			'old_beneficiaries': 'Минулий директор в компанiях',
			'wanted': 'Особи якi у розшуку',
			'sessions': 'Пошук у судових справах',
			'fop': 'ФОП',
			'aliments': 'Боржникiв по алiментах',
			'corrupt_officials': 'Корупціонери'
		},
		grantedDriverCategories: [
			'wanted',
			'sessions',
			'fop',
			'aliments'
		],
		selectedCategory: null,
		selectedIndex: null,
		name: null,
		birthday: null,

		isFullInfo: false,
		keyForFilter: null,
		shortInfo: null,
		fullInfo: null,
		last_request: null,
		id: null,
		isError: false
	},
	getters: {
		getCategoryDataByKey: state => {
			let key = null
			const companyKeys = [
				'ceo',
				'old_ceo',
				'beneficiaries',
				'old_beneficiaries'
			]
			const personsKeys = [
				'wanted',
				'fop'
			]

			if (companyKeys.includes(state.selectedCategory)) {
				key = 'companies'
			} else if (personsKeys.includes(state.selectedCategory)) {
				key = 'persons'
			}

			return key || state.selectedCategory
		},
		getDataByCategory: (state) => {
			if (!state.isFullInfo && state.shortInfo && state.shortInfo.length) {
				return state.shortInfo
			} else if (!state.isFullInfo && state.shortInfo && !state.shortInfo.length) {
				return []
			}

			return state.fullInfo

		},
		getCategoryDataBySelectedIndex: (state, getters) => getters.getDataByCategory[state.selectedIndex],
		isSelectedCompany: (state, getters) => getters.getCategoryDataByKey === 'companies',
		isSelectedWantedCategory: state => state.selectedCategory === 'wanted',
		isSelectedSessionsCategory: state => state.selectedCategory === 'sessions',
		isSelectedFopCategory: state => state.selectedCategory === 'fop',
		isSelectedAlimentsCategory: state => state.selectedCategory === 'aliments',
		isDatabotResultIsNotEmpty: state => Object.keys(state.resultsMapper).find(i => state.data[i].count > 0),
		isVisibleCategory: (state, getters, rootState, rootGetters) => category => (
		    rootGetters['CRM_CONTACT/contactIsDriver'] ?
				state.grantedDriverCategories.includes(category) : true) &&
        state.data[category].count,
		getDatabotCategories: state => Object.keys(state.resultsMapper)
	},
	mutations: {
		SET_NAME (state, {
			first_name,
			middle_name,
			last_name
		}) {
			state.name = [last_name, first_name, middle_name].reduce((memo, name) => {
				if (name) {
					memo.push(name)
				}

				return memo
			}, []).join(' ')
		},
		SET_FULL_INFO_FLAG (state) {
			state.isFullInfo = !state.isFullInfo
		},
		SET_DATA (state, data) {
			state.data = data
		},
		SET_BIRTHDATE (state, date) {
			state.birthday = date
		},
		SELECT_CATEGORY_INDEX (state, payload) {
			state.selectedIndex = payload
		},
		SELECT_CATEGORY (state, payload) {
			state.selectedCategory = payload
		},
		FILTER_KEY (state, key) {
			state.keyForFilter = key
		},
		SET_SHORT_INFO (state, shortInfo) {
			state.shortInfo = shortInfo
		},
		SET_FULL_INFO (state, fullInfo) {
			state.fullInfo = fullInfo
		},
		SET_LOADING_FLAG (state, flag = false) {
			state.processing = flag
		},
		RESET_STATE (state) {
			state.data = null
			state.selectedCategory = null
			state.selectedIndex = null
			state.name = null
			state.birthday = null
			state.isError = null
		},
		SET_ERROR_FLAG (state, flag) {
			state.isError = flag
		},
		SET_FIELD (state, {field, value}) {
			state[field] = value
		}
	},
	actions: {
		selectedCategory ({ commit, dispatch }, category) {
			commit('SELECT_CATEGORY', category)
			dispatch('selectCategoryFilterKey', category)
			dispatch('getShortAndFullInfo')
		},
		getShortAndFullInfo ({ commit, state, rootState, getters }) {
			const fullInfo = state.selectedCategory && getters.getCategoryDataByKey ?
				state.data[state.selectedCategory][getters.getCategoryDataByKey] : []
			const regExp = new RegExp(rootState.CRM_CONTACT.contact.full_name)
			const shortInfo = fullInfo ? fullInfo.filter(info => regExp.test(info[state.keyForFilter])) : []

			commit('SET_SHORT_INFO', shortInfo)
			commit('SET_FULL_INFO', fullInfo)
		},
		selectCategoryFilterKey ({ commit }, category) {
			let key = null
			switch (category) {
			case 'sessions':
				key = 'involved'
				break
			case 'fop':
			case 'wanted':
			case 'aliments':
				key = 'full_name'
				break
			case 'companies':
			case 'beneficiaries':
			case 'ceo':
			case 'old_ceo':
				key = 'ceo_name'
				break
			default: key = ''
			}
			commit('FILTER_KEY', key)
		},
		selectCategoryIndex ({ commit }, index) {
			commit('SELECT_CATEGORY_INDEX', index)
		},
		async fetchPersonInfo ({ dispatch, rootGetters }, payload) {
			let data = null
			if (payload && payload.person && !rootGetters['CRM_CONTACT/isContactNameChanged']) {
				data = await dispatch('getPersonFromState', payload)
			} else if (rootGetters['CRM_CONTACT/isContactNameChanged'] || !payload.person) {
				data = await dispatch('getPersonInfo', payload)
				this.commit('CRM_CONTACT/UPDATE_CONTACT_STATE', { field: '_fullName', value: payload })
			}

			return this.dispatch('CRM_CONTACT/savePersonDatabotInfo', data)
		},
		getPersonFromState ({
			commit
		}, statePayload) {
			if (statePayload) {
				commit('SET_NAME', statePayload)
				commit('SET_BIRTHDATE', statePayload.birth_date)
				commit('SET_DATA', statePayload.person)
				commit('SET_FIELD', {
					field: 'last_request',
					value: statePayload.last_request
				})
			}

			return statePayload
		},
		async getPersonInfo ({
			state,
			commit
		}, payload) {
			let databotInfo = null

			try {
				commit('SET_NAME', payload)
				commit('SET_BIRTHDATE', payload.birth_date)
				commit('SET_LOADING_FLAG', true)
				commit('SET_ERROR_FLAG', false)
				commit('SET_FIELD', {
					field: 'id',
					value: payload.id
				})

				databotInfo = await databotApi.getPersonInfo(
					state.name,
					state.birthday,
					state.id
				)

				if (databotInfo && databotInfo.person) {
					commit('SET_DATA', databotInfo.person)
					commit('SET_FIELD', {
						field: 'last_request',
						value: databotInfo.last_request
					})
				}
			} catch (e) {
				window.console.error(e)
				commit('SET_ERROR_FLAG', true)
			} finally {
				commit('SET_LOADING_FLAG', false)
			}

			return databotInfo || null
		},
		setFullInfoFlag ({ commit }) {
			commit('SET_FULL_INFO_FLAG')
		},
		resetDatabotState ({ commit }) {
			commit('RESET_STATE')
		}
	}
}

export default DATABOT_STATE
