export default {
	getDateRangeHelper (state) {
	  return (key) => {
			const delimiter = ' — '
			const range = {
				start: null,
				end: null
			}

			if (state[key]) {
				if (state[key].indexOf(delimiter) > -1) {
					const array = state[key].split(delimiter)
					range.start = array[0] || null
					range.end = array[1] || null
				} else {
					range.start = state[key]
					range.end = state[key]
				}
			}

			return range
		}
	},
	getFilterPayload (state, getters) {
	  const dateLoadingInfo = getters.getDateRangeHelper('loadingDateRange')

		return {
	    'loading_date_from': dateLoadingInfo.start,
	    'loading_date_to': dateLoadingInfo.end,
			'loading_from': state.loadingFrom,
			'loading_to': state.loadingTo,
			'vehicle_type_id': state.vehicleType,
			'capacity_from': state.capacityFrom,
			'capacity_to': state.capacityTo,
			'volume_from': state.volumeFrom,
			'volume_to': state.volumeTo
		}
	}
}
