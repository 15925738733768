import PHONE_MODULE from '@/store/modules/login/phone'
import VERIFICATION_MODULE from '@/store/modules/login/verification'
import expressAgent from 'express-useragent/lib/express-useragent.min'
import router from '@/router'
import lsKeys from '@/config/local-storage-keys'
import md5 from 'crypto-js/md5'
import { StatusCodes } from 'http-status-codes'
import loginTypes from '@/config/login-types'
import userApi from '@/api/user'
import axios from 'axios'
import roles from '@/config/roles.config'

import state from './state'
import getters from './getters'
import mutations from './mutations'

const login = {
	namespaced: true,
	modules: {
		phone: PHONE_MODULE,
		verification: VERIFICATION_MODULE
	},
	state: state(),
	getters: getters,
	mutations: mutations,
	actions: {
		async authenticate ({ getters, rootGetters }, credentials = {}) {
			const response = await userApi.login(credentials.login, credentials.password, credentials.key)
			const { data } = response

			if (response.code === StatusCodes.OK) {
				this.commit('SET_USER_DATA', {
					user: data.user,
					token: data.token
				})
				axios.defaults.headers.common.Authorization = data.token

				if (getters.currentRoute.query && getters.currentRoute.query.redirect) {
					await router.push({
						name: getters.currentRoute.query.redirect,
						params: {
							...(getters.currentRoute.query.redirect_params || {})
						},
						query: {
							...(getters.currentRoute.query.redirect_query || {})
						}
					})

					return true
				}

				if (rootGetters.getRoles.length === 1 && rootGetters.getRoles[0].name === roles.ROLE_CASHIER) {
					await router.push({ name: 'cashier' })
				} else if (rootGetters.getRoles.length === 1 && rootGetters.getRoles[0].name === roles.ROLE_MC) {
					await router.push({ name: 'MONITORING_CENTER' })
				} else if (rootGetters.canGetter('access to cfo table')) {
					await router.push({ name: 'CFO' })
				} else {
					await router.push({ name: 'Home' })
				}

				return true
			}

			return false
		},
		async authenticateUser ({
			state,
			commit,
			dispatch
		}) {
			try {
				commit('phone/UPDATE_LOGIN_STATE', {
					field: 'loading',
					value: true
				})

				return await dispatch('authenticate', {
					login: state.phone.login,
					password: state.phone.password,
					key: state.sessionId
				})
			} catch (e) {
				if (
					e &&
          e.response &&
          e.response.data &&
          e.response.data.code === StatusCodes.FORBIDDEN
				) {
					const data = e.response.data.errors

					if (data) {
						commit('SET_ACTION_TYPE', data.type)

						// eslint-disable-next-line default-case
						switch (data.type) {
						case loginTypes.SUPPORT_TYPE:
						case loginTypes.DEVICE_REJECTED_TYPE: {
							this._vm.$crmNotify.warning(data.messages)

							break
						}
						case loginTypes.PHONE_CODE_SEND_TYPE: {
							commit('SET_MESSAGE', data.messages)

							await router.push({
								name: 'Login',
								query: {
									s: state.steps.submitSmsCode
								}
							}).catch(() => {})

							await dispatch('verification/setUntilCount', data.messages)
							await dispatch('verification/startCountdown')

							break
						}
						case loginTypes.CONFIRM_PHONE_TYPE: {
							commit('SET_MESSAGE', data.messages)

							await router.push({
								name: 'Login',
								query: {
									s: state.steps.submitSmsCode
								}
							}).catch(() => {})

							await dispatch('verification/startCountdown')

							break
						}

						case loginTypes.DEVICE_ON_APPROVE_TYPE:
						case loginTypes.BANNED_TYPE:
						case loginTypes.COMPROMISED_TYPE:
						case loginTypes.DEVICE_APPROVING_TYPE: {
							commit('SET_MESSAGE', data.messages)

							await router.push({
								name: 'Login',
								query: {
									s: state.steps.wait
								}
							}).catch(() => {})

							break
						}
						}
					}
				}
			} finally {
				commit('phone/UPDATE_LOGIN_STATE', {
					field: 'loading',
					value: false
				})
			}
		},
		verifyBySmsCode ({ dispatch }) {
			return dispatch('verification/onSubmit')
		},
		// async handleAwesomeAuth ({ state, commit, rootState, dispatch }) {
		//   if (rootState.token) {
		//     return false
		//   }
		//
		//   const userAgentData = expressAgent.UserAgent().parse(window.navigator.userAgent)
		//   const lsItem = state.userAgentStorageData
		//   let isNewSession = true
		//
		//   if (userAgentData && !lsItem && rootState.token) {
		//     commit('SET_USER_AGENT_DATA', userAgentData)
		//   } else if (userAgentData && lsItem) {
		//     const userAgentData = expressAgent.UserAgent().parse(window.navigator.userAgent)
		//
		//     isNewSession = !!Object.keys(state.userAgentStorageData).filter((key) => {
		//       return ['geoIp', 'version'].includes(key)
		//         ? false
		//         : userAgentData[key] !== state.userAgentStorageData[key]
		//     }).length
		//   }
		//
		//   console.log(await dispatch('generateSessionId'))
		//
		//   return isNewSession
		// },
		handleAwesomeSessionId ({ commit }) {
			const id = localStorage.getItem(lsKeys.SESSION_ID_KEY)

			if (id) {
				commit('SET_SESSION_ID', id)

				return id
			}

			const userAgentData = expressAgent.UserAgent().parse(window.navigator.userAgent)

			delete userAgentData.version
			delete userAgentData.geoIp

			const timestamp = +new Date()
			const hash = md5(JSON.stringify(userAgentData) + timestamp).toString()

			localStorage.setItem(lsKeys.SESSION_ID_KEY, hash)
			commit('SET_SESSION_ID', hash)

			return hash
		}
	}
}

export default login
