<template>
  <label class="fox-input-wrapper">
    <div v-if="$slots.prepend" class="prepend-element">
      <slot name="prepend" />
    </div>

    <div v-if="label || $slots.label" class="fox-input-label">
      <div v-if="label && !$slots.label">{{ label }}
        <span v-if="required" class="required">*</span>
      </div>
      <slot v-else-if="$slots.label" name="label" />
    </div>

    <input
      :id="id"
      v-model="vModel"
      class="fox-input"
      :class="{
        'fox-input__sm': size === 'sm'
      }"
      :type="type"
      :step="type === 'number' ? step : 'any'"
      :placeholder="placeholder"
      :disabled="disabled"
      @keypress="onKeyPress"
    >

    <div v-if="$slots.append" class="append-element">
      <slot name="append" />
    </div>
  </label>
</template>

<script>
export default {
	name: 'FoxInput',
	props: {
		value: {
			required: false,
			default: '',
			type: [String, Number]
		},
		id: {
			required: false,
			default: '',
			type: String
		},
		type: {
			required: false,
			default: 'text',
			type: String
		},
		placeholder: {
			required: false,
			default: '',
			type: String
		},
		disabled: Boolean,
		size: {
			required: false,
			default: 'md',
			type: String
		},
		label: {
			required: false,
			default: '',
			type: String
		},
    step: {
      required: false,
      default: '.1',
      type: String
    },
		required: Boolean
	},
	computed: {
		vModel: {
			get () {
				return this.value
			},
			set (value) {
				this.$emit('input', value)
			}
		},
		isFilled () {
			return this.vModel && this.vModel.length
		}
	},
	methods: {
		onKeyPress ($event) {
			if (this.type !== 'number') {
				return true
			}

			const charCode = (typeof $event.which === 'undefined') ? $event.keyCode : $event.which
			const charStr = String.fromCharCode(charCode)
      const allowedSymbols = ['.', ',']

      if (allowedSymbols.includes(charStr)) {
        return true
      }

			if (!/\d/.test(charStr)) {
				$event.preventDefault()

				return false
			}
		}
	}
}
</script>

<style scoped lang="sass">
@import '../../scss/components/input'
</style>
