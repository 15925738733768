import jsonToFormData from '@/mixins/jsonToFormData'
import { get } from '@/mixins/light_lodash'
import Vue from 'vue'

import contactApi from '@/api/contact'

import CONTACT_FILTER from '@/store/modules/CRM/contact/filter'
import COPY_STATE from '@/store/modules/CRM/contact/copy'
import INDEX_MODULE from '@/store/modules/CRM/contact/index'
import MAILING_STATE from '@/store/modules/mailing'

import contactTypes from '@/config/contact-types'
import { getAvatarsFromFiles } from '@/business-logic/contact'
import appendContractToFormData from '@/business-logic/form-data/contract-form-data'
import stuffApi from '@/api/stuff'
import { CLIENT_TYPE } from '@/config/company-type.config'


const LOGO_ID = 20
const SKYPE_ID = 2
const TELEGRAM_ID = 1
const PHYSICAL_CONTACT_TAX_NUMBER = 10

const defaultDriverState = () => ({
	id: null,
	vehicles: [],
	separated_vehicles: { vehicle: [], trailer: [] },
	driver_license_number: '',
	files: [],
	lpr_contact: null,
	direct_transporter: true,
	dispatchers: [],
	med_book: false,
	forwarders: [],
	driver_review_count: {
		deals_count: 0
	},
	number_check_result: [],
	_license_number: null,
	vehicleInfo: null
})

const defaultSmallState = () => ({
	id: null,
	companies: [],
	contact_types: [],
	deals_count: 0,
	driver: {},
	first_name: null,
	full_name: null,
	last_name: null,
	middle_name: null,
	is_blocked: null,
	managers: [],
	name_surname: null,
	reviews: [],
	separated_vehicles: [],
	last_deal: null
})

const defaultState = () => ({
	id: null,
	selectedCompanyId: null,
	contact_types: [],
	first_name: null,
	last_name: null,
	middle_name: null,
	tax_number: null,
	passport_number: null,
	passport_issuer: null,
	passport_issue_date: null,
	passport_address: null,
	birthday: null,
	gender: null,
	is_lpr: false,
	review_required: false,
	phones: [],
	telegram: [],
	skype: [],
	emails: [],
	files: [],
	contact_access: [],
	managers: [],
	credit_cards: [],
	contracts: [],
	companies: [],
	lpr_contacts: [],
	lpr_drivers: [],
	reviews: [],
	driver: defaultDriverState(),
	is_blocked: false,
	deals_count: 0,
	related_contacts: [],
	all_related_contacts: [],
	dispatcher_for: [],
	person: null,
	description: '',
	contactDuplicates: [],

	responseFromOpenData: [],
	isOpenDataLoading: false,
	isCheckDriverClicked: false,
	isCheckPersonClicked: false,
	isVehicleUpdated: false,
	_fullName: null,
	user: null
})

const CRM_CONTACT = {
	namespaced: true,
	modules: {
		filter: CONTACT_FILTER,
		copy: COPY_STATE,
		index: INDEX_MODULE,
		mailing: MAILING_STATE
	},
	state: {
		contact: defaultState(),
		contactOptions: [],
		processing: false
	},
	getters: {
		isFullContact: (state) => Object.keys(state.contact).length > (Object.keys(defaultSmallState()).length + 3),
		getContactName: (state) => {
			if (!state.contact) {
				return '-'
			}

			return `${state.contact.last_name || ''} ${state.contact.first_name || ''}`
		},
		getContactTypes: (state) => state.contact.contact_types.map(type => type.name).join(', '),
		checkIsDriverDataExist: (state) => state.contact.driver.number_check_result ?
			!!state.contact.driver.number_check_result.length : false,
		checkIsPersonDataExist: (state) => !!state.contact.person,
		isContactNameChanged: (state, getters) => {
			if (state.contact._fullName && getters.contactIsDriver) {
				return state.contact._fullName.first_name !== state.contact.first_name ||
            state.contact._fullName.last_name !== state.contact.last_name ||
            state.contact._fullName.middle_name !== state.contact.middle_name
			}
		},
		isDriverLicenseNumberChanged: (state, getters) => {
			if (state.contact.driver._license_number && getters.contactIsDriver) {
				return state.contact.driver._license_number !== state.contact.driver.driver_license_number
			}
		},
		getContactState: state => state.contact,
		getDriverState: state => state.contact.driver,
		getContactPhoto: (state) => {
			const photos = getAvatarsFromFiles(state.contact.files)

			if (photos.length > 0) {
				return photos[photos.length - 1]
			}

			return null
		},
		reviewRating: (state) => state.contact &&
      state.contact.driver &&
      state.contact.driver.driver_review_count
			? state.contact.driver.driver_review_count.deals_count
			: 0,
		getReviewsByKey: state => (key) => state.contact.reviews.filter(i => i.type === key),
		getReviewsPositive: (state, getters) => getters.getReviewsByKey('positive'),
		getReviewsNegative: (state, getters) => getters.getReviewsByKey('negative'),
		getContactOptions: state => state.contactOptions.length > 0
			? state.contactOptions.map((contact) => {
				contact.label = contact.full_name
				contact.type = contact.is_blocked ? 'not-selectable' : ''

				return contact
			})
			: [],
		getAvatar: (state, getters) => {
			const file = getters.getContactPhoto

			return file ? (file.base64 || file.link || URL.createObjectURL(file.fileObject)) : null
		},
		companiesIsNotClients: (state) => state.contact.companies.reduce((memo, cur) => {
			if (cur && cur.company_types) {
				memo.push(...cur.company_types)
			}

			return memo
		}, []).every(id => id.id !== CLIENT_TYPE),
		checkContactTypeByType: state => (typeID) => !!state.contact.contact_types.find(type => type.id === typeID),
		contactIsLogist: (state, getters) => getters.checkContactTypeByType(contactTypes.LOGIST),
		contactIsDirector: (state, getters) => getters.checkContactTypeByType(contactTypes.DIRECTOR),
		contactIsFO: (state, getters) => getters.checkContactTypeByType(contactTypes.FO),
		contactIsDriver: (state, getters) => getters.checkContactTypeByType(contactTypes.DRIVER),
		contactIsLogistOrDirector: (state, getters) => getters.contactIsLogist || getters.contactIsDirector,
		contactIsLPR: (state) => !!state.contact.is_lpr,
		isContactFilledPassportData: (state, getters) => {
			if (!getters.contactIsFO) {
				return true
			}

			return !!(state.contact.tax_number &&
        state.contact.passport_number &&
        state.contact.passport_issue_date &&
        state.contact.passport_issuer &&
        state.contact.passport_address)
		},
		isContactNotFilledPassportData: (state, getters) => !getters.isContactFilledPassportData,
		contactTaxNumberIsValid: (state) => state.contact.tax_number &&
            ('' + state.contact.tax_number).length === PHYSICAL_CONTACT_TAX_NUMBER,

		contactRelation: (state) => {
			let relation = null
			const relatedContacts = state.contact.related_contacts
			const dispatchers = state.contact.dispatcher_for

			if (relatedContacts && relatedContacts.length) {
				relation = 'related_contacts'
			} else if (dispatchers && dispatchers.length) {
				relation = 'dispatcher_for'
			}

			const lastRelation = state.contact[relation]
				? state.contact[relation][state.contact[relation].length - 1]
				: null

			return {
				key: relation,
				label: lastRelation ? (lastRelation.name || lastRelation.full_name) : null
			}
		}
	},
	mutations: {
		MUTATION_SET_CONTACT (state, contact) {
			const _contact = { ...contact }

			if (contact) {
				if (contact.driver && !contact.driver.id) {
					_contact.driver = defaultDriverState()
				}
				if (contact.messengers_login) {
					_contact.telegram = contact.messengers_login.filter(i => i.type === TELEGRAM_ID)
					_contact.skype = contact.messengers_login.filter(i => i.type === SKYPE_ID)
				}
			}

			if (
				contact &&
        contact.related_contacts &&
        contact.all_related_contacts
			) {
				_contact.related_contacts = contact.all_related_contacts
			}
			Vue.set(state, 'contact', _contact)
		},
		CLEAR_EMPTY_DRIVER_TRANSPORTS (state) {
			if (state.contact && state.contact.driver && state.contact.driver.separated_vehicles) {
				const statePath = state.contact.driver.separated_vehicles
				statePath.vehicle = statePath.vehicle.filter(v => v)
				statePath.trailer = statePath.trailer.filter(t => t)
			}
		},

		UPDATE_CONTACT_STATE (state, payload) {
			Vue.set(state.contact, payload.field, payload.value)
		},
		MUTATION_ADD_CONTACT_PHOTOS (state, file) {
			state.contact.files.push(file)
		},
		MUTATION_ADD_REVIEW (state, review) {
			state.contact.reviews.push(review)
		},
		RESET_CONTACT_STATE (state) {
			const s = defaultState()
			Object.keys(s).forEach((key) => {
				Vue.set(state.contact, key, s[key])
			})
		},
		DELETE_ITEM_BY_KEY (state, data) {
			get(state.contact, data.field).splice(data.value, 1)
			// state.contact[data.field].splice(data.value, 1)
		},

		SET_COMPANY_OPTIONS (state, payload) {
			state.contactOptions = payload
		},
		PUSH_TO_VEHICLES (state, payload) {
			if (payload.index || payload.index === 0) {
				Vue.set(state.contact.driver.separated_vehicles[payload.type], payload.index, payload.value)
			} else {
				state.contact.driver.separated_vehicles[payload.type].push({ ...payload.value })
			}
		},
		UPDATE_CONTACT_TELEGRAM (state, payload) {
			if (payload.index || payload.index === 0) {
				Vue.set(state.contact.telegram, payload.index, payload.value)
			} else {
				state.contact.telegram.push({ ...payload.value })
			}
		},
		UPDATE_CONTACT_SKYPE (state, payload) {
			if (payload.index || payload.index === 0) {
				Vue.set(state.contact.skype, payload.index, payload.value)
			} else {
				state.contact.skype.push({ ...payload.value })
			}
		},
		UPDATE_CONTACT_DRIVER (state, payload) {
			Vue.set(state.contact.driver, payload.field, payload.value)
		},
		PUSH_TO_COMPANIES (state, payload) {
			state.contact.companies.push(payload)
		},
		PUSH_TO_WORKERS (state, payload) {
			state.contact.lpr_contacts.push(payload)
		},
		PUSH_DRIVER_FORWARDERS (state, payload) {
			if (!state.contact.driver.forwarders) {
				Vue.set(state.contact.driver, 'forwarders', [])
			}
			state.contact.driver.forwarders.push(payload)
		},
		PUSH_DRIVER_DISPATCHERS (state, payload) {
			if (!state.contact.driver.dispatchers) {
				Vue.set(state.contact.driver, 'dispatchers', [])
			}
			state.contact.driver.dispatchers.push(payload)
		},
		PUSH_CONTACT_LPR_DRIVERS (state, payload) {
			if (!state.contact.lpr_drivers) {
				Vue.set(state.contact, 'lpr_drivers', [])
			}
			state.contact.lpr_drivers.push(payload)
		},
		UPDATE_CONTRACTS (state, payload) {
			if (!state.contact.contracts) {
				Vue.set(state.contact, 'contracts', [])
			}
			if (payload.index !== null && payload.index >= 0) {
				Vue.set(state.contact.contracts, payload.index, payload.value)
			} else {
				state.contact.contracts.push(payload.value)
			}
		},
		PUSH_CONTACT_DATA_BY_KEY (state, payload) {
			if (!state.contact[payload.field]) {
				state.contact[payload.field] = []
			}
			state.contact[payload.field].push(payload.value)
		},
		SET_CONTACT_SHORT_INFO (state, payload) {
			state.contact.first_name = payload.first_name
			state.contact.last_name = payload.last_name
			state.contact.middle_name = payload.middle_name
			state.contact.birthday = payload.date_birth
		},
		SET_INN_DATA (state, payload) {
			state.contact.tax_number = payload.code
			state.contact.first_name = payload.first_name
			state.contact.last_name = payload.last_name
			state.contact.middle_name = payload.middle_name
			state.contact.birthday = payload.date_birth
		},
		DELETE_RELATION (state, payload) {
			state.contact[payload.key].splice(payload.index, 1)
		},
		SET_PHONES_NOT_DEFAULT (state) {
			const phones = (state.contact.phones || []).reduce((memo, phone) => {
				const _phone = { ...phone }
				_phone.is_default = false

				memo.push(_phone)

				return memo
			}, [])

			Vue.set(state.contact, 'phones', phones)
		},
		SET_PHONE_BY_INDEX (state, payload) {
			Vue.set(state.contact.phones, payload.index, payload.value)
		},
		PUSH_PHONE_TO_CONTACT (state, payload) {
			if (!state.contact.phones) {
				Vue.set(state.contact, 'phones', [])
			}
			state.contact.phones.push(payload)
		},
		UNSHIFT_PHONE_TO_CONTACT (state, payload) {
			if (!state.contact.phones) {
				Vue.set(state.contact, 'phones', [])
				state.contact.phones.push(payload)
			} else {
				Vue.set(state.contact, 'phones', [
					payload,
					...state.contact.phones
				])
			}
		},
		SET_EMAIL_BY_INDEX (state, payload) {
			Vue.set(state.contact.emails, payload.index, payload.value)
		},
		PUSH_EMAIL_TO_CONTACT (state, payload) {
			if (!state.contact.emails) {
				Vue.set(state.contact, 'emails', [])
			}
			state.contact.emails.push(payload)
		},
		SET_CREDIT_CARDS_BY_INDEX (state, payload) {
			Vue.set(state.contact.credit_cards, payload.index, payload.value)
		},
		UPDATE_CREDIT_CARD_BY_ID (state, payload) {
			const index = state.contact.credit_cards.findIndex(card => card.id === payload.id)
			if (index !== -1) {
				Vue.set(state.contact.credit_cards, index, payload.data)
			}
		},
		PUSH_CREDIT_CARDS_TO_CONTACT (state, payload) {
			if (!state.contact.credit_cards) {
				Vue.set(state.contact, 'credit_cards', [])
			}
			state.contact.credit_cards.push(payload)
		},
		SET_LINK_BY_INDEX (state, payload) {
			Vue.set(state.contact.contact_access, payload.index, payload.value)
		},
		PUSH_LINK_TO_CONTACT (state, payload) {
			if (!state.contact.emails) {
				Vue.set(state.contact, 'contact_access', [])
			}
			state.contact.contact_access.push(payload)
		},
		SET_PROCESSING_FLAG (state, flag) {
			state.processing = flag
		},
		ADD_COMPANY_TYPES (state, payload) {
			const currCompany = state.contact.companies.find(i => i.id === state.contact.selectedCompanyId)
			if (currCompany) {
				Vue.set(currCompany, 'company_types', payload)
			}
		},
		CHANGE_IMAGE_TEMP_URL (state, payload) {
			Vue.set(state.contact.files[payload.index], 'tmp_path', payload.path)
			Vue.set(state.contact.files[payload.index], 'base64', payload.base64)
    },
		PUSH_TO_JUNIORS_BOSSES(state, {type, model}) {
			if (!state.contact.user[type]) {
				state.contact.user[type] = []
			}

			state.contact.user[type].push(model)
		},
		UPDATE_OR_PUSH_BY_MODEL (state, {
			file,
			index,
			model,
			modelIndex
		}) {
			if (index === null || index === undefined) {
				const files = state.contact[model][modelIndex].files

				if (!files) {
					state.contact[model][modelIndex].files = []
				}

				state.contact[model][modelIndex].files.push(file)
			} else {
				Vue.set(state.contact[model][modelIndex].files, index, file)
			}
		},
		SET_FILES_TO_MODEL(state, {model, files, id}) {
			const modelIdx = state.contact[model].findIndex(m => m.id === id)

			if (modelIdx !== -1) {
				Vue.set(state.contact[model][modelIdx], 'files', files)
			}
		}
	},
	actions: {
		async GET_CONTACT ({ commit, dispatch, rootState }, id) {
			try {
				commit('SET_PROCESSING_FLAG', true)

				const { data } = await contactApi.getContactById(id)
				if (data && !data.managers.length && rootState.user && rootState.user.contact) {
					data.managers = [{...rootState.user.contact}]
				}

				commit('MUTATION_SET_CONTACT', data)

				commit('UPDATE_CONTACT_STATE', {
					field: '_fullName',
					value: {
						first_name: data.first_name,
						last_name: data.last_name,
						middle_name: data.middle_name
					}
				})
				commit('UPDATE_CONTACT_DRIVER', {
					field: '_license_number',
					value: data.driver.driver_license_number
				})
				commit('CLEAR_EMPTY_DRIVER_TRANSPORTS')
				dispatch('copy/preUpdateDefaultPhones')
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка получения контакта! ' + e)
				window.console.error(e)
			} finally {
				commit('SET_PROCESSING_FLAG', false)
			}
		},

		async setCompanyBlockedStatus ({ state }) {
			try {
				await contactApi.postContact({
					id: state.contact.id,
					is_blocked: state.contact.is_blocked
				})

				this._vm.$crmNotify.success(
					state.contact.is_blocked
						? 'Вы добавили контакт в черный список'
						: 'Вы удалили контакт с черного списка'
				)
			} catch (e) {
				this._vm.$crmNotify.warning('Whoopse! не получилось, пожалуйста обратитесь в IT отдел!' + e)
				window.console.error(e)
			}
		},

		async GET_CONTACT_BY_PHONE ({ commit }, payload = {}) {
			const response = await contactApi.getContacts({
				'search[full_name]': payload,
				object: true
			})
			const { data } = response
			commit('SET_COMPANY_OPTIONS', data.data)
		},

		postReview ({ commit }, payload) {
			commit('MUTATION_ADD_REVIEW', payload)
		},

		async POST_CONTACT ({ getters, state, rootGetters }) {
			const getMessengerData = () => {
				const tel = state.contact.telegram.map(t => ({
					login: t.login,
					id: t.id ? t.id : '',
					type: TELEGRAM_ID
				}))
				const skype = state.contact.skype.map(t => ({
					login: t.login,
					id: t.id ? t.id : '',
					type: SKYPE_ID
				}))

				return [...tel, ...skype]
			}

			const contact = {
				id: state.contact.id,
				contact_types: state.contact.contact_types.map(i => ({
					id: i.id
				})),
				first_name: state.contact.first_name || null,
				last_name: state.contact.last_name || null,
				middle_name: state.contact.middle_name || null,
				tax_number: state.contact.tax_number,
				passport_number: state.contact.passport_number,
				passport_issuer: state.contact.passport_issuer,
				passport_issue_date: state.contact.passport_issue_date,
				passport_address: state.contact.passport_address,
				birthday: state.contact.birthday,
				gender: state.contact.gender,
				is_lpr: state.contact.is_lpr,
				phones: state.contact.phones.reduce((memo, phone) => {
					if (phone) {
						phone.is_for_login = !!phone.is_for_login
						memo.push(phone)
					}

					return memo
				}, []),
				messengers_login: getMessengerData(),
				emails: state.contact.emails,
				managers: state.contact.managers.map(i => ({
					id: i.id
				})),
				credit_cards: state.contact.credit_cards,
				companies: state.contact.companies,
				lpr_drivers: state.contact.lpr_drivers ? state.contact.lpr_drivers.map(i => ({ id: i.id })) : [],
				lpr_contacts: state.contact.lpr_contacts ? state.contact.lpr_contacts.map(i => ({ id: i.id })) : [],
				related_contacts: state.contact.related_contacts ?
					state.contact.related_contacts.map(i => ({ id: i.id })) : [],
				dispatcher_for: state.contact.dispatcher_for ?
					state.contact.dispatcher_for.map(i => ({ id: i.id })) : [],
				is_blocked: state.contact.is_blocked,
				person: state.contact.person,
				description: state.contact.description,
				last_request: state.contact.last_request
			}

			if (rootGetters.canGetter('review contacts & companies') || state.contact.isVehicleUpdated) {
				contact.review_required = state.contact.isVehicleUpdated ? true : state.contact.review_required
			}

			const isDriver = state.contact.contact_types.filter(i => i.id === 8).length >= 1

			if (isDriver) {
				contact.driver = {
					id: state.contact.driver.id,
					driver_license_number: state.contact.driver.driver_license_number,
					lpr_contact: state.contact.driver.lpr_contact,
					direct_transporter: state.contact.driver.direct_transporter,
					med_book: state.contact.driver.med_book,
					forwarders: state.contact.driver.forwarders ?
						state.contact.driver.forwarders.map(i => ({ id: i.id })) : [],
					dispatchers: state.contact.driver.dispatchers ?
						state.contact.driver.dispatchers.map(i => ({ id: i.id })) : [],
					number_check_result: state.contact.driver.number_check_result &&
                    state.contact.driver.number_check_result.length ? state.contact.driver.number_check_result : ''
				}
			}

			try {
				const _mapIterator = file => ({
					document_type_id: file.document_type.id,
					model_type: file.model_type,
					model_id: file.model_id,
					weight: 0,
					files: file.fileObject || null,
					name: file.name || file.original_name || null,
					tmp_path: file.tmp_path || null
				})

				const contactsStateArr = getters.getContactState &&
                getters.getContactState.files ? getters.getContactState.files : []
				const obj = contactsStateArr.reduce((memo, val) => {
					if (val.fileObject || val.tmp_path) {
						const documentType = val.document_type &&
                        val.document_type.id ? val.document_type.id : val.document_type_id
						if (+documentType !== LOGO_ID) {
							memo.contactFiles.push(_mapIterator(val))
						} else {
							memo.logoFiles.push(_mapIterator(val))
						}
					}

					return memo
				}, { contactFiles: [], logoFiles: [] })

				const contactFormData = jsonToFormData(contact, {
					includeNullValues: true,
					fieldsForNullValue: [
						'is_lpr',
						'driver[direct_transporter]',
						'driver[lpr_contact][id]',
						'driver[forwarders]',
						'driver[dispatchers]',
						'driver[number_check_result]'
					]
				})

				if (!state.contact.id) {
					appendContractToFormData(state.contact.contracts, contactFormData)
				}

				// TODO fixme DRY violation, refactor
				state.contact.contact_access.forEach((value, index) => {
					if (value.id) {
						contactFormData.append(`contact_access[${index}][id]`, value.id)
					}
					contactFormData.append(`contact_access[${index}][url]`, value.url ? value.url : '')
					contactFormData.append(`contact_access[${index}][link_type][id]`, value.link_type.id)
					contactFormData
						.append(`contact_access[${index}][description]`, value.description ? value.description : '')
					contactFormData.append(`contact_access[${index}][login]`, value.login ? value.login : '')
					contactFormData.append(`contact_access[${index}][password]`, value.password ? value.password : '')
					value.files.forEach((v, i) => {
						if (v.fileObject) {
							contactFormData
								.append(`contact_access[${index}][files][${i}][document_type_id][]`, v.document_type.id)
							contactFormData.append(`contact_access[${index}][files][${i}][weight][]`, 0)
							contactFormData.append(`contact_access[${index}][files][${i}][files][]`, v.fileObject)
						}
					})
				})

				const addVehicleToResult = function (value, index) {
					if (value.id) {
						contactFormData.append(`driver[vehicles][${index}][id]`, value.id)
					}
					// TODO fixme refactor - DRY violation && contactFormData is out of scope
					if (value.files) {
						value.files.forEach((v, i) => {
							if (v.fileObject) {
								contactFormData
									.append(`contact_access[${index}][files][${i}][document_type_id][]`,
										v.document_type.id)
								contactFormData.append(`contact_access[${index}][files][${i}][weight][]`, 0)
								contactFormData.append(`contact_access[${index}][files][${i}][files][]`, v.fileObject)
							}
						})
					}
				}

				if (isDriver &&
                    (!state.contact.id ||
                        state.contact.driver &&
                        !state.contact.driver.id)
                    && state.contact.driver.separated_vehicles
                    && state.contact.driver.separated_vehicles.vehicle.length > 0) {
					state.contact.driver.separated_vehicles.vehicle.forEach((value, index) => {
						addVehicleToResult(value, index)
					})
				}

				if (isDriver &&
                    (!state.contact.id ||
                        state.contact.driver &&
                        !state.contact.driver.id) &&
                    state.contact.driver.separated_vehicles &&
                    state.contact.driver.separated_vehicles.trailer.length > 0) {
					const vehiclesCount = state.contact.driver.separated_vehicles.vehicle.length
					state.contact.driver.separated_vehicles.trailer.forEach((value, index) => {
						addVehicleToResult(value, index + vehiclesCount)
					})
				}

				const filesFormAppend = (val, index, keys) => {
					keys.forEach((k) => {
						if (!val[k]) {
							return
						}
						const path = `files[${index}][${k}][]`
						let value = val[k]

						if (val.tmp_path) {
							if (k === 'files') {
								value = ''
							}
							if (k === 'tmp_path') {
								contactFormData.append(`files[${index}][name]`, val.name)
								contactFormData.append(`files[${index}][tmp_path]`, val.tmp_path)

								return
							}
						}

						contactFormData.append(path, value)
					})
				}

				const files = [...obj.contactFiles.filter(f => f.tmp_path), ...obj.logoFiles]

				files.forEach((value, index) => filesFormAppend(value, index,
					['document_type_id', 'weight', 'files', 'tmp_path']))

				if (obj.contactFiles.length > 0) {
					contactFormData.set('review_required', true)
				}

				const { data } = await contactApi.postContact(contactFormData, {
					'Content-Type': 'multipart/form-data'
				})

				this._vm.$notify({
					type: 'success',
					text: 'Контакт успешно сохранен!'
				})

				// todo: IF YOU WANT MAKE SOME MAGIC PLACE IT BELOW THIS LINE
				return data
			} catch (e) {
				console.error(e)
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка создания контакта!',
					message: e
				})

				return e.response
			}
		},

		async updateContactFields ({ state }, payload = {}) {
			let contact = {
				id: state.contact.id
			}
			if (payload.type === 'contact') {
				const data_ = state.contact[payload.field] ? state.contact[payload.field] : []
				contact = {
					...contact,
					[payload.field]: [...data_, payload.value].map(i => ({ id: i.id || i.contact_id }))
				}
			}

			const isDriver = state.contact.contact_types.filter(i => i.id === 8).length >= 1

			if (isDriver && payload.type === 'driver') {
				const data_ = state.contact.driver[payload.field] ? state.contact.driver[payload.field] : []
				contact.driver = {
					id: state.contact.driver.id,
					[payload.field]: [...data_, payload.value].map(i => ({ id: i.id || i.contact_id }))
				}
			}

			const contactFormData = jsonToFormData(contact, {
				includeNullValues: true,
				fieldsForNullValue: [
					'companies',
					'lpr_contacts',
					'driver[forwarders]',
					'driver[dispatchers]'
				]
			})

			try {
				const { data } = await contactApi.postContact(contactFormData, {
					'Content-Type': 'multipart/form-data'
				})

				this._vm.$notify({
					type: 'success',
					text: 'Контакт успешно сохранен!'
				})

				return data
			} catch (e) {
				this._vm.$notify({
					type: 'error',
					text: 'Ошибка создания контакта!',
					message: e
				})

				return false
			}
		},

		/**
     * Update contact types for contact
     * @param state
     * @returns {*|Promise<AxiosResponse<T>>}
     */
		updateContactTypes ({ state }) {
			return contactApi.postContact({
				id: state.contact.id,
				contact_types: state.contact.contact_types
			})
		},

		/**
     * Reset entire contact state
     * @param commit
     */
		resetContactState ({ commit, dispatch }) {
			commit('RESET_CONTACT_STATE')

			dispatch('copy/resetContactCopyState')
		},

		/**
     * Update contact avatar
     * @param commit
     * @param file
     */
		async updateContactAvatar ({ commit }, file) {
			if (file) {
				const { data } = await stuffApi.makeTempFile({ file })

				commit('MUTATION_ADD_CONTACT_PHOTOS', {
					document_type: { id: 20 },
					tmp_path: data.tmp_path,
					base64: data.base64,
					name: data.name,
					weight: 0,
					original_name: file.name,
					newlyAdded: true
				})
			}
		},
		async saveRelation ({ state, commit }, { payload, relation, stateRelation }) {
			if (payload) {
				try {
					if (state.contact.id) {
						await contactApi.saveRelation(state.contact.id, relation, payload.id)
					}
				} catch (e) {
					console.error(e)
					this._vm.$crmNotify.warning(
						'Ошибка сохранения связи! Пожалуйста, сохраните карточку полностью! ' +
            e)
				} finally {
					commit('PUSH_CONTACT_DATA_BY_KEY', {
						field: stateRelation,
						value: payload
					})
				}
			}

			return this
		},
		saveRelationRelatedContacts ({ dispatch }, payload) {
			return dispatch('saveRelation', {
				payload,
				relation: 'related_contacts',
				stateRelation: 'related_contacts'
			})
		},
		saveRelationDispatcherFor ({ dispatch }, payload) {
			return dispatch('saveRelation', {
				payload,
				relation: 'dispatcher_for',
				stateRelation: 'dispatcher_for'
			})
		},
		saveRelationWorksFor ({ dispatch }, payload) {
			return dispatch('saveRelation', {
				payload,
				relation: 'lpr_contacts',
				stateRelation: 'lpr_contacts'
			})
		},
		async getContactsInfoByINN ({ state }) {
			let contacts = []

			try {
				const response = await contactApi.searchByINN(state.contact.tax_number)

				contacts = response && response.data ? response && response.data : []
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка поиска по ИНН!', e)
				console.error(e)
			}

			return contacts
		},
		async getContactsInfoByNameAndBirthday ({ state }) {
			let contacts = []

			try {
				const response = await contactApi.searchByNameAndBirthdate({
					last_name: state.contact.last_name || null,
					first_name: state.contact.first_name || null,
					middle_name: state.contact.middle_name || null,
					birth: state.contact.birthday || null
				})

				contacts = response && response.data ? response && response.data : []
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка поиска по фамилии и дате рождения!', e)
				console.error(e)
			}

			return contacts
		},
		setContactShortInfo ({ commit }, payload) {
			commit('SET_CONTACT_SHORT_INFO', payload)
		},
		setContactInnData ({ commit }, payload) {
			commit('SET_INN_DATA', payload)
		},
		savePersonDatabotInfo ({
			commit
		}, payload) {
			if (payload && payload.person) {
				commit('UPDATE_CONTACT_STATE', {
					field: 'person',
					value: payload.person
				})
				commit('UPDATE_CONTACT_STATE', {
					field: 'last_request',
					value: payload.last_request
				})
			}
		},
		fillContactPassportAddress ({ commit }, address) {
			commit('UPDATE_CONTACT_STATE', {
				field: 'passport_address',
				value: address
			})
		},
		changeReviewRequiredFlag ({ commit }, flag) {
			commit('UPDATE_CONTACT_STATE', {
				field: 'review_required',
				value: flag
			})
		},
		async savePhoneWithFormer ({ state }, phone) {
			try {
				await contactApi.updateContactPhone({
					...phone,
					phone_id: phone.id,
					contact_id: state.contact.id,
					former: phone.former
				})
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка сохранения телефона у контакта! ', e)
			}
		},
		async updateContactPhones ({ state, commit, dispatch }, payload) {
			const [index, phone] = payload

			if (phone.is_default) {
				commit('SET_PHONES_NOT_DEFAULT')
			}

			if (state.contact && state.contact.id && phone.id) {
				await dispatch('savePhoneWithFormer', phone)
			}

			if (index !== null) {
				commit('SET_PHONE_BY_INDEX', {
					index,
					value: { ...phone }
				})
			} else if (phone.is_default) {
				commit('UNSHIFT_PHONE_TO_CONTACT', phone)
			} else {
				commit('PUSH_PHONE_TO_CONTACT', phone)
			}
		},
		deleteEmailFromContact ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'emails',
				index
			})
		},
		deleteContractFromContact ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'contracts',
				index
			})
		},
		updateEmailsInContact ({ commit }, payload) {
			const [index, email] = payload

			if (index !== null) {
				commit('SET_EMAIL_BY_INDEX', {
					index,
					value: email
				})
			} else {
				commit('PUSH_EMAIL_TO_CONTACT', email)
			}
		},
		updateCreditCardsInContact ({ commit }, payload) {
			const [index, card] = payload

			if (index !== null) {
				commit('SET_CREDIT_CARDS_BY_INDEX', {
					index,
					value: card
				})
			} else {
				commit('PUSH_CREDIT_CARDS_TO_CONTACT', card)
			}
		},
		deleteLinkFromContact ({ commit }, index) {
			commit('DELETE_RELATION', {
				key: 'contact_access',
				index
			})
		},
		updateLinksInContact ({ commit }, payload) {
			const [index, link] = payload

			if (index !== null) {
				commit('SET_LINK_BY_INDEX', {
					index,
					value: link
				})
			} else {
				commit('PUSH_LINK_TO_CONTACT', link)
			}
		},
		async checkPhoneInDb ({ commit }, phone) {
			try {
				commit('SET_PROCESSING_FLAG', true)

				const response = await contactApi.searchByPhone(phone)

				return response && response.data ? response.data : []
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка проверки телефона! ' + e)

				return []
			} finally {
				commit('SET_PROCESSING_FLAG', false)
			}
		},
		async checkContactInDbByINN ({ commit }, inn) {
			try {
				commit('SET_PROCESSING_FLAG', true)

				const response = await contactApi.searchByInnInDB(inn)

				return response && response.data ? response.data : []
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка проверки инн! ' + e)

				return []
			} finally {
				commit('SET_PROCESSING_FLAG', false)
			}
		},

		async checkContactInDbByTax ({ commit }, tax) {
			try {
				commit('SET_PROCESSING_FLAG', true)

				const response = await contactApi.searchByTaxInDB(tax)

				return response && response.data ? response.data : []
			} catch (e) {
				console.error(e)

				this._vm.$crmNotify.warning('Ошибка проверки тех паспорта! ' + e)

				return []
			} finally {
				commit('SET_PROCESSING_FLAG', false)
			}
		},
		setContactBaseShortInfo ({ commit }, payload) {
			const { phone, inn, tax, type } = payload

			commit('PUSH_CONTACT_DATA_BY_KEY', {
				field: 'phones',
				value: {
					phone
				}
			})
			commit('UPDATE_CONTACT_STATE', {
				field: 'tax_number',
				value: inn
			})

			if (tax) {
				commit('UPDATE_CONTACT_DRIVER', {
					field: 'driver_license_number',
					value: tax
				})
			}

			if (type) {
				commit('PUSH_CONTACT_DATA_BY_KEY', {
					field: 'contact_types',
					value: type
				})
			}
		},
		async checkDriverLicense ({ commit, state, getters, dispatch }) {
			try {
				if (!getters.checkIsDriverDataExist || getters.isDriverLicenseNumberChanged) {
					await dispatch('refreshDriverLicenseData')
				} else {
					commit('UPDATE_CONTACT_STATE', {
						field: 'responseFromOpenData',
						value: state.contact.driver.number_check_result
					})
				}
			} catch (e) {
				console.error(e)
				this._vm.$crmNotify.error(e)
			} finally {
				commit('UPDATE_CONTACT_DRIVER', {
					field: '_license_number',
					value: state.contact.driver.driver_license_number
				})
				commit('UPDATE_CONTACT_STATE', {
					field: 'isCheckDriverClicked',
					value: true
				})
				commit('UPDATE_CONTACT_STATE', {
					field: 'isOpenDataLoading',
					value: false
				})
			}
		},

		async refreshDriverLicenseData ({ state, commit }) {
			commit('UPDATE_CONTACT_STATE', {
				field: 'isOpenDataLoading',
				value: true
			})
			try {
				const response = await contactApi.checkDriverLicense({
					license: state.contact.driver.driver_license_number
				})
				commit('UPDATE_CONTACT_DRIVER', {
					field: 'number_check_result',
					value: response.data
				})
				commit('UPDATE_CONTACT_STATE', {
					field: 'responseFromOpenData',
					value: response
				})
			} catch (e) {
				console.error(e)
				this._vm.$crmNotify.error(e)
				commit('UPDATE_CONTACT_STATE', {
					field: 'responseFromOpenData',
					value: []
				})
				commit('UPDATE_CONTACT_DRIVER', {
					field: 'number_check_result',
					value: []
				})
			} finally {
				commit('UPDATE_CONTACT_STATE', {
					field: 'isOpenDataLoading',
					value: false
				})
			}
		},

		async getCompanyTypesByID ({ commit, state, dispatch }) {
			const selectedCompany = await dispatch('updateContactFields')
			const selectedCompanyTypes = selectedCompany
				.companies.find(i => i.id === state.contact.selectedCompanyId).company_types
			if (selectedCompany && selectedCompanyTypes) {
				commit('ADD_COMPANY_TYPES', selectedCompanyTypes)
			}
		},

		async deleteResponsible ({ state }, index) {
			try {
				if (state.contact.id && index >= 0 && state.contact.managers[index]) {
					await stuffApi.deleteResponsible(
						state.contact.managers[index].id,
						'contacts',
						state.contact.id
					)
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка удаления ответственного!' + e)
			}
		},

		async addResponsible ({ state }, contactId) {
			try {
				if (contactId) {
					await stuffApi.addResponsible({
						contact_id: contactId,
						type: 'contacts',
						id: state.contact.id
					})
				}
			} catch (e) {
				this._vm.$crmNotify.warning('Ошибка добавления ответственного!' + e)
			}
		},

		resetContactDuplicates ({commit}) {
			commit('UPDATE_CONTACT_STATE', {
				field: 'contact_duplicates',
				value: []
			})
		},

		pushJuniorsBossesToUser ({commit}, payload) {
			commit('PUSH_TO_JUNIORS_BOSSES', payload)
		},
		addFileToModel ({state, commit}, {file, index, model, modelId}) {
			const modelIndex = state.contact[model].findIndex(m => m.id === modelId)

			if (modelIndex === -1) {
				return false
			}

			commit('UPDATE_OR_PUSH_BY_MODEL', {
				file,
				index,
				model,
				modelIndex
			})
		},
	}
}

export default CRM_CONTACT
