import * as Sentry from '@sentry/browser'

export default function sentrySetUser (user = {}) {
	const _user = {...user}

	// delete sensitive data
	if (_user && _user.contact && _user.contact.files) {
		delete _user.contact.files
	}

	Sentry.setUser(_user)
}
