import defaultState from './client/index/state'
import getters from './client/index/getters'
import mutations from './client/index/mutations'
import actions from './client/index/actions'

import filterModule from './client/index/filter/index'

const CRM_CLIENTS_ORDERS_V2 = {
	namespaced: true,
	modules: {
		filter: filterModule
	},
	state: defaultState(),
	getters,
	mutations,
	actions
}

export default CRM_CLIENTS_ORDERS_V2
