export default {
	resetSelected ({commit}) {
		commit('SET_MULTI_EDIT_DATA', {
			field: 'selected_ids',
			value: {}
		})
	},
	resetAll ({commit}) {
		commit('SET_MULTI_EDIT_DATA', {
			field: 'is_multi_edit_enabled',
			value: false
		})
		commit('SET_MULTI_EDIT_DATA', {
			field: 'selected_ids',
			value: {}
		})
	},
	onDeselectRow ({commit}, id) {
		commit('DESELECT_ID', id)
	},
	onSelectRow ({state, commit}, {id, code, name}) {
		if (state.selected_ids[`#${id}`]) {
			commit('DESELECT_ID', `#${id}`)
		} else {
		  commit('SELECT_ID', {
		  	id,
				code,
				name
		  })
		}
	},
	selectAllRows ({commit}, data) {
		data.forEach(company => {
			commit('SELECT_ID', {
				id: company.id,
				code: company.code,
				name: company.name
			})
		})
	},
	onSelectRows ({state, commit, getters}, companies = []) {
		const isAlreadySelected = getters.allPageIsSelected(companies)

		if (isAlreadySelected) {
			companies.forEach(company => {
				commit('DESELECT_ID', `#${company.id}`)
			})
		} else {
			companies.forEach(company => {
				const element = state.selected_ids[`#${company.id}`]

				if (!element) {
					commit('SELECT_ID', {
						id: company.id,
						code: company.code,
						name: company.name
					})
				}
			})
		}
	}
}
