import Vue from 'vue'
import paymentForms from '@/config/payment-forms'
import dealApi from '@/api/deal'
import helperApi from '@/api/helper'
import transactionApi from '@/api/transaction'
import transactionTypes from '@/config/transaction-types'

const getState = () => ({
	deal: null,
	paymentForms: [],
	activePaymentForm: null,
	editingTransactionID: null,
	editingDocumentTurnoverID: null,
	editingDeclarationNumber: null,
	editingComment: null,
	editingPhone: null,
	editingAmount: null,
	editingCreditCard: {
		id: null,
		card: null,
		first_name: null,
		middle_name: null,
		last_name: null,
		tax_number: null
	},
	selectedCardFromPayment: null,
	editingTransactionFuel: {
		price_per_liter: null,
		liters: null,
		fuel_type: {
			name: null
		}
	},
	closeCallback: null,
	receivedMethod: null,
	transporter_credit_cards: []
})

const REGISTRY_MODULE = {
	namespaced: true,
	state: getState(),

	getters: {
		isToplyvo: (state, getters) => paymentForms.TOPLYVO_TYPE === getters.getActivePaymentForm,
		isCash: (state, getters) => paymentForms.CASH_TYPE === getters.getActivePaymentForm,
		isSoft: (state, getters) => paymentForms.SOFT_TYPE === getters.getActivePaymentForm,
		isDealIsset: state => state.deal && Object.keys(state.deal).length > 0,
		clientHasNonCashTransaction: (state, getters) => getters.getDeal ? 
			getters.getDeal.transaction.reduce((memo, item) => {

				if (item.type && item.type.id === 1 && !item.payment_form.is_cash) {
					memo++;
				}

				return memo;
			}, 0) > 0 : false,
		getDeal: state => state.deal,
		getPaymentForms: state => state.paymentForms,
		getActivePaymentForm: (state) => state.activePaymentForm,

		// Getters for editable data
		editingTransactionID: state => state.editingTransactionID,
		editingTransactionFuel: state => state.editingTransactionFuel,
		editingDocumentTurnoverID: state => state.editingDocumentTurnoverID,

		getTransactionFuelTypeName: (state, getters) => {
			const fuel = getters.editingTransactionFuel
			if (fuel.fuel_type) {
				return fuel.fuel_type.name
			}

			return null
		},
		getSelectedTransaction: state => {
			if (state.deal) {
				return state.deal.transaction.find(i => i.id === state.editingTransactionID)
			}

			return null
		},
		getLastCreatedTransactionCreditCard: state => {
			if (state.deal && state.deal.transaction && state.deal.transaction.length) {
				return state.deal.transaction[0].credit_card;
			}

			return null;
		},
		// получить все транзакции, которые можно подвесить на оплату
		getRegistryTransporterTransactions: state => {
			if (!state.deal) {
				return []
			}

			return state.deal.transaction.reduce((memo, i) => {
				const transactionIsNew = i.status.id === 1 && i.children.length === 0 && i.parent === null
				const transactionStatusInRequired = i.type.id === 2 || i.type.id === 13

				if (transactionIsNew && transactionStatusInRequired) {
					memo.push({
						text: `${i.type.name}: ${i.amount} грн. 
						${i.payment_form ? i.payment_form.name : ''} ${i.payment_term ? i.payment_term.name : ''}`,
						value: i.id
					})
				}

				return memo;
			}, [])
		},
		// получить все подвешеные транзакции
		getPendingRegistryTransactions: state => {
			if (!state.deal) {
				return []
			}

			return state.deal.transaction.filter(i => i.status.id !== 1 && !i.parent)
		},
		getTransporterTransaction: state => {
			if (!state.deal) {return null}

			return state.deal.transaction
				.filter(i => i.status.id !== 4 && i.parent === null && i.type.id === 2)
		},
		getCountOfTransporterTransactions: (state, getters) => {
			if (!state.deal) {return 0}

			return getters.getTransporterTransaction.length
		},
		transporterHasOnlyOneFreight: (state, getters) => getters.getCountOfTransporterTransactions === 1,
		/**
     * If freight > 0 && one payment
     */
		transporterHasMinimumOnePayment: (state, getters) => {
			if (!getters.getTransporterTransaction) {return false}

			return getters.getTransporterTransaction.length - getters.getTransporterTransaction
				.filter(i => i.children.length > 0).length > 1
		},
		getClientDocumentTurnover: (state, getters) => {
			if (!getters.getDeal) {return null}

			return getters.getDeal.document_turnover.filter(i => i.type === 'client')[0] || null
		},
		getCloseCallback: state => state.closeCallback,
		getDriverCreditCards: (state, getters) => getters.getDeal &&
        getters.getDeal.driver &&
        getters.getDeal.driver.contact &&
        getters.getDeal.driver.contact.credit_cards &&
        getters.getDeal.driver.contact.credit_cards,
		getFreightByTransactionTypeAndPaymentForm: (state) => (type, paymentFormID) => {
			if (!state.deal) {
				return 0
			}

			return state.deal.transaction.reduce((amount, transaction) => {
				if (
					transaction.type && 
          transaction.payment_form && 
          transaction.payment_form.id === paymentFormID && 
          transaction.type.id === type && 
          !transaction.parent
				) {
					amount += +transaction.amount
				}

				return amount
			}, 0)
		},
		getClientFreight: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm
		(transactionTypes.CLIENT_FREIGHT, paymentForms.BEZ_NAL_TYPE_0_NDS),
   
		getClientNdsFreight: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm(
			transactionTypes.CLIENT_FREIGHT, paymentForms.BEZ_NAL_TYPE_WITH_NDS),

		getClientFreightWithoutNds: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm
		(transactionTypes.CLIENT_FREIGHT, paymentForms.BEZ_NAL_TYPE_WITHOUT_NDS),

		getClientFreightAmount: (state, getters) => getters.getClientFreight + 
			getters.getClientNdsFreight + getters.getClientFreightWithoutNds,

		getNdsTransporterFreight: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm
		(transactionTypes.TRANSPORTER_FREIGHT, paymentForms.BEZ_NAL_TYPE_0_NDS),

		getTransporterFreightWithNds: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm
		(transactionTypes.TRANSPORTER_FREIGHT, paymentForms.BEZ_NAL_TYPE_WITH_NDS),

		getTransporterFreightWithoutNds: (state, getters) => getters.getFreightByTransactionTypeAndPaymentForm
		(transactionTypes.TRANSPORTER_FREIGHT, paymentForms.BEZ_NAL_TYPE_WITHOUT_NDS),

		getTransporterFreightAmount:(state, getters) => getters.getNdsTransporterFreight + 
			getters.getTransporterFreightWithNds + getters.getTransporterFreightWithoutNds,

		getReceived: (state) => state.receivedMethod,

		getSelectedCardFromPayment (state) {
			return state.selectedCardFromPayment
		}

	},

	mutations: {
		SAVE_DEAL (state, value) {
			state.deal = value
		},
		SAVE_PAYMENT_FORMS (state, value) {
			state.paymentForms = value
		},
		EDIT_TRANSACTION_COMMENT (state, value) {
			state.editingComment = value
		},
		SET_EDITING_TRANSACTION_ID (state, value) {
			state.editingTransactionID = value
		},
		SET_TOPLYVO_PHONE_NUMBER (state, value) {
			state.editingPhone = value
		},
		SET_CARD_NUMBER (state, value) {
			state.editingCreditCard.card = value
		},
		SET_CARD_ID (state, id) {
			state.editingCreditCard.id = id
		},
		SET_CARD_FROM_TRANSACTION_PAYMENT (state, value) {
			state.selectedCardFromPayment = value
		},
		SET_CARD_FIRST_NAME (state, value) {
			state.editingCreditCard.first_name = value
		},
		SET_CARD_LAST_NAME (state, value) {
			state.editingCreditCard.last_name = value
		},
		SET_CARD_MIDDLE_NAME (state, value) {
			state.editingCreditCard.middle_name = value
		},
		SET_TAX_NUMBER (state, value) {
			state.editingCreditCard.tax_number = value
		},
		EDIT_DEAL_DECLARATION_NUMBER_ID (state, value) {
			state.editingDocumentTurnoverID = value
		},
		EDIT_DEAL_DECLARATION_NUMBER (state, value) {
			state.editingDeclarationNumber = value
		},
		RESET_REGISTRY_DATA (state) {
			const s = getState()
			Object.keys(s).forEach(key => {
				state[key] = s[key]
			})
		},
		SET_ACTIVE_PAYMENT_TYPE (state, type) {
			state.activePaymentForm = type
		},
		SET_TRANSACTION_FUEL_PRICE_PER_LITER (state, pricePerLiter) {
			state.editingTransactionFuel.price_per_liter = pricePerLiter
		},
		SET_TRANSACTION_FUEL_LITERS (state, liters) {
			state.editingTransactionFuel.liters = liters
		},
		SET_TRANSACTION_FUEL_TYPE_NAME (state, name) {
			state.editingTransactionFuel.fuel_type.name = name
		},
		SET_TRANSACTION_FUEL (state, transaction) {
			state.editingTransactionFuel = transaction
		},
		SET_TRANSACTION_AMOUNT (state, amount) {
			state.editingAmount = amount
		},
		SET_CLOSE_CALLBACK (state, value) {
			state.closeCallback = value
		},
		SET_RECEIVING_METHOD (state, value) {
			state.receivedMethod = value
		},
		SET_CARD_DATA (state, {id, card, first_name, middle_name, last_name, tax_number}) {
			Vue.set(state, 'editingCreditCard', {
				id: id,
				card: card,
				first_name: first_name,
				middle_name: middle_name,
				last_name: last_name,
				tax_number: tax_number
			})
		},
		DELETE_CARD_BY_INDEX (state, payload) {
			payload.data.splice(payload.index, 1)
		},
		SET_TRANSPORTER_CARDS (state, payload) {
			state.transporter_credit_cards = payload
		}
	},

	actions: {
		setSelectedData ({ commit, getters }, payload) {
			if (getters.getSelectedTransaction) {
				if (payload && payload.receiving_method) {
					commit('SET_RECEIVING_METHOD', payload.receiving_method)
				}

				commit('SET_TRANSACTION_AMOUNT', getters.getSelectedTransaction.amount)
				commit('SET_ACTIVE_PAYMENT_TYPE', getters.getSelectedTransaction.payment_form.id)
				if (getters.getSelectedTransaction.phone) {
					commit('SET_TOPLYVO_PHONE_NUMBER', getters.getSelectedTransaction.phone.phone)
				}

				let card = null

				if (getters.getDriverCreditCards.length > 0) {
					card = getters.getSelectedCardFromPayment
				} else if (getters.getSelectedTransaction.credit_card) {
					card = getters.getSelectedTransaction.credit_card
				}

				if (card) {
					commit('SET_CARD_DATA', card)
				} else {
					commit('SET_CARD_ID', null)
				}

				if (getters.getSelectedTransaction.transaction_fuel) {
					delete getters.getSelectedTransaction.transaction_fuel.id
					commit('SET_TRANSACTION_FUEL', getters.getSelectedTransaction.transaction_fuel)
				}
			}
		},

		async setDeal ({ commit }, dealId, type = 'transporter') {
			const response = await dealApi.getDealById(dealId)
			const { data } = response

			await commit('SAVE_DEAL', data)
			if (data.document_turnover) {
				const transporterDocumentTurnovers = data.document_turnover.filter(i => i.type === type)
				if (transporterDocumentTurnovers && transporterDocumentTurnovers.length > 0) {
					commit('EDIT_DEAL_DECLARATION_NUMBER', {
						number_declaration: transporterDocumentTurnovers[0].number_declaration
					})
					if (transporterDocumentTurnovers[0].receiving_method) {
						commit('EDIT_DEAL_DECLARATION_NUMBER', {
							receiving_method: transporterDocumentTurnovers[0].receiving_method.id
						})
					}
					commit('EDIT_DEAL_DECLARATION_NUMBER_ID', {
						id: transporterDocumentTurnovers[0].id
					})
				}
			}
			const selectedCard = data.transaction
				.find(i => i.type.id === transactionTypes.TRANSPORTER_FREIGHT && i.credit_card)
			if (selectedCard) {
				commit('SET_CARD_FROM_TRANSACTION_PAYMENT', selectedCard.credit_card)
			}
		},

		async createChildTransaction ({ state, getters }) {
			let paymentFormParams = {}
			let updatedParams = {
				update: {
					id: state.editingTransactionID,
					payment_form: {id: getters.getActivePaymentForm},
					amount: state.editingAmount
				}
			}

			const clonedTransaction = Object.assign(updatedParams, getters.getSelectedTransaction)
			delete clonedTransaction.id
			delete clonedTransaction.parent
			delete clonedTransaction.status

			switch (getters.getActivePaymentForm) {
			case paymentForms.TOPLYVO_TYPE:
				paymentFormParams = {
					phone: {
						phone: state.editingPhone,
						has_toplyvo: true
					}
				}
				break
			case paymentForms.SOFT_TYPE:
				paymentFormParams = {
					credit_card: state.editingCreditCard
				}
				break
			default:
				paymentFormParams = {}
			}

			const payload = Object.assign({}, {
				parent: {
					id: state.editingTransactionID
				}
			},
			{
				put_on_payment_form: true
			},
			clonedTransaction,
			{
				received_method: state.receivedMethod
			},
			paymentFormParams,
			{
				description: state.editingComment,
				amount: state.editingAmount,
				payment_form: { id: getters.getActivePaymentForm }
			})
			const response = await transactionApi.createTransaction(payload)
			const { data } = response

			return data
		},

		async postTransaction ({ getters }, payload) {
			const clonedTransaction = Object.assign({}, getters.getSelectedTransaction)
			delete clonedTransaction.id
			delete clonedTransaction.parent
			delete clonedTransaction.children
			delete clonedTransaction.status

			const newPayload = Object.assign({},
				clonedTransaction,
				payload
			)

			// todo remove deletes
			if (payload._payTransaction) {
				newPayload.transaction_currency = null
				delete newPayload._payTransaction
			}

			try {
				const response = await transactionApi.createTransaction(newPayload)
				const { data } = response

				return data
			} catch (e) {
				this.$crmNotify.warning(e)
			}
		},

		async deleteTransaction (context, id) {
			const response = await transactionApi.deleteTransaction(id)
			const { data } = response

			return data
		},

		async setPaymentForms ({ commit }) {
			const response = await helperApi.getPaymentForms()
			const { data } = response

			commit('SAVE_PAYMENT_FORMS', data.data)
		},

		async getDealAndSetParams ({dispatch}, {deal}) {
			await dispatch('setDeal', deal)
		},

		deleteCardByIndex ({getters, commit, state}, id) {
			const cardIndex = getters.getDriverCreditCards.findIndex(card => card.id === id)
			commit('DELETE_CARD_BY_INDEX', {
				data: state.transporter_credit_cards,
				index: cardIndex
			})
			commit('SET_CARD_DATA', {})
		},

		async getTransporterCreditCards ({ commit, getters }) {
			const {data} = await helperApi.getTransporterCards({
				id: getters.getDeal.id
			})
			commit('SET_TRANSPORTER_CARDS', data)
		}
	}
}

export default REGISTRY_MODULE
