export default {
	SET_DATA (state, data) {
		state.data = data
	},
	SET_PERSONAL_STATS (state, data) {
		state.personalStats = data
	},
	SET_METRICS_LOADING_PROCESSING_FLAG (state, flag) {
		state.processing = flag
	}
}
