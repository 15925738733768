import * as formats from './formats'
import dayjs from 'dayjs'
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import durationPlugin from 'dayjs/plugin/duration'
import isTodayPlugin from 'dayjs/plugin/isToday'
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow'
import ru from 'dayjs/locale/ru'

dayjs.extend(customParseFormatPlugin)
dayjs.extend(durationPlugin)
dayjs.extend(isTodayPlugin)
dayjs.extend(isTomorrowPlugin)

dayjs.locale('ru')

function date (date) {
	return dayjs(date)
}

function dateWithParse (date, parseFormat) {
	return dayjs(date, parseFormat)
}

const getCurrentDate = (format = formats.DEFAULT_DATE_FORMAT) => dayjs().format(format)

const convertDate = (date, format = formats.DEFAULT_DATE_FORMAT, parseFormat = null) => {
	if (date && Number.isInteger(date)) {
		return dayjs(date).format(format)
	}

	const dayjsInstance = parseFormat ? dayjs(date, parseFormat) : dayjs(date)

	return dayjsInstance.format(format)
}

const getDiff = (firstDate, secondDate) => dayjs(firstDate).diff(dayjs(secondDate), 'milliseconds')

const getDurationInMs = (ms) => dayjs.duration(ms).asMilliseconds()

const getTomorrowDate = (format = formats.DEFAULT_DATE_FORMAT) => dayjs(
	new Date().setDate(new Date().getDate() + 1)).format(format)

function add (date, value, key) {
	return dayjs(date).add(value, key)
}
function sub (date, value, key) {
	return dayjs(date).subtract(value, key)
}
function isToday (date) {
	return dayjs(date).isToday()
}
function isTomorrow (date) {
	return dayjs(date).isTomorrow()
}

export {
	date,
	dateWithParse,
	formats,
	getCurrentDate,
	convertDate,
	getDiff,
	getDurationInMs,
	getTomorrowDate,
	add,
	sub,
	isToday,
	isTomorrow
}
