export default {
	CLIENT_FREIGHT: 'client',
	TRANSPORTER_FREIGHT: 'transporter',

	CHECKBOXES_DOC_LIST_IDS: [3, 2, 5],
	RADIOS_DOC_LIST_IDS: [1, 4],

	POST_COST_TRANSPORTER_TRANSACTION_ID: 8,
	POST_COST_CLIENT_TRANSACTION_ID: 7,
	AUTOGENERATED_INVOICE_ID: 7
}
