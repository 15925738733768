import filterState from './filter/state'
import getters from './filter/getters'
import mutations from './filter/mutations'
import actions from './filter/actions'

const CFO_FILTER_MODULE = {
	namespaced: true,
	state: filterState(),
	getters,
	mutations,
	actions
}

export default CFO_FILTER_MODULE
