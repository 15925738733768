export default {
  paymentTerms: [],
  transactionTypes: [],
  postalServiceOptions: [],
  paymentForms: [],
  paymentCondition: [],
  currency: [],
  contacts: [],
  users: [],
  insets: [],
  cargoTypes: [],
  companies: [],
  contactTypes: [],
  companyTypes: [],
  mcPriorities: [],
  dealStatuses: [],
  ourCompanies: [],
  banks: [],
  marineLines: [],
  link_types: [],
  location_types: [],
  industry: [],
  locationCategories: [],
  contactsByRole: [],
  lardiUsers: [],
  companiesByOrderType: [],
  contactsByOrderType: [],
  ordersOptions: [],
  userContact: [],
  userPhones: [],
  roles: [],
  addresses: [],
  dealDepartments: [],
  isFileUploading: false
}
