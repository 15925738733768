import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate/dist/vee-validate.full.esm'
import ru from 'vee-validate/dist/locale/ru'
import luhnAlgorithm from '@/helpers/validators/credit-card-validator'
import seriaAndNumberCheck from '@/helpers/validators/seria-and-number-validator'
localize('ru', ru)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('luhn', {
	validate (value) {
		return luhnAlgorithm(value)
	},
	message: 'Неккоректный номер карты!'
})

extend('seriaAndNumber', {
	validate (value) {
		return seriaAndNumberCheck(value)
	},
	message: 'Введите три буквы и шесть цифр!'
})

extend('isEmpty', value => !value)