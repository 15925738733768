import defaultState from './batch-editing/state'
import mutations from './batch-editing/mutations'
import actions from './batch-editing/actions'

const BATCH_EDITING_MODULE = {
	namespaced: true,
	state: defaultState(),
	mutations,
	actions
}

export default BATCH_EDITING_MODULE
