import Vue from 'vue'
import defaultState from './state'

export default {
	SET_DEAL_SELECTED (state, value = []) {
		Vue.set(state, 'dealSelected', value)
	},
	SET_POSTAL_SERVICE_ID (state, data) {
		state.postal_service_id = data
	},
	SET_INVOICE_CREATION_DATE (state, data) {
		state.client_invoice_creation_date = data
	},
	SET_DOCUMENT_DATE_SEND (state, data) {
		state.client_document_date_send = data
	},
	SET_NOTES_FOR_DOC (state, data) {
		state.notes_for_doc = data
	},
	SET_DOCUMENT_INVOICE_NUMBER (state, data) {
		state.client_document_invoice_number = data
	},
	SET_DOCUMENT_NUMBER_DECLARATION (state, data) {
		state.client_document_number_declaration = data
	},
	SET_HEAD_CHECKBOX (state, data = false) {
		state.headCheckbox = data
	},
	RESET_DOCUMENT_STATE (state) {
		Object.assign(state, defaultState())
	},
	PUSH_ID_TO_SELECTED_DEALS (state, payload) {
		state.dealSelected.push(payload)
	},
	REMOVE_DEAL_ID_FROM_ARRAY (state, index) {
		state.dealSelected.splice(index, 1)
	}
}
