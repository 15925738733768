import authMiddleware from './middleware/auth'
import {registerStore} from '@/mixins/registerStore'
import MC_MODULE from '@/store/modules/mc.module'
import GOOGLE_MAP_MODULE from '@/store/modules/google-map.module'
import CRM_DEAL from '@/store/modules/CRM/deal.module'
import canMiddleware from './middleware/can'

const MC_ROUTER = [
	{
		path: '/monitoring-center/:id?',
		name: 'MONITORING_CENTER',
		component: () => import(/* webpackChunkName: "mc" */ '../views/MC/MonitoringCenterLayout.vue'),
		meta: {
			title: 'Fox - Мониторинг центр',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to monitoring center'])
			]
		},
		beforeEnter (to, from, next) {
			registerStore('CRM_DEAL', CRM_DEAL)
			registerStore('MC_MODULE', MC_MODULE)
			registerStore('GOOGLE_MAP_MODULE', GOOGLE_MAP_MODULE)
			next()
		}
	}
]

export default MC_ROUTER
