export default () => ({
	client_document_date_send: null,
	client_document_invoice_number: null,
	client_document_number_declaration: null,
	client_invoice_creation_date: null,
	postal_service_id: null,
	notes_for_doc: null,
	dealSelected: [],
	headCheckbox: false
})
