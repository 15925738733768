import localStorageKeys from '@/config/local-storage-keys'
import hardcodedFields from '@/store/modules/cfo/hardcodedFields'
import fieldsLocalization from '@/store/modules/cfo/fieldsLocalization'

export default () => ({
	/**
   * List of available modes
   */
	modes: [],
	/**
   * ID of selected mode
   */
	selectedModeID: +localStorage.getItem(localStorageKeys.OPERATING_MODE_ID) || 1,
	/**
   * List of VISIBLE fields, that need to show in table
   */
	fields: (() => {
		let res = []
		try {
			res = JSON.parse(localStorage.getItem(localStorageKeys.CFO_TABLE_FIELDS))

			if (!res) {
				res = Object.keys(hardcodedFields)
			}
		} catch (e) {
			res = Object.keys(hardcodedFields)
		}

		return res
	})(),
	/**
   * List of all columns in table
   */
	listOfFields: Object.freeze(Object.keys(hardcodedFields)),
	/**
   * Localization of table fields
   */
	fieldsLocalization: Object.freeze(hardcodedFields),
	hardcodedFieldsLocalization: Object.freeze(fieldsLocalization)
})
