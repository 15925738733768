<template>
  <button
    :type="type"
    :disabled="disabled"
    class="fox-button"
    :class="{
      ...sizeClasses,
      ...variantClasses,
      'fox-button__block': block,
    }"
  >
    <template v-if="!loading">
      <slot v-if="$slots.icon" name="icon" />
      <slot />
    </template>
    <template v-else>
      <FoxSpinner />
    </template>
  </button>
</template>

<script>
import FoxSpinner from "@/UIKit/components/button/FoxSpinner"
export default {
  name: 'FoxButton',
  components: {
    FoxSpinner
  },
  props: {
    type: {
      required: false,
      default: 'button',
      type: String
    },
    disabled: Boolean,
    size: {
      required: false,
      default: 'lg', // lg, md, sm, xs
      type: String
    },
    loading: Boolean,
    block: Boolean,
    variant: {
      required: false,
      default: 'primary',
      type: String // primary, secondary, outline, link, text
    }
  },
  data () {
    return {
      sizes: ['lg', 'md', 'sm', 'xs'],
      variants: ['primary', 'secondary', 'outline', 'outline-green', 'outline-red', 'system-green', 'system-red', 'link', 'text']
    }
  },
  computed: {
    sizeClasses () {
      return this.sizes.reduce((memo, size) => {
        memo[`fox-button__${size}`] = this.size === size

        return memo
      }, {})
    },
    variantClasses () {
      return this.variants.reduce((memo, variant) => {
        memo[`fox-button__${variant}`] = this.variant === variant

        if (this.variant === variant && this.disabled) {
          memo[`fox-button__${variant}--disabled`] = true
        }

        return memo
      }, {})
    }
  }
}
</script>

<style scoped lang="sass">
@import '../../scss/components/button'
</style>
