<template>

    <div v-if="file" class="vue-image-previewer">
        <img class="vue-image-previewer__image" :src="file_" alt="">
    </div>

</template>

<script>
  export default {
    name: 'VueImagePreview',
    props: {
      file: {
        required: true
      }
    },
    data () {
      return {
        file_: this.handleFile()
      }
    },
    watch: {
      async file () {
        await this.handleFile()
      }
    },
    methods: {
      async handleFile () {
        if (!this.file) return false

        if (!(this.file instanceof File)) {
          this.file_ = this.file
          return true
        }

        const reader = new FileReader()
        const _this = this

        reader.onload = e => {
          _this.file_ = e.target.result
        }
        await reader.readAsDataURL(this.file)
      }
    },
    async mounted() {
      await this.handleFile()
    }
  }
</script>

<style lang="sass">

    .vue-image-previewer
        &__image
            max-width: 100%

</style>
