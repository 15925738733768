import FoxButton from './components/button/FoxButton'
import FoxRadioButton from './components/radio-button/FoxRadioButton'
import FoxCheckbox from './components/checkbox/FoxCheckbox'
import FoxCheckboxGroup from './components/checkbox-group/FoxCheckboxGroup'
import FoxInput from './components/input/FoxInput'

const components = [
  FoxButton,
  FoxRadioButton,
  FoxCheckbox,
  FoxInput,
  FoxCheckboxGroup
]

const UIKit = {
  install (Vue, options) {
    components.forEach(component => Vue.component(component.name, component))
  }
}

export default UIKit
