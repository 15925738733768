import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST

export default {
  getCashierData (params) {
    return axios.get(`${HOST_URL}/cfo/cashier`, {
      params: Object.assign({ 'page-limit': -1 }, params),
    })
  },
  getCashierBalances () {
    return axios.get(`${HOST_URL}/cfo/cashier/balance`)
  },
  postRate (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/rate`, params)
  },
  postExchange (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/exchange`, params)
  },
  postCredit (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/credit`, params)
  },
  postDebit (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/debit`, params)
  },
  postCashierPayment (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/debit/transaction`, params)
  },
  postCashierDeclarationMark (params) {
    return axios.post(`${HOST_URL}/cfo/cashier/number-declaration`, params)
  },
  postDealReceivingInfo (dealID, typeID, transactionID) {
    return axios.post(`${HOST_URL}/set-received-type/${dealID}`, {
      type_id: typeID,
      transaction_id: transactionID
    })
  },
}
