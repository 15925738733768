import * as Sentry from '@sentry/vue'

export default function sentryCaptureError (err) {
	if (!process.env.VUE_APP_SENTRY_DSN) {
		return false
	}
	if (!err) {
		return false
	}

	let payload = err.response.config.data || '{}'

	if (payload instanceof FormData) {
		let obj = {}
		payload.forEach(function (value, key) {
			obj[key] = value;
		})

		payload = JSON.stringify(obj)
	}

	Sentry.setContext('Request', {
		payload: JSON.parse(payload)
	})
	Sentry.setContext('Response', {
		response: JSON.stringify(err.response.data)
	})
	Sentry.captureException(err)

	return true
}
