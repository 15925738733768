import authMiddleware from '../middleware/auth'
import {registerStore} from '@/mixins/registerStore'
import canMiddleware from '../middleware/can'

import TRANSPORT_DEPARTMENT_ANALYTICS from '@/store/modules/analytics/transport-department'

const TRANSPORT_DEPARTMENT_ROUTER = [
	{
		path: '/analytics/transport-department',
		name: 'ANALYTICS.TRANSPORT_DEPARTMENT',
		component: () => import(/* webpackChunkName: "trans-dep-analytics" */ '@/views/Analytics/TransportDepartment'),
		meta: {
			title: 'Доходы & расходы транспортного отдела',
			middleware: [
				authMiddleware,
				canMiddleware.bind(undefined, ['access to analytics transport-department'])
			]
		},
		beforeEnter (to, from, next) {
			registerStore('TRANSPORT_DEPARTMENT_ANALYTICS', TRANSPORT_DEPARTMENT_ANALYTICS)
			next()
		}
	}
]

export default TRANSPORT_DEPARTMENT_ROUTER
