export default {
	FO: 16,
	DRIVER: 8,
	DIRECTOR: 2,
	LOGIST: 4,
	FOP: 13,
	DISPATCHER: 14,
	ACCOUNTANT: 6,
	OWNER: 28,
	ZAM_DIRECTOR: 25,
	HEAD_OF_THE_TRANSPORT_DEPARTMENT: 21,
	HEAD_OF_PURCHASING_DEPARTMENT: 29,
	HEAD_OF_LOGISTICS_DEPARTMENT: 15,
	HEAD_OF_SALES: 3
}
