export default {
  SUPPORT_TYPE: 'support',
  BANNED_TYPE: 'banned',
  DEVICE_ON_APPROVE_TYPE: 'send_agent',
  DEVICE_APPROVING_TYPE: 'is_confirm',
  DEVICE_REJECTED_TYPE: 'rejected',
  PHONE_CODE_SEND_TYPE: 'phone_send_at',
  CONFIRM_PHONE_TYPE: 'confirm_phone',
  CODE_APPROVED_TYPE: 'phone_code_success',
  INCORRECT_VERIFICATION_CODE: 'phone_code_incorrect',
  COMPROMISED_TYPE: 'compromised'
}
