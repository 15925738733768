export default {
	SET_USER (state, user) {
		state.user = user
	},
	SET_TOKEN (state, token) {
		if (token) {
			state.token = token
			localStorage.setItem('token', token)
		} else {
			state.token = null
			localStorage.removeItem('token')
		}
	},
	SET_USER_DATA (state, { user, token }) {
		state.user = user
		state.token = token

		// window.localStorage.setItem('user', JSON.stringify(user))
		localStorage.setItem('token', token)
	},
	LOGOUT_USER (state) {
		state.user = null
		state.token = null

		// window.localStorage.removeItem('user')
		localStorage.removeItem('token')
	},
	SET_LOGOUT_PROCESSING (state, flag = false) {
		state.processingLogout = flag
	}
}
