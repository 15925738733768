export default {
	VEHICLE_AVATAR: 18,
	AVATAR: 20,
	COMPANY_AVATAR: 21,
	TTN: 3,
	CMR: 4,
	BROKEN_ACT: 6,
	CLIENT_ORDER: 9,
	TRANSPORTER_ORDER: 10,
	SCORE: 8,
	INVOICE: 29,
	ACT: 30
}
