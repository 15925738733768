import defaultState from './document-turnover/state'
import getters from './document-turnover/getters'
import mutations from './document-turnover/mutations'
import actions from './document-turnover/actions'

const deal_document_turnover = {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions
}

export default deal_document_turnover
