import Vue from 'vue'
import cashierApi from '@/api/cashier'
import cashierConfig from '@/config/cashier.config'

const cashier = {
  namespaced: true,
  state: {
    data: [],
    rate: 0,
    USD: 0,
    UAH: 0,
    processing: false
  },
  getters: {

  },
  mutations: {
    SET_DATA (state, data) {
      state.data = data
    },
    SET_RATE (state, value) {
      state.rate = value
    },
    SET_USD (state, value) {
      state.USD = value
    },
    SET_UAH (state, value) {
      state.UAH = value
    },
    SET_PROCESSING_FLAG(state, value = false) {
      state.processing = value
    },
    REMOVE_DEAL_BY_ID (state, dealIndex) {
      state.data.splice(dealIndex, 1)
    },
    DISABLE_WILL_PICK_UP_BUTTON (state, dealIndex) {
      /**
       * Vue set used to REACTIVELY set flag and disable button in interface
       */
      Vue.set(state.data[dealIndex], 'has_received_type', true)
    },
  },
  actions: {
    async GET_BALANCES ({commit}, payload = {}) {
      const response = await cashierApi.getCashierBalances(payload)
      const {data} = response

      commit('SET_RATE', data.rate)
      commit('SET_USD', data.USD)
      commit('SET_UAH', data.UAH)
    },

    async GET_DATA ({commit}, payload = {}) {
      const response = await cashierApi.getCashierData(payload)
      const {data} = response

      commit('SET_DATA', data.data)
    },

    async UPDATE_RATE({commit}, payload) {
      const response = await cashierApi.postRate(payload)

      if (response.code === 200) {
        commit('SET_RATE', payload.rate)
      }
    },
    async POST_EXCHANGE({commit}, payload) {
      await cashierApi.postExchange(payload)
    },
    async POST_CREDIT({commit}, payload) {
      await cashierApi.postCredit(payload)
    },
    async POST_DEBIT({commit}, payload) {
      await cashierApi.postDebit(payload)
    },
    async POST_CASHIER_PAYMENT({commit}, payload) {
      await cashierApi.postCashierPayment(payload)
    },
    async POST_DECLARATION_MARK({commit}, payload) {
      await cashierApi.postCashierDeclarationMark(payload)
    },

    async pickUpMethodDecorator ({ state, commit }, {
      id,
      type,
      transactionID
    }) {
      try {
        commit('SET_PROCESSING_FLAG', true)

        const response = await cashierApi.postDealReceivingInfo(id, type, transactionID)

        if (response && response.code) {
          const dealIndex = state.data.findIndex(deal => deal.id === id)

          if (type === cashierConfig.WILL_PICKUP_TYPE) {
            commit('DISABLE_WILL_PICK_UP_BUTTON', dealIndex)
          }
          if (type === cashierConfig.WILL_NOT_PICKUP_TYPE) {
            commit('REMOVE_DEAL_BY_ID', dealIndex)
          }

          return true
        }
      } catch (e) {
        console.error(e)

        this._vm.$crmNotify.error('Ошибка обработки!', e)

        return false
      } finally {
        commit('SET_PROCESSING_FLAG', false)
      }
    },

    async willPickUpDocs ({ dispatch }, {
      dealID,
      transactionID
    }) {
      if (window.confirm(`Вы действительно будете забирать доки по сделке ${dealID}?`)) {
        return dispatch('pickUpMethodDecorator', {
          id: dealID,
          type: cashierConfig.WILL_PICKUP_TYPE,
          transactionID: transactionID
        })
      }
    },
    async willNotPickUpDocs ({ dispatch }, {
      dealID,
      transactionID
    }) {
      if (window.confirm(`Вы действительно не будете забирать доки по сделке ${dealID}?`)) {
        return dispatch('pickUpMethodDecorator', {
          id: dealID,
          type: cashierConfig.WILL_NOT_PICKUP_TYPE,
          transactionID: transactionID
        })
      }
    },
  }
}

export default cashier
