import defaultState from './metrics/state'
import getters from './metrics/getters'
import mutations from './metrics/mutations'
import actions from './metrics/actions'

const metrics = {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions
}

export default metrics
