export default () => ({
	login: null,
	password: null,
	passwordType: 'password',
	passwordTypes: {
		text: 'text',
		password: 'password'
	},
	loading: false
})
