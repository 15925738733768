import Vue from 'vue'
import transportAnalyticsApi from '@/api/analytics/transport-department'

const filterState = () => ({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1
})

const TRANSPORT_DEPARTMENT_ANALYTICS = {
  namespaced: true,
  state: {
    filter: filterState(),
    response: {},
    processing: false,
    categoriesProcessing: false,
    categories: []
  },
  getters: {
    getFilterState: state => state.filter,
    getProcessingFlag: state => state.processing,
    getCategoriesProcessing: state => state.categoriesProcessing,
    /**
     * Amounts
     */
    getAllTotalsKey: state => key => {
      const response = state.response
      if (response && response[key]) {
        return +response[key].all_totals
      }

      return 0
    },
    getExpensesAmount: (state, getters) => {
      return (getters.getAllTotalsKey('drivers_expenses') +
        getters.getAllTotalsKey('contractors_expenses')).toFixed(2)
    },
    getTotalsAmount: (state) => {
      return +state.response.total || 0
    },

    getKeyData: state => key => {
      const response = state.response
      if (response && response[key]) {
        return response[key]
      }

      return []
    },

    getCategories: state => state.categories,

    years: () => [
      {
        value: 2019,
        label: 2019
      },
      {
        value: 2020,
        label: 2020
      },
      {
        value: 2021,
        label: 2021
      }
    ],
    months: () => [
      {
        value: 1,
        label: 'Январь'
      },
      {
        value: 2,
        label: 'Февраль'
      },
      {
        value: 3,
        label: 'Март'
      },
      {
        value: 4,
        label: 'Апрель'
      },
      {
        value: 5,
        label: 'Май'
      },
      {
        value: 6,
        label: 'Июнь'
      },
      {
        value: 7,
        label: 'Июль'
      },
      {
        value: 8,
        label: 'Август'
      },
      {
        value: 9,
        label: 'Сентябрь'
      },
      {
        value: 10,
        label: 'Октябрь'
      },
      {
        value: 11,
        label: 'Ноябрь'
      },
      {
        value: 12,
        label: 'Декабрь'
      }
    ]
  },
  mutations: {
    SET_FILTER_DATA (state, payload) {
      state.filter[payload.field] = payload.value
    },
    SET_GLOBAL_DATA (state, payload) {
      state[payload.field] = payload.value
    },
    SET_EDITED_DRIVER_WORK_DAYS (state, payload) {
      Vue.set(state.response.drivers_stats.data[payload.index],
        'custom_set_working_days', payload.value)
    },
    SET_DRIVER_EXPENSES_DATA (state, data) {
      Vue.set(state.response.drivers_expenses, 'drivers', data)
    }
  },
  actions: {
    async fetchTransportDepartmentStats ({ getters, commit }) {
      try {
        commit('SET_GLOBAL_DATA', {
          field: 'processing',
          value: true
        })

        const { data } = await transportAnalyticsApi.getTransportDepartmentStatus(
          {
            month: getters.getFilterState.month,
            year: getters.getFilterState.year
          })

        commit('SET_GLOBAL_DATA', {
          field: 'response',
          value: data
        })
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_GLOBAL_DATA', {
          field: 'processing',
          value: false
        })
      }
    },
    async fetchCostCategories ({ commit, getters }) {
      try {
        commit('SET_GLOBAL_DATA', {
          field: 'categoriesProcessing',
          value: true
        })

        const { data } = await transportAnalyticsApi.getCostCategories({
          month: getters.getFilterState.month,
          year: getters.getFilterState.year
        })

        commit('SET_GLOBAL_DATA', {
          field: 'categories',
          value: data
        })
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_GLOBAL_DATA', {
          field: 'categoriesProcessing',
          value: false
        })
      }
    }
  }
}

export default TRANSPORT_DEPARTMENT_ANALYTICS
