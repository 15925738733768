import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  getTransportDepartmentStatus(payload = {}) {
    return axios.get(
      `${HOST_URL}/transport-department-stats`, {
        params: payload,
      })
  },
  getCostCategories (payload = {}) {
    return axios.get(
      `${HOST_URL}/transport-department-cost-categories`, {
        params: payload,
      })
  },
  postCostCategories (payload = {}) {
    return axios.post(
      `${HOST_URL}/transport-department-cost-categories`, payload)
  },
  saveDriverWorkDays (payload = {}) {
    return axios.post(
      `${HOST_URL}/transport-department-working-days`, payload)
  },
  saveDriverExpense (payload = {}) {
    return axios.post(
      `${HOST_URL}/transport-department-expenses`, payload)
  },
  getDriverPercentsData (payload = {}) {
    return axios.get(
      `${HOST_URL}/driver-percents`, {
        params: payload,
      })
  },
  postDriverPercentsData (payload = {}) {
    return axios.post(`${HOST_URL}/driver-percents`, payload)
  },
}
