import turnoverConfig from '@/config/document-turnover-config'
import { DEFAULT_DOCUMENT_STATE } from '@/store/modules/document-turnover/state'

export default {
	getStateDocument: state => state.document,
	getGlobalState: state => state,
	getClientGetDocsDate: (state) => state.choosedType === turnoverConfig.CLIENT_FREIGHT
		? state.document.client_get_docs_date
		: null,
	getTransporterDate: (state) => state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT
		? state.document.date
		: null,
	getGetDocsDate: (state) => state.document.client_get_docs_date,

	getTransactionOptions: (state) => state.documents.reduce((memo, i) => {
		if (state.choosedType && i.type === state.choosedType) {
			// add text label of selected document type
			let optionLabel = state.choosedType === turnoverConfig.CLIENT_FREIGHT
				? 'Отправка'
				: 'Получение'
			// add item ID if exists
			optionLabel += ` № ${i.id};`
			// add timestamp
			optionLabel += ` ${i.created_at}`

			memo.push({
				id: i.id,
				type: optionLabel
			})
		}

		return memo
	}, []),
	getSelectedDocumentInstance: (state) => {
		const activeTransaction = state.documents.find(d => d.id === state.activeTransaction)

		return state.activeTransaction && activeTransaction ? activeTransaction : DEFAULT_DOCUMENT_STATE()
	},
	getClientGetDocsContact: state => state.document ? state.document.client_get_docs_contact : null,
	getClientFiles: (state) => state.clientFiles.filter(f => f.document_type && f.document_type.id === 9),
	getRequiredListItems: (state) => (key = []) => state.list_doc.reduce((memo, i) => {
		if (key.includes(i.id)) {
			memo.push(i)
		}

		return memo
	}, []),
	getListCheckboxes: (state, getters) => getters.getRequiredListItems(turnoverConfig.CHECKBOXES_DOC_LIST_IDS),
	getListRadios: (state, getters) => getters.getRequiredListItems(turnoverConfig.RADIOS_DOC_LIST_IDS),
	getReceivingMethodName: state => state.document.receiving_method ? state.document.receiving_method.name : null,
	getReceivingMethodId: state => state.document.receiving_method ? state.document.receiving_method.id : null,

	getRadioList: state => {
		if (state.document && state.document.list_docs) {
			return state.document.list_docs
				.filter(r => turnoverConfig.RADIOS_DOC_LIST_IDS.includes(r.id))
				.map(r => r.id).pop()
		}

		return null

	},
	getCheckboxesList: (state) => {
		const list = []

		if (state.document && state.document.list_docs) {
			state.document.list_docs.reduce((memo, i) => {
				if (turnoverConfig.CHECKBOXES_DOC_LIST_IDS.includes(i.id)) {
					memo.push(i.id)
				}

				return memo
			}, list)
		}

		return list
	},
	isClientFreight (state) {
		return state.choosedType === turnoverConfig.CLIENT_FREIGHT
	},
	isTransporterFreight (state) {
		return state.choosedType === turnoverConfig.TRANSPORTER_FREIGHT
	}
}
