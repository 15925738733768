import axios from 'axios'

const HOST_URL = process.env.VUE_APP_API_HOST
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  getTransactionsDashboardData(payload = {}) {
    return axios.get(`${HOST_URL}/transactions-dashboard`, {
      params: payload
    })
  },
  updateFinanceStatus (payload) {
    return axios.post(`${HOST_URL}/transaction/batch-editing`, payload)
  },
  createTransaction(payload = {}) {
    return axios.post(`${HOST_URL}/v${API_VERSION}/transaction`, payload)
  },
  deleteTransaction(id) {
    return axios.delete(`${HOST_URL}/v${API_VERSION}/transaction/${id}`)
  },
}
